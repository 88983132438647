import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Input,
    UncontrolledTooltip,
    CardSubtitle,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem, NavLink, TabContent, ButtonDropdown
} from "reactstrap";
import ReactTable from "react-table";
import {connect} from 'react-redux'
import _, {get} from 'lodash';
// For auto registry purpose
import './redux/lavoratori.reducer';
import * as Commands from './redux/lavoratori.commands';
import Fade from "react-reveal/Fade";
import moment from 'moment';
import UpdateTags from "./components/lavoratori.modifica.tags.component";
import ManageAbsences from "./components/lavoratori.gestisci.assenze.component";
import Sms from "./components/lavoratori.sms.component";
import ReactTableFilterSelectComponent from "../../../components/ReactTableFilter/reactTable.filter.select.component";

import * as CommandsDocument from '../documents/redux/document.commands';


class LavoratoriComponent extends Component {

    state = {
        data: [],
        loading: false,
        showUpdateTags: false,
        showManageAbsencesModal: false,
        showSmsModal: false,
        selectedRecordForUpdate: {},
        absences: [],
        filters: {}
    };


    caricaDati = async () => {
        this.setState({loading: true});
        await Commands.OttieniLavoratoriValidi();
        this.setState({loading: false});
    };


    componentDidMount() {
        this.caricaDati();
        this.setState({
            tabName: "list"
        });
    };

    toggleshowUpdateTags = (record) => {
        this.setState({
            showUpdateTags: !this.state.showUpdateTags,
            selectedRecordForUpdate: record
        })
    };

    toggleShowManageAbsencesModal = () => {
        this.setState({
            showManageAbsencesModal: !this.state.showManageAbsencesModal,
        })
    };
    toggleShowSms = () => {
        this.setState({
            showSmsModal: !this.state.showSmsModal,
        })
    };

    changeActiveTab = (e, tabName) => {

        e.preventDefault();
        this.setState({
            tabName
        });
    };


    dataTableIds = () => {
        const wrappedInstance = this.checkboxTable;

        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance ? wrappedInstance.getResolvedState().sortedData : this.props.data;
        const data = currentRecords.map(item => item._original.id);
        return data;
    };

    mobNumbers = () => {
        const wrappedInstance = this.checkboxTable;

        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance ? wrappedInstance.getResolvedState().sortedData : this.props.data;
        const data = currentRecords.map(item => item._original.telefono);
        return data;
    };


    actions = (cellInfo) => (

        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <UncontrolledDropdown>
                <DropdownToggle
                    caret
                    className="btn-link btn-icon"
                    color="default"
                    data-toggle="dropdown"
                    type="button"
                >
                    <i className="tim-icons icon-settings-gear-63"/>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem
                        onClick={async e => {
                            e.preventDefault();
                            CommandsDocument.ImportGescoopDocument(cellInfo.original.id);
                        }}
                    >
                        Importa documenti da gescoop
                    </DropdownItem>
                    <DropdownItem
                        onClick={async e => {
                            e.preventDefault();
                            this.toggleFinestraAssegnazioni(cellInfo.original);
                        }}
                    >
                        Modifica metas
                    </DropdownItem>
                    <DropdownItem

                        onClick={async e => {
                            e.preventDefault();
                            this.toggleshowUpdateTags(cellInfo.original);
                        }}

                    >
                        Modifica tags
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );


    render() {
        console.log('@@RENDER ON TOP');
        const getColumnWidth = (accessor, headerText) => {
            let {data} = this.state;
            let max = 0;
            const maxWidth = 400;
            const magicSpacing = 18;

            for (var i = 0; i < data.length; i++) {
                if (data[i] !== undefined && data[i][accessor] !== null) {
                    if (JSON.stringify(data[i][accessor] || 'null').length > max) {
                        max = JSON.stringify(data[i][accessor] || 'null').length;
                    }
                }
            }

            return Math.min(maxWidth, Math.max(max, headerText.length) * magicSpacing);
        };


        return (
            <>


                {
                    this.state.showUpdateTags ? <UpdateTags {...this.state.selectedRecordForUpdate}
                                                            show={this.state.showUpdateTags}
                                                            toggle={this.toggleshowUpdateTags}/> : null
                }

                {
                    this.state.showSmsModal ? <Sms show={this.state.showSmsModal}
                                                   toggle={this.toggleShowSms}
                                                   mobNumbers={this.mobNumbers()}/> : null
                }

                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <Input
                                                type="search"
                                                name="search"
                                                id="exampleSearch"
                                                placeholder="Ricerca lavoratori..."
                                            /> </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>


                        <Card>
                            <CardBody>
                                <Nav className="nav-pills-info" pills>
                                    <NavItem>
                                        <NavLink
                                            data-toggle="tab"
                                            className={
                                                this.state.horizontalTabs === "list" ? "active" : ""
                                            }
                                            onClick={e =>
                                                this.changeActiveTab(e, "list")
                                            }
                                        >
                                            Elenco
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            data-toggle="tab"
                                            className={
                                                this.state.horizontalTabs === "absences" ? "active" : ""
                                            }
                                            onClick={e =>
                                                this.changeActiveTab(e, "absences")
                                            }
                                        >
                                            Assenze
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent
                                    className="tab-space"
                                    activeTab={this.state.horizontalTabs}
                                >
                                    {this.state.tabName === 'list' && <>
                                        <Col xs={12} md={12}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle tag="h4">Elenco lavoratori</CardTitle>
                                                    <Fade when={this.state.loading}>
                                                        <CardSubtitle>Caricamento in corso <i
                                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                                    </Fade>
                                                    <Col className="d-flex justify-content-end">
                                                        <button onClick={() => this.toggleShowSms()}
                                                                className="btn btn-primary">SMS
                                                        </button>

                                                        <button id="refresh" onClick={() => {
                                                            this.caricaDati();
                                                        }} className="btn btn-primary btn-fab btn-icon ">
                                                            <i className="tim-icons icon-refresh-01"></i>
                                                        </button>
                                                        <UncontrolledTooltip placement="bottom" target="refresh"
                                                                             delay={0}>
                                                            Ricarica i dati
                                                        </UncontrolledTooltip>
                                                    </Col>

                                                    {/*            <Col className="mr-auto">
                                                            <button className="btn btn-primary " id="nuovoModal"
                                                                    onClick={() => this.toggleShowManageAbsencesModal()}><i
                                                                className="tim-icons icon-simple-add"/>Gestisci assenze
                                                            </button>
                                                        </Col>*/}
                                                </CardHeader>
                                                <CardBody>
                                                    <ReactTable
                                                        data={this.props.data}
                                                        ref={r => (this.checkboxTable = r)}

                                                        filterable
                                                        columns={[
                                                            {
                                                                Header: 'Id',
                                                                accessor: 'id',
                                                                width: getColumnWidth('id', 'Id'),
                                                                show: false,
                                                            },
                                                            {
                                                                Header: 'Nome',
                                                                accessor: 'nome',
                                                                width: 200,
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.nome);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'nome';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.sortBy(this.props.data, [filterName])}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }
                                                            },
                                                            {
                                                                Header: 'Cognome',
                                                                accessor: 'cognome',
                                                                width: 200,
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.cognome);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'cognome';

                                                                    return <ReactTableFilterSelectComponent

                                                                        closeMenuOnSelect={false}

                                                                        options={_.sortBy(this.props.data, [filterName])}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Cod. Fiscale',
                                                                accessor: 'codiceFiscale',
                                                                width: getColumnWidth('codiceFiscale', 'Cod. Fiscale'),
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.codiceFiscale);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'codiceFiscale';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.sortBy(this.props.data, [filterName])}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Coop',
                                                                accessor: 'cooperativaAppartenenza',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.cooperativaAppartenenza);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'cooperativaAppartenenza';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.sortBy(this.props.data, [filterName])}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Matricola',
                                                                accessor: 'matricola',

                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.matricola);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'matricola';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.sortBy(this.props.data, [filterName])}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Metas',
                                                                accessor: 'metas',
                                                                filterable: false,
                                                                Cell: row => (`${JSON.stringify(row.value)}`)
                                                            },
                                                            {
                                                                Header: 'Tags',
                                                                accessor: 'tags',
                                                                filterable: false,
                                                            },
                                                            {
                                                                Header: 'Assunzione',
                                                                accessor: 'dataAssunzione',
                                                                Cell: row => (`${moment.unix(row.value).format("DD/MM/YY")}`)
                                                            },
                                                            {
                                                                Header: 'Scad. contratto',
                                                                accessor: 'dataScadenzaContratto',
                                                                Cell: row => (`${moment.unix(row.value).format("DD/MM/YY")}`)
                                                            },
                                                            {
                                                                Header: 'Scad. visita med.',
                                                                accessor: 'dataScadenzaVisitaMedica',
                                                                Cell: row => (`${moment.unix(row.value).format("DD/MM/YY")}`)
                                                            },
                                                            {
                                                                Header: 'Residenza',
                                                                colId: 'residenza',
                                                                accessor: 'residenza',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.residenza);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'residenza';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Num. Badge',
                                                                colId: 'numeroBadge',
                                                                accessor: 'numeroBadge',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.numeroBadge);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'numeroBadge';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Ore contratto',
                                                                colId: 'oreContratto',
                                                                accessor: 'oreContratto',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.oreContratto);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'oreContratto';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Telefono',
                                                                colId: 'telefono',
                                                                accessor: 'telefono',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.telefono);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'telefono';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Mansioni',
                                                                colId: 'mansioni',
                                                                accessor: 'mansioni',
                                                                filterable: false,

                                                            },
                                                            {
                                                                Header: 'Cantiere abituale',
                                                                colId: 'luogoPartenza',
                                                                accessor: 'luogoPartenza',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.luogoPartenza);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'luogoPartenza';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'IBAN',
                                                                colId: 'iban',
                                                                accessor: 'iban',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.iban);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'iban';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Intestatario CC',
                                                                colId: 'intestatarioConto',
                                                                accessor: 'intestatarioConto',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.intestatarioConto);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'intestatarioConto';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Email',
                                                                colId: 'email',
                                                                accessor: 'email',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.email);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'email';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },
                                                            {
                                                                Header: 'Email PEC',
                                                                colId: 'emailPec',
                                                                accessor: 'emailPec',
                                                                filterMethod: (filter, row) => {
                                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                                    return filter.value.some(f => f.value === row.emailPec);
                                                                },
                                                                Filter: ({onChange}) => {
                                                                    const filterName = 'emailPec';

                                                                    return <ReactTableFilterSelectComponent
                                                                        options={_.uniq(_.sortBy(this.props.data, [filterName]))}
                                                                        optionValue={filterName}
                                                                        optionLabel={filterName}
                                                                        filterName
                                                                        value={this.state.filters[filterName]}
                                                                        onChange={(value) => this.setState({
                                                                            filters: {
                                                                                ...this.state.filters,
                                                                                [filterName]: value
                                                                            }
                                                                        }, () => onChange(value))}
                                                                    />
                                                                }

                                                            },

                                                            {
                                                                Header: "",
                                                                Cell: this.actions,
                                                                className: 'showDropDown',
                                                                sortable: false,
                                                                filterable: false
                                                            }
                                                        ]}
                                                        defaultPageSize={10}
                                                        showPaginationBottom={true}
                                                        className="-striped -highlight"
                                                        nextText={'Avanti'}
                                                        previousText={'Indietro'}
                                                        pageText={'Pagina'}
                                                        ofText={'di'}
                                                        rowsText={'elementi'}
                                                        noDataText={'Nessun dato'}
                                                        loadingText={'Caricamento in corso'}
                                                        loading={false}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </>}

                                    {this.state.tabName === 'absences' && <>
                                        <ManageAbsences show={this.state.showManageAbsencesModal}
                                                        toggle={this.toggleShowManageAbsencesModal}/>
                                    </>}
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    const data = {
        data: get(state, "lavoratori.data", []),
        loading: get(state, "lavoratori.loading", false)
    };


    return data;
};


export default connect(mapStateToProps, () => ({}))(LavoratoriComponent)

