import React, {Component} from 'react';
import FirmaOrdiniMailModal from "views/rai/ordini/components/ordini.firma.mail.modal";
import queryString from "query-string";

class FirmaOrdiniMail extends Component {

    state = {
        error: false,
        loading: true,
        firmato: false,
        errDescr: '',
        totRes: 0
    }

    toggle = (firmato) => {
        this.setState({firmato});
    }

    render() {

        if (this.state.error) {
            return (
                <div className={"firmaSms"}>
                    {this.state.errDescr}
                </div>);
        }

        if (this.state.firmato) {
            return (
            <div className={"firmaSms"}>
                Ordini firmati con successo
            </div>
        );}

        if (this.state.firmato) {
            return (
                <div className={"firmaSms"}>
                    Ordine firmato con successo
                </div>
            );
        }

        return (
            <div>
                <FirmaOrdiniMailModal toggle={this.toggle} token={queryString.parse(this.props.location.search).token}
                                show={true}/>
            </div>
        );
    }
}

export default FirmaOrdiniMail;
