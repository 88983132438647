import React, {Component} from 'react';

import {Col, Row} from "reactstrap";

export default class SetupMezziComponent extends Component {


    render() {

        const hostname = window.location.hostname

       return (
            <div className="content">
                <Row>
                    <Col xs={12} md={12} style={{
                        marginBottom: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <a href={"https://" + hostname + "/setupmezzi/chrome79.apk"}>Chrome 79</a>
                        <p/>
                        <a href={"https://" + hostname + "/setupmezzi/com.mendhak.gpslogger_103_apps.evozi.com.apk"}>GPS Logger APK</a>
                        <p/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FW657DT.properties"}>Configurazione FW657DT</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FW659DT.properties"}>Configurazione FW659DT</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FW660DT.properties"}>Configurazione FW660DT</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FW661DT.properties"}>Configurazione FW661DT</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FY714SD.properties"}>Configurazione FY714SD</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FY715SD.properties"}>Configurazione FY715SD</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/GA096CH.properties"}>Configurazione GA096CH</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/GA097CH.properties"}>Configurazione GA097CH</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FC854SZ.properties"}>Configurazione FC854SZ</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FC855SZ.properties"}>Configurazione FC855SZ</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FC860SZ.properties"}>Configurazione FC860SZ</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FR890EF.properties"}>Configurazione FR890EF</a><br/>
                        <a href={"gpslogger://properties/https://" + hostname + "/setupmezzi/FR891EF.properties"}>Configurazione FR891EF</a><br/>
                    </Col>
                </Row>
            </div>
        );
    }
}


