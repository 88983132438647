import openSocket from 'socket.io-client';
import store from 'store/store';
let Peer = require('simple-peer')
let client = {};
// let clientViewer = {};

const SIGNALING_SERVER = process.env.REACT_APP_SIGNALING_SERVER;

export const  Request = () =>  {
    const role = 'emitter';
    const socket = openSocket(SIGNALING_SERVER);
    const username = store.getState().login.user.username;
    const roomName = 'view-'+ username;

    socket.emit('NewRoom', {roomName, username});
    console.log("CALLED REQUEST ASSISTANCE");


    navigator.mediaDevices.getDisplayMedia({ video: true, audio: true }).then(stream => gotMedia(stream)).catch( () => alert("Devi prima dare i permessi di condivisione dal broswer."));

    function gotMedia (stream) {
        function InitPeer(type) {
            console.log(role, '::InitPeer');

            let peer = new Peer({ initiator: (type === 'init') ? true : false, stream: stream, trickle: false})
            peer.on('stream', function (stream) {
                console.log(role, '::Received on stream');
                CreateVideo(stream)
            });

            peer.on('data', function (data) {
                console.log(role, '::Received on data');

                new TextDecoder('utf-8').decode(data);
            })
            return peer
        }
        function MakePeer() {
            console.log(role, '::Received on CreatePeer');
            client.gotAnswer = false;
            let peer = InitPeer('init');
            peer.on('signal', function (data) {
                console.log(role, '::Received signal');
                if (!client.gotAnswer) {
                    console.log(role, '::Sending offer signal');
                    socket.emit('Offer', data, roomName)
                }
            })
            client.peer = peer;
        }

        //for peer of type not init

        function SignalAnswer(answer) {
            console.log(role, '::Received SignalAnswer');

            client.gotAnswer = true;
            let peer = client.peer;
            peer.signal(answer)
        }

        function CreateVideo(stream) {

            let video = document.createElement('video');
            video.id = 'peerVideo';
            video.srcObject = stream
            video.setAttribute('class', 'embed-responsive-item')
            document.querySelector('#peerDiv').appendChild(video);
            video.play()
            //wait for 1 sec
        }

        function SessionActive() {
            document.write('Session Active. Please come back later')
        }

        function RemovePeer() {
            if (client.peer) {
                client.peer.destroy()
                client.gotAnswer = false;
            }
            alert("Non c'è più nessuno a guardare la condivisione dello schermo");
        }

        socket.on('BackAnswer', SignalAnswer)
        socket.on('SessionActive', SessionActive)
        socket.on('CreatePeer', MakePeer)
        socket.on('UserLeftRoom', RemovePeer)
    }
};

export const  AnswerToRequest = (roomName) =>  {
        console.log("CALLED ANSWER ASSISTANCE");
        const role = 'viewwer';

        const socket = openSocket(SIGNALING_SERVER);
        socket.emit('JoinRoom', roomName);

        function InitPeer() {
            console.log(role, '::InitPeer');

            let peer = new Peer({ initiator: false, trickle: false})
            peer.on('stream', function (stream) {
                console.log(role, '::Received on stream');
                CreateVideo(stream)
            });

            peer.on('data', function (data) {
                console.log(role, '::Received on data');

                let decodedData = new TextDecoder('utf-8').decode(data)
                let peervideo = document.querySelector('#peerVideo')
                peervideo.style.filter = decodedData
            })
            return peer
        }

        //for peer of type not init
        function FrontAnswer(offer) {
            console.log(role, '::Received FrontAnswer');

            let peer = InitPeer();
            peer.on('signal', (data) => {
                console.log(role, '::Received signal');
                socket.emit('Answer', data,roomName)
            });

            peer.signal(offer);
            client.peer = peer
        }

    function CreateVideo(stream) {

            let video = document.createElement('video')
            video.id = 'peerVideo'
            video.srcObject = stream
            video.setAttribute('class', 'embed-responsive-item')
            document.querySelector('#peerDiv').appendChild(video)
            video.play()
            //wait for 1 sec

            video.addEventListener('click', () => {
                if (video.volume !== 0)
                    video.volume = 0
                else
                    video.volume = 1
            })

        }

        function SessionActive() {
           alert('La richiesta è già in visualizzazione');
        }

    function RemovePeer() {
            console.log(role, '::Received RoomMasterLeft');

            document.getElementById("peerVideo").remove();

            if (client.peer) {
                client.peer.destroy()
            }
            alert("L'interlocutore ha interrotto la condivisione");
            socket.disconnect();
        }

        socket.on('BackOffer', FrontAnswer)
        socket.on('SessionActive', SessionActive)
        socket.on('RoomMasterLeft', RemovePeer)

};
