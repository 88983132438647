import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from "./servizi.types";
import * as actions from "./servizi.actions";

export function Crea(
  voce,
  descrizione,
  contratto,
  lotto,
  tipologia,
  prezzoVendita,
  prezzoAcquisto,
  oreModulo,
  numeroAgenti,
  daKm,
  aKm
) {
  var jsonExtra = {
    lotID: lotto.id,
    voce: voce,
    type: tipologia.description,
    contractID: contratto.id,
    contractDescription: contratto.description,
  };

  if (tipologia.description.toLowerCase() === "modulo") {
    jsonExtra.moduleHours = oreModulo.toString();
    jsonExtra.moduleWorkers = numeroAgenti.toString();
  }

  if (tipologia.description.toLowerCase() === "modulo-trasporto") {
    jsonExtra.moduleHours = oreModulo.toString();
    jsonExtra.moduleWorkers = numeroAgenti.toString();
    jsonExtra.daKm = daKm.toString();
    jsonExtra.aKm = aKm.toString();
  }

  var variables = {
    description: descrizione,
    buyingPrice: prezzoAcquisto,
    sellingPrice: prezzoVendita,
    contractID: contratto.id,
    jsonExtra: JSON.stringify(jsonExtra),
  };

  let query = {
    fetchPolicy: "no-cache",
    variables: variables,
    mutation: gql`
      mutation create(
        $code: String
        $description: String
        $buyingPrice: Float
        $sellingPrice: Float
        $jsonExtra: String
        $contractID: String
      ) {
        createdObject: createArticle(
          buyingPrice: $buyingPrice
          sellingPrice: $sellingPrice
          description: $description
          jsonExtra: $jsonExtra
          contractID: $contractID
          code: $code
        ) {
          code
          buyingPrice
          description
          id
          sellingPrice
          jsonExtra
          contractID
        }
      }
    `,
  };

  return Client.mutate(query)
    .then(async (response) => {
      var data = response.data.createdObject;

      if (process.env.NODE_ENV === "development") {
        data = JSON.parse(JSON.stringify(data));
      }

      store.dispatch(actions.created(data));

      return {
        hasError: false,
      };
    })
    .catch((e) => {
      var message = "Impossibile creare il lotto";
      console.error(e);

      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        message = e.graphQLErrors[0].message;
      }

      return {
        hasError: true,
        message: message,
      };
    });
}

export function OttieniDati() {
  store.dispatch({
    type: types.REQUEST_READ_ALL,
  });

  let query = {
    fetchPolicy: "network-only",
    query: gql`
      query {
        objectList: articles {
          code
          buyingPrice
          sellingPrice
          description
          id
          jsonExtra
          contractID
        }
      }
    `,
  };
  return Client.query(query)
    .then(async (response) => {
      var data = response.data.objectList;

      store.dispatch({
        type: types.READED_ALL,
        payload: data,
      });

      return;
    })
    .catch((e) => {
      var message = "Impossibile ottenere i servizi";
      console.error(e);

      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        message = e.graphQLErrors[0].message;
      }

      store.dispatch({
        type: types.READ_ALL_ERROR,
      });

      return {
        hasError: true,
        message: message,
      };
    });
}

export function Aggiorna(
  id,
  voce,
  descrizione,
  contratto,
  lotto,
  tipologia,
  prezzoVendita,
  prezzoAcquisto,
  oreModulo,
  numeroAgenti,
  daKm,
  aKm
) {
  var jsonExtra = {
    lotID: lotto.id,
    voce: voce,
    type: tipologia.description,
    contractID: contratto.id,
    contractDescription: contratto.description,
  };

  if (tipologia.description === "Modulo") {
    jsonExtra.moduleHours = oreModulo.toString();
    jsonExtra.moduleWorkers = numeroAgenti.toString();
  }

  if (tipologia.description.toLowerCase() === "modulo-trasporto") {
    jsonExtra.moduleHours = oreModulo.toString();
    jsonExtra.moduleWorkers = numeroAgenti.toString();
    jsonExtra.daKm = daKm.toString();
    jsonExtra.aKm = aKm.toString();
  }

  var variables = {
    id,
    description: descrizione,
    buyingPrice: prezzoAcquisto,
    sellingPrice: prezzoVendita,
    contractID: contratto.id,
    jsonExtra: JSON.stringify(jsonExtra),
  };

  let query = {
    fetchPolicy: "no-cache",
    variables: variables,
    mutation: gql`
      mutation update(
        $id: String!
        $code: String
        $description: String
        $buyingPrice: Float
        $sellingPrice: Float
        $jsonExtra: String
        $contractID: String
      ) {
        updatedObject: updateArticle(
          id: $id
          code: $code
          buyingPrice: $buyingPrice
          sellingPrice: $sellingPrice
          description: $description
          jsonExtra: $jsonExtra
          contractID: $contractID
        ) {
          id
          code
          buyingPrice
          description
          sellingPrice
          jsonExtra
        }
      }
    `,
  };

  return Client.mutate(query)
    .then(async (response) => {
      var data = response.data.updatedObject;

      store.dispatch(actions.updated(data));

      return {
        hasError: false,
      };
    })
    .catch((e) => {
      var message = "Impossibile aggiornare il lotto";
      console.error(e);

      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        message = e.graphQLErrors[0].message;
      }
      return {
        hasError: true,
        message: message,
      };
    });
}

export function EliminaLotto(id) {
  var variables = { id };

  let query = {
    fetchPolicy: "no-cache",
    variables: variables,
    mutation: gql`
      mutation ($id: String!) {
        deleted: deleteArticle(id: $id) {
          id
        }
      }
    `,
  };

  store.dispatch(actions.requestDelete(id));

  return Client.mutate(query)
    .then(async (response) => {
      var data = response.data.deleted;

      store.dispatch(actions.deleted(data));

      return {
        hasError: false,
      };
    })
    .catch((e) => {
      var message = "Impossibile eliminare il lotto";
      console.error(e);
      store.dispatch(actions.deleteError(id));

      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        message = e.graphQLErrors[0].message;
      }

      return {
        hasError: true,
        message: message,
      };
    });
}

export function SyncPrices(ids) {
  var variables = { ids };

  let query = {
    fetchPolicy: "no-cache",
    variables: variables,
    mutation: gql`
      mutation ($id: [String!]) {
        updatePrices: syncPricesWithOrders(ids: $ids) {
          ids
        }
      }
    `,
  };

  return Client.mutate(query)
    .then(async (response) => {
      var data = response.data.updatePrices;

      return {
        hasError: false,
      };
    })
    .catch((e) => {
      var message = "Impossibile aggiornare i prezzi";
      console.error(e);

      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        message = e.graphQLErrors[0].message;
      }

      return {
        hasError: true,
        message: message,
      };
    });
}
