import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux'
import {get} from 'lodash';
// For auto registry purpose
import './redux/documentType.reducer';
import * as Commands from './redux/documentType.commands';
import CreateComponent from "./components/documentType.create.component";
import UpdateComponent from "./components/documentType.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import moment from "moment";

class DocumentTypeComponent extends Component {

    state = {
        data: [],
        showCreateModal: false,
        showUpdateModal: false,
        loading: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {}
    };

    caricaDati = async () => {
        this.setState({loading: true});
        await Commands.ReadAll();
        this.setState({loading: false});
    };


    componentDidMount() {
        this.caricaDati();
    }

    toggleShowCreateModal = () => {
        /*        alert("Not implemented");
                return;*/
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    };

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    };

    elimina = async () => {
        var res = await Commands.DeleteDocumentType(this.state.selectedRecordForDelete.id);

        if (res.hasError) {
            alert('Impossibile eliminare il settore');
            return;
        }
        this.toggleDeleteAlert();
    };

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {

        return (
            <>
                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecordForDelete.description", "")}
                        </ReactBSAlert> : null}

                {/*Modal creazione e aggiornamento uorg*/}
                <CreateComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {
                    this.state.showUpdateModal ? <UpdateComponent {...this.state.selectedRecordForUpdate}
                                                                  show={this.state.showUpdateModal}
                                                                  toggle={this.toggleShowUpdateModal}/> : null
                }

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuova tipologia documento
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuovo settore<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Elenco tipologie documenti</CardTitle>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                show: false,
                                            },
                                            {
                                                Header: "Descrizione",
                                                accessor: "description"
                                            },
                                            {
                                                Header: "Ruoli",
                                                Cell: row => {
                                                    return row.original.enableForRole.map((r)=> r + ", ")
                                                },

                                            },
                                            {
                                                Header: "Regex",
                                                Cell: row => {
                                                    return row.original.extractionRule.regex? row.original.extractionRule.regex.map((r)=>{
                                                        return r + ", "
                                                    }):""
                                                },

                                            },



                                            {
                                                Header: "Privato",
                                                accessor: "private",
                                                width: 120,
                                                Cell: row => {
                                                    if (row.original.private === true)return "Si";
                                                    return "No";
                                                },
                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                width: 120,
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    const data = {
        data: get(state, "documentType.data", []),
        loading: get(state, "documentType.loading", false)
    };


    return data;
};

export default connect(mapStateToProps, () => ({}))(DocumentTypeComponent)

