import * as types from "./coops.types";

const initialState = {
    data: [],
    loading: false
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST_READ_ALL:
            state = {
                ...state,
                loading: true
            };
            break;
        case types.READ_ALL_ERROR: {
            state = {
                ...state,
                loading: false,
                data: []
            };
            break;
        }
        case types.READED_ALL:
            state = {
                ...state,
                data: action.payload,
                loading: false
            };
            break;
        case types.CREATED:
            state = {
                ...state,
                data: [
                    ...state.data,
                    action.payload
                ]
            };
            break;
        case types.DELETED: {
            let data = [...state.data];
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].id === action.payload.id) {
                        data.splice(i, 1);
                        break;
                    }
                }
            }
            state = {
                ...state,
                data: data
            };
            break;
        }
        case types.REQUEST_DELETE: {
            let data = [...state.data];

            const index = data.findIndex(item => item.id === action.payload);

            if (index === -1) {
                break
                break
            }

            data = [
                ...data.slice(0, index), // everything before current post
                {
                    ...data[index],
                    _deleting: true,
                },
                ...data.slice(index + 1), // everything after current post
            ];
            state = {
                ...state,
                data: data
            };
            break;
        }
        case types.DELETE_ERROR: {
            let data = [...state.data];
            if (data) {
                const index = data.findIndex(item => item.id === action.payload);
                if (index === -1) {
                    break
                }
                data = [
                    ...data.slice(0, index), // everything before current post
                    {
                        ...data[index],
                        _deleting: false,
                    },
                    ...data.slice(index + 1), // everything after current post
                ]
            }
            state = {
                ...state,
                data: data
            };
            break;
        }

        case types.UPDATED: {
            let data = [...state.data];
            if (data) {
                for (let i = 0; i < data.length; i++)
                    if (data[i].id === action.payload.id) {
                        data[i] = action.payload;
                        break;
                    }
            }
            state = {
                ...state,
                data: data
            };
            break;
        }


        default:
            break;
    }

    return state;
};

export default reducer;
