import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux';
import {get} from 'lodash';
import './redux/budgets.reducer';
import * as Commands from './redux/budgets.commands';
import CreateProductionComponent from "./components/budgets.create.component";
import UpdateProductionComponent from "./components/budgets.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import moment from "moment";

class BudgetsComponent extends Component {

    state = {
        data: [],
        showCreateModal: false,
        loading: false,
        showUpdateModal: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
    }

    caricaDati = async () => {
        this.setState({loading: true});
        await Commands.OttieniBudgets();
        this.setState({loading: false});
    }


    componentDidMount() {
        this.caricaDati();
    }

    toggleShowCreateModal = () => {

        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }

    elimina = async () => {
        var res = await Commands.EliminaBudgets(this.state.selectedRecordForDelete.id);

        if (res.hasError) {
            alert('Impossibile eliminare');
            return;
        }
        this.toggleDeleteAlert();
    }

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {


        return (
            <>
                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecordForDelete.name", "")}
                        </ReactBSAlert> : null}

                {/*Modal creazione e aggiornamento produzione*/}
                <CreateProductionComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {
                    this.state.showUpdateModal ? <UpdateProductionComponent {...this.state.selectedRecordForUpdate}
                                                                            show={this.state.showUpdateModal}
                                                                            toggle={this.toggleShowUpdateModal}/> : null
                }

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuovo budgets
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuovo budgets<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista budgets</CardTitle>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                show: false
                                            },
                                            {
                                                Header: "contractId",
                                                accessor: "contractId",
                                                show: false
                                            },
                                            {
                                                Header: "Contratto",
                                                accessor: "contractDescription"
                                            },
                                            {
                                                Header: "Tipologia",
                                                accessor: "tipologia"
                                            },
                                            {
                                                Header: "Settori",
                                                Cell: row => {
                                                    let settori = [];
                                                    row.original.sectors.forEach(s => {
                                                        settori.push(s.description)
                                                    });
                                                    return settori.join(' , ');
                                                },

                                            },
                                            {
                                                Header: "Inizio validità",
                                                accessor: "startDate",
                                                Cell: row => (`${moment.unix(row.value).utc().format("DD/MM/YY")}`),
                                                minWidth: 80,

                                            },
                                            {
                                                Header: "Fine Validità",
                                                accessor: "endDate",
                                                Cell: row => (`${moment.unix(row.value).utc().format("DD/MM/YY")}`),
                                                minWidth: 80,
                                            },
                                            {
                                                Header: "Euro",
                                                Cell: row => {
                                                    return <div
                                                        className={"importoFatturato"}>{row.original.euro.toLocaleString("es-ES", {minimumFractionDigits: 2}) + " €"}</div>
                                                },
                                            },
                                            {
                                                Header: "Num. Applicazione",
                                                accessor: "applicationNumber"
                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    const data = {
        data: get(state, "raiBudgets.data", []),
        loading: get(state, "raiBudgets.loading", false)
    };


    return data;
};


export default connect(mapStateToProps, () => ({}))(BudgetsComponent)

