import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/produzioni.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function CreateProductionComponent(props) {

    const nome = TextInputHook(props.newName || '');
    const descrizione = TextInputHook('');
    const matricola = NumberInputHook('');

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [contract, setContract] = useState(props.contract || null);
    const [tipologia, setTipologia] = useState(null);

    const [labelMatricola, setLabelMatricola] = useState({label:"..."});

    const contracts = useSelector(state => state.contract.data);
    if (!props.contract && contracts.length > 0 && !contract) {
        setContract(contracts[0]);
    }

    const salvaProduzione = async () => {
        setLoading(true);
        var res = await Commands.SalvaProduzione(nome.getValue(), matricola.getValue(), descrizione.getValue(), contract.id, tipologia.value);
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }


        if (props.onSuccess) {
            props.onSuccess(res.data);
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        nome.clear();
        matricola.clear();
        descrizione.clear();
    };


    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuova</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaProduzione();
                }}>
                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome produzione"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="tipologia">Tipo contabilizzazione</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={tipologia}
                            getOptionLabel={(item) => item.description}
                            onChange={value => {

                                setLabelMatricola(value);
                                setTipologia(value);
                            }
                            }
                            options={[
                                {
                                    value: "",
                                    description: "Tipologia",
                                    isDisabled: true
                                },
                                {
                                    value: "P",
                                    description: "Matricola produzione",
                                    label:"Numero matricola",
                                    help: "",
                                },
                                {
                                    value: "F",
                                    description: "Ordine interno spese di funzionamento",
                                    label: "Numero ordine interno (UORG.MATRICOLA)",
                                    help: ""
                                },
                                {
                                    value: "K",
                                    description: "Centro di costo",
                                    label: "Indicare solo UORG",
                                    help: ""
                                }
                            ]}
                            placeholder="Seleziona una tipologia matricola"
                        />
                    </FormGroup>



                    <FormGroup>
                        <Label for="matricola">{labelMatricola.label}</Label>
                        <Input
                            {...matricola.input}
                            name="matricola"
                            id="matricola"
                            placeholder="Inserire valore..."
                        />
                        <FormText color="muted">
                            {labelMatricola.help}
                        </FormText>
                    </FormGroup>



                    {!props.contract && contracts.length > 1 && <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setContract(value)
                            }
                            options={[{
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce la produzione
                        </FormText>
                    </FormGroup>
                    }

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare la produzione.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaProduzione}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
