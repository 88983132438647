import gql from "graphql-tag";
import Client from "Apolloclient";

export function OttieniLavoratoriLiberiPerAssegnazione(idAssegnazione) {

    var variables = {
        id: idAssegnazione
    }

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
         query getAss($id:String){
              queryRes: getFreeWorkersForAssignment(id:$id) {
                    workerId
                    fullName
                    contractHour
                    monthWorkedHours
                    monthAbsentHours
                    prevStartTime
                    prevEndTime
                  societa
              }
            }
        `,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i lavoratori disponibili"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AssegnaLavoratore(idAssegnazione, idWorker) {


    var variables = {
        id: idAssegnazione,
        workerId: idWorker
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $workerId: String){
              queryRes: setWorkerToAssignment(id: $id, workerId:$workerId){
     id
                endTime
                jsonWorker
                workerID
                periodID
                startTime
                state
                jsonExtra
                auto
                accepted
                acceptedOn
                refusedReason
                smsState
                auto
              }
        }`,
    };
    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile assegnare il lavoratore al servizio"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function RimuoviLavoratore(idAssegnazione, idWorker) {

    var variables = {
        id: idAssegnazione,
        workerId: idWorker
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $workerId: String){
              queryRes: removeWorkerFromAssignment(id: $id, workerId:$workerId){
      id
                endTime
                jsonWorker
                workerID
                periodID
                startTime
                state
                jsonExtra
                auto
                acceptedOn
                refusedReason
                smsState
              }
        }`,
    };
    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile rimuovere il lavoratore dal servizio"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function CambioCapoSquadra(idAssegnazione, capoSquadra) {

    var variables = {
        id: idAssegnazione,
        teamLeaeder: capoSquadra
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $teamLeaeder: Boolean){
              queryRes: changeTeamLeader(id: $id, teamLeaeder:$teamLeaeder){
                id
                endTime
                jsonWorker
                workerID
                periodID
                startTime
                state
                jsonExtra 
                auto     
                acceptedOn
                refusedReason
                smsState
              }
        }`,
    };

    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile cambiare il capo squadra"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

// function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

export function InviaSmsAccettazione(idAssegnazione) {


    var variables = {
        id: idAssegnazione
    };


    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        query: gql`,
         query  set($id:String){
              data: assignmentSendSmsAcceptanceMessage(id: $id){
               total
               sent
              }
        }`,
    };

    return Client
        .query(query).then(
            async response => {
                var data = response.data.data;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile cambiare il capo squadra"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function RifiutaAssegnazione(id, workerId, phone, userAgent, reason ) {
    var variables = {
        id,
        workerId,
        phone,
        userAgent,
        reason
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $workerId: String, $phone: String, $userAgent:String, $reason:String){
              queryRes: refuseAssignment(id: $id, workerId:$workerId, phone: $phone, userAgent: $userAgent, reason: $reason){
                id
              }
        }`,
    };
    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile rifiutare l'assegnazione: contattare l'amministratore."
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AccettaAssegnazione(id, workerId, phone, userAgent ) {
    var variables = {
        id,
        workerId,
        phone,
        userAgent
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $workerId: String, $phone: String, $userAgent:String){
              queryRes: acceptAssignment(id: $id, workerId:$workerId, phone: $phone, userAgent: $userAgent){
                id
              }
        }`,
    };
    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile accettare l'assegnazione: contattare l'amministratore."
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function InviaSmsTimbrata(idAssegnazione) {


    var variables = {
        id: idAssegnazione
    };


    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        query: gql`,
         query  set($id:String){
              data: inviaSmsTimbrata(id: $id){
               total
               sent
              }
        }`,
    };

    return Client
        .query(query).then(
            async response => {
                var data = response.data.data;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile inviare l'sms timbrata"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function TimbraEntrata(id, lat, long, orario, userAgent ) {

    var variables = {
        id,
        lat,
        long,
        orario,
        userAgent
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $lat: Float, $long: Float, $orario: Int, $userAgent:String){
              queryRes: timbraEntrata(id: $id, lat: $lat, long:$long, orario:$orario, userAgent:$userAgent){
                id
              }
        }`,
    };

    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile inviare la timbrata. Contattare l'amministrazione."
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function TimbraUscita(id,lat, long, orario, userAgent ) {

    var variables = {
        id,
        lat,
        long,
        orario,
        userAgent
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $lat: Float, $long: Float, $orario: Int, $userAgent:String){
              queryRes: timbraUscita(id: $id, lat: $lat, long:$long, orario:$orario, userAgent:$userAgent){
                id
              }
        }`,
    };

    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile inviare la timbrata. Contattare l'amministrazione."
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function SalvaNote(idAssegnazione, note) {

    var variables = {
        id: idAssegnazione,
        notes: note
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
         mutation  set($id:String, $notes: String){
              queryRes: assignmentSetNotes(id: $id, notes:$notes){
                id
                endTime
                jsonWorker
                workerID
                periodID
                startTime
                state
                jsonExtra
                auto
                acceptedOn
                refusedReason
                smsState
                notes
              }
        }`,
    };
    return Client
        .mutate(query).then(
            async response => {
                var data = response.data.queryRes;
                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile salvare le note";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

