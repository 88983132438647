import React, {Component} from 'react';
import {DateRangePicker} from 'react-dates';
import moment from 'moment';
import {
    Button
} from 'reactstrap';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css'

function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    // Compare least significant, most likely to change units first
    // Moment's isSame clones moment inputs and is a tad slow
    return a.date() === b.date()
        && a.month() === b.month()
        && a.year() === b.year();
}

class DateRangePickerWrapper extends Component {
    constructor(props) {
        super(props);

        new Date();
        var momDate = moment();
        var firstDay = moment().startOf('month');
        var lastDay = moment().endOf('month');
        var firstWeekDay = moment().startOf('week');
        var lastWeekDay = moment().endOf('week');
        var previousFirstDay = moment().startOf('month').subtract(1, 'month');
        var previousLastDay = moment().subtract(1, 'month').endOf('month');
        var nextFirstDay = moment().startOf('month').add(1, 'month');
        var nextLastDay = moment().add(1, 'month').endOf('month');
        moment().startOf('year');
        moment().endOf('year');
        var yesterday = moment().subtract(1, 'days');
        var tomorrow = moment().add(1, 'days');

        this.state = {
            startDate: props.initialStartDate,
            endDate: props.initialEndDate,
            presets: [
                {
                    label: "Oggi",
                    startDate: momDate,
                    endDate: momDate
                },
                {
                    label: "Ieri",
                    startDate: yesterday,
                    endDate: yesterday
                },
                {
                    label: "Domani",
                    startDate: tomorrow,
                    endDate: tomorrow
                },
                {
                    label: "Settimana",
                    startDate: firstWeekDay,
                    endDate: lastWeekDay
                },
                {
                    label: "Mese",
                    startDate: firstDay,
                    endDate: lastDay
                },
                {
                    label: "Mese -1",
                    startDate: previousFirstDay,
                    endDate: previousLastDay
                },
                {
                    label: "Mese +1",
                    startDate: nextFirstDay,
                    endDate: nextLastDay
                },
            ],
        };
    }

    renderDatePresets = () => {
        return (
            <div>
                {this.state.presets.map(({label, startDate, endDate}) => {
                    const isSelected = isSameDay(this.state.startDate, startDate) && isSameDay(this.state.endDate, endDate);
                    let className = '';
                    if (!isSelected) {
                        className = "btn-simple";
                    }
                    return (
                        <Button size="sm" className={className} color="primary"
                                key={label}
                                onClick={() => {
                                    this.onDatesChange({startDate: startDate, endDate: endDate}, true);
                                    this.setState({focusedInput: false});
                                }}>
                            {label}
                        </Button>
                    );
                })}
            </div>
        );
    }

    onDatesChange = ({startDate, endDate}, preset) => {
        if (!preset && this.state.focusedInput === 'startDate')endDate = null;
        this.setState({startDate, endDate});
        if (this.props.onChange && startDate && endDate && this.state.focusedInput === 'endDate') this.props.onChange(startDate, endDate);
        else if (preset)this.props.onChange(startDate, endDate);
    };

    render() {

        const {startDate, endDate} = this.state;
        return (
            <DateRangePicker
                {...this.props}
                startDate={startDate}
                onDatesChange={this.onDatesChange}
                startDateId={"startDate"}
                endDate={endDate}
                renderCalendarInfo={this.renderDatePresets}
                preset
                minimumNights={0}
                hideKeyboardShortcutsPanel
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,

            />
        );
    }
}

export default DateRangePickerWrapper;
