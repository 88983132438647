import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Button, UncontrolledTooltip, CardHeader, CardTitle, CardSubtitle
} from "reactstrap";
import classNames from "classnames";
import {connect} from 'react-redux'
import {default as _, get} from 'lodash';
import DateRangePickerWrapper from 'components/DateRangePicker/DateRangePickerWrapper';
import * as Commands from './redux/setupMailAccount.commands';
import moment from 'moment';
import {GetSectorsForRoles, isAdmin, RUOLO_AMMINISTRATORE, RUOLO_FUNZIONARIO_RAI} from "../utenti/components/costants";
import ShowConfirmationDialog from "../../../components/ShowConfirmationDialog";
import Fade from "react-reveal/Fade";
import ReactTable from "react-table";
import {Tooltip} from "react-tippy";
import CreateMailAccount from "./components/setupMailAccount.create.component";
import UpdateMailAccount from "./components/setupMailAccount.update.component";

class SetupMailAccountComponent extends Component {

    state = {
        data: [],
        showCreateModal: false,
        showUpdateModal: false,
        loading: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
    }

    caricaDati = async () => {
        this.setState({loading: true});
        await Commands.ReadAll();
        this.setState({loading: false});
    }


    componentDidMount() {
        this.caricaDati();
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }

    elimina = async (row) => {
        const doAction = await ShowConfirmationDialog({
            title: "Conferma eliminazione",
            confirmButtonText: 'Si!',
            cancelButtonText: 'No',
            canEscapeKeyCancel: true
        });

        if (doAction.confirm) {

            const res = Commands.Delete(row.id);
            if (res.hasError) {
                ShowConfirmationDialog({
                    title: "Errore durante eliminazione account",
                    confirmButtonText: 'Ok!',
                    cancelButtonText: 'No',
                    canEscapeKeyCancel: true,
                    showCancel: false
                });
            }
        }
    }

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                _.get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.elimina(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {
        return (
            <>

                {/*Modal creazione e aggiornamento produzione*/}
                <CreateMailAccount show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {
                    this.state.showUpdateModal ? <UpdateMailAccount {...this.state.selectedRecordForUpdate}
                                                                      show={this.state.showUpdateModal}
                                                                      toggle={this.toggleShowUpdateModal}/> : null
                }

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            {
                                                !this.props.readOnly && <>
                                                    <button className="btn btn-primary " id="nuovoModal"
                                                            onClick={() => this.toggleShowCreateModal()}><i
                                                        className="tim-icons icon-simple-add"/>Nuovo account di posta
                                                    </button>
                                                    <UncontrolledTooltip placement="bottom" target="nuovoModal"
                                                                         delay={0}>
                                                        Crea un nuovo account<br/> ( Hotkey F11)
                                                    </UncontrolledTooltip>
                                                </>
                                            }
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista account di posta</CardTitle>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={true}
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                show: true,
                                            },
                                            {
                                                Header: "Cooperativa",
                                                accessor: "coop",

                                            },
                                            {
                                                Header: "InServerType",
                                                accessor: "inServerType",

                                            },
                                            {
                                                Header: "InServe",
                                                accessor: "inServer",

                                            },
                                            {
                                                Header: "InServerPort",
                                                accessor: "inServerPort",

                                            },
                                            {
                                                Header: "OutServer",
                                                accessor: "outServer",

                                            },
                                            {
                                                Header: "OutServerPort",
                                                accessor: "outServerPort",

                                            },
                                            {
                                                Header: "Username",
                                                accessor: "username",

                                            },
                                            {
                                                Header: "Password",
                                                accessor: "password",
                                            },
                                            {
                                                Header: "Pec",
                                                Cell: row => {
                                                    // console.log(row)
                                                    if (row.original.pec) return "Si"
                                                    return "No"
                                                },

                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            },

                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    let userAdmin = false;
    let userRole = get(state, "login.user.role", "-");

    if (userRole.toLowerCase() === RUOLO_AMMINISTRATORE) {
        userAdmin = true
    }


    const data = {
        data: get(state, "mailAccounts.data", []),
        loading: get(state, "mailAccounts.loading", false),
        userAdmin
    };

    return data;
};

export default connect(mapStateToProps, () => ({}))(SetupMailAccountComponent)

