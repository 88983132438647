import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './assegnazioni.types';
import * as actions from './assegnazioni.actions';

export function SalvaLotto(name, number, description, contractid, customerid) {

    var variables = {name, number, description, contractid, customerid};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
            mutation create (
            $name: String!
            $description: String
            $contractid: String
            $customerid: String
        ) {
            createdObject: createLot (
                name: $name,
                description: $description,
                contractid: $contractid,
                customerid: $customerid,
            ) {
                id
                name
                description
                contract {
                    id
                    description
                    startDate
                    endDate
                    company
                } 
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare il lotto';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniAssegnazioni(customerId) {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {
        "customerID":customerId
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($customerID:ID!){
            objectList :
            ordersByCompany(customerID:$customerID) {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                startDate
                state
                number
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                }
                assignments {
                    id
                    endTime
                    jsonWorker
                    workerID
                    periodID
                    startTime
                    state
                    jsonExtra
                    auto
                }
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {

                var message = "Impossibile ottenere i assegnazioni"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }

            }
        );
}

export function AggiornaAssegnazione(id, name, number, description, contractid, customerid) {

    var variables = {id, name, number, description,contractid, customerid};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
#        mutation update(
#            $id: String!,
#
#        ){
#            updatedObject: updateArticle(
#                id: $id,
#
#            ) {
#                id
#            }
#        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare l\'ordine';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaLotto(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteArticle (id: $id) {
                id
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il lotto';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
