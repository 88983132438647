import React, {Component} from 'react';
import {Button, FormGroup, Input} from "reactstrap";
import Select from "react-select";
import * as _ from 'lodash';


class DocumentMetasComponent extends Component {

    constructor(props) {

        super(props);

        let count = 0;
        let max = _.maxBy(_.get(this.props, "metas", []), 'index');

        if (max) count = max.index;

        let options = [
            {
                fieldName: "Dati aggiuntivi",
                fieldType: "",
                isDisabled: true
            }
        ];

        let metas = _.get(props, 'metas', {});

        let list = Object.keys(metas).map(fieldName => ({fieldName: fieldName, fieldType: metas[fieldName].valueType, value: metas[fieldName].value}));
        // console.log('Object', Object.keys(metas), 'list', list);

        if (props.documentType && props.documentType.metas && props.documentType.metas.length > 0) {
             options = [options, ...props.documentType.metas.map(mt =>
                ({
                    fieldName: mt.fieldName,
                    fieldType: mt.fieldType,
                })
            )];
        }

        this.state = {
            list,
            count,
            options
        };
    }

    componentWillReceiveProps(props, nextContext) {
        // console.log('@@@@@@ METAS PROPS', props.metas);
        if (props.documentType && props.documentType.metas && props.documentType.metas.length > 0) {
            let options = [this.state.options, ...props.documentType.metas.map(mt =>
                ({
                    fieldName: mt.fieldName,
                    fieldType: mt.fieldType,
                })
            )];

            this.setState({options})
        }
    }

    getMetas = () => this.state.list;

    removeItemFromList = (index) => {
        this.setState({list: this.state.list.filter((el) => el.index !== index)});
    };

    addNewMeta = (e) => {
        e.preventDefault();
        this.setState({
            list: _.orderBy([...this.state.list, {index: this.state.count + 1, fieldName: null, fieldType: null}]),
            count: this.state.count + 1
        });
    };

    updateFieldValue = (index, value) => {
        const preUpdate = this.state.list.filter((el) => el.index === index)[0];
        this.setState({
            list: _.orderBy([...this.state.list.filter((el) => el.index !== index), {
                ...preUpdate,
                value: value
            }], 'index')
        });
    };

    updateField = (index, value) => {
        const preUpdate = this.state.list.filter((el) => el.index === index)[0];
        this.setState({
            list: _.orderBy([...this.state.list.filter((el) => el.index !== index), {
                ...preUpdate,
                fieldName: value.fieldName,
                fieldType: value.fieldType,
            }], 'index')
        });
    };

    render() {
        return (
            <div>
                {this.state.list.map((el, index) => <div
                    key={index + "list"}
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <FormGroup className="extendFlex" style={{flex: 1}} q label="Chiave">
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={this.state.options.find(e => e.fieldName === el.fieldName)}
                            getOptionLabel={(item) => item.fieldName}
                            getOptionValue={(item) => item.fieldName}
                            closeMenuOnSelect={false}
                            onChange={value => {
                                this.updateField(el.index, value);
                            }}
                            options={this.state.options}
                            placeholder="Seleziona tipologia"
                        />
                    </FormGroup>
                    <div style={{width: '5px'}}/>
                    <FormGroup className="extendFlex" style={{flex: 1}} label="Valore">
                        <Input
                            name="fieldName"
                            type="text"
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={el.value}
                            onChange={(e) => this.updateFieldValue(el.index, e.target.value)}
                            placeholder="Inserire valore"
                        />
                    </FormGroup>
                    <Button
                        className={"h-100 ml-3"}
                        color="danger"
                        size="sm"
                        onClick={() => this.removeItemFromList(el.index)}
                    >
                        <i className="tim-icons icon-simple-remove"/>
                    </Button>
                </div>)}
                <button className="btn btn-primary w-100"
                        onClick={this.addNewMeta}><i
                    className="tim-icons icon-simple-add"/>Aggiungi metadato
                </button>
            </div>
        );
    }
}

export default DocumentMetasComponent;
