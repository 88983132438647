import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './contratti.types';
import * as actions from './contratti.actions';
import * as _ from 'lodash';
import {RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP} from "../../utenti/components/costants";

export function SalvaContratto(coopID, coopName,description, startdate, enddate, companyID, number) {

    var variables = {coopID, coopName,description, startdate, enddate, companyID,number};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create (
            $description: String!
            $number: String!
            $startdate: Int
            $enddate: Int
            $companyID: String!
            $coopID: String!
            $coopName: String!
        ) {
            createdObject: addContract (
                description: $description,
                number: $number,
                startDate: $startdate,
                endDate: $enddate,
                companyID: $companyID,
                coopID: $coopID,
                coopName: $coopName
            ) {
                id
                company
                description
                startDate
                number
                endDate
                coopID
                coopName

            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare il contratto';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniContratti() {


    store.dispatch({
        type: types.REQUEST_READ_ALL
    });
    // Controllo se l'utente è admin, altrimenti popolo con gli allowedContracts

    let isAdmin = false;
    const role = _.get(store.getState(), "login.user.role", []);
    const allowedContracts = _.get(store.getState(), "login.user.jsonextra.allowedContracts", []);

    if (role.toLowerCase() === RUOLO_AMMINISTRATORE || role.toLowerCase() === RUOLO_AMMINISTRATORE_COOP) {
        isAdmin = true;
    }

    if (!isAdmin) {
        store.dispatch(
            {
                type: types.READED_ALL,
                payload: allowedContracts
            }
        );
        return;
    }

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList : contracts {
                id
                company
                description
                number
                endDate
                startDate
                coopName
                coopID
            }
        }        `,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.objectList;
                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );
                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i comtratti"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AggiornaContratto(coopID,coopName,id, description, startDate, endDate, companyID, number) {

    var variables = {coopID, coopName,id, description, startDate, endDate, companyID, number};
    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
            $description: String,
            $startDate: Int,
            $endDate: Int,
            $number: String,
            $companyID: String,
            $coopID: String!,
            $coopName: String!

        ){
            updatedObject: updateContract(
                id: $id,
                description: $description,
                startDate: $startDate,
                endDate: $endDate,
                number: $number,
                companyID: $companyID,
                coopID: $coopID,
                coopName: $coopName
            ) {
                id
                company
                description
                startDate
                endDate
                number
                coopName
                coopID
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare il contratto';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaContratto(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: removeContract (id: $id) {
                id
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il contratto';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
