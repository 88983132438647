import { ApolloClient } from 'apollo-client';
import { createUploadLink } from "apollo-upload-client";

import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import store from 'store/store';
import {get} from 'lodash';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';

let history = null;

export function SetHistory(hist){
    history = hist;
}

const cache = new InMemoryCache();

const link = new createUploadLink({
    uri: process.env.REACT_APP_ENDPOINT + '/graphql'
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token =  get(store.getState(), 'login.token', '');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : "",
        }
    }
});

const logoutLink = onError(({graphQLErrors} ) => {
    if (!graphQLErrors)return;
    graphQLErrors.forEach(err =>  {
        if (err.message === "auth::parse token-> signature is invalid"){
            // azione redux

            console.log("ERRORE CON L'AUTH!!!", err);
            history.push("/auth/login");
        }
    })
})

const client = new ApolloClient({
    cache,
    link:  logoutLink.concat(authLink.concat(link))
})



console.log("##### APOLLO URl", process.env.REACT_APP_ENDPOINT);

//  req.options.headers['Authorization'] = get(store.getState(), 'login.token', '');

export default client;
