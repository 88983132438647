/* eslint-disable */

const ns = 'RAI_LAVORATORI_';

export const REQUEST_CREATE = `${ns}REQUEST_CREATE`;
export const REQUEST_READ_ALL = `${ns}REQUEST_READ_ALL` ;
export const READ_ALL_ERROR = `${ns}READ_ALL_ERROR`;

export const REQUEST_READ = `${ns}REQUEST_READ` ;
export const REQUEST_UPDATE = `${ns}REQUEST_UPDATE` ;

export const CREATED =  `${ns}CREATED`;
export const READED_ALL =  `${ns}READED_ALL`;
export const READED = `${ns}READED`;
export const UPDATED =  `${ns}UPDATED`;

export const REQUEST_DELETE = `${ns}REQUEST_DELETE`;
export const DELETED =  `${ns}DELETED`;
export const DELETE_ERROR =  `${ns}DELETE_ERROR`;

/*export const CHANGE_PWD =  `${ns}CHANGE_PWD`;
export const CHANGED_PWD =  `${ns}CHANGED_PWD`;*/

const nsa = 'RAI_ASSENZE_';

export const ABS_REQUEST_READ_ALL = `${nsa}REQUEST_READ_ALL` ;
export const ABS_READED_ALL =  `${nsa}READED_ALL`;
export const ABS_READ_ALL_ERROR = `${nsa}READ_ALL_ERROR`;

export const ABS_REQUEST_READ = `${nsa}REQUEST_READ` ;
export const ABS_READED = `${nsa}READED`;
export const ABS_READ_ERROR = `${nsa}READ_ERROR`;

export const ABS_REQUEST_UPDATE = `${nsa}REQUEST_UPDATE` ;
export const ABS_UPDATED = `${nsa}UPDATED`;
export const ABS_UPDATE_ERROR = `${nsa}UPDATE_ERROR`;

export const ABS_REQUEST_CREATE = `${nsa}REQUEST_CREATE`;
export const ABS_CREATED =  `${nsa}CREATED`;
export const ABS_CREATE_ERROR = `${nsa}CREATE_ERROR`;

export const ABS_REQUEST_DELETE = `${nsa}REQUEST_DELETE`;
export const ABS_DELETED =  `${nsa}DELETED`;
export const ABS_DELETE_ERROR =  `${nsa}DELETE_ERROR`;