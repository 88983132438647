import { combineReducers } from 'redux'

import CustomerReducer from 'views/rai/clienti/redux/clienti.reducer'
import ContractsReducer from 'views/rai/contratti/redux/contratti.reducer'
import LoginReducer from 'views/pages/Login/redux/login.reducer'
import StudiReducer from 'views/rai/studi/redux/studi.reducer'
import LottiReducer from 'views/rai/lotti/redux/lotti.reducer'
import MotiviAssenzaReducer from 'views/rai/motivi/redux/motivi.reducer'
import UorgReducer from 'views/rai/uorg/redux/uorg.reducer'
import SettoriReducer from 'views/rai/settori/redux/settori.reducer'
import ProduzioniReducer from 'views/rai/produzioni/redux/produzioni.reducer'
import ServiziReducer from 'views/rai/servizi/redux/servizi.reducer'
import IndirizziReducer from 'views/rai/indirizzi/redux/indirizzi.reducer'
import UtentiReducer from 'views/rai/utenti/redux/utenti.reducer'
import OrdiniReducer from 'views/rai/ordini/redux/ordini.reducer'
import LavoratoriReducer from 'views/rai/lavoratori/redux/lavoratori.reducer'
import analytics from 'views/rai/analytics/redux/analytics.reducer'
import analyticsSoci from 'views/rai/analyticsSoci/redux/analyticsSoci.reducer'
import BudgetsReducer from "views/rai/budgets/redux/budgets.reducer";
import DocumentType from "views/rai/documentsType/redux/documentType.reducer";
import Documents from "views/rai/documents/redux/document.reducer";
import MailAccount from "views/rai/setupMailAccount/redux/setupMailAccount.reducer";
import coops from "views/rai/coops/redux/coops.reducer";

export default   combineReducers({
    customer: CustomerReducer,
    contract: ContractsReducer,
    login: LoginReducer,
    raiLotti: LottiReducer,
    raiStudi: StudiReducer,
    raiUorg: UorgReducer,
    raiSettori: SettoriReducer,
    raiProduzioni: ProduzioniReducer,
    raiServizi: ServiziReducer,
    raiIndirizzi: IndirizziReducer,
    raiUtenti: UtentiReducer,
    raiOrdini: OrdiniReducer,
    lavoratori: LavoratoriReducer,
    analytics,
    raiAnalyticsSoci: analyticsSoci,
    raiBudgets: BudgetsReducer,
    documentType: DocumentType,
    documents: Documents,
    mailAccounts: MailAccount,
    coops: coops,
    motiviAssenza: MotiviAssenzaReducer,
});
