import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './coops.types';
import * as actions from "./coops.actions";
import {CurrentUser, isAdminCoop} from "../../utenti/components/costants";


export function Create(companyName, emails, iban, partitaiva, validatedAddress, codiceGescoop) {

    var variables = {companyName, emails, iban, partitaiva, validatedAddress, codiceGescoop};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createCoop (
            $companyName: String!
            $emails: [String!]!
            $iban: [String!]!
            $partitaiva: String!
            $validatedAddress: String!
            $codiceGescoop: String!
        ) {
            createdObject:  createCoop(
                companyName: $companyName
                emails: $emails
                iban: $iban
                partitaiva: $partitaiva
                validatedAddress: $validatedAddress
                codiceGescoop: $codiceGescoop
            ) {
                id
                companyName
                emails
                iban
                partitaiva
                validatedAddress
                codiceGescoop
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare coop';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function ReadAll() {

    let user = CurrentUser();

    if (isAdminCoop()) {

        store.dispatch(
            {
                type: types.READED_ALL,
                payload: [{id: user.coopId, companyName:user.coopName}]
            }
        );
        return;
    }

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList :  coops{
                id
                companyName
                emails
                iban
                partitaiva
                validatedAddress
                codiceGescoop
            }
        }        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;
                if (!data) return;
                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere coops";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function Update(id, companyName, emails, iban, partitaiva, validatedAddress, codiceGescoop) {
    var variables = {id, companyName, emails, iban, partitaiva, validatedAddress, codiceGescoop};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation updateCoop (
            $id: String!
            $companyName: String!
            $emails: [String!]!
            $iban: [String!]!
            $partitaiva: String!
            $validatedAddress: String!
            $codiceGescoop: String!

        ) {
            createdObject:  updateCoop(
                id: $id
                companyName: $companyName
                emails: $emails
                iban: $iban
                partitaiva: $partitaiva
                validatedAddress: $validatedAddress
                codiceGescoop: $codiceGescoop
            ) {
                id
                companyName
                emails
                iban
                partitaiva
                validatedAddress
                codiceGescoop
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare account';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}


export function Delete(id) {


    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteMailAccount (id: $id) {
                id
                username
                password
                inServerType
                inServer
                inServerPort
                outServer
                outServerPort
                company_id
                company {
                    id
                    companyName
                }
                pec            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare lo account';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });

}
