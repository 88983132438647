import gql from "graphql-tag";
import Client from "Apolloclient";


export function OttieniTimbrate() {

    let query = {
        fetchPolicy: 'network-only',
        variables: {},
        query: gql`,
        query {
        stamps: assignmentsStamp {
                id
                entrata {
                  device
                  ip
                  lat
                  long
                  orarioDichiarato
                  orarioEffettivo
                }
                uscita {
                  device
                  ip
                  lat
                  long
                  orarioDichiarato
                  orarioEffettivo
                }
                jsonWorker
              }
              }
        `,
    };

    return Client
        .query(query).then(
            async response => {
                var data = response.data.stamps;

                return {
                    hasError: false,
                    data
                }

            }
        ).catch(
            e => {
                var message = "Impossibile ottenere le timbrate"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

