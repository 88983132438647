import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './analyticsSoci.types';


export function OttieniAssegnazioni(startDate, endDate, fullText) {

    store.dispatch({
        type: types.REQUEST_READ_ALL_ASSIGNEMENTS
    });

    var variables = {
        startDate,
        endDate,
        fullText
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($startDate: UnixDate, $endDate: UnixDate, $fullText: String){
            objectList :
            assignments(startDate: $startDate, endDate: $endDate, fullText: $fullText) {
                auto
                id
                jsonExtra
                jsonWorker
                orderID
                periodID
                startTime
                endTime
                state
                workerID
                entrata {
                     orarioDichiarato
                     orarioEffettivo
                }
                uscita {
                    orarioDichiarato
                    orarioEffettivo
                }
                order{
                    number
                }
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL_ASSIGNEMENTS,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i assegnazioni"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ASSIGNEMENTS_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}



export function OttieniAssegnazioniAnalytics(startDate, endDate) {

    store.dispatch({
        type: types.REQUEST_READ_ALL_ASSIGNEMENTS
    });

    var variables = {
        startDate,
        endDate,
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($startDate: UnixDate, $endDate: UnixDate){
            objectList :
            assignmentsAnalytics(startDate: $startDate, endDate: $endDate) {
                assignementId,
                workerId,
                worker,
                assignementStartTime,
                assignementEndTime,
                stampStartTimeDeclared,
                stampStartTimeEffective,
                stampEndTimeDeclared,
                stampEndTimeEffective,
                lotto,
                valutazione,
                tipologia,
                luogo,
                produzione,
                oreContratto,
                oreAssegnazione,
                ordine,
                qta,
                servizio,
                statoAssegnazione,
                statoOrdine,
                contratto,
                tipoOrdine,
                dataOrdine,
                settore,
                uorg,
                smsState,
                societa,
                oreMese,
                oreSettore
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL_ASSIGNEMENTS,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i assegnazioni"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ASSIGNEMENTS_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function GetReport(ids, reportName, workersIds) {
    var variables = {
        ids,
        reportName,
        workersIds
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query send($ids: [String], $reportName: String, $workersIds: [String]){
            report: getReport(ids: $ids, reportName: $reportName, sortBy:{values:$workersIds})
        }
        `
    };

    return Client
        .query(query)
        .then(async (response) => {
            var data = response.data.report;

            return {
                error: false,
                data
            };
        })
        .catch((e) => {
            console.error(e);
            var message = 'Non è stato possibile scaricare il report.';

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                message,
                error: true
            };
        });
}
