/* eslint-disable */
const ns = 'DOCUMENT_TYPE_';

// C - Create
export const REQUEST_CREATE = `${ns}REQUEST_CREATE`;
export const CREATED =  `${ns}CREATED`;

// R - Read
export const REQUEST_READ_ALL = `${ns}REQUEST_READ_ALL` ;
export const READ_ALL_ERROR = `${ns}READ_ALL_ERROR`;
export const READED_ALL =  `${ns}READED_ALL`;

export const REQUEST_READ = `${ns}REQUEST_READ` ;
export const READED = `${ns}READED`;
export const READ_ERROR = `${ns}READ_ALL_ERROR`;

// U - Update
export const REQUEST_UPDATE = `${ns}REQUEST_UPDATE` ;
export const UPDATED =  `${ns}UPDATED`;
export const UPDATE_ERROR =  `${ns}UPDATE_ERROR`;

// D - Delete
export const REQUEST_DELETE = `${ns}REQUEST_DELETE`;
export const DELETED =  `${ns}DELETED`;
export const DELETE_ERROR =  `${ns}DELETE_ERROR`;

