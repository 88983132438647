import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Col,
  Row,
  Alert,
} from "reactstrap";

import React, { useState, useEffect, useRef } from "react";
import { NumberInputHook } from "hooks/number_input.hook";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import SparkleDayPicker from "./daypicker.component";
import * as Commands from "../redux/ordini.commands";
import _ from "lodash";
import CreateProductionComponent from "views/rai/produzioni/components/create_production.component";
import CreateUorgComponent from "views/rai/uorg/components/uorg.create.component";
import CreateStudyComponent from "views/rai/studi/components/studi.create.component";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import axios from "axios";

import "react-day-picker/lib/style.css";
import CreateAddressComponent from "views/rai/indirizzi/components/indirizzi.create.component";
import {
  canInsertPlanedOrder,
  GetSectorsForRole,
  isAdminCoop,
  isAdminOrAdminCoop,
  RUOLO_AMMINISTRATORE,
  RUOLO_AMMINISTRATORE_COOP,
  RUOLO_RICHIEDENTE_RAI,
} from "../../utenti/components/costants";
import { isAdmin } from "views/rai/utenti/components/costants";
import { PlannedOrdersApproved } from "../redux/ordini.commands";
import Geosuggest from "react-geosuggest";
import { Checkbox } from "@blueprintjs/core";

export default function CreateOrderComponent(props) {
  const initialValues = {
    tabAttiva: "singolo",
    loading: false,
    lotto: null,
    tipologia: null,
    servizio: null,
    contratto: null,
    utenteRichiedente: null,
    produzione: null,
    luogoLavoro: null,
    uorg: null,
    settore: null,
    studio: null,
    orderType: { value: "ordinary", description: "Variazione" },
    luogoPartenza: "",
    luogoArrivo: "",
    kmCalcolati: "-",
  };

  const daysPickerRef = useRef(null);

  const utenteLoggato = useSelector((state) => state.login.user);

  let tipologie = [
    {
      value: "",
      description: "Modulo",
    },
    {
      value: "",
      description: "Modulo-Trasporto",
    },
    {
      value: "",
      description: "Trasporto",
    },
  ];

  if (
    utenteLoggato.role.toLowerCase() === RUOLO_AMMINISTRATORE ||
    utenteLoggato.role.toLowerCase() === RUOLO_AMMINISTRATORE_COOP
  ) {
    tipologie = [
      {
        value: "",
        description: "Canone",
      },
      ...tipologie,
    ];
  }

  const [duplicated, setDuplicated] = useState(false);

  const contracts = useSelector((state) => state.contract.data);
  const lotti = useSelector((state) => state.raiLotti.data);
  const produzioni = useSelector((state) => state.raiProduzioni.data);
  const servizi = useSelector((state) => state.raiServizi.data);
  const luoghiLavoro = useSelector((state) => state.raiIndirizzi.data);
  const studi = useSelector((state) => state.raiStudi.data);
  const utenti = useSelector((state) => state.raiUtenti.data);

  const uorgs = useSelector((state) => state.raiUorg.data);

  const settori = useSelector((state) => state.raiSettori.data).filter(
    (sett) => {
      if (isAdminOrAdminCoop()) return true;
      const writePermissionSector = GetSectorsForRole(RUOLO_RICHIEDENTE_RAI);
      const res = writePermissionSector.some((s) => s.sector.id === sett.id);
      return res;
    }
  );

  let contrattoCercato = [];
  let lottoCercato = [];
  let utenteRichiedenteCercato = [];
  let utenteApprovanteCercato = [];
  let tipologiaCercato = [];
  let servizioCercato = [];
  let produzioneCercato = [];
  let luogoLavoroCercato = [];
  let uorgCercato = [];
  let settoreCercato = [];
  let studioCercato = [];

  const cambioQtaTrasporto = (nuovaQta) => {
    qtaModulo.setValue(nuovaQta);
  };

  const cambioNumeroAgenti = (nuovoNumeroAgenti) => {
    if (
      tipologia &&
      tipologia.description &&
      tipologia.description.toLowerCase() === "modulo"
    ) {
      const oreModulo = parseInt(
        _.get(servizio, "jsonExtra.moduleHours", 0),
        10
      );
      const lavoratoriModulo = parseInt(
        _.get(servizio, "jsonExtra.moduleWorkers", 0),
        10
      );
      const multiple = Math.ceil(durataInOre / oreModulo);

      const nuovaQtaModulo = Math.floor(
        (nuovoNumeroAgenti * multiple) / lavoratoriModulo
      );
      qtaModulo.setValue(nuovaQtaModulo);
    }

    numeroLavoratoriRichiesti.setValue(nuovoNumeroAgenti);
  };

  //
  // CODE FOR FIELDS DUPLICATION
  //

  if (props.dataToDuplicate) {
    const dup = props.dataToDuplicate;
    contrattoCercato = contracts.filter(
      (contratto) => dup.contractID === contratto.id
    );

    // ricerca il lotto nello state, altrimenti alert e chiusura del modal
    lottoCercato = lotti.filter((lotto) => dup.jsonExtra.lot.id === lotto.id);

    utenteRichiedenteCercato = utenti.filter(
      (utente) => dup.jsonExtra.applicantUser.id === utente.id
    );
    utenteApprovanteCercato = utenti.filter(
      (utente) => dup.jsonExtra.approvingUser.id === utente.id
    );
    tipologiaCercato = dup.jsonExtra.article.jsonExtra.type;
    servizioCercato = servizi.filter((servizio) => {
      return dup.jsonExtra.article.id === servizio.id;
    });

    produzioneCercato = produzioni.filter((produzione) => {
      return dup.jsonExtra.production.id === produzione.id;
    });
    luogoLavoroCercato = luoghiLavoro.filter((luogoLavoro) => {
      return dup.jsonExtra.workPlace.id === luogoLavoro.id;
    });

    uorgCercato = uorgs.filter((uorg) => {
      return dup.uorg.id === uorg.id;
    });

    settoreCercato = settori.filter((sett) => {
      return dup.sector.id === sett.id;
    });

    studioCercato = studi.filter((sett) => {
      return dup.study.id === sett.id;
    });

    if (
      (contrattoCercato.length === 0) | (lottoCercato.length === 0) ||
      settoreCercato.length === 0 ||
      utenteRichiedenteCercato.length === 0 ||
      utenteApprovanteCercato.length === 0 ||
      tipologiaCercato.length === 0 ||
      servizioCercato.length === 0 ||
      produzioneCercato.length === 0 ||
      uorgCercato.length === 0 ||
      luogoLavoroCercato.length === 0
    ) {
      ShowConfirmationDialog({
        title: "C'è stato un errore!",
        text: `Non è stato possibile recuperare i dati dall'ordine originale`,
        confirmBtnText: "Ok",
        showCancel: false,
        canEscapeKeyCancel: true,
      });

      // close modal
      props.toggle();
    } else {
      initialValues.lotto = lottoCercato[0];
      initialValues.tipologia = {
        value: "",
        description: tipologiaCercato,
      };
      initialValues.servizio = servizioCercato[0];
      initialValues.produzione = produzioneCercato[0];
      initialValues.luogoLavoro = luogoLavoroCercato[0];
      initialValues.uorg = uorgCercato[0];
      initialValues.contratto = contrattoCercato[0];
      initialValues.settore = settoreCercato[0];
      initialValues.studio = studioCercato[0];

      if (!duplicated) setDuplicated(true);
    }
  }

  // END SPECIFIC DUPLICATION CODE

  const [submitError, setSubmitError] = useState(false);
  const [showCreateProduction, setShowCreateProduction] = useState({
    show: false,
    newName: "",
  });
  const [showCreateUorg, setShowCreateUorg] = useState({
    show: false,
    newName: "",
  });
  const [showCreateAddress, setShowCreateAddress] = useState({
    show: false,
    newName: "",
  });
  const [showCreateStudy, setShowCreateStudy] = useState({
    show: false,
    newName: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [showPlannedOrder, setShowPlannedOrder] = useState(false);

  const [contratto, setContratto] = useState(initialValues.contratto);

  const [lotto, setLotto] = useState(initialValues.lotto);
  const [utenteRichiedente, setUtenteRichiedente] = useState();
  const [utenteApprovante, setUtenteApprovante] = useState();
  const [tipologia, setTipologia] = useState(initialValues.tipologia);
  const [servizio, setServizio] = useState(initialValues.servizio);
  const [produzione, setProduzione] = useState(initialValues.produzione);
  const [luogoLavoro, setLuogoLavoro] = useState(initialValues.luogoLavoro);
  const [studio, setStudio] = useState(initialValues.studio);
  const [settore, setSettore] = useState(initialValues.settore);
  const [orderType, setOrderType] = useState(initialValues.orderType);

  const [luogoPartenza, setLuogoPartenza] = useState(
    initialValues.luogoPartenza
  );
  const [coordinateLuogoPartenza, setCoordinateLuogoPartenza] = useState();
  const [luogoArrivo, setLuogoArrivo] = useState(initialValues.luogoArrivo);
  const [coordinateLuogoArrivo, setCoordinateLuogoArrivo] = useState();

  const [calcoloKilometri, setCalcoloKilometri] = useState(false);
  const [kilometri, setKilometri] = useState(0);
  const [andataERitorno, setAndataERitorno] = useState(true);

  const luogoPartenzaRef = useRef(null);
  const luogoArrivoRef = useRef(null);

  const qtaModulo = NumberInputHook();

  const [uorg, setUorg] = useState(initialValues.uorg);
  const numeroLavoratoriRichiesti = NumberInputHook();

  const [dataInizio] = useState(new moment().toDate());

  const [oraInizio, setOraInizio] = useState(
    new moment().hour(8).minute(0).second(0).toDate()
  );
  const [oraFine, setOraFine] = useState(
    new moment().hour(12).minute(0).second(0).toDate()
  );

  let durataInOre = (oraFine.getTime() - oraInizio.getTime()) / (3600 * 1000);

  const calcolaKilometri = (coordPartenza, coordArrivo) => {
    var data = JSON.stringify({
      partenza: { lat: coordPartenza.lat, lon: coordPartenza.lng },
      arrivo: { lat: coordArrivo.lat, lon: coordArrivo.lng },
      itineraryType: 1,
    });

    var config = {
      method: "post",
      url: "https://distanzekm-api.services.aci.it/api/viaMichelinCalls/getPercorso",
      headers: {
        Connection: "keep-alive",
        Accept: "application/json, text/plain, */*",
        DNT: "1",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.141 Safari/537.36",
        "Content-Type": "application/json",
        Origin: "https://distanzekm-ui.aci.it",
        "Sec-Fetch-Site": "same-site",
        "Sec-Fetch-Mode": "cors",
        "Sec-Fetch-Dest": "empty",
        Referer: "https://distanzekm-ui.aci.it/",
        "Accept-Language": "it-IT,it;q=0.9,en-US;q=0.8,en;q=0.7,fr;q=0.6",
      },
      data: data,
    };

    setCalcoloKilometri(true);

    axios(config)
      .then(function (response) {
        setCalcoloKilometri(false);
        const km =
          response.data.itinerario.itinerario.iti.header.summaries[0].totalDist;

        let kmFormattati = Math.ceil(km / 1000);

        if (andataERitorno) {
          kmFormattati *= 2;
        }
        setKilometri(kmFormattati);

        if (tipologia && tipologia.description === "Trasporto") {
          cambioQtaTrasporto(kmFormattati);
        }
      })
      .catch(function (error) {
        setCalcoloKilometri(false);
        alert("Impossibile calcolare la distanza in kilometri");
        console.log(error);
      });
  };

  const onSuggestLuogoPartenza = (suggest) => {
    if (suggest && suggest.gmaps) {
      setCoordinateLuogoPartenza(suggest.location);
      setLuogoPartenza(suggest.gmaps.formatted_address);
      setLuogoArrivo(initialValues.luogoArrivo);
      luogoArrivoRef.current.clear();
    }
  };

  const onSuggestLuogoArrivo = (suggest) => {
    if (suggest && suggest.gmaps) {
      setCoordinateLuogoArrivo(suggest.location);
      setLuogoArrivo(suggest.gmaps.formatted_address);
      // Calcolo distanza km qua

      calcolaKilometri(coordinateLuogoPartenza, suggest.location);
    }
  };

  const cambioQtaModulo = (nuovaQta) => {
    if (
      tipologia &&
      tipologia.description &&
      tipologia.description.toLowerCase() === "modulo"
    ) {
      const oreModulo = parseInt(
        _.get(servizio, "jsonExtra.moduleHours", 0),
        10
      );
      const lavoratoriModulo = parseInt(
        _.get(servizio, "jsonExtra.moduleWorkers", 0),
        10
      );
      const multiple = Math.ceil(durataInOre / oreModulo);

      const numeroLavoratori = Math.floor(
        (nuovaQta * lavoratoriModulo) / multiple
      );
      // console.log('durata in ore', durataInOre, 'novaQtaMod', nuovaQta, 'ORE MODULO', oreModulo, 'lavoratoriModulo', lavoratoriModulo, 'multiple', multiple);
      numeroLavoratoriRichiesti.setValue(numeroLavoratori);
    }

    qtaModulo.setValue(nuovaQta);
  };

  const orderIsValid = async () => {
    if (!contratto) {
      setSubmitError(true);
      setErrorMessage("Nessun contratto selezionato");
      return false;
    }

    if (!settore) {
      setSubmitError(true);
      setErrorMessage("Nessun settore selezionato");
      return false;
    }

    if (!lotto) {
      setSubmitError(true);
      setErrorMessage("Nessun lotto selezionato");
      return false;
    }

    if (!servizio) {
      setSubmitError(true);
      setErrorMessage("Nessun servizio selezionato");
      return false;
    }

    if (!produzione) {
      setSubmitError(true);
      setErrorMessage("Nessuna produzione selezionata");
      return false;
    }

    if (!uorg) {
      setSubmitError(true);
      setErrorMessage("Nessun uorg selezionato");
      return false;
    }

    if (!luogoLavoro) {
      setSubmitError(true);
      setErrorMessage("Nessun luogo selezionato");
      return false;
    }

    if (daysPickerRef.current.getSelectedDays().length === 0) {
      setSubmitError(true);
      setErrorMessage("Nessuna data selezionat");
      return false;
    }

    if (
      numeroLavoratoriRichiesti.getValue() === 0 &&
      tipologia &&
      tipologia.description !== "Trasporto" &&
      tipologia.description !== "Modulo-Trasporto"
    ) {
      setSubmitError(true);
      setErrorMessage("Impossibile calcolare le ore richieste");
      return false;
    }

    if (oraFine < oraInizio) {
      ShowConfirmationDialog({
        title: "C'è stato un errore!",
        text: `L'ora di fine non può essere inferiore all'ora di inizio`,
        confirmBtnText: "Ok",
        showCancel: false,
        canEscapeKeyCancel: true,
      });
      return;
    }

    if (tipologia && tipologia.description === "Modulo-Trasporto") {
      const oreModulo = parseInt(
        _.get(servizio, "jsonExtra.moduleHours", 0),
        10
      );
      const oreImpostate = moment
        .duration(new moment(oraFine).diff(new moment(oraInizio)))
        .asHours();

      if (oreModulo != oreImpostate) {
        ShowConfirmationDialog({
          title: "C'è stato un errore!",
          html: (
            <div>
              {" "}
              Il servizio {servizio.description} vale {oreModulo} ore.
              <br /> Hai selezionato {oreImpostate} ore!
              <br /> Correggi l'orario e salva di nuovo l'ordine.
            </div>
          ),
          confirmBtnText: "Ok",
          showCancel: false,
          canEscapeKeyCancel: true,
        });
        return;
      }
    }

    if (tipologia && tipologia.description === "Modulo-Trasporto") {
      const daKm = servizio.jsonExtra.daKm;
      const aKm = servizio.jsonExtra.aKm;

      if (servizio.description.toLowerCase() === "modulo e radiofonia") {
        if (kilometri < daKm) {
          // Sul modulo E conto solo i kilometri minimi
          ShowConfirmationDialog({
            title: "C'è stato un errore!",
            html: (
              <div>
                {" "}
                Il servizio {servizio.description} prevede un viaggio compreso
                tra{" "}
                <b>
                  <u>{daKm}</u>
                </b>{" "}
                e{" "}
                <b>
                  <u>{aKm}</u>
                </b>{" "}
                kilometri.
                <br />
                <br />
                Il viaggio che va da{" "}
                <b>
                  <u>{luogoPartenza}</u>
                </b>{" "}
                a{" "}
                <b>
                  <u>{luogoArrivo}</u>
                </b>{" "}
                è lungo{" "}
                <b>
                  <u>{kilometri} km</u>
                </b>
                .
                <br />
                <br />
                Il servizio scelto non è quindi valido per il viaggio
                selezionato.
              </div>
            ),
            confirmBtnText: "Ok",
            showCancel: false,
            canEscapeKeyCancel: true,
          });
          return;
        }
      } else if (kilometri < daKm || kilometri > aKm) {
        ShowConfirmationDialog({
          title: "C'è stato un errore!",
          html: (
            <div>
              {" "}
              Il servizio {servizio.description} prevede un viaggio compreso tra{" "}
              <b>
                <u>{daKm}</u>
              </b>{" "}
              e{" "}
              <b>
                <u>{aKm}</u>
              </b>{" "}
              kilometri.
              <br />
              <br />
              Il viaggio che va da{" "}
              <b>
                <u>{luogoPartenza}</u>
              </b>{" "}
              a{" "}
              <b>
                <u>{luogoArrivo}</u>
              </b>{" "}
              è lungo{" "}
              <b>
                <u>{kilometri} km</u>
              </b>
              .
              <br />
              <br />
              Il servizio scelto non è quindi valido per il viaggio selezionato.
            </div>
          ),
          confirmBtnText: "Ok",
          showCancel: false,
          canEscapeKeyCancel: true,
        });
        return;
      }
    }

    if (isAdminOrAdminCoop()) {
      let richiestaPwd = false;
      const now = new moment();
      const selectedDays = daysPickerRef.current.getSelectedDays();
      for (let giorno of selectedDays) {
        let giornoMoment = new moment(giorno);

        // Se la data è precedente ad oggi si blocca
        if (giornoMoment < now) {
          richiestaPwd = true;
          break;
        }
      }

      if (richiestaPwd) {
        const doAction = await ShowConfirmationDialog({
          title: "Inserire password",
          text: "Per inserire ordini nel passato è necessario inserire la password",
          confirmBtnText: "Si",
          canEscapeKeyCancel: true,
          input: true,
          placeholder: "Password",
          validationMsg: "Devi inserire una password valida!",
        });

        if (!doAction.confirm) return false;
        const passwordInserita = doAction.response;
        if (passwordInserita !== "ass123!") {
          await ShowConfirmationDialog({
            title: "Errore",
            text: "La password inserita non è valida!!",
            confirmBtnText: "Si",
            canEscapeKeyCancel: true,
            input: false,
          });
          return false;
        }
      }
    }

    if (!isAdminOrAdminCoop()) {
      // Rai non deve poter creare ordini < di adesso+60 minuti
      const now = new moment();
      const selectedDays = daysPickerRef.current.getSelectedDays();
      for (let giorno of selectedDays) {
        let giornoMoment = new moment(giorno);

        // Se la data è precedente ad oggi si blocca
        if (giornoMoment < now) {
          setSubmitError(true);
          setErrorMessage("Impossibile inserire richiesta, data nel passato.");
          return false;
        }

        let oraInizioMoment = new moment(oraInizio);
        if (
          moment(giornoMoment, "year").isSame(now, "year") &&
          moment(giornoMoment, "month").isSame(now, "month") &&
          moment(giornoMoment, "day").isSame(now, "day")
        ) {
          var duration = moment.duration(oraInizioMoment.diff(now));
          var minutes = duration.asMinutes();

          if (minutes < 60) {
            setSubmitError(true);
            setErrorMessage(
              "Impossibile inserire richiesta, orario d’inizio non corretto: minuti " +
                minutes
            );
            return false;
          }
        }
      }
    }

    if (
      tipologia &&
      tipologia.description &&
      tipologia.description.toLowerCase() === "modulo"
    ) {
      // In creazione ordine si possono creare servizi che non sono multipli di 4 ad esempio dalle 8 alle 17
      const oreModulo = parseInt(
        _.get(servizio, "jsonExtra.moduleHours", 0),
        10
      );
      const oreImpostate = moment
        .duration(new moment(oraFine).diff(new moment(oraInizio)))
        .asHours();

      if (oreImpostate % oreModulo !== 0) {
        setSubmitError(true);
        setErrorMessage(
          "Le ore del servizio devono essere multiplo di " + oreModulo
        );
        return false;
      }
    }
    return true;
  };

  const [note, setNote] = useState();

  const checkForSimilarOrders = async (
    days,
    startTime,
    endTime,
    production
  ) => {
    setLoading(true);
    const res = await Commands.ControlloEsistenzaOrdiniSimili(
      days,
      startTime,
      endTime,
      production.id
    );
    setLoading(false);

    if (res.hasError) {
      setSubmitError(true);
      setErrorMessage(res.message);
      return {
        continue: false,
        error: true,
      };
    }

    if (res.data.length > 0) {
      const doAction = await ShowConfirmationDialog({
        title: "Attenzione",
        html: (
          <div style={{ textAlign: "justify" }}>
            Per la produzione <b>{production.name}</b> nel giorno
            <b>{moment(startTime).format("DD/MM/YYYY")}</b>
            risultano già inseriti i seguenti ordini <br />
            <ul style={{ listStyle: "none", padding: 10, color: "black" }}>
              {res.data.map((order) => (
                <li style={{ color: "black" }}>
                  Numero ordine: <b>{order.number} </b> del{" "}
                  {moment.unix(order.periods[0].startTime).format("DD/MM/YYYY")}
                </li>
              ))}
            </ul>
            <br />
            Procedere comunque con l'inserimento degli ordini?
          </div>
        ),
        confirmBtnText: "Si",
        canEscapeKeyCancel: false,
      });

      if (doAction) {
        return {
          continue: true,
        };
      }
    }
    return {
      continue: true,
    };
  };

  const salvaOrdine = async () => {
    let cleanUorg = stripTypenames(uorg, "__typename");

    let qta = qtaModulo.getValue();
    let workers = numeroLavoratoriRichiesti.getValue();
    if (tipologia && tipologia.description === "Trasporto") {
      workers = 1;
    }

    if (tipologia.description.toLowerCase() === "modulo" && qta === 0) {
      alert("La quantità modulo non può essere 0.");
      return;
    }

    let orderValid = await orderIsValid();

    if (!orderValid) return;

    // alert("Ordine valido: Bloccato per test!!");
    // return;

    const checkSimilar = await checkForSimilarOrders(
      daysPickerRef.current.getSelectedDays(),
      oraInizio,
      oraFine,
      produzione
    );

    if (checkSimilar.error) {
      return;
    }

    if (!checkSimilar.continue) {
      clearForm();
      props.toggle();
      return;
    }

    setLoading(true);
    const res = await Commands.CreaOrdineRicorrente(
      contratto,
      utenteRichiedente,
      utenteApprovante,
      lotto,
      tipologia,
      servizio,
      produzione,
      luogoLavoro,
      cleanUorg,
      dataInizio,
      oraInizio,
      oraFine,
      workers,
      qta,
      note,
      daysPickerRef.current.getSelectedDays(),
      settore,
      studio,
      orderType.value,
      luogoPartenza,
      luogoArrivo,
      kilometri
    );
    setLoading(false);

    if (res.hasError) {
      setSubmitError(true);
      setErrorMessage(res.message);
      return;
    }

    clearForm();
    props.toggle();
  };

  // code added to strip __typeName /////////////////////////////////
  function stripTypenames(obj, propToDelete) {
    for (const property in obj) {
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        delete obj.property;
        const newData = stripTypenames(obj[property], propToDelete);
        obj[property] = newData;
      } else {
        if (property === propToDelete) {
          delete obj[property];
        }
      }
    }
    return obj;
  }

  const toggleShowCreateProduction = (newName) => {
    setShowCreateProduction({ show: !showCreateProduction.show, newName });
  };

  const toggleShowCreateUorg = (newName) => {
    setShowCreateUorg({ show: !showCreateUorg.show, newName });
  };

  const toggleShowCreateAddress = (newName) => {
    setShowCreateAddress({ show: !showCreateAddress.show, newName });
  };

  const toggleShowCreateStudy = (newName) => {
    setShowCreateStudy({ show: !showCreateStudy.show, newName });
  };

  const cambioUorg = (uorg) => {
    // setProduzione(null);
    // setLuogoLavoro(null);
    setUorg(uorg);
  };

  const cambioContratto = (contratto) => {
    cambioLotto(null);
    setContratto(contratto);
  };

  const cambioLotto = (lotto) => {
    if (tipologie.length > 1) cambioTipologia(null);
    setLotto(lotto);
  };

  const cambioTipologia = (tipologia) => {
    cambioServizio(null);
    setTipologia(tipologia);
  };

  const cambioServizio = (servizio) => {
    cambioNumeroAgenti(0);
    cambioQtaModulo(0);
    setServizio(servizio);
    if (
      tipologia &&
      tipologia.description.toLowerCase() === "modulo-trasporto" &&
      servizio
    ) {
      cambioNumeroAgenti(parseInt(servizio.jsonExtra.moduleWorkers));
      cambioQtaTrasporto(1);
    }
  };

  const checkPlannedOrder = async () => {
    if (isAdminOrAdminCoop()) {
      setShowPlannedOrder(true);
      setOrderType({ value: "ordinary", description: "Variazione" });
      return;
    }

    //Recupero min e max dal DatePicker
    let minDate = 1;
    let maxDate = 1;
    const selectedDays = daysPickerRef.current.getSelectedDays();

    for (let giorno of selectedDays) {
      let giornoUnix = new moment(new Date(giorno)).unix();
      if (giornoUnix < minDate || minDate === 1) minDate = giornoUnix;
      if (giornoUnix > maxDate || maxDate === 1) maxDate = giornoUnix;
    }

    let minDateMoment = moment.unix(minDate);
    let maxDateMoment = moment.unix(maxDate);
    let startDateMonth = moment.unix(minDateMoment.unix());
    let endDateMonth = moment.unix(maxDateMoment.unix());

    startDateMonth.date(1);
    endDateMonth.date(1);
    endDateMonth.month(endDateMonth.month() + 1).subtract(1, "day");

    const currentDateMoment = new moment(new Date());

    setShowPlannedOrder(false);
    setOrderType({ value: "ordinary", description: "Variazione" });

    // console.log("startDateMonth", startDateMonth.unix())
    // console.log("endDateMonth", endDateMonth.unix())
    //
    // console.log("minDateMoment ", minDateMoment.unix())
    // console.log("maxDateMoment ", maxDateMoment.unix())
    // console.log("currentDateMoment  ", currentDateMoment.unix())
    //
    // console.log("minDateMoment.get('month')  ", minDateMoment.get('month'))
    // console.log("maxDateMoment.get('month')  ", maxDateMoment.get('month'))
    //
    // console.log("currentDate.getMonth()  ", currentDateMoment.get('month'))
    // console.log("currentDateMoment.get('date')  ", currentDateMoment.get('date'))

    //Controlo se min e max sono nello stesso mese
    if (minDateMoment.get("month") !== maxDateMoment.get("month")) {
      alert("Le date devono essere nello stesso mese");
      return;
    }

    if (minDateMoment.get("month") === currentDateMoment.get("month")) {
      //SONO NEL MESE CORRENTE E STO INSERENDO ORDINI DI QUESTO MESE
      //L'ORDINE E' TI TIPO VARIAZIONE
      // console.log("c1 - //SONO NEL MESE CORRENTE E STO INSERENDO ORDINI DI QUESTO MESE\n//L'ORDINE E' TI TIPO VARIAZIONE")
      return;
    }

    if (currentDateMoment.get("date") <= 20) {
      console.log(
        "c2 - //STO INSERENDO ORDINI PER IL MESE SUCCESSIVO PRIMA DEL 20 DEL MESE\n//L'ORDINE è DI TIPO PIANIFICATO"
      );
      //STO INSERENDO ORDINI PER IL MESE SUCCESSIVO PRIMA DEL 20 DEL MESE
      //L'ORDINE è DI TIPO PIANIFICATO
      setShowPlannedOrder(false);
      setOrderType({ value: "planned", description: "Pianificato" });
      return;
    }

    //IL FUNZIONARIO HA APPROVATO ORDINI NEL PERIODO ?
    const ordiniApprovatiNelPeriodo = await PlannedOrdersApproved(
      startDateMonth.unix(),
      endDateMonth.unix(),
      settore.id
    );
    // console.log("ordiniApprovatiNelPeriodo", ordiniApprovatiNelPeriodo)
    if (ordiniApprovatiNelPeriodo) {
      console.log("c3 - //L'ORDINE è DI DIPO VARIAZIONE");
      //L'ORDINE è DI DIPO VARIAZIONE
      return;
    }

    //L'UTENTE RICHIEDENTE E' ABILITATO ALLA MODIFICA DELLA PIANIFCAZIONE ?
    const utenteRichiedenteAbilitatoModificaPianificazione =
      canInsertPlanedOrder();

    console.log(
      "utenteRichiedenteAbilitatoModificaPianificazione",
      utenteRichiedenteAbilitatoModificaPianificazione
    );

    if (!utenteRichiedenteAbilitatoModificaPianificazione) {
      console.log("c4 - //L'ORDINE è DI DIPO VARIAZIONE");
      //L'ORDINE è DI DIPO VARIAZIONE
      return;
    }

    if (
      currentDateMoment.get("date") >= 25
      /* TODO: Con la modifica chiesta da gugli
         gli ordin iinseriti dopo il 25 sono tutti VARIAZIONE;
         anche se uno  abilitato all'inserimento di ordini pianificati ( questo perchè l'email v2 partirebbe il 26 all'1 di notte */
    ) {
      console.log("c4-25 - //L'ORDINE è DI DIPO VARIAZIONE");
      //L'ORDINE è DI DIPO VARIAZIONE
      return;
    }

    // console.log("c5 - //L'UTENTE è ABILITATO ALL'INSERIMENTO DI ORDINI PIANIFICATI")
    //L'UTENTE è ABILITATO ALL'INSERIMENTO DI ORDINI PIANIFICATI
    setShowPlannedOrder(true);
    setOrderType({ value: "planned", description: "Pianificato" });
  };

  const clearForm = () => {
    setContratto(undefined);
  };

  if (tipologie.length === 1 && !tipologia) {
    setTipologia(tipologie[0]);
  }

  useEffect(() => {
    // console.log('UTENTE RICHIEDENTE', utenteRichiedente);

    if (!utenteRichiedente)
      setUtenteRichiedente(
        utenti.filter((utente) => utente.id === utenteLoggato.id)[0]
      );
    if (!utenteApprovante)
      setUtenteApprovante(
        utenti.filter((utente) => utente.id === utenteLoggato.id)[0]
      );
    if (initialValues.contratto === null && !contratto)
      setContratto(contracts[0]);

    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [
    contracts,
    initialValues.contratto,
    submitError,
    utenteLoggato.id,
    utenti,
  ]);

  useEffect(() => {
    cambioServizio(servizio);
  }, duplicated);

  return (
    <>
      {showCreateProduction.show && (
        <CreateProductionComponent
          contract={contratto}
          onSuccess={(res) => setProduzione(res)}
          show
          newName={showCreateProduction.newName}
          toggle={toggleShowCreateProduction}
        />
      )}

      {showCreateUorg.show && (
        <CreateUorgComponent
          contract={contratto}
          onSuccess={(res) => setUorg(res)}
          show
          newName={showCreateUorg.newName}
          toggle={toggleShowCreateUorg}
        />
      )}

      {showCreateStudy.show && (
        <CreateStudyComponent
          contract={contratto}
          onSuccess={(res) => setStudio(res)}
          show
          newName={showCreateStudy.newName}
          toggle={toggleShowCreateStudy}
        />
      )}

      {showCreateAddress.show && (
        <CreateAddressComponent
          contract={contratto}
          onSuccess={(res) => setLuogoLavoro(res)}
          show
          newName={showCreateAddress.newName}
          toggle={toggleShowCreateAddress}
        />
      )}
      <Modal
        modalClassName="modal-black"
        isOpen={props.show}
        centered
        toggle={props.toggle}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggle}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Nuovo ordine</h6>

          {props.dataToDuplicate && (
            <h6 className="title title-up">&nbsp; (DUPLICATO) </h6>
          )}
        </div>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              salvaOrdine();
            }}
          >
            <>
              {contracts.length > 1 && (
                <FormGroup className={"row"}>
                  <Col xs={3}>
                    <Label
                      className="col-form-label"
                      style={{ fontSize: "14px" }}
                      for="contratto"
                    >
                      Contratto *
                    </Label>
                  </Col>
                  <Col xs={9}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={contratto}
                      getOptionLabel={(item) => item.description}
                      onChange={(value) => cambioContratto(value)}
                      options={[
                        {
                          value: "",
                          description: "Contratto",
                          isDisabled: true,
                        },
                        ...contracts,
                      ]}
                      placeholder="Seleziona un contratto"
                    />
                  </Col>
                </FormGroup>
              )}
              {isAdminOrAdminCoop() && (
                <FormGroup className={"row"}>
                  <Col xs={3}>
                    <Label
                      className="col-form-label"
                      style={{ fontSize: "14px" }}
                      for="contratto"
                    >
                      Richiedente *
                    </Label>
                  </Col>
                  <Col xs={9}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={utenteRichiedente}
                      getOptionLabel={(item) => item.username}
                      onChange={(value) => setUtenteRichiedente(value)}
                      options={[
                        {
                          value: "",
                          description: "Utente",
                          isDisabled: true,
                        },
                        ...utenti,
                      ]}
                      placeholder="Selez. un utente richiedente"
                    />
                  </Col>
                </FormGroup>
              )}
              {isAdminOrAdminCoop() && (
                <FormGroup className={"row"}>
                  <Col xs={3}>
                    <Label className="col-form-label" for="contratto">
                      Approvante *
                    </Label>
                  </Col>
                  <Col xs={9}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={utenteApprovante}
                      getOptionLabel={(item) => item.username}
                      onChange={(value) => setUtenteApprovante(value)}
                      options={[
                        {
                          value: "",
                          description: "Utente",
                          isDisabled: true,
                        },
                        ...utenti,
                      ]}
                      placeholder="Selez. un utente approvante"
                    />
                  </Col>
                </FormGroup>
              )}
            </>

            <fieldset
              disabled={!(contratto && utenteApprovante && utenteRichiedente)}
            >
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="lotto">
                    Settore *
                  </Label>
                </Col>
                <Col xs={9}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={settore}
                    getOptionLabel={(item) => item.description}
                    onChange={(value) => {
                      setSettore(value);
                      lotti.forEach((lotto) => {
                        if (lotto.sectors.includes(value.id)) {
                          setLotto(lotto);

                          console.log("LOTTO", lotto);
                        }
                      });
                    }}
                    options={[
                      {
                        value: "",
                        description: "Settore",
                        isDisabled: true,
                      },
                      ...settori.filter(
                        (sett) => contratto && sett.contract.id === contratto.id
                      ),
                    ]}
                    placeholder="Seleziona un settore"
                  />
                </Col>
              </FormGroup>
            </fieldset>

            <fieldset disabled={true}>
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="lotto">
                    Lotto *
                  </Label>
                </Col>
                <Col xs={9}>
                  <Select
                    inputProps={{ readOnly: true }}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={lotto}
                    isDisabled={true}
                    getOptionLabel={(item) => item.name}
                    readOnly
                    options={[
                      {
                        value: "",
                        name: "Lotto",
                        isDisabled: true,
                      },
                      ...lotti.filter(
                        (lotto) =>
                          contratto && lotto.contract.id === contratto.id
                      ),
                    ]}
                    placeholder="Seleziona un lotto"
                  />
                </Col>
              </FormGroup>
            </fieldset>

            {tipologie.length > 1 && (
              <fieldset disabled={!lotto}>
                <FormGroup className={"row"}>
                  <Col xs={3}>
                    <Label className="col-form-label" for="tipologia">
                      Tipologia *
                    </Label>
                  </Col>
                  <Col xs={9}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={tipologia}
                      getOptionLabel={(item) => item.description}
                      onChange={(value) => cambioTipologia(value)}
                      options={[
                        {
                          value: "",
                          description: "Tipologia",
                          isDisabled: true,
                        },
                        ...tipologie,
                      ]}
                      placeholder="Seleziona una tipologia"
                    />
                  </Col>
                </FormGroup>
              </fieldset>
            )}

            <fieldset disabled={!tipologia}>
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="tipologia">
                    Servizio *
                  </Label>
                </Col>
                <Col xs={9}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={servizio}
                    getOptionLabel={(item) => {
                      const voce = _.get(item, "jsonExtra.voce", null);
                      return item.description + (voce ? ` (Voce:${voce})` : "");
                    }}
                    onChange={(value) => {
                      cambioServizio(value);
                    }}
                    options={[
                      {
                        value: "",
                        name: "Servizio",
                        isDisabled: true,
                      },
                      ..._.orderBy(
                        servizi.filter((servizio) => {
                          const lottoServizio = _.get(
                            servizio,
                            "jsonExtra.lot.id",
                            undefined
                          );
                          const lottoSelezionato = _.get(
                            lotto,
                            "id",
                            undefined
                          );

                          const tipologiaServizio = _.get(
                            servizio,
                            "jsonExtra.type",
                            ""
                          );
                          const tipologiaSelezionata = _.get(
                            tipologia,
                            "description",
                            undefined
                          );

                          return (
                            lottoServizio &&
                            lottoServizio === lottoSelezionato &&
                            tipologiaSelezionata &&
                            tipologiaSelezionata.toLowerCase() ===
                              tipologiaServizio.toLowerCase()
                          );
                        }),
                        ["description"]
                      ),
                    ]}
                    placeholder="Seleziona un servizio"
                  />
                </Col>
              </FormGroup>
            </fieldset>

            <fieldset disabled={!servizio}>
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="lotto">
                    Prod/Attività *
                  </Label>
                </Col>
                <Col xs={9}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={produzione}
                    noOptionsMessage={(query) => {
                      return (
                        <Button
                          color="primary"
                          type="button"
                          onClick={() =>
                            toggleShowCreateProduction(query.inputValue)
                          }
                        >
                          Crea nuova produzione
                        </Button>
                      );
                    }}
                    getOptionLabel={(item) => {
                      const matricola = _.get(item, "number", null);
                      return (
                        item.name + (matricola ? ` ( Matr. ${matricola})` : "")
                      );
                    }}
                    onChange={(production) => {
                      setProduzione(production);
                      setUorg(null);

                      if (
                        production.tipologia === "K" ||
                        production.tipologia === "F"
                      ) {
                        let productionUorg = production.number;
                        productionUorg = productionUorg.split(".");

                        uorgs.forEach((uorg) => {
                          if (uorg.number === productionUorg[0]) {
                            setUorg(uorg);
                          }
                        });
                      }
                    }}
                    options={[
                      {
                        value: "",
                        name: "Produzione",
                        isDisabled: true,
                      },
                      ..._.orderBy(produzioni, ["name"]),
                    ]}
                    placeholder="Seleziona una produzione/attività"
                  />
                </Col>
              </FormGroup>
            </fieldset>

            <fieldset disabled={!produzione}>
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="uorg">
                    Uorg *
                  </Label>
                </Col>
                <Col xs={9}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={uorg}
                    getOptionLabel={(item) => {
                      const descr = _.get(item, "description", null);
                      return item.number + (descr ? ` (${descr})` : "");
                    }}
                    onChange={(value) => cambioUorg(value)}
                    noOptionsMessage={(query) => {
                      return (
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => toggleShowCreateUorg(query.inputValue)}
                        >
                          Crea nuovo Uorg
                        </Button>
                      );
                    }}
                    options={[
                      {
                        value: "",
                        number: "Uorg",
                        isDisabled: true,
                      },
                      ...uorgs,
                    ]}
                    placeholder="Seleziona uno Uorg"
                  />
                </Col>
              </FormGroup>
            </fieldset>

            <fieldset disabled={!uorg}>
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="lotto">
                    Studio
                  </Label>
                </Col>
                <Col xs={9}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={studio}
                    getOptionLabel={(item) => {
                      return item.name;
                    }}
                    onChange={(value) => {
                      setStudio(value);

                      luoghiLavoro.forEach((luogo) => {
                        if (luogo.id === value.address.id) {
                          setLuogoLavoro(luogo);
                        }
                      });
                    }}
                    noOptionsMessage={(query) => {
                      return (
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            toggleShowCreateStudy(query.inputValue);
                          }}
                        >
                          Crea nuovo studio
                        </Button>
                      );
                    }}
                    options={[
                      {
                        value: "",
                        description: "Studio",
                        isDisabled: true,
                      },
                      ...studi,
                    ]}
                    placeholder="Seleziona un luogo di lavoro"
                  />
                </Col>
              </FormGroup>
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="lotto">
                    Luogo *
                  </Label>
                </Col>
                <Col xs={9}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={luogoLavoro}
                    getOptionLabel={(item) => {
                      const formAddr = _.get(item, "formattedAdress", null);
                      return (
                        item.description + (formAddr ? ` (${formAddr})` : "")
                      );
                    }}
                    onChange={(value) => setLuogoLavoro(value)}
                    noOptionsMessage={(query) => {
                      return (
                        <Button
                          color="primary"
                          type="button"
                          onClick={() =>
                            toggleShowCreateAddress(query.inputValue)
                          }
                        >
                          Crea nuovo indirizzo
                        </Button>
                      );
                    }}
                    options={[
                      {
                        value: "",
                        description: "Luogo di lavoro",
                        isDisabled: true,
                      },
                      ...luoghiLavoro,
                    ]}
                    placeholder="Seleziona un luogo di lavoro"
                  />
                </Col>
              </FormGroup>
            </fieldset>

            {/*

                                                */}
            <Col xs={12} className={"d-flex justify-content-center"}>
              <SparkleDayPicker
                ref={daysPickerRef}
                onChange={() => {
                  checkPlannedOrder();

                  setOraInizio(
                    moment()
                      .set({
                        hour: 8,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      })
                      .toDate()
                  );
                  setOraFine(
                    moment()
                      .set({
                        hour: 12,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      })
                      .toDate()
                  );
                }}
              />
            </Col>

            {tipologia &&
            tipologia.description === "Modulo-Trasporto" &&
            servizio ? (
              <Alert color={"info"}>
                IL servizio{" "}
                <u>
                  <b>{servizio.description}</b>
                </u>{" "}
                vale{" "}
                <u>
                  <b>{servizio.jsonExtra.moduleHours}</b>
                </u>{" "}
                ore.
              </Alert>
            ) : null}

            <FormGroup className={"row"}>
              <Col xs={3}>
                <Label className="col-form-label" for="lotto">
                  Ora inizio *
                </Label>
              </Col>

              <Col xs={9}>
                <Datetime
                  className={"sparkleTimePicker"}
                  dateFormat={false}
                  timeFormat="HH:mm"
                  inputProps={{
                    className: "form-control",
                    placeholder: "Time picker",
                  }}
                  onChange={(startTime) => {
                    if (
                      tipologia &&
                      tipologia.description !== "Trasporto" &&
                      tipologia.description !== "Modulo-Trasporto"
                    ) {
                      cambioNumeroAgenti(0);
                      cambioQtaModulo(0);
                    }
                    if (typeof startTime === "object") {
                      setOraInizio(startTime.toDate());
                    }
                  }}
                  value={oraInizio}
                />
              </Col>
            </FormGroup>

            <FormGroup className={"row"}>
              <Col xs={3}>
                <Label className="col-form-label" for="lotto">
                  Ora fine *
                </Label>
              </Col>

              <Col xs={9}>
                <Datetime
                  className={"sparkleTimePicker"}
                  dateFormat={false}
                  timeFormat="HH:mm"
                  inputProps={{
                    className: "form-control",
                    placeholder: "Time picker",
                  }}
                  onChange={(endTime) => {
                    if (
                      tipologia &&
                      tipologia.description !== "Trasporto" &&
                      tipologia.description !== "Modulo-Trasporto"
                    ) {
                      cambioNumeroAgenti(0);
                      cambioQtaModulo(0);
                    }

                    if (typeof endTime === "object") {
                      if (
                        endTime.toDate().getHours() <= oraInizio.getHours() &&
                        endTime.toDate().getDate() === oraInizio.getDate()
                      ) {
                        const cl = endTime.clone();
                        setOraFine(cl.add(1, "day").toDate());
                        return;
                      } else if (
                        endTime.toDate().getHours() >= oraInizio.getHours() &&
                        endTime.toDate().getDate() !== oraInizio.getDate()
                      ) {
                        const cl = endTime.clone();
                        setOraFine(cl.add(-1, "day").toDate());
                        return;
                      }

                      setOraFine(endTime.toDate());
                    }
                  }}
                  value={oraFine}
                />
              </Col>
            </FormGroup>

            {tipologia && tipologia.description === "Modulo" && (
              <FormGroup className={"row"}>
                <Col xs={3}>
                  <Label className="col-form-label" for="lotto">
                    Qta Modulo *
                  </Label>
                </Col>

                <Col xs={9}>
                  <Input
                    placeholder="Inserisci qta modulo"
                    {...qtaModulo.input}
                    onChange={(e) => cambioQtaModulo(e.target.value)}
                  />
                </Col>
              </FormGroup>
            )}

            {tipologia &&
            tipologia.description === "Modulo-Trasporto" &&
            servizio ? (
              <Alert color={"warning"}>
                IL servizio{" "}
                <u>
                  <b>{servizio.description}</b>
                </u>{" "}
                permette un viaggio che va da{" "}
                <u>
                  <b>{servizio.jsonExtra.daKm}</b>
                </u>{" "}
                Km a{" "}
                <u>
                  <b>{servizio.jsonExtra.aKm}</b>
                </u>{" "}
                Km
              </Alert>
            ) : null}

            {tipologia &&
              (tipologia.description === "Trasporto" ||
                tipologia.description === "Modulo-Trasporto") && (
                <>
                  <FormGroup className={"row"}>
                    <Col xs={3}>
                      <Label className="col-form-label" for="contratto">
                        Luogo partenza
                      </Label>
                    </Col>
                    <Col xs={9}>
                      <Geosuggest
                        ref={luogoPartenzaRef}
                        inputClassName={"form-control"}
                        initialValue={luogoPartenza}
                        placeholder={"Inserisci indirizzo.."}
                        onSuggestSelect={onSuggestLuogoPartenza}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className={"row"}>
                    <Col xs={3}>
                      <Label className="col-form-label" for="contratto">
                        Luogo arrivo
                      </Label>
                    </Col>
                    <Col xs={9}>
                      <Geosuggest
                        inputValue={luogoArrivo}
                        ref={luogoArrivoRef}
                        inputClassName={"form-control"}
                        initialValue={luogoArrivo}
                        placeholder={"Inserisci indirizzo.."}
                        onSuggestSelect={onSuggestLuogoArrivo}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className={"row"}>
                    <Col xs={3}>
                      {" "}
                      <Label className="col-form-label" for="contratto">
                        Andata e ritorno
                      </Label>
                    </Col>
                    <Col xs={9}>
                      <Checkbox
                        checked={andataERitorno}
                        onChange={() => {
                          setAndataERitorno(!andataERitorno);
                          if (!andataERitorno) {
                            setKilometri(kilometri * 2);
                          } else {
                            setKilometri(kilometri / 2);
                          }
                        }}
                      ></Checkbox>
                    </Col>
                  </FormGroup>

                  {calcoloKilometri ? (
                    <i className="fa fa-spin fa-spinner" />
                  ) : (
                    <>
                      <FormGroup className={"row"}>
                        <Col xs={3}>
                          {" "}
                          <Label className="col-form-label" for="contratto">
                            KM calcolati:
                          </Label>
                        </Col>
                        <Col xs={9}>
                          <div style={{ marginTop: 5 }}>{kilometri}</div>
                        </Col>
                      </FormGroup>

                      <FormGroup className={"row"}>
                        <Col xs={3}>
                          <Label className="col-form-label" for="lotto">
                            Qta *
                          </Label>
                        </Col>

                        <Col xs={9}>
                          <Input
                            disabled={
                              tipologia &&
                              tipologia.description.toLowerCase() ===
                                "modulo-trasporto"
                            }
                            placeholder="Inserisci qta"
                            {...qtaModulo.input}
                            onChange={(e) => cambioQtaTrasporto(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </>
              )}

            {isAdminOrAdminCoop() &&
              tipologia &&
              tipologia.description !== "Trasporto" && (
                <FormGroup className={"row"}>
                  <Col xs={3}>
                    <Label className="col-form-label" for="lotto">
                      Numero agenti *
                    </Label>
                  </Col>

                  <Col xs={9}>
                    <Input
                      disabled={
                        tipologia &&
                        tipologia.description.toLowerCase() ===
                          "modulo-trasporto"
                      }
                      placeholder="Inserisci numero agenti"
                      {...numeroLavoratoriRichiesti.input}
                      onChange={(e) => cambioNumeroAgenti(e.target.value)}
                    />
                  </Col>
                </FormGroup>
              )}

            <FormGroup className={"row"}>
              <Col xs={3}>
                <Label for="contratto">Note</Label>
              </Col>

              <Col xs={9}>
                {" "}
                <Input
                  type="textarea"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Col>
            </FormGroup>

            {showPlannedOrder && (
              <fieldset>
                <FormGroup className={"row"}>
                  <Col xs={3}>
                    <Label className="col-form-label" for="tipoOrdine">
                      Tipo ordine *
                    </Label>
                  </Col>
                  <Col xs={9}>
                    <Select
                      value={orderType}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(value) => setOrderType(value)}
                      getOptionLabel={(item) => {
                        return item.description;
                      }}
                      options={[
                        {
                          value: "Tipologia",
                          name: "Tipologia",
                          isDisabled: true,
                        },
                        { value: "planned", description: "Pianificato" },
                        { value: "ordinary", description: "Variazione" },
                      ]}
                      placeholder="Seleziona tipo ordine"
                    />
                  </Col>
                </FormGroup>
              </fieldset>
            )}

            {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
            <button style={{ display: "none" }} type="submit" />
          </form>
          <CSSTransition
            in={submitError}
            appear={true}
            unmountOnExit
            classNames="error"
            timeout={300}
          >
            <blockquote className="blockquote blockquote-danger">
              <p className="mb-0 text-danger">
                Non è stato possibile salvare l'ordine.
              </p>
              <p className="mb-0 text-danger">{errorMessage}</p>
            </blockquote>
          </CSSTransition>
        </ModalBody>
        {!showCreateProduction.show &&
          !showCreateUorg.show &&
          !showCreateAddress.show &&
          !showCreateStudy.show && (
            <ModalFooter>
              <Button
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={props.toggle}
              >
                Annulla
              </Button>
              <Button color="primary" type="button" onClick={salvaOrdine}>
                {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
              </Button>
            </ModalFooter>
          )}
      </Modal>
    </>
  );
}
