import Dashboard from "views/Dashboard.jsx";
import Login from "views/pages/Login/login.jsx";
import Produzioni from "views/rai/produzioni/produzioni.component";
import Lotti from "views/rai/lotti/lotti.component";
import MotiviAssenza from "views/rai/motivi/motivi.component";
import Budgets from "views/rai/budgets/budgets.component";
import Studi from "views/rai/studi/studi.component";
import Ordini from "views/rai/ordini/ordini.component";
import Servizi from "views/rai/servizi/servizi.component";
import Indirizzi from "views/rai/indirizzi/indirizzi.component";
import Lavoratori from "views/rai/lavoratori/lavoratori.component";
import Documents from "views/rai/documents/document.component";
import DocumentType from "views/rai/documentsType/documentType.component";
import Utenti from "views/rai/utenti/utenti.component";
import Contratti from "views/rai/contratti/contratti.component";
import Uorg from "views/rai/uorg/uorg.component";
import Settori from "views/rai/settori/settori.component";
import Clienti from "views/rai/clienti/clienti.component";
import Analitycs from "views/rai/analytics/analytics.component"
import AnalitycsSoci from "views/rai/analyticsSoci/analitycsSoci.component"
import AnalitycsStatisticheLavoratori
    from "views/rai/analyticsStatisticheLavoratori/analitycsStatisticheLavoratori.component"
import GeoLavoratori from "views/rai/geoLavoratori/geoLavoratori.component"
import GeoMezzi from "views/rai/geoMezzi/geoMezzi.component"
import ExportDb from "views/rai/exportDb/exportDb.component"
import Email from "views/rai/email/email.component"
import ViewAssistence from "views/rai/ViewAssistence/assistance"
import MagazzinoContainer from "./views/rai/magazzinoContainer/magazzinoContainer.component";
import Coops from "./views/rai/coops/coops.component";

import {
    RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP,
    RUOLO_AUSILIARIO_RAI,
    RUOLO_COGECO_RAI,
    RUOLO_FUNZIONARIO_RAI,
    RUOLO_OPERATIVO,
    RUOLO_OPERATORE_SAP_RAI, RUOLO_REFERENTE_CANONE, RUOLO_REFERENTE_RAI,
    RUOLO_REFERENTE_SAP_RAI,
    RUOLO_RICHIEDENTE_RAI,
    RUOLO_FIRMA_ORDINI,
    RUOLO_SUPERVISORE_RAI
} from "./views/rai/utenti/components/costants";
import AiutoComponent from "views/rai/Aiuto/aiuto.component";
import SetupMezziComponent from "./views/rai/setupMezzi/setupMezzi.component";
import SetupMailAccountComponent from "./views/rai/setupMailAccount/setupMailAccount.component";


let companyName = "Oversinerco";
if (window.location.hostname.indexOf("savilog") >= 0) {
    companyName = "Savilog";
}

const routes = [
    {
        path: "/dashboard",
        name: "KPI",
        icon: "tim-icons icon-chart-bar-32",
        component: Dashboard,
        layout: "/admin",
        roles: [RUOLO_AMMINISTRATORE]
    },
    {
        path: "/ordini",
        name: "Ordini",
        icon: "iconmoon-order",
        component: Ordini,
        layout: "/admin",
        roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_RICHIEDENTE_RAI, RUOLO_SUPERVISORE_RAI, RUOLO_AUSILIARIO_RAI, RUOLO_REFERENTE_SAP_RAI, RUOLO_REFERENTE_CANONE, RUOLO_REFERENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_COGECO_RAI, RUOLO_OPERATIVO,
            RUOLO_FIRMA_ORDINI]
    },
    // {
    //     path: "/assegnazioni",
    //     name: "Assegnazioni",
    //     icon: "iconmoon-bricks",
    //     component: Assegnazioni,
    //     layout: "/admin",
    //     roles: [RUOLO_AMMINISTRATORE]
    // },
    {
        path: "/lavoratori",
        name: "Lavoratori",
        icon: "iconmoon-visitor-identification",
        component: Lavoratori,
        layout: "/admin",
        roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO]
    },
    {
        path: "/analitycs",
        name: "Analytics",
        icon: "iconmoon-profit",
        component: Analitycs,
        layout: "/admin",
        roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_SUPERVISORE_RAI, RUOLO_AUSILIARIO_RAI, RUOLO_REFERENTE_SAP_RAI, RUOLO_REFERENTE_CANONE, RUOLO_REFERENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_OPERATORE_SAP_RAI, RUOLO_REFERENTE_SAP_RAI, RUOLO_COGECO_RAI, RUOLO_OPERATIVO]
    },
    // {
    //     path: "/analisiSoci",
    //     name: "Analitycs soci",
    //     brandText: "Analitycs soci",
    //     icon: "iconmoon-management",
    //     component: AnalitycsSoci,
    //     layout: "/admin",
    //     roles: [RUOLO_AMMINISTRATORE]
    //
    // },

    {
        collapse: true,
        name: "Analytics Soci",
        icon: "iconmoon-square",
        state: "AnalyticsLavoratori",
        roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
        views: [
            {
                path: "/analisiStatisticheSoci",
                name: "Statistiche",
                icon: "iconmoon-management",
                component: AnalitycsStatisticheLavoratori,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO]

            },
            {
                path: "/analisiSociLiberi",
                name: "Soci liberi",
                icon: "iconmoon-management",
                component: AnalitycsSoci,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO]

            },
        ]
    },

    {
        path: "/mapgeoLavoratori",
        name: "Geo Lavoratori",
        brandText: "Geo Lavoratori",
        icon: "iconmoon-location-on-map",
        component: GeoLavoratori,
        layout: "/admin",
        roles: [
            RUOLO_AMMINISTRATORE,
            RUOLO_AMMINISTRATORE_COOP,
            ((companyName === "Oversinerco") ? RUOLO_SUPERVISORE_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_AUSILIARIO_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_REFERENTE_SAP_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_REFERENTE_CANONE : ''),
            ((companyName === "Oversinerco") ? RUOLO_REFERENTE_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_FUNZIONARIO_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_COGECO_RAI : ''),
        ]
    },
    {
        path: "/mapgeoMezzi",
        name: "Geo Mezzi",
        brandText: "Geo Mezzi",
        icon: "iconmoon-location-on-map",
        component: GeoMezzi,
        layout: "/admin",
        roles: [
            RUOLO_AMMINISTRATORE,
            RUOLO_AMMINISTRATORE_COOP,
            ((companyName === "Oversinerco") ? RUOLO_SUPERVISORE_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_AUSILIARIO_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_REFERENTE_SAP_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_REFERENTE_CANONE : ''),
            ((companyName === "Oversinerco") ? RUOLO_REFERENTE_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_FUNZIONARIO_RAI : ''),
            ((companyName === "Oversinerco") ? RUOLO_COGECO_RAI : ''),
        ]
    },
    {
        collapse: true,
        name: "Tabelle",
        icon: "iconmoon-square",
        state: "Tabelle2PagesCollapse",
        roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_SUPERVISORE_RAI, RUOLO_AUSILIARIO_RAI, RUOLO_REFERENTE_SAP_RAI, RUOLO_REFERENTE_CANONE, RUOLO_REFERENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_COGECO_RAI],
        views: [
            {
                path: "/utentiRai",
                name: "Utenti",
                icon: "sub-menu-icon iconmoon-clerk-with-tie",
                component: Utenti,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_FUNZIONARIO_RAI]
            },
            {
                path: "/clienti",
                name: "Clienti",
                icon: "sub-menu-icon iconmoon-customer",
                component: Clienti,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]

            },
            {
                path: "/contratti",
                name: "Contratti",
                icon: "sub-menu-icon iconmoon-writing",
                component: Contratti,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]

            },
            {
                path: "/lotti",
                name: "Lotti",
                icon: "sub-menu-icon iconmoon-lotti",
                component: Lotti,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
            {
                path: "/motiviassenza",
                name: "Motivi assenza",
                icon: "sub-menu-icon iconmoon-square",
                component: MotiviAssenza,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
            {
                path: "/studi",
                name: "Studi",
                icon: "sub-menu-icon iconmoon-lotti",
                component: Studi,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_SUPERVISORE_RAI, RUOLO_AUSILIARIO_RAI, RUOLO_REFERENTE_SAP_RAI, RUOLO_REFERENTE_CANONE, RUOLO_REFERENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_COGECO_RAI]
            },
            {
                path: "/uorg",
                name: "Uorg",
                icon: "sub-menu-icon iconmoon-uorg",
                component: Uorg,
                layout: "/admin",
                roles: [
                    RUOLO_AMMINISTRATORE,
                    RUOLO_AMMINISTRATORE_COOP,
                    RUOLO_SUPERVISORE_RAI,
                    RUOLO_AUSILIARIO_RAI,
                    RUOLO_REFERENTE_SAP_RAI,
                    RUOLO_REFERENTE_CANONE,
                    RUOLO_REFERENTE_RAI,
                    RUOLO_FUNZIONARIO_RAI,
                    ((companyName === "Oversinerco") ? RUOLO_COGECO_RAI : ''),

                ]
            },
            {
                path: "/sectors",
                name: "Settori",
                icon: "sub-menu-icon iconmoon-website-with-different-sections",
                component: Settori,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP],
            },
            {
                path: "/indirizzi",
                name: "Indirizzi",
                icon: "sub-menu-icon iconmoon-international-delivery",
                component: Indirizzi,
                layout: "/admin",
                roles: [
                    RUOLO_AMMINISTRATORE,
                    RUOLO_AMMINISTRATORE_COOP,
                    RUOLO_SUPERVISORE_RAI,
                    RUOLO_AUSILIARIO_RAI,
                    RUOLO_REFERENTE_SAP_RAI,
                    RUOLO_REFERENTE_CANONE,
                    RUOLO_REFERENTE_RAI,
                    RUOLO_FUNZIONARIO_RAI,
                    ((companyName === "Oversinerco") ? RUOLO_COGECO_RAI : ''),

                ]

            },
            {
                path: "/produzioni",
                name: "Produzioni",
                icon: "sub-menu-icon iconmoon-live-1",
                component: Produzioni,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_SUPERVISORE_RAI, RUOLO_AUSILIARIO_RAI, RUOLO_REFERENTE_SAP_RAI, RUOLO_REFERENTE_CANONE, RUOLO_REFERENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_COGECO_RAI]

            },
            {
                path: "/servizi",
                name: "Servizi",
                icon: "sub-menu-icon iconmoon-delivery-packages-on-a-trolley",
                component: Servizi,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
            {
                path: "/budgets",
                name: "Budgets",
                icon: "sub-menu-icon iconmoon-lotti",
                component: Budgets,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_COGECO_RAI, RUOLO_AMMINISTRATORE_COOP]
            },

        ]
    },
    {
        path: "/magazzino/container",
        name: "Magazzino",
        icon: "iconmoon-forklift-with-boxes",
        component: MagazzinoContainer,
        layout: "/s",
        roles: [
            RUOLO_AMMINISTRATORE,
            RUOLO_AMMINISTRATORE_COOP,
            ((companyName === "Oversinerco") ? RUOLO_COGECO_RAI : ''),
        ]
    },

    {
        collapse: true,
        name: "Documenti",
        state: "DocuemntiPagesCollapse",
        icon: "iconmoon-settings-gears",
        roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP],
        views: [
            {
                path: "/documentsList",
                name: "Elenco",
                icon: "sub-menu-icon iconmoon-walkie-talkie",
                component: Documents,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]

            },
            {
                path: "/documentsType",
                name: "Tipologie",
                icon: "sub-menu-icon iconmoon-walkie-talkie",
                component: DocumentType,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]

            },
        ]

    },
    {
        collapse: true,
        name: "Utility",
        state: "UtilityPagesCollapse",
        icon: "iconmoon-settings-gears",
        views: [
            {
                path: "/assistance",
                name: "Richieste assistenza",
                icon: "sub-menu-icon iconmoon-video-editing",
                component: ViewAssistence,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
            {
                path: "/setupmezzi",
                name: "Setup mezzi",
                icon: "sub-menu-icon iconmoon-video-editing",
                component: SetupMezziComponent,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
            // {
            //     path: "/walkie",
            //     name: "Walkie Talkie",
            //     icon: "sub-menu-icon iconmoon-walkie-talkie",
            //     component: WalkieTalkieComponent,
            //     layout: "/admin"
            // },

            {
                path: "/aiuto",
                name: "Aiuto",
                icon: "sub-menu-icon iconmoon-help",
                component: AiutoComponent,
                layout: "/admin"
            },
            {
                path: "/esportazioneDB",
                name: "Esportazione DB",
                icon: "sub-menu-icon iconmoon-database",
                component: ExportDb,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE]
            },

            {
                path: "/email",
                name: "Email",
                icon: "sub-menu-icon iconmoon-database",
                component: Email,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
        ]
    },
    {
        collapse: true,
        name: "Setup",
        state: "SetupPagesCollapse",
        icon: "iconmoon-settings-gears",
        views: [
            {
                path: "/mail",
                name: "E-Mail / PEC",
                icon: "sub-menu-icon iconmoon-video-editing",
                component: SetupMailAccountComponent,
                layout: "/admin",
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
            {
                path: "/coops",
                name: "Coops",
                icon: "sub-menu-icon iconmoon-video-editing",
                component: Coops,
                layout: "/admin",
                // TODO REMOVE RUOLO AMMINISTRATORE COOP DA QUA, SERVE SOLO PER I TEST
                roles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP]
            },
        ]
    },
    {
        path: "/login",
        icon: "tim-icons icon-image-02",
        name: "Login",
        component: Login,
        layout: "/auth",
        visible: false
    },
];

export default routes;

