import * as types from "./ordini.types.js";

export function created(customer){
    return {
        type: types.CREATED,
        payload: customer
    }
}

export function readedAll(obj){
    return {
        type: types.READED_ALL,
        payload: obj
    }
}

export function requestRead(){
    return {
        type: types.REQUEST_READ
    }
}

export function readed(obj){
    return {
        type: types.READED,
        payload: obj
    }
}


//UPDATE
export function requestUpdate(obj){
    return {
        type: types.REQUEST_UPDATE,
        payload: obj
    }
}

export function updated(obj){
    return {
        type: types.UPDATED,
        payload: obj
    }
}

//DELETE
export function requestDelete(id){
    return {
        type: types.REQUEST_DELETE,
        payload: id
    }
}


export function deleteError(id){
    return {
        type: types.DELETE_ERROR,
        payload: id
    }
}

export function deleted(obj) {
    return {
        type: types.DELETED,
        payload: obj
    };
}

export function changedState(order) {
    return {
        type: types.ORDER_CHANGED_STATE,
        payload: order
    };
}