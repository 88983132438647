import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './document.types';
import * as actions from './document.actions';
import {createUploadLink} from "apollo-upload-client";
import _ from 'lodash';
import React from "react";
import moment from "moment";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";


export function ImportGescoopDocument(workerId) {


    const variables = {workerId};

    const query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation importGescoopDocument (
            $workerId: String!
        ) {
            createdObject:  importGescoopDocument(
                workerId:$workerId,
            ) {
                id
                createdByUserId
                createdByUserUsername
                documentTypeId
                documentUrl
                state
                periodUnix
                metas
                worker{
                    id
                    cognomeNome
                    cooperativaAppartenenza
                    luogoPartenza
                    matricola
                }
                documentType{
                    id
                    description
                    private
                    metas
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            data.metas = JSON.parse(data.metas);

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            let message = 'Impossibile impportare documenti';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}


export function Create(periodUnix, content, workerIds, documentTypeId, metas) {
    const jsonMetas = JSON.stringify(metas);
    const variables = {periodUnix, content: content[0], workerIds, documentTypeId, metas: jsonMetas};

    const query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createDocument (
            $periodUnix: Int!
            $content: Upload!
            $workerIds: [String!]!
            $documentTypeId: String!
            $metas: String!
        ) {
            createdObject:  createDocument(
                periodUnix:$periodUnix,
                content:$content,
                workerId:$workerIds,
                documentTypeId:$documentTypeId,
                metas:$metas,
            ) {
                id
                createdByUserId
                createdByUserUsername
                documentTypeId
                documentUrl
                state
                periodUnix
                metas
                worker{
                    id
                    cognomeNome
                    cooperativaAppartenenza
                    luogoPartenza
                    matricola
                }
                documentType{
                    id
                    description
                    private
                    metas
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            data.forEach(row => {
                try {
                    if (typeof row.metas !== "string") return;
                    if (row.metas.length === 0) return;
                    row.metas = JSON.parse(row.metas)
                } catch (error) {
                    // console.log("Error with JSON.parse", {error, metas: row.metas});
                    row.metas = [];
                }
            });

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            let message = 'Impossibile creare documento';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function ReadAll(filters) {

    const variables = {
        filters
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query readAllDocs($filters: [Filter]){
            objectList :  ReadAllDocument(filters: $filters){
                    id
                    createdByUserId
                    createdByUserUsername
                    documentTypeId
                    documentUrl
                    periodUnix
                    state
                    metas
                    worker{
                        id
                        cognomeNome
                        cooperativaAppartenenza
                        luogoPartenza
                        matricola
                    }
                    documentType{
                        id
                        description
                        private
                        metas
                    }
              }
         
        }        `,
    };

    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                data.forEach(row => {
                    try {
                        if (typeof row.metas !== "string") return;
                        if (row.metas.length === 0) return;
                        row.metas = JSON.parse(row.metas)
                    } catch (error) {
                        // console.log("Error with JSON.parse", {error, metas: row.metas});
                        row.metas = [];
                    }
                });

                // console.log('DATA TO RETURN', data);

                return {
                    hasError: false,
                    data
                };
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i documenti";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function ReadAllDocumentByString(text) {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {text};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query (
            $text: String!
        )
        {
            objectList: ReadAllDocumentByString(
                text: $text
            ) {
                id
                createdByUserId
                createdByUserUsername
                documentTypeId
                documentUrl
                periodUnix
                state
                metas
                worker{
                    id
                    cognomeNome
                    cooperativaAppartenenza
                    luogoPartenza
                    matricola
                }
                documentType{
                    id
                    description
                    private
                }
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = JSON.parse(JSON.stringify(response.data.objectList));

                data.map(row => {

                    try {
                        row.metas = JSON.parse(row.metas)
                    } catch (error) {
                        row.metas = [];
                    }
                });


                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere gli UORG";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function DownloadDocument(documentId) {


    let variables = {documentId};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        query: gql`,
        query (
            $documentId: String
        )
        {
            readedObject: downloadDocument(
                documentId: $documentId
            ) {
                OriginalFileName
                Base64
                FileType
            }
        }
        `
    };

    return Client
        .query(query)
        .then(async (response) => {
            const data = response.data.readedObject;

            return {
                data,
                hasError: false
            };
        })
        .catch((e) => {
            var message = "Impossibile scaricare documento";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true, message
            };
        });
}

export function UpdateDocument(id, period, workerId, documentTypeId, metas) {

    const jsonMetas = JSON.stringify(metas)

    let periodUnix = moment(period.year + '-' + period.month + '-15').unix()
    const variables = {id, periodUnix, workerId, documentTypeId, metas: jsonMetas};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation updateDocument (
            $id: String!
            $periodUnix: Int!
            $workerId: String!
            $documentTypeId: String!
            $metas: Json!
        ) {
            createdObject:  updateDocument(
                id:$id,
                periodUnix:$periodUnix,
                workerId:$workerId,
                documentTypeId:$documentTypeId,
                metas:$metas,
            ) {
                id
                createdByUserId
                createdByUserUsername
                documentTypeId
                documentUrl
                state
                periodUnix
                metas
                worker{
                    id
                    cognomeNome
                    cooperativaAppartenenza
                    luogoPartenza
                    matricola
                }
                documentType{
                    id
                    description
                    private
                }
            }
        }
        `
    };

    // console.log("QUERU", query.mutation)
    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }
            data.metas = JSON.parse(data.metas);

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare il documento';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function DeleteDocument(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteDocument (id: $id) {
                id
                createdByUserId
                createdByUserUsername
                documentTypeId
                documentUrl
                state
                periodUnix
                metas
                worker{
                    id
                    cognomeNome
                    cooperativaAppartenenza
                    luogoPartenza
                    matricola
                }
                documentType{
                    id
                    description
                    private
                }
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il documento';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}


export function ReadAllWorkers() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query{

            templates:getAllMetaTemplates {
                metas
                name
            }

            objectList:GetAllWorkers {
                id
                cognomeNome
                cooperativaAppartenenza
                luogoPartenza
                matricola
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = _.sortBy(response.data.objectList, ['cognomeNome']);

                store.dispatch(
                    {
                        type: types.READED_ALL_WORKER,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i lavoratori";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_WORKER_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function DownloadAllDocumentsSeparatedZIP(ids) {
    var variables = {ids};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $ids: [String!]!
        ) {
            file: downloadAllDocumentsSeparatedZIP (ids: $ids) {
                OriginalFileName
                Base64
                FileType
            }
        }
        `
    };

    // store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            const data = response.data.file;

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il documento';
            console.error(e);
            // store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}

export function DownloadAllDocumentsUniquePDF(ids) {
    var variables = {ids};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $ids: [String!]!
        ) {
            file: downloadAllDocumentsUniquePDF (ids: $ids) {
                OriginalFileName
                Base64
                FileType
            }
        }
        `
    };

    // store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            const data = response.data.file;

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il documento';
            console.error(e);
            // store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}


export function SendDocumentsWithMail(ids, to, cc, ccn) {
    const variables = {ids, to, cc, ccn};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $ids: [String!]!
            $to: [String!]!
            $cc: [String!]!
            $ccn: [String!]!
        ) {
            file: SendDocumentsWithMail (ids: $ids, to: $to, cc: $cc, ccn: $ccn) {
                Empty
            }
        }
        `
    };

    // store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            const data = response.data.file;

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile mandare mail';
            console.error(e);
            // store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}

export function CreatePayslip(periodUnix, content, workerIds, documentTypeId, metas) {
    const jsonMetas = JSON.stringify(metas);
    if (!periodUnix) periodUnix = 0;

    const variables = {periodUnix, content: content[0], workerIds, documentTypeId, metas: jsonMetas};


    const query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createPayslip (
            $periodUnix: Int!
            $content: Upload!
            $workerIds: [String!]!
            $documentTypeId: String!
            $metas: String!
        ) {
            stats:  createPayslip(
                periodUnix:$periodUnix,
                content:$content,
                workerId:$workerIds,
                documentTypeId:$documentTypeId,
                metas:$metas,
            ) {
                stats {
                    coop
                    totalCount
                    busteCount
                    lulCount
                    pagesNotProcessed
                } 
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.stats;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            let message = 'Impossibile caricare buste paga';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function CreatePayslipOsra(periodUnix, content, workerIds, documentTypeId, metas) {
    const jsonMetas = JSON.stringify(metas);

    if (!periodUnix) periodUnix = 0;

    const variables = {periodUnix, content: content[0], workerIds, documentTypeId, metas: jsonMetas};

    const query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createPayslipOsra (
            $periodUnix: Int!
            $content: Upload!
            $workerIds: [String!]!
            $documentTypeId: String!
            $metas: String!
        ) {
            stats:  createPayslipOsra(
                periodUnix:$periodUnix,
                content:$content,
                workerId:$workerIds,
                documentTypeId:$documentTypeId,
                metas:$metas,
            ) {
              stats {
                    coop
                    totalCount
                    workersNotProcessed
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.stats;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            return {
                data,
                hasError: false
            }
        })
        .catch((e) => {
            let message = 'Impossibile caricare buste paga';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function CreatePayslipLuoghiLavoro(periodUnix, content, workerIds, documentTypeId, metas) {
    const jsonMetas = JSON.stringify(metas);

    if (!periodUnix) periodUnix = 0;

    const variables = {periodUnix, content: content[0], workerIds, documentTypeId, metas: jsonMetas};

    const query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createpayslipLuoghiLavoro (
            $periodUnix: Int!
            $content: Upload!
            $workerIds: [String!]!
            $documentTypeId: String!
            $metas: String!
        ) {
            stats:  createpayslipLuoghiLavoro(
                periodUnix:$periodUnix,
                content:$content,
                workerId:$workerIds,
                documentTypeId:$documentTypeId,
                metas:$metas,
            ) {
                stats {
                    coop
                    totalCount
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.stats;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            return {
                data,
                hasError: false
            }
        })
        .catch((e) => {
            let message = 'Impossibile caricare buste paga';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}


export function GetAllFilters() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
              {
                 filters: GetAllFilters {
                       FilterName
                        Values {
                                id
                              description
              }
             }
                    }`,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.filters;

                var filters = {};

                data.map(row => {
                    filters[row.FilterName] = row.Values;
                });

                return {
                    data: filters,
                    hasError: false
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i filtri per i documenti";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function SendDocumentsWithMailPec(ids) {

    const variables = {ids};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $ids: [String!]!
        ) {
            file: SendDocumentsToWorkerWithPec (ids: $ids) {
                Empty
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            const data = response.data.file;

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile mandare mail';
            console.error(e);
            // store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}

export function CreateFromFiscalCode(periodUnix, content, documentTypeId, coop) {
    if (!periodUnix) periodUnix = 0;
    const variables = {periodUnix, content: content[0], documentTypeId, coop};


    const query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createFilesFromFiscalCode (
            $periodUnix: Int!
            $content: Upload!
            $documentTypeId: String!
            $coop: String!
        ) {
            stats:  createFilesFromFiscalCode(
                periodUnix:$periodUnix,
                content:$content,
                documentTypeId:$documentTypeId,
                coop:$coop,
            ) {
                   validated
                   notValidated
                   totalFilesCreated
            
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.stats;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            let message = 'Impossibile caricare file da codice fiscale';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}


export function SendDocumentsToWorkersWithMail(ids) {

    const variables = {ids};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $ids: [String!]!
        ) {
            file: SendDocumentsToWorkersWithMail (ids: $ids) {
                Empty
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            const data = response.data.file;

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile mandare mail';
            console.error(e);
            // store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}