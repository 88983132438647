import { useState } from "react";

export const NumberInputHook = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const clear = () => setValue(undefined);
    const getValue = () => value;

    return {
        clear,
        getValue,
        setValue,
        input: {
            value,
            type: "number",
            onChange: (e) => {
                console.log('E',e);
                setValue(e.target.value);
            }
        }
    };
};
