import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import _ from "lodash";

export default function SignModelDiurnoNotturno(props) {

    let diurnaNotturnaInit = 22;
    let notturnaDiurnaInit = 6;
    let initFerialeDiurna = {};
    let initFerialeNotturna = {};
    let initFestivaDiurna = {};
    let initFestivaNotturna = {};
    const servizi = useSelector(state => state.raiServizi.data);

    if (props.signConfig && props.signConfig.diurnaNotturna) {
        const cfg = props.signConfig;
        diurnaNotturnaInit = cfg.diurnaNotturna;
        notturnaDiurnaInit = cfg.notturnaDiurna;
        initFerialeDiurna = servizi.find(el => el.id === cfg.ferialeDiurna);
        initFerialeNotturna = servizi.find(el => el.id === cfg.ferialeNotturna);
        initFestivaDiurna = servizi.find(el => el.id === cfg.festivaDiurna);
        initFestivaNotturna = servizi.find(el => el.id === cfg.festivaNotturna);
    }

    const [ferialeDiurna, setFerialeDiurna] = useState(initFerialeDiurna);
    const [ferialeNotturna, setFerialeNotturna] = useState(initFerialeNotturna);
    const [festivaDiurna, setFestivaDiurna] = useState(initFestivaDiurna);
    const [festivaNotturna, setFestivaNotturna] = useState(initFestivaNotturna);
    const diurnaNotturna = TextInputHook(diurnaNotturnaInit);
    const notturnaDiurna = TextInputHook(notturnaDiurnaInit);

    useEffect(() => {
        // console.log('PROPS', props.signConfig);

        const ferialeDiurnaId = _.get(ferialeDiurna, "id", undefined);
        const ferialeNotturnaId = _.get(ferialeNotturna, "id", undefined);
        const festivaDiurnaId = _.get(festivaDiurna, "id", undefined);
        const festivaNotturnaId = _.get(festivaNotturna, "id", undefined);

        const ferialeDiurnaProps = _.get(props.signConfig, "ferialeDiurna", undefined);
        const ferialeNotturnaProps = _.get(props.signConfig, "ferialeNotturna", undefined);
        const festivaDiurnaProps = _.get(props.signConfig, "festivaDiurna", undefined);
        const festivaNotturnaProps = _.get(props.signConfig, "festivaNotturna", undefined);


        const diurnaNotturnaProps = _.get(props.signConfig, "diurnaNotturna", 22);
        const notturnaDiurnaProps = _.get(props.signConfig, "notturnaDiurna", 6);

        if ((props.cb && !props.signConfig && festivaDiurnaId && ferialeNotturnaId && festivaNotturnaId && festivaDiurnaId) || (props.cb && props.signConfig && festivaDiurnaId && ferialeNotturnaId && festivaNotturnaId && festivaDiurnaId
            && (
                diurnaNotturna.getValue() !== diurnaNotturnaProps
                || notturnaDiurna.getValue() !== notturnaDiurnaProps
                || ferialeDiurnaId !== ferialeDiurnaProps
                || ferialeNotturnaId !== ferialeNotturnaProps
                || festivaDiurnaId !== festivaDiurnaProps
                || festivaNotturnaId !== festivaNotturnaProps
            ))
        ) {

            // console.log('Dati', {
            //     props: props.signConfig,
            //     ferialeDiurna: ferialeDiurnaId,
            //     ferialeNotturna: ferialeNotturnaId,
            //     festivaDiurna: festivaDiurnaId,
            //     festivaNotturna: festivaNotturnaId,
            //     diurnaNotturna: diurnaNotturna.getValue(),
            //     notturnaDiurna: notturnaDiurna.getValue()
            // });

            props.cb({
                ferialeDiurna: ferialeDiurnaId,
                ferialeNotturna: ferialeNotturnaId,
                festivaDiurna: festivaDiurnaId,
                festivaNotturna: festivaNotturnaId,
                diurnaNotturna: diurnaNotturna.getValue(),
                notturnaDiurna: notturnaDiurna.getValue()
            });

            // console.log('Called cb diurna');
        }
    }, [ferialeDiurna, ferialeNotturna, festivaDiurna, festivaNotturna, diurnaNotturna, notturnaDiurna]);

    return (
        <>

            <FormGroup>
                <Label for="contrattoNumero">Orario cambio da diurna a notturna</Label>
                <Input
                    {...diurnaNotturna.input}
                    name="diurnaNotturna"
                    id="diurnaNotturna"
                    placeholder="Inserisci ora"
                />
            </FormGroup>

            <FormGroup>
                <Label for="contrattoNumero">Orario cambio da notturna a diurna</Label>
                <Input
                    {...notturnaDiurna.input}
                    name="notturnaDiurna"
                    id="notturnaDiurna"
                    placeholder="Inserisci ora"
                />
            </FormGroup>

            <FormGroup>
                <Label for="contratto">Servizio Feriale Diurna</Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={ferialeDiurna}
                    getOptionLabel={(item) => item.description}
                    onChange={value => {
                        setFerialeDiurna(value);
                    }}
                    options={[{
                        value: "",
                        description: "Servizio",
                        isDisabled: true
                    }, ..._.orderBy(servizi.filter(
                        servizio => {
                            const lottoServizio = _.get(servizio, 'jsonExtra.lot.id', undefined);
                            const lottoSelezionato = props.lotto;

                            const tipologiaServizio = _.get(servizio, 'jsonExtra.type', "");
                            const tipologiaSelezionata = "Modulo";

                            return servizio &&
                                lottoServizio === lottoSelezionato &&
                                tipologiaSelezionata &&
                                tipologiaSelezionata.toLowerCase() === tipologiaServizio.toLowerCase();
                        }
                    ))]}
                    placeholder="Seleziona un servizio"
                />
            </FormGroup>

            <FormGroup>
                <Label for="contratto">Servizio Feriale Notturna</Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={ferialeNotturna}
                    getOptionLabel={(item) => item.description}
                    onChange={value => {
                        setFerialeNotturna(value);
                    }}
                    options={[{
                        value: "",
                        description: "Servizio",
                        isDisabled: true
                    }, ..._.orderBy(servizi.filter(
                        servizio => {

                            const lottoServizio = _.get(servizio, 'jsonExtra.lot.id', undefined);
                            const lottoSelezionato = props.lotto;

                            const tipologiaServizio = _.get(servizio, 'jsonExtra.type', "");
                            const tipologiaSelezionata = "Modulo";

                            return servizio &&
                                lottoServizio === lottoSelezionato &&
                                tipologiaSelezionata &&
                                tipologiaSelezionata.toLowerCase() === tipologiaServizio.toLowerCase();
                        }
                    ))]}
                    placeholder="Seleziona un servizio"
                />
            </FormGroup>

            <FormGroup>
                <Label for="contratto">Servizio Festiva Diurna</Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={festivaDiurna}
                    getOptionLabel={(item) => item.description}
                    onChange={value => {
                        setFestivaDiurna(value);
                    }}
                    options={[{
                        value: "",
                        description: "Servizio",
                        isDisabled: true
                    }, ..._.orderBy(servizi.filter(
                        servizio => {

                            const lottoServizio = _.get(servizio, 'jsonExtra.lot.id', undefined);
                            const lottoSelezionato = props.lotto;

                            const tipologiaServizio = _.get(servizio, 'jsonExtra.type', "");
                            const tipologiaSelezionata = "Modulo";

                            return servizio &&
                                lottoServizio === lottoSelezionato &&
                                tipologiaSelezionata &&
                                tipologiaSelezionata.toLowerCase() === tipologiaServizio.toLowerCase();
                        }
                    ))]}
                    placeholder="Seleziona un servizio"
                />
            </FormGroup>

            <FormGroup>
                <Label for="contratto">Servizio Festiva Notturna</Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={festivaNotturna}
                    getOptionLabel={(item) => item.description}
                    onChange={value => {
                        setFestivaNotturna(value);
                    }}
                    options={[{
                        value: "",
                        description: "Servizio",
                        isDisabled: true
                    }, ..._.orderBy(servizi.filter(
                        servizio => {

                            const lottoServizio = _.get(servizio, 'jsonExtra.lot.id', undefined);
                            const lottoSelezionato = props.lotto;

                            const tipologiaServizio = _.get(servizio, 'jsonExtra.type', "");
                            const tipologiaSelezionata = "Modulo";

                            return servizio &&
                                lottoServizio === lottoSelezionato &&
                                tipologiaSelezionata &&
                                tipologiaSelezionata.toLowerCase() === tipologiaServizio.toLowerCase();
                        }
                    ))]}
                    placeholder="Seleziona un servizio"
                />
            </FormGroup>
        </>
    );

}
