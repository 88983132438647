import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Row,
    Button,
    Col,
    UncontrolledTooltip
    , CardFooter
} from "reactstrap";

import ReactTable from "react-table";
import { connect } from 'react-redux'
import { get } from 'lodash';
import './redux/ordini.reducer';
import * as Commands from './redux/ordini.commands';
import CreateOrderComponent from "./components/ordini.create";
import UpdateProductionComponent from "./components/update_production.component";
import FirmaComponent from "./components/ordini.firma.modal";
import HistoryComponent from "./components/ordini.history.modal";
import DateRangePickerWrapper from 'components/DateRangePicker/DateRangePickerWrapper';
import Viewer from 'react-viewer';

import Fade from 'react-reveal/Fade';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ShowConfirmationDialog from 'components/ShowConfirmationDialog';
import FinestraAssegnzioni from 'views/rai/assegnazioni/finestra.assegnazioni.component';
import moment from 'moment';
import * as _ from 'lodash';
import classNames from 'classnames';

import Input from "reactstrap/es/Input";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import PropTypes from "prop-types";
import ReactTableFilterSelectComponent from "../../../components/ReactTableFilter/reactTable.filter.select.component";
import {
    RUOLO_AMMINISTRATORE, RUOLO_FUNZIONARIO_RAI,
    RUOLO_RICHIEDENTE_RAI,
    RUOLO_AMMINISTRATORE_COOP,
    RUOLO_FIRMA_ORDINI,
    RUOLO_SUPERVISORE_RAI,
    RUOLO_OPERATIVO
} from "../utenti/components/costants";

import { OneOfRole, CurrentUser, GetSectorsForRoles } from "views/rai/utenti/components/costants";

selectTableHOC(ReactTable);

class OrdiniComponent extends Component {
    static defaultProps = {
        keyField: "id"
    };

    static propTypes = {
        keyField: PropTypes.string
    };


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            showCreateModal: false,
            showUpdateModal: false,
            showDuplicateModal: false,
            loading: false,
            beforeStoppingLoading: false,
            selectedRecordForUpdate: {},
            dropdownOpen: false,
            showFinestraAssegnazioni: false,
            showFinestraFirma: false,
            showFinestraHistory: false,
            startDate: new moment(),
            endDate: new moment(),
            initialStartDate: new moment(),
            initialEndDate: new moment(),
            fullText: "",
            selectAll: false,
            selection: [],
            filters: {}
        }
    }

    ottieniColore = (indice) => {
        var colori = [
            '#85a5ff',
            '#f759ab',
            '#36cfc9',
            '#ffd666',
            '#bae637',
            '#73d13d',
            '#40a9ff',
            '#b37feb'
        ];

        return colori[indice];
    };

    DettaglioOrdine = (props) => {
        const ordine = props.ordine;
        let signingInfo = null;

        if (ordine.jsonExtra.signingUser && ordine.jsonExtra.signingUser.length > 0) {
            let signingUser = JSON.parse(ordine.jsonExtra.signingUser);

            let cognomeNome = "-";
            let matricola = "-";

            if (signingUser.jsonextra && signingUser.jsonextra.length > 0) {
                let jsonExtra = JSON.parse(signingUser.jsonextra);
                cognomeNome = jsonExtra.cognome + " " + jsonExtra.nome;
                matricola = jsonExtra.matricola;
            }
            signingInfo = <div className="statoFirmato">L'ordine è stato firmato dall'utente {cognomeNome} (
                Matricola: {matricola} )</div>
        }

        return (
            <div className={"p-3"}>

                <Row>
                    <div xs={12} md={6}>
                        <Row>
                            {signingInfo && <Col xs={12}>
                                <div className="ordineFirmato dettaglioFirma">
                                    {signingInfo}
                                </div>
                            </Col>}
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Ordine effettuato da:</div>
                                    <div className={"orderDetailValue"}>{ordine.jsonExtra.applicantUser.username}</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Ordine approvato da:</div>
                                    <div
                                        className={"orderDetailValue"}>{ordine.state.toLowerCase() !== 'creato' ? ordine.jsonExtra.approvingUser.username : "-"}</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Lotto:</div>
                                    <div className={"orderDetailValue"}>{ordine.jsonExtra.lot.name}</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Tipologia:</div>
                                    <div className={"orderDetailValue"}>{ordine.jsonExtra.type}</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Luogo:</div>
                                    <div className={"orderDetailValue"}>{ordine.jsonExtra.workPlace.description}</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Produzione:</div>
                                    <div className={"orderDetailValue"}>{ordine.jsonExtra.production.name}</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Uorg:</div>
                                    <div className={"orderDetailValue"}>{ordine.uorg.description}</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="orderDetailData">
                                    <div className={"orderDetailLabel"}>Note:</div>
                                    <div className={"orderDetailValue"}>{ordine.notes}</div>
                                </div>
                            </Col>

                            <div className={"periodsWrapper"}>
                                {
                                    ordine.periods.map((periodo, index) => {
                                        const colore = this.ottieniColore(index);

                                        console.log("PERIODO", periodo)
                                        return (
                                            <div className="periodWrapper">
                                                <Card style={{ border: "1px solid white", "borderColor": colore }}>
                                                    <CardBody>
                                                        <Col xs={12}>
                                                            <div className="orderDetailData">
                                                                <div className={"orderDetailLabel"}>Ora inizio:</div>
                                                                <div
                                                                    className={"orderDetailValue"}>{moment.unix(periodo.startTime).format("HH:mm")}</div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <div className="orderDetailData">
                                                                <div className={"orderDetailLabel"}>Ora fine:</div>
                                                                <div
                                                                    className={"orderDetailValue"}>{moment.unix(periodo.endTime).format("HH:mm")}</div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <div className="orderDetailData">
                                                                <div className={"orderDetailLabel"}>Numero lavoratori:
                                                                </div>
                                                                <div
                                                                    className={"orderDetailValue"}>{periodo.workers}</div>
                                                            </div>
                                                            {
                                                                periodo.assignments && periodo.assignments.map(ass => {
                                                                    let cognome = _.get(ass, "jsonWorker.cognome", "");
                                                                    let nome = _.get(ass, "jsonWorker.nome", "");

                                                                    let capoSquadra = _.get(ass, "jsonExtra.teamLeader", false);
                                                                    let teamLaderSign = "";

                                                                    if (capoSquadra) {
                                                                        teamLaderSign = " *"
                                                                    }  

                                                                    const cognomeNome = cognome + " " + nome + teamLaderSign;
                                                                    
                                                                    return <div
                                                                        className={"nomeLavoratoreDettaglio"}>{cognomeNome}</div>;
                                                                })
                                                            }
                                                        </Col>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <hr />
                                                        <div style={{ color: colore }}
                                                            className="stats detailOrderFooter">
                                                            <i className="icon-single-copy" />Servizio {periodo.jsonArticle.description} {index > 0 ? " ( Straordinario ) " : " (Servizio base)"}
                                                        </div>
                                                    </CardFooter>
                                                </Card>
                                            </div>
                                        )
                                    })
                                }</div>
                        </Row>
                    </div>
                </Row>
            </div>
        );
    };


    caricaDati = async (startDate, endDate, fullText) => {
        // Evito di modificare i rirferimenti con il clone
        const startDateClone = startDate.clone();
        const endDateClone = endDate.clone();

        this.setState({ loading: true });
        await Commands.OttieniOrdini(startDateClone.format("YYYY-MM-DD"), endDateClone.add(1, 'days').format("YYYY-MM-DD"), fullText);
        this.setState({ loading: false });
    };

    toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })

    onDatesChanged(startDate, endDate) {

        this.setState(
            {
                startDate,
                endDate
            }, () => {
                this.caricaDati(startDate, endDate, this.state.fullText)
            });
    }

    componentDidMount() {
        this.caricaDati(this.state.initialStartDate, this.state.initialEndDate, this.state.fullText);
    }

    toggleFinestraAssegnazioni = (ordine) => {
        if (typeof ordine === 'string') {
            Commands.OttieniOrdineAggiornato(ordine);
        }

        this.setState({
            showFinestraAssegnazioni: !this.state.showFinestraAssegnazioni,
            selectedOrder: ordine
        });
    }

    toggleFinestraHistory = (ordine) => {
        this.setState({
            showFinestraHistory: !this.state.showFinestraHistory,
            selectedOrder: ordine
        })
    }

    toggleFinestraFirma = (ordine) => this.setState({
        showFinestraFirma: !this.state.showFinestraFirma,
        selectedOrder: ordine
    })

    toggleShowDuplicateModal = (record) => {
        this.setState({
            showDuplicateModal: !this.state.showDuplicateModal,
            selectedRecordForUpdate: record
        })
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }

    elimina = async (record) => {
        var res = await Commands.EliminaProduzione(record.id);

        if (res.hasError) {
            alert('Impossibile eliminare');
            return;
        }
    }


    actions = (cellInfo) => {
        const actions = [
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
                requiredSectorRoles: [RUOLO_FIRMA_ORDINI],
                name: "Firma ordine",
                icon: "",
                action: async e => {
                    e.preventDefault();
                    this.toggleFinestraFirma(cellInfo.original);
                },
            },
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_SUPERVISORE_RAI, RUOLO_RICHIEDENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_OPERATIVO],
                name: "Duplica ordine",
                icon: "",
                action: async e => {
                    e.preventDefault();
                    this.toggleShowDuplicateModal(cellInfo.original);
                },
            },
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
                name: "Reinvia sms firma",
                icon: "",
                action: async e => {
                    e.preventDefault();
                    const doAction = await ShowConfirmationDialog({
                        title: "Conferma invio sms",
                        text: "Continuare?",
                        confirmButtonText: 'Si!',
                        cancelButtonText: 'No',
                        canEscapeKeyCancel: true
                    });

                    if (doAction.confirm) {
                        const res = await Commands.InviaSmsFirma(cellInfo.original.id);
                        if (res.hasError) {
                            ShowConfirmationDialog({
                                title: "Errore durante l'invio sms",
                                text: res.message,
                                confirmBtnText: 'Ok',
                                canEscapeKeyCancel: true,
                                showCancel: false
                            });
                        } else {
                            ShowConfirmationDialog({
                                title: "Sms inviato con successo",
                                confirmBtnText: 'Ok',
                                canEscapeKeyCancel: true,
                                showCancel: false

                            });
                        }
                    }
                },
            },
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
                name: "Assegna personale",
                icon: "",
                action: async e => {
                    e.preventDefault();
                    this.toggleFinestraAssegnazioni(cellInfo.original);
                },
            },
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
                name: "Visualizza storia",
                icon: "",
                action: async e => {
                    e.preventDefault();
                    this.toggleFinestraHistory(cellInfo.original);
                },
            },
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_SUPERVISORE_RAI, RUOLO_RICHIEDENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_OPERATIVO],
                name: "Approva",
                icon: "",
                action: async (e) => {
                    e.preventDefault();

                    if (cellInfo.original.state !== 'creato') {
                        ShowConfirmationDialog({
                            title: "Errore durante l'approvazione",
                            text: "Non è possibile un ordine in stato: " + cellInfo.original.state,
                            confirmBtnText: 'Ok',
                            showCancel: false,
                            canEscapeKeyCancel: true
                        });
                        return;
                    }

                    const doAction = await ShowConfirmationDialog({
                        title: "Conferma approvazione",
                        text: "Una volta approvato l'ordine verrà messo in lavorazione, continuare?",
                        confirmButtonText: 'Si!',
                        cancelButtonText: 'No',
                        canEscapeKeyCancel: true
                    });

                    if (doAction.confirm) {
                        const res = await Commands.ApprovaOrdine(cellInfo.original.id);
                        if (res.error) {
                            ShowConfirmationDialog({
                                title: "Errore durante l'approvazione",
                                text: res.message,
                                confirmBtnText: 'Ok',
                                showCancel: false,
                                canEscapeKeyCancel: true
                            });
                        }
                    }
                },
            },
            /*  {
                  requiredRoles: [RUOLO_AMMINISTRATORE],
                  name: "Annulla approvazione",
                  icon: "",
                  action: async (e) => {
                      e.preventDefault();

                      if (cellInfo.original.state !== 'approvato') {
                          ShowConfirmationDialog({
                              title: "Errore durante l'operazione",
                              text: "Non è possibile modificare un ordine in stato: " + cellInfo.original.state,
                              confirmBtnText: 'Ok',
                              showCancel: false,
                              canEscapeKeyCancel: true
                          });
                          return;
                      }

                      const doAction = await ShowConfirmationDialog({
                          title: "Conferma operazione",
                          text: "Operazione permessa solo agli amministratori di sistema, continuare?",
                          confirmButtonText: 'Si!',
                          cancelButtonText: 'No',
                          canEscapeKeyCancel: true
                      });

                      if (doAction.confirm) {
                          const res = await Commands.CancellaApprovazioneOrdine(cellInfo.original.id);
                          if (res.error) {
                              ShowConfirmationDialog({
                                  title: "Errore durante l'operazione",
                                  text: res.message,
                                  confirmBtnText: 'Ok',
                                  showCancel: false,
                                  canEscapeKeyCancel: true
                              });
                          }
                      }
                  },
              },*/
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
                blackList: [
                    "luigia.perilli",
                    "valerio.ridda"
                ],
                name: "Annulla firma",
                icon: "",
                action: async (e) => {
                    e.preventDefault();

                    if (cellInfo.original.state !== 'firmato') {
                        ShowConfirmationDialog({
                            title: "Errore ",
                            text: "Non è annullare la firma di un ordine che non è stato firmato",
                            confirmBtnText: 'Ok',
                            showCancel: false,
                            canEscapeKeyCancel: true
                        });
                        return;
                    }

                    const doAction = await ShowConfirmationDialog({
                        title: "Annulla firma",
                        text: "Inserisci sotto il motivo per il quale stai annullando la firma",
                        confirmBtnText: 'Si',
                        canEscapeKeyCancel: true,
                        input: true,
                        placeholder: 'Motivo annullamento',
                        validationMsg: 'Devi inserire un motivo valido!'
                    });

                    if (doAction.confirm) {
                        const motivoRifiuto = doAction.response;

                        const res = await Commands.AnnullaFirmaOrdine(cellInfo.original.id, motivoRifiuto);
                        if (res.error) {
                            ShowConfirmationDialog({
                                title: "Errore durante l'approvazione",
                                text: res.message,
                                confirmBtnText: 'Ok',
                                showCancel: false,
                                canEscapeKeyCancel: true
                            });
                        }
                    }
                },
            },
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_SUPERVISORE_RAI, RUOLO_RICHIEDENTE_RAI, RUOLO_FUNZIONARIO_RAI, RUOLO_OPERATIVO],
                name: "Elimina",
                icon: "",
                action: async e => {
                    e.preventDefault();

                    if (!this.props.userAdmin) {
                        if (cellInfo.original.state === 'firmato') {
                            ShowConfirmationDialog({
                                title: "Errore durante l'eliminazione",
                                text: "Non è possibile eliminare una richiesta firmata",
                                confirmBtnText: 'Ok',
                                showCancel: false,
                                canEscapeKeyCancel: true
                            });
                            return;
                        }

                        // Rai deve poter cancellare solo ordini che INIZIANO tra più di 60 minuti nella giornata odierna; nient'altro
                        const oraInizio = new moment.unix(cellInfo.original.periods[0].startTime);
                        const now = new moment();

                        var duration = moment.duration(oraInizio.diff(now));

                        var minutes = duration.asMinutes();
                        if (minutes < 60) {
                            ShowConfirmationDialog({
                                title: "Errore durante l'eliminazione",
                                text: "Impossibile eliminare richiesta",
                                confirmBtnText: 'Ok',
                                showCancel: false,
                                canEscapeKeyCancel: true
                            });
                            return
                        }

                    }

                    const doAction = await ShowConfirmationDialog({
                        title: "Conferma eliminazione",
                        text: "Una volta eliminato l'ordine non sarà più visibile, continuare?",
                        confirmButtonText: 'Si!',
                        cancelButtonText: 'No',
                        canEscapeKeyCancel: true
                    });

                    if (doAction.confirm) {
                        const res = await Commands.EliminaOrdine(cellInfo.original.id);
                        if (res.hasError) {
                            ShowConfirmationDialog({
                                title: "Errore durante l'eliminazione",
                                text: res.message,
                                confirmBtnText: 'Ok',
                                showCancel: false,
                                canEscapeKeyCancel: true
                            });
                        }

                    }

                },
            }
        ]

        const checkRole = (requiredRoles) => {
            return OneOfRole(requiredRoles);
        };


        const isInBlackList = (blackList) => {
            // Check if user is in blacklist
            if (blackList && blackList.includes(CurrentUser().username)) {
                return true;
            }

            return false
        };

        const checkSectorRole = (sectorId, requiredRoles) => {
            const validSectors = GetSectorsForRoles(requiredRoles);
            return validSectors.some(validSector => validSector.sector.id === sectorId); 
        };

        return <>
            <div className="actions-right">
                {
                    get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner" /> : null
                }
                <UncontrolledDropdown>
                    <DropdownToggle
                        caret
                        className="btn-link btn-icon"
                        color="default"
                        data-toggle="dropdown"
                        type="button"
                    >
                        <i className="tim-icons icon-settings-gear-63" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {actions.map(action => {


                            const hasValidRoles = checkRole(action.requiredRoles, action.blackList);
                            const hasValidSectorRole = checkSectorRole(cellInfo.original.sector.id, action.requiredSectorRoles);

                            const blockedByBlackList = isInBlackList(action.blackList);

                            if (blockedByBlackList) return null;

                            if (!hasValidRoles && !hasValidSectorRole) return null;
                            
                            return <DropdownItem
                                onClick={action.action}
                            >
                                {action.name}
                            </DropdownItem>
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </>
    };


    azioniGlobali = () => {

        var t = this;
        const actions = [
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
                name: "Invia sms accettazione",
                icon: "",
                action: async e => {
                    e.preventDefault();

                    const wrappedInstance = t.checkboxTable;
                    const currentRecords = wrappedInstance.getResolvedState().sortedData;
                    const ids = [];

                    currentRecords.forEach(item => {
                        ids.push(item._original.id);
                    });

                    const doAction = await ShowConfirmationDialog({
                        title: "Procedere",
                        text: "Verranno inviati i messaggi ai soci che non hanno ancora accettato (o riiutato) l'assegnazione, procedere?",
                        confirmButtonText: 'Si!',
                        cancelButtonText: 'No',
                        canEscapeKeyCancel: true
                    });

                    if (doAction.confirm) {
                        const res = await Commands.InviaSmsAccettazione(ids);
                        if (res.error) {
                            ShowConfirmationDialog({
                                title: "Errore durante l'approvazione",
                                text: res.message,
                                confirmBtnText: 'Ok',
                                showCancel: false,
                                canEscapeKeyCancel: true
                            });
                        }

                        ShowConfirmationDialog({
                            title: "Successo",
                            text: "E' stato predisposto l'invio di " + res.data + " son successo.",
                            confirmBtnText: 'Ok',
                            showCancel: false,
                            canEscapeKeyCancel: true
                        });

                    }

                },
            },
            {
                requiredRoles: [RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_OPERATIVO],
                name: "Reinvia sms firma",
                icon: "",
                action: async e => {
                    e.preventDefault();
                    ShowConfirmationDialog({
                        title: "Da implementare",
                        text: "...",
                        confirmButtonText: 'Si!',
                        cancelButtonText: 'No',
                        canEscapeKeyCancel: true
                    });
                },
            },
        ];

        const checkRole = (requiredRoles) => {
            return OneOfRole(requiredRoles);
        };

        return <>
            <UncontrolledDropdown>
                <DropdownToggle className={"pulsante-azioni btn-primary"} caret data-toggle="dropdown">
                    Azioni
                </DropdownToggle>
                <DropdownMenu right>
                    {actions.map(action => {
                        if (!checkRole(action.requiredRoles)) return null;
                        return <DropdownItem
                            onClick={action.action}
                        >
                            {action.name}
                        </DropdownItem>
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    };

    /**
     * Toggle a single checkbox for select table
     */
    toggleSelection = (key) => {
        // start off with the existing state


        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }


        // update the state
        this.setState({ selection });


    };

    /**
     * Toggle all checkboxes for select table
     */
    toggleAll = () => {
        const { keyField } = this.props;
        const selectAll = !this.state.selectAll;
        const selection = [];

        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }
        this.setState({ selectAll, selection });
    };

    /**
     * Whether or not a row is selected for select table
     */
    isSelected = key => {
        return this.state.selection.includes(`select-${key}`);
    };

    rowFn = (state, rowInfo) => {
        const { selection } = this.state;

        return {
            onClick: (e, handleOriginal) => {
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                    handleOriginal();
                }
            },
            style: {
                background:
                    rowInfo &&
                    selection.includes(`select-${rowInfo.original.id}`) &&
                    "lightpurple"
            }
        };
    };

    render() {
        return (
            <>
                <Viewer
                    visible={this.state.showSign}
                    onClose={() => this.setState({ showSign: false })}
                    images={[{ src: this.state.sign, alt: this.state.signAlt }]}
                />
                {/*Modal creazione e aggiornamento produzione e duplicazione */}
                {this.state.showCreateModal &&
                    <CreateOrderComponent
                        show={this.state.showCreateModal}
                        {...this.props}
                        toggle={this.toggleShowCreateModal} />}

                {
                    this.state.showUpdateModal && <UpdateProductionComponent {...this.state.selectedRecordForUpdate}
                        show={this.state.showUpdateModal}
                        {...this.props}
                        toggle={this.toggleShowUpdateModal} />}
                {
                    this.state.showDuplicateModal &&
                    <CreateOrderComponent dataToDuplicate={this.state.selectedRecordForUpdate}
                        show={this.state.showDuplicateModal}
                        {...this.props}
                        toggle={this.toggleShowDuplicateModal} />}

                {this.state.showFinestraAssegnazioni &&
                    <FinestraAssegnzioni toggle={this.toggleFinestraAssegnazioni} ordine={this.state.selectedOrder}
                        show={this.state.showFinestraAssegnazioni} />}
                        
                {this.state.showFinestraHistory &&
                    <HistoryComponent toggle={this.toggleFinestraHistory} ordine={this.state.selectedOrder}
                        show={this.state.showFinestraHistory} />}
                        
                {this.state.showFinestraFirma &&
                    <FirmaComponent toggle={this.toggleFinestraFirma} ordine={this.state.selectedOrder}
                        show={this.state.showFinestraFirma} />}

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <DateRangePickerWrapper
                                                disabled={false}
                                                initialStartDate={this.state.initialStartDate} // momentPropTypes.momentObj or null,
                                                startDateId="startDate" // PropTypes.string.isRequired,
                                                initialEndDate={this.state.initialEndDate} // momentPropTypes.momentObj or null,
                                                endDateId="endDate" // PropTypes.string.isRequired,
                                                small
                                                enableOutsideDays={true}
                                                startDatePlaceholderText={"Data inizio"}
                                                endDatePlaceholderText={"Data fine"}
                                                showDefaultInputIcon
                                                isOutsideRange={() => false}
                                                onChange={(startDate, endDate) => this.onDatesChanged(startDate, endDate)}
                                            />
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            {OneOfRole([RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP, RUOLO_RICHIEDENTE_RAI, RUOLO_SUPERVISORE_RAI, RUOLO_OPERATIVO]) &&
                                                <>
                                                    <button className="btn btn-primary " id="nuovoModal"
                                                        onClick={() => this.toggleShowCreateModal()}><i
                                                            className="tim-icons icon-simple-add" />Nuovo ordine
                                                    </button>
                                                    <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                        Crea un nuovo ordine<br /> ( Hotkey F11)
                                                    </UncontrolledTooltip></>
                                            }
                                            {this.props.userAdmin && <this.azioniGlobali />}
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati(this.state.startDate, this.state.endDate, this.state.fullText);
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>


                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs={12} sm={12} md={1}> <CardTitle tag="h4">Ordini</CardTitle>
                                        </Col>
                                        <Col>
                                            <Input onChange={(e) => {
                                                this.setState({ fullText: e.target.value }, () => {
                                                    this.caricaDati(this.state.startDate, this.state.endDate, this.state.fullText)
                                                });
                                            }}
                                                value={this.state.fullText} placeHolder="Ricerca...." />
                                        </Col>
                                    </Row>

                                </CardHeader>
                                <CardBody>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner" /></CardSubtitle>
                                    </Fade>
                                    {/*<SelectTable*/}
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={true}
                                        collapseOnDataChange={false}
                                        LoadingComponent={this.LoadingComponent}
                                        showPagination={true}
                                        keyField={"id"}
                                        ref={r => (this.checkboxTable = r)}
                                        toggleSelection={this.toggleSelection}
                                        selectAll={this.state.selectAll}
                                        selectType="checkbox"
                                        toggleAll={this.toggleAll}
                                        isSelected={this.isSelected}
                                        getTrProps={this.rowFn}
                                        defaultPageSize={20}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        columns={[
                                            {
                                                Header: "Num. Ord",
                                                accessor: "number",
                                                maxWidth: 100,
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.number);
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'number';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['number'])}
                                                        optionValue={'number'}
                                                        optionLabel={'number'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },

                                            {
                                                Header: "Data",
                                                accessor: "startDate",
                                                Cell: row => (`${moment.unix(row.value).utc().format("ddd DD/MM/YY")}`),
                                                minWidth: 80,
                                                Filter: <></>,
                                            },

                                            {
                                                Header: "Inizio",
                                                id: 'oraInzio',
                                                accessor: row => {
                                                    return row.periods[0].startTime
                                                },
                                                Cell: row => {
                                                    return (`${moment.unix(row.value).format("HH:mm")}`)
                                                },
                                                minWidth: 50,
                                                Filter: <></>,
                                            },

                                            {
                                                Header: "Fine",
                                                id: 'oraFine',
                                                accessor: row => {
                                                    return row.periods[0].endTime
                                                },
                                                Cell: row => {
                                                    return (`${moment.unix(row.value).format("HH:mm")}`)
                                                },
                                                minWidth: 50,
                                                Filter: <></>,
                                            },

                                            {
                                                Header: "Contratto",
                                                id: "Contratto",
                                                accessor: 'jsonExtra.article.jsonExtra.contract.description',
                                                show: this.props.userAdmin,
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    const res = filter.value.some(f => {
                                                        const matcher = _.get(row._original, 'jsonExtra.article.jsonExtra.contract.description', '');
                                                        return f.value === matcher;
                                                    });

                                                    return res;
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'contratto';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['jsonExtra.article.jsonExtra.contract.description'])}
                                                        optionValue={'jsonExtra.article.jsonExtra.contract.description'}
                                                        optionLabel={'jsonExtra.article.jsonExtra.contract.description'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },

                                            {
                                                Header: "Tipo",
                                                accessor: "orderType",
                                                Cell: (cellInfo => {
                                                    const state = cellInfo.original.orderType.toLowerCase();
                                                    switch (state) {
                                                        case "planned":
                                                            return <div
                                                                className={"ordinePianificato"}>Pianificato</div>
                                                        case "ordinary":
                                                            return <div className={"ordineVariazione"}>Variazione</div>
                                                        default:
                                                            return "-"
                                                    }
                                                }),
                                                filterMethod: (filter, row) => {

                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.orderType);
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'orderType';
                                                    return <ReactTableFilterSelectComponent
                                                        options={[
                                                            { value: "planned", label: "Pianificato" },
                                                            { value: "ordinary", label: "Variazione" }]}
                                                        optionValue={'value'}
                                                        optionLabel={'label'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },

                                            {
                                                Header: "Stato",
                                                accessor: "state",
                                                Cell: (cellInfo => {
                                                    const state = cellInfo.original.state.toLowerCase();
                                                    switch (state) {
                                                        case "approvato":
                                                            return <div className={"ordineApprovato"}>{state}</div>
                                                        case "firmato":
                                                            return <div className={"ordineFirmato"}>{state}</div>
                                                        case "rifiutato":
                                                            return <div className={"ordineRifiutato"}>{state}</div>
                                                        default:
                                                            return <div className={"ordineCreato"}>{state}</div>
                                                    }
                                                }),
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.state);
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'stato';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['state'])}
                                                        optionValue={'state'}
                                                        optionLabel={'state'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },

                                            {
                                                Header: "Assegnazione",
                                                id: 'assegnazione',
                                                minWidth: 300,
                                                show: this.props.userAdmin,
                                                accessor: row => {
                                                    let state = {
                                                        stato: '',
                                                    };


                                                    let totalHours = 0;
                                                    let totalAssignedHours = 0;

                                                    let assignmentNumber = 0;
                                                    let assignmentAssignedNumber = 0;

                                                    let totalToAccept = 0;
                                                    let totalAccepted = 0;
                                                    let totalSmsSent = 0;
                                                    let totalRefused = 0;

                                                    switch (row.state.toLowerCase()) {
                                                        case "creato":
                                                            state.stato = '-';
                                                            return state;
                                                        case "firmato":
                                                            state.stato = 'Completa';
                                                            return state;
                                                        default:
                                                    }

                                                    row.periods.forEach((period) => {
                                                        const hoursUnit = period.totalHours / period.workers;
                                                        assignmentNumber += period.workers;
                                                        const assignedNumber = period.totalAssignedHours / hoursUnit;

                                                        if (!isNaN(assignedNumber)) assignmentAssignedNumber += assignedNumber;

                                                        totalHours += period.totalHours;
                                                        totalAssignedHours += period.totalAssignedHours;

                                                        if (period.assignments) {
                                                            period.assignments.forEach(ass => {
                                                                if (ass.smsState.length > 0) {
                                                                    totalSmsSent++;
                                                                }
                                                                totalToAccept++;
                                                                if (ass.acceptedOn > 0 && ass.accepted) {
                                                                    totalAccepted++;
                                                                } else if (ass.acceptedOn > 0 && !ass.accepted) {
                                                                    totalRefused++;
                                                                }

                                                            });
                                                        }
                                                    });

                                                    if (totalToAccept === totalAccepted) {
                                                        state.stato = 'finita';
                                                    } else if (totalAssignedHours >= totalHours) {
                                                        state.stato = 'completa'
                                                    } else if (totalAssignedHours > 0) {
                                                        state.stato = 'parziale'
                                                    } else if (totalAssignedHours === 0) {
                                                        state.stato = 'assente'
                                                    }

                                                    state = {
                                                        ...state,
                                                        totalHours,
                                                        totalAssignedHours,
                                                        assignmentNumber,
                                                        assignmentAssignedNumber,
                                                        totalToAccept,
                                                        totalAccepted,
                                                        totalSmsSent,
                                                        totalRefused
                                                    };

                                                    return state;
                                                },

                                                Cell: (cellInfo => {
                                                    const state = cellInfo.value;

                                                    switch (state.stato) {
                                                        case "finita":
                                                            return <div
                                                                className={classNames({
                                                                    "assegnazioneCompleta": true,
                                                                    "assegnazioniAccettate": true,
                                                                })}>Finita ( CONFERME RICEVUTE )</div>;
                                                        case "completa":
                                                            if (state.totalSmsSent === 0) {
                                                                return <div
                                                                    className={classNames({
                                                                        "assegnazioneCompleta": true
                                                                    })}>COMPLETA ( INVIARE SMS )
                                                                    ({state.assignmentAssignedNumber}/{state.assignmentNumber})</div>;
                                                            }

                                                            if (state.totalToAccept === state.totalAccepted) {
                                                                return <div
                                                                    className={classNames({
                                                                        "assegnazioneCompleta": true,
                                                                        "assegnazioniAccettate": true,
                                                                    })}>Finita ( CONFERME RICEVUTE )</div>;
                                                            }

                                                            if (state.totalToAccept > 0 && state.totalRefused === 0) {
                                                                return <div
                                                                    className={classNames({
                                                                        "assegnazioneCompleta": true,
                                                                        "assegnazioniAccettateParzialmente": true,
                                                                    })}>COMPLETA ( IN ACCETTAZIONE )</div>;
                                                            }

                                                            return <div
                                                                className={classNames({
                                                                    "assegnazioneCompleta": true,
                                                                    "orderWithAssNotReady": true,
                                                                })}>RICEVUTO RIFIUTO</div>;

                                                        case "parziale":
                                                            return <div
                                                                className={classNames({
                                                                    "assegnazioneParziale": true
                                                                })}>{state.stato} ({state.assignmentAssignedNumber}/{state.assignmentNumber})</div>
                                                        case "assente":
                                                            return <div
                                                                className={classNames({
                                                                    "assegnazioneAssente": true
                                                                })}>{state.stato} ({state.assignmentAssignedNumber}/{state.assignmentNumber})</div>
                                                        default:
                                                            return null
                                                    }
                                                }),

                                                filterMethod: (filter, row) => {

                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row.assegnazione.stato);
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'statoAss';
                                                    return <ReactTableFilterSelectComponent
                                                        options={[{ value: "assente" }, { value: "parziale" }, { value: "completa" }, { value: "finita" }]}
                                                        optionValue={'value'}
                                                        optionLabel={'value'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },

                                            {
                                                Header: "Settore",
                                                accessor: "sector.description",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => {
                                                        const matcher = _.get(row._original, 'sector.id', '');
                                                        return f.value === matcher
                                                    });
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'settore';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['sector.description'])}
                                                        optionValue={'sector.id'}
                                                        optionLabel={'sector.description'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },

                                            {
                                                Header: "Studio",
                                                accessor: "study.name",
                                                id: "study",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;

                                                    const res = filter.value.some(f => {
                                                        const matcher = _.get(row._original, 'study.id', '');
                                                        return f.value === matcher;
                                                    });

                                                    return res;
                                                },

                                                Filter: ({ onChange }) => {
                                                    const filterName = 'study';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['study.name'])}
                                                        optionValue={'study.id'}
                                                        optionLabel={'study.name'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "Tipologia",
                                                id: "tipologia",
                                                accessor: 'jsonExtra.type',
                                                show: this.props.userAdmin,
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    const res = filter.value.some(f => {
                                                        const matcher = _.get(row._original, 'jsonExtra.type', '');
                                                        return f.value === matcher;
                                                    });

                                                    return res;
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = "tipologia";
                                                    return <ReactTableFilterSelectComponent
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        options={_.sortBy(this.props.data, ['jsonExtra.type'])}
                                                        optionValue={'jsonExtra.type'}
                                                        optionLabel={'jsonExtra.type'}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />;
                                                }

                                            },

                                            {
                                                Header: "Servizio richiesto",
                                                id: "servizio",
                                                Cell: row => {
                                                    const servizi = [];
                                                    row.original.periods.map(periodo => servizi.push(periodo.jsonArticle.description));
                                                    return servizi.join(' , ');
                                                },
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    const res = filter.value.some(f => {
                                                        const matcher = _.get(row._original, 'jsonExtra.article.description', '');
                                                        return f.value === matcher;
                                                    });

                                                    return res;
                                                },


                                                Filter: ({ onChange }) => {
                                                    const filterName = 'servizio';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['jsonExtra.article.description'])}
                                                        optionValue={'jsonExtra.article.description'}
                                                        optionLabel={'jsonExtra.article.description'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }


                                            },

                                            {
                                                Header: "Q.tà",
                                                accessor: "jsonExtra.qty",
                                                maxWidth: 60,
                                                Filter: <></>,
                                            },

                                            {
                                                Header: "Produzione",
                                                accessor: "jsonExtra.production.name",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.jsonExtra.production.id);
                                                },

                                                Filter: ({ onChange }) => {
                                                    const filterName = 'produzione';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['jsonExtra.production.name'])}
                                                        optionValue={'jsonExtra.production.id'}
                                                        optionLabel={'jsonExtra.production.name'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },

                                            {
                                                Header: "Richiesto da",
                                                accessor: "jsonExtra.applicantUser.username",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.jsonExtra.applicantUser.username);
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'richiestoDa';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['jsonExtra.applicantUser.username'])}
                                                        optionValue={'jsonExtra.applicantUser.username'}
                                                        optionLabel={'jsonExtra.applicantUser.username'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },

                                            {
                                                Header: "Approvato da",
                                                accessor: "jsonExtra.approvingUser.username",
                                                Cell: row => {
                                                    switch (row.original.state.toLowerCase()) {
                                                        case "creato":
                                                            return "-";
                                                        default:
                                                            return row.value;
                                                    }

                                                },
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.jsonExtra.approvingUser.username);
                                                },
                                                Filter: ({ onChange }) => {
                                                    const filterName = 'approvatoDa';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['jsonExtra.approvingUser.username'])}
                                                        optionValue={'jsonExtra.approvingUser.username'}
                                                        optionLabel={'jsonExtra.approvingUser.username'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },

                                            {
                                                Header: "Firma",
                                                accessor: "jsonExtra.sign",
                                                Cell: row => {

                                                    if (row.original.state.toLowerCase() !== "firmato") {
                                                        return null
                                                    }

                                                    if (this.state.loadingRow === row.original.id) {
                                                        return <i className="fa fa-spin fa-spinner" />
                                                    }

                                                    return (
                                                        <Button
                                                            className="btn-link btn-icon btn-primary"
                                                            data-toggle="dropdown"
                                                            color={"white"}
                                                            type="button"
                                                            onClick={async () => {
                                                                this.setState({ loadingRow: row.original.id });
                                                                const res = await Commands.OttieniImmagineFirma(row.original.id);
                                                                this.setState({ loadingRow: "" });

                                                                if (res.hasError) {
                                                                    ShowConfirmationDialog({
                                                                        title: "Errore durante l'ottenimento della firma",
                                                                        text: res.message,
                                                                        confirmBtnText: 'Ok',
                                                                        canEscapeKeyCancel: true,
                                                                        showCancel: false
                                                                    });
                                                                    return;
                                                                }
                                                                this.setState({
                                                                    showSign: true,
                                                                    sign: res.data,
                                                                    signAlt: "Firma ordine " + row.original.number
                                                                });
                                                            }}
                                                        >
                                                            <i className="tim-icons icon-image-02" />
                                                        </Button>
                                                    )
                                                        ;
                                                },
                                                filterable: false,
                                            },
                                            {
                                                Header: "",
                                                Cell: this.actions,
                                                className: 'showDropDown',
                                                sortable: false,
                                                filterable: false,
                                            }
                                        ]}


                                        SubComponent={row => {
                                            return (<this.DettaglioOrdine ordine={row.original} />);
                                        }}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    let userAdmin = false;
    let userRole = get(state, "login.user.role", "-").toLowerCase();


    if (userRole === RUOLO_AMMINISTRATORE || userRole === RUOLO_AMMINISTRATORE_COOP || RUOLO_OPERATIVO) {
        userAdmin = true
    }

    const data = {
        data: get(state, "raiOrdini.data", []),
        loading: get(state, "raiOrdini.loading", false),
        userAdmin,
        userRole
    };

    return data;
};

export default connect(mapStateToProps, () => ({}))(OrdiniComponent)

