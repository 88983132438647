/* eslint-disable no-param-reassign */
import React, { Suspense } from 'react';
import compose from 'recompose/compose';
import defaultProps from 'recompose/defaultProps';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import MapStyle from './mapsStyles';
import Flexbox from 'flexbox-react';


const CanvasHoverMap = React.lazy(() => import('./Map'));

export const map = ({
	style,
	options,
	markerHoverDistance,
	markers,
	renderMarkers,
	renderMarker,
	mapParams: { zoom, center },
	onMapParamsChange,
	_onChildClick,
	_onChildMouseEnter,
	_onChildMouseLeave,
}) => (
	<div style={{ width: '1489x', height: '100vh' }}>
		<Flexbox className="mappa" width="100%" height="100%">
			<Suspense fallback={'Ok'}>
				<CanvasHoverMap
					// flex: 1 here
					// google map options https://developers.google.com/maps/documentation/javascript/controls#ControlOptions
					// options={options}
					// see CanvasMap onMouseMove, distance at which algorithm decides that marker is hovered
					markerHoverDistance={markerHoverDistance}
					// google-map-react props
					center={center}
					zoom={zoom}
					onChange={onMapParamsChange}
					// canvas markers, and render functions
					markers={markers}
					// render markers at canvas
					renderMarkers={renderMarkers}
					// render hovered marker as is
					renderMarker={renderMarker}
					bootstrapURLKeys={{ key: 'AIzaSyBIiMyTH4FOO1nCRRWWKTDRvBykFa-bdFU' }}
					language="it"
					region="it"
					options={MapStyle[2]}
					// onChildClick={_onChildClick}
					 onChildMouseEnter={_onChildMouseEnter}
					 onChildMouseLeave={_onChildMouseLeave}
					// to force redraw just pass a new empty object to refresh for example
					refresh={{}}
				/>
			</Suspense>
		</Flexbox>
	</div>
);

export const mapHOC = compose(
	defaultProps({
		options: {
			// scrollwheel: true,
			// zoomControl: true,
			zoomControlOptions: {
				position: 1 // google.maps.ControlPosition.LEFT_TOP
			},
			minZoom: 3,
			zoom: 10,
			maxZoom: 18,
			disableDoubleClickZoom: true
		},

		hoverDistance: 15,
		markerHoverDistance: 15,
		markers: [],
	}),
	withState('mapParams', 'setMapParams', {
		center: {
			lat: 41.9879669,
			lng: 12.4764515
		},
		zoom: 10
	}),
	withHandlers({
		onMapParamsChange: ({ setMapParams }) => ({ center, zoom, bounds }) => {
			setMapParams({ center, zoom, bounds });
		},
		_onChildClick: (key, childProps) => {

		},
		_onChildMouseEnter: (key, childProps) => {

		},
		_onChildMouseLeave: (key, childProps) => {

		},
		renderMarker: () => (marker) => {
			return <p >...</p>
		},
		// be sure in current implementation markers is tile markers, not all markers.
		// tiling is used as it allows some perf optimizations not used here
		renderMarkers: () => ({ ctx, markers, tileSize }) => {
			ctx.clearRect(0, 0, tileSize, tileSize);
			const radius = 45;
			markers.forEach(({ matricola, x, y }) => {
				// just circles here but can be images, use id or other marker props to render
				ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
				ctx.beginPath();
				ctx.arc(x, y, radius + 3, 0, Math.PI * 2, true);
				ctx.closePath();
				ctx.fill();

				ctx.fillStyle = 'white';
				ctx.beginPath();
				ctx.arc(x, y, radius + 2, 0, Math.PI * 2, true);
				ctx.closePath();
				ctx.fill();

				var mainColor = '#AD99FF';
				var textColor = 'white';

				ctx.font = '14px Arial';
				ctx.fontWeight = 'bold';
				ctx.fillStyle = mainColor;
				ctx.beginPath();
				ctx.arc(x, y, radius, 0, Math.PI * 2, true);

				ctx.closePath();
				ctx.fill();
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillStyle = textColor;
				ctx.fillText(matricola, x, y);
				ctx.font = '8px Arial';


			});
		}
	})
);

export default mapHOC(map);
