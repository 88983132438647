import {takeLatest, select} from 'redux-saga/effects';
import * as lottiCommands from 'views/rai/lotti/redux/lotti.commands';
import * as produzioniCommands from 'views/rai/produzioni/redux/produzioni.commands';
import * as lavoratoriCommands from 'views/rai/lavoratori/redux/lavoratori.commands';
import * as serviziCommands from 'views/rai/servizi/redux/servizi.commands';
import * as utentiCommands from 'views/rai/utenti/redux/utenti.commands';
import * as indirizziCommands from 'views/rai/indirizzi/redux/indirizzi.commands';
import * as contrattiCommands from 'views/rai/contratti/redux/contratti.commands';
import * as uorgCommands from 'views/rai/uorg/redux/uorg.commands';
import * as sectorCommands from 'views/rai/settori/redux/settori.commands';
import * as studyCommands from 'views/rai/studi/redux/studi.commands';
import * as documentTypeCommands from 'views/rai/documentsType/redux/documentType.commands';

import * as coopsCommands from 'views/rai/coops/redux/coops.commands';
import * as motiviAssenzaCommands from 'views/rai/motivi/redux/motivi.commands';
import * as Assistance from 'assistance/index';
import {isAdminOrAdminCoop} from "../views/rai/utenti/components/costants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* userLoggedOut(action) {
    setTimeout(() => {
        if (action.payload && action.payload.history) action.payload.history.push('/auth/login');
    }, 100);
}

function* fetchRequiredData(action) {
    contrattiCommands.OttieniContratti();
    utentiCommands.OttieniUtenti();
    lavoratoriCommands.OttieniLavoratoriValidi();

    lottiCommands.OttieniLotti();
    produzioniCommands.OttieniProduzioni();
    serviziCommands.OttieniDati();
    indirizziCommands.OttieniIndirizzi();
    uorgCommands.OttieniUorg();
    sectorCommands.OttieniSettori();
    studyCommands.OttieniStudi();
    documentTypeCommands.ReadAll();
    coopsCommands.ReadAll();

    motiviAssenzaCommands.OttieniMotiviAssenza();
}

function* checkDataIntegrity() {
    const state = yield select(state => state);
    // const userRole = get(state,"login.user.role", "-");

    if (!state.raiLotti.loading && (!state.raiLotti.data || state.raiLotti.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Lotti non presenti: ricarico.');
        lottiCommands.OttieniLotti();
    }

    if (!state.raiProduzioni.loading && (!state.raiProduzioni.data || state.raiProduzioni.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Produzioni non presenti: ricarico.');
        produzioniCommands.OttieniProduzioni();
    }

    if (!state.raiServizi.loading && (!state.raiServizi.data || state.raiServizi.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Servizi non presenti: ricarico.');
        serviziCommands.OttieniDati();
    }


    if (!state.raiIndirizzi.loading && (!state.raiIndirizzi.data || state.raiIndirizzi.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Indirizzi non presenti: ricarico.');
        indirizziCommands.OttieniIndirizzi();
    }


    if (!state.raiStudi.loading && (!state.raiStudi.data || state.raiStudi.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Studi non presenti: ricarico.');
        studyCommands.OttieniStudi();
    }

    if (!state.raiUorg.loading && (!state.raiUorg.data || state.raiUorg.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Uorgs non presenti: ricarico.');
        uorgCommands.OttieniUorg();
    }

    if (!state.lavoratori.loading && (!state.lavoratori.data || state.lavoratori.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Uorgs non presenti: ricarico.');
        lavoratoriCommands.OttieniLavoratori();
    }

    if (!state.contract.loading && (!state.contract.data || state.contract.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Contratti non presenti: ricarico.');
        contrattiCommands.OttieniContratti();
    }

    if (!state.raiSettori.loading && (!state.raiSettori.data || state.raiSettori.data.length === 0)) {
        console.log('ControlloIntegritàDati:: Settori non presenti: ricarico.');
        sectorCommands.OttieniSettori();
    }


    if (isAdminOrAdminCoop()) {
        if (!state.raiUtenti.loading && (!state.raiUtenti.data || state.raiUtenti.data.length === 0)) {
            console.log('ControlloIntegritàDati:: Utenti non presenti: ricarico.');
            utentiCommands.OttieniUtenti();
        }
    }
}

function* requestAssistance(action) {
    Assistance.Request();
}


function* answerAssistance(action) {
    console.log('ACTION', action);
    Assistance.AnswerToRequest(action.payload.roomName);
}


function* mySaga() {
    yield takeLatest('AUTH_LOGOUT', userLoggedOut);
    yield takeLatest('AUTH_LOGGED', fetchRequiredData);
    yield takeLatest('AUTH_REFRESHED_TOKEN', fetchRequiredData);
    yield takeLatest('REQUEST_ASSISTANCE', requestAssistance);
    yield takeLatest('ANSWER_ASSISTANCE', answerAssistance);

    yield takeLatest('CHECK_DATA_INTEGRITY', checkDataIntegrity);
    yield takeLatest('CHECK_DATA_UPDATE', fetchRequiredData);
}

export default mySaga;
