import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/budgets.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function UpdateProductionComponent(props) {

    const nome = TextInputHook(props.name);
    const descrizione = TextInputHook(props.description);
    const matricola = NumberInputHook(props.number);
    const contrattoNumero = TextInputHook(props.contractNumber);
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [contract, setContract] = useState(props.contract);
    const contracts = useSelector(state => state.contract.data);

    const sectors = useSelector(state => state.raiSettori.data);

    const [sectorsSelected, setSectorsSelected] = useState(sectors.filter((sector)=>(props.sectors.includes(sector.id))));

    const Submit = async () => {
        setLoading(true);

        var res = await Commands.AggiornaBudgets(props.id,nome.getValue(), matricola.getValue(), descrizione.getValue(), contract.id, contrattoNumero.getValue(), sectorsSelected);

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };






    const clearForm = () => {
        descrizione.clear();
        nome.clear();
        matricola.clear();
        setContract(undefined);
    }


    useEffect(() => {

        if (submitError) {

            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica lotto</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    Submit();
                }}>
                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome produzione"
                        />
                    </FormGroup>
                    {/*<FormGroup>*/}
                    {/*    <Label for="descrizione">Descrizione</Label>*/}
                    {/*    <Input*/}
                    {/*        {...descrizione.input}*/}
                    {/*        name="descrizione"*/}
                    {/*        id="descrizione"*/}
                    {/*        placeholder="Inserisci descrizione"*/}
                    {/*    />*/}
                    {/*    <FormText color="muted">*/}
                    {/*        La descrizione è facoltativa.*/}
                    {/*    </FormText>*/}
                    {/*</FormGroup>*/}
                    <FormGroup>
                        <Label for="settori">Settore</Label>

                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={sectorsSelected}
                            getOptionLabel={(item) => item.description}
                            getOptionValue={(item) => item.id}
                            onChange={value =>
                            {
                                setSectorsSelected(value);
                            }
                            }
                            closeMenuOnSelect={false}
                            isMulti
                            options={[{
                                value: "",
                                description: "Settore",
                                isDisabled: true,
                                key: 'id'
                            }, ...sectors]}
                            placeholder="Seleziona un settore"
                        />


                        <FormText color="muted">
                            Contratto a cui si riferisce la produzione
                        </FormText>
                    </FormGroup>

                    <FormGroup>
                        <Label for="contrattoNumero">Contratto numero</Label>
                        <Input
                            {...contrattoNumero.input}
                            name="contrattoNumero"
                            id="contrattoNumero"
                            placeholder="Inserisci numero contratto"
                        />
                    </FormGroup>                    <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value => {
                                setContract(value);
                            }}
                            options={[  {
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce il lotto
                        </FormText>
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare la produzione.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={Submit}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
