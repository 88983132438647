import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/clienti.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from "react-redux";
import Select from "react-select";
import {CurrentUser, IsRole, RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP} from "../../utenti/components/costants";

export default function CreateLotComponent(props) {

    const companyName = TextInputHook('');
    const vatID = TextInputHook('');

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const coops = useSelector(state => state.coops.data.map(item => ({...item, key: item.id})));

    let defaultCoop = undefined;

    if (IsRole(RUOLO_AMMINISTRATORE_COOP)) {
        defaultCoop = {
            id: CurrentUser().coopId,
            companyName: CurrentUser().coopName
        };
    }

    // console.log('Current', CurrentUser());
    const [selectedCoop, setSelectedCoop] = useState(defaultCoop);

    const salvaLotto = async () => {
        setLoading(true);
        var res = await Commands.SalvaCLiente(selectedCoop.id, selectedCoop.companyName, companyName.getValue(), vatID.getValue());
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        vatID.clear();
        companyName.clear();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo cliente</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaLotto();
                }}>
                    {
                        IsRole(RUOLO_AMMINISTRATORE) &&
                        <FormGroup>
                            <Label for="contratto">Cooperativa</Label>
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={selectedCoop}
                                getOptionLabel={(item) => item.companyName}
                                getOptionValue={(item) => item.id}
                                onChange={value => {
                                    setSelectedCoop(value);
                                }
                                }
                                closeMenuOnSelect={false}
                                options={[{
                                    value: "",
                                    companyName: "Coop",
                                    isDisabled: true,
                                    key: 'coop'
                                }, ...coops]}
                                placeholder="Seleziona cooperativa"
                            />
                            <FormText color="muted">
                                Cooperativa di pertinenza
                            </FormText>
                        </FormGroup>
                    }
                    <FormGroup>
                        <Label for="companyName">Ragione sociale</Label>
                        <Input
                            {...companyName.input}
                            name="companyName"
                            id="companyName"
                            placeholder="Inserisci ragione sociale"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="vatID">Partita IVA</Label>
                        <Input
                            {...vatID.input}
                            name="vatID"
                            id="vatID"
                            placeholder="Inserisci partita Iva"
                        />
                        <FormText color="muted">
                            La vatID è facoltativa.
                        </FormText>
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il lotto.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaLotto}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
