import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect, useRef} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/utenti.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import TagsInput from "react-tagsinput";
import SectorRoleComponent from "views/rai/utenti/components/SectorRoleComponent";
import {IsRole, ruoli, ruoliSettori, RUOLO_AMMINISTRATORE_COOP} from "views/rai/utenti/components/costants";
import {CurrentUser, GetLowerRolesForRole, isAdmin} from "./costants";

export default function CreateUserComponent(props) {

    const username = TextInputHook('');
    const pass = TextInputHook('');
    const email = TextInputHook('');
    const [tags, setTags] = useState([]);

    const nome = TextInputHook('');
    const cognome = TextInputHook('');
    const contratto = TextInputHook('Rai');
    const matricola = TextInputHook('');

    const [role, setRole] = useState({});

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedContracts, setSelectedContracts] = useState([]);

    const [orderCreationNotification, setOrderCreationNotification] = useState([]);
    const [orderSignNotification, setOrderSignNotification] = useState([]);
    const utenti = useSelector(state => state.raiUtenti.data);

    let defaultCoop = undefined;

    if (IsRole(RUOLO_AMMINISTRATORE_COOP)) {
        defaultCoop = {
            id: CurrentUser().coopId,

            companyName: CurrentUser().coopName
        };
    }

    const [selectedCoop, setSelectedCoop] = useState(defaultCoop);

    // eslint-disable-next-line no-unused-vars
    const [selectedSectors, setSelectedSectors] = useState([]);

    const coops = useSelector(state => state.coops.data.map(item => ({...item, key: item.id})));
    const contracts = useSelector(state => state.contract.data.map(item => ({...item, key: item.id})));
    const sectors = useSelector(state => state.raiSettori.data.map(item => ({...item, key: item.id})));

    const sectorRoleComponentRef = useRef(null);

    const handleTags = (newTags) => {
        setTags(newTags);
    };

    const salvaUtente = async () => {
        const sectorsRoles = sectorRoleComponentRef.current.getSectorsRoles();
        setLoading(true);

        var res = await Commands.SalvaUtente(
            username.getValue(),
            pass.getValue(),
            email.getValue(),
            nome.getValue(),
            cognome.getValue(),
            sectorsRoles,
            role,
            matricola.getValue(),
            tags,
            selectedContracts,
            selectedCoop.id,
            selectedCoop.companyName,
            orderCreationNotification.map(item => item.email),
            orderSignNotification.map(item => item.email)
        );

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        nome.clear();
        contratto.clear();
        setSelectedContracts([]);
        setSelectedSectors([]);
    }

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo utente</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaUtente();
                }}>
                    <FormGroup>
                        <Label for="nome">Username</Label>
                        <Input
                            {...username.input}
                            name="username"
                            id="username"
                            placeholder="Inserisci username"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Password</Label>
                        <Input
                            {...pass.input}
                            name="pass"
                            id="pass"
                            placeholder="Inserisci password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="descrizione">E-Mail</Label>
                        <Input
                            {...email.input}
                            name="email"
                            id="email"
                            placeholder="Inserisci indirizzo mail"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="cognome">Cognome</Label>
                        <Input
                            {...cognome.input}
                            name="cognome"
                            id="cognome"
                            placeholder="Inserisci cognome"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="uorg">Settori - Ruolo</Label>
                        <SectorRoleComponent ref={sectorRoleComponentRef} ruoli={ruoliSettori} sectors={sectors}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="ruolo">Ruolo</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={role}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setRole(value)
                            }
                            options={[{
                                value: "",
                                description: "Ruolo",
                                isDisabled: true
                            }, ...GetLowerRolesForRole()]}
                            placeholder="Seleziona un ruolo"
                        />
                        <FormText color="muted">
                            Ruolo a cui si riferisce l'utente
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Matricola</Label>
                        <Input
                            {...matricola.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci matricola"
                        />
                    </FormGroup>

                    <TagsInput
                        tagProps={{className: 'react-tagsinput-tag info'}}
                        value={tags}
                        onChange={handleTags}
                        name="tags"
                        id="tags"
                        inputProps={{placeholder: 'Nuovo tag'}}
                    />
                    {
                        isAdmin() ?
                            <>
                                <FormGroup>
                                    <Label for="superiori">Notifica creazione ordine</Label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Seleziona utenti"
                                        name="multipleSelect"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={orderCreationNotification}
                                        onChange={value =>
                                            setOrderCreationNotification(value)
                                        }
                                        getOptionLabel={utente => utente.username}
                                        getOptionValue={utente => utente.id}
                                        options={[{
                                            value: "",
                                            description: "Destinatari",
                                            isDisabled: true
                                        }, ...utenti]}

                                    />
                                    <FormText color="muted">
                                        Destinatari notifica creazione ordine
                                    </FormText>

                                </FormGroup>

                                <FormGroup>
                                    <Label for="superiori">Notifica firma ordine</Label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Seleziona utenti"
                                        name="multipleSelect"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={orderSignNotification}
                                        onChange={value =>
                                            setOrderSignNotification(value)
                                        }
                                        getOptionLabel={utente => utente.username}
                                        getOptionValue={utente => utente.id}
                                        options={[{
                                            value: "",
                                            description: "Destinatari",
                                            isDisabled: true
                                        }, ...utenti]}

                                    />
                                    <FormText color="muted">
                                        Destinatari notifica firma ordine
                                    </FormText>

                                </FormGroup>

                                <FormGroup>
                                    <Label for="contratto">Cooperativa</Label>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={selectedCoop}
                                        getOptionLabel={(item) => item.companyName}
                                        getOptionValue={(item) => item.id}
                                        onChange={value => {
                                            setSelectedCoop(value);
                                            setSelectedContracts([]);
                                        }
                                        }
                                        closeMenuOnSelect={false}
                                        options={[{
                                            value: "",
                                            companyName: "Coop",
                                            isDisabled: true,
                                            key: 'coop'
                                        }, ...coops]}
                                        placeholder="Seleziona cooperativa"
                                    />
                                    <FormText color="muted">
                                        Cooperativa di pertinenza dell'utente
                                    </FormText>
                                </FormGroup></>
                            : null
                    }
                    {
                        selectedCoop !== undefined ?
                            <FormGroup>
                                <Label for="contratto">Contratto</Label>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={selectedContracts}
                                    getOptionLabel={(item) => item.description}
                                    getOptionValue={(item) => item.id}
                                    onChange={value => {
                                        setSelectedContracts(value);
                                    }
                                    }
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={[{
                                        value: "",
                                        description: "Contratto",
                                        isDisabled: true,
                                        key: 'contratto'
                                    }, ...contracts.filter(el => el.coopID === selectedCoop.id)]}
                                    placeholder="Seleziona un contratto"
                                />
                                <FormText color="muted">
                                    Contratto/i di pertinenza dell'utente
                                </FormText>
                            </FormGroup> : null
                    }
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare l'utente.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaUtente}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
