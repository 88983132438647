import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/uorg.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function CreateUorgComponent(props) {

    const numero = TextInputHook(props.newName || '');
    const descrizione = TextInputHook('');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(props.contract || null);

    const contracts = useSelector(state => state.contract.data);
    if (!props.contract && contracts.length > 0 && !contract){
        setContract(contracts[0]);
    }

    const customerId = useSelector(state => state.customer.id);

    const salvaUorg = async () => {

        setLoading(true);
        var res = await Commands.SalvaUorg(numero.getValue(), descrizione.getValue(),  customerId, contract.id);
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        if (props.onSuccess){
            props.onSuccess(res.data);
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
        numero.clear();
        setContract(undefined);
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo Uorg</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaUorg();
                }}>
                    <FormGroup>
                        <Label for="nome">Numero</Label>
                        <Input
                            {...numero.input}
                            name="numero"
                            id="numero"
                            placeholder="Inserisci il nome della UORG"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="descrizione">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserisci descrizione della UORG"
                        />
                    </FormGroup>
                    {!props.contract && contracts.length > 1 && <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setContract(value)
                            }
                            options={[  {
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce il lotto
                        </FormText>
                    </FormGroup> }
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il lotto.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaUorg}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
