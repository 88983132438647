import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './clienti.types';
import * as actions from './clienti.actions';

export function SalvaCLiente(coopID, coopName, companyName, vatID) {

    var variables = {coopID, coopName, companyName, vatID};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create (
            $companyName: String!
            $vatID: String
            $coopID: String!
            $coopName: String!
        ) {
            createdObject: createCompany (
                companyName: $companyName,
                vatID: $vatID,
                coopID: $coopID,
                coopName: $coopName
            ) {
                companyName
                id
                vatID
                associated
                coopID
                coopName
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare il cliente';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniClienti() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {}

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList:companies (
                type: "customer"
            ) {
                companyName
                id
                vatID
                associated
                coopName
                coopID
            }
        }
        `,
    };

    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {


                var message = "Impossibile ottenere i clienti"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AggiornaLotto(id, name, number, description, contractid, customerid) {

    var variables = {id, name, number, description, contractid, customerid};
    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
            $code: String,
            $description: String,
            $buyingPrice: Float,
            $sellingPrice: Float,
            $jsonExtra: String,
            $contractID: String

        ){
            updatedObject: updateArticle(
                id: $id,
                code: $code,
                buyingPrice: $buyingPrice,
                sellingPrice: $sellingPrice,
                description: $description,
                jsonExtra: $jsonExtra,
                contractID: $contractID

            ) {
                id
                code
                buyingPrice
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare il cliente';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaCliente(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteCompany (id: $id) {
                id
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il cliente';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
