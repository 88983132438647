/* eslint-disable */

const ns = 'ANALYTICS';

export const REQUEST_READ_ALL = `${ns}REQUEST_READ_ALL` ;
export const READ_ALL_ERROR = `${ns}READ_ALL_ERROR`;
export const READED_ALL =  `${ns}READED_ALL`;
export const REQUEST_UPDATE = `${ns}REQUEST_UPDATE` ;
export const REQUEST_UPDATE_PAYMENT = `${ns}REQUEST_UPDATE_PAYMENT` ;
export const UPDATED =  `${ns}UPDATED`;
