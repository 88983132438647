import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux'
import {get} from 'lodash';
// For auto registry purpose
import './redux/clienti.reducer';
import * as Commands from './redux/clienti.commands';
import CreateProductionComponent from "./components/clienti.create.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';

class ClientiComponent extends Component {

    state = {
        data: [],
        showCreateModal: false,
        showUpdateModal: false,
        loading: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {}
    }

    caricaDati = async () => {
        this.setState({loading: true});
        await Commands.OttieniClienti();
        this.setState({loading: false});
    }

    componentDidMount() {
        this.caricaDati();
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }

    elimina = async () => {
        var res = await Commands.EliminaCliente(this.state.selectedRecordForDelete.id);

        if (res.hasError) {
            alert('Impossibile eliminare');
            return;
        }
        this.toggleDeleteAlert();
    }

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            {/*<Button*/}
            {/*    color="warning"*/}
            {/*    size="sm"*/}
            {/*    onClick={() => this.toggleShowUpdateModal(cellInfo.original)}*/}
            {/*    className={classNames("btn-icon btn-link like")}>*/}
            {/*    <i className="tim-icons icon-pencil"/>*/}
            {/*</Button>{" "}*/}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {
        return (
            <>
                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecordForDelete.companyName", "")}
                        </ReactBSAlert> : null}

                {/*Modal creazione e aggiornamento produzione*/}
                <CreateProductionComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuovo cliente
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuovo cliente<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista clienti</CardTitle>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "id",
                                                accessor: "id",
                                                show:false,
                                            },
                                            {
                                                Header: "companyName",
                                                accessor: "companyName"
                                            },
                                            {
                                                Header: "vatID",
                                                accessor: "vatID",

                                            },
                                            {
                                                Header: "Cooperativa",
                                                accessor: "coopName"
                                            }
                                         /*   {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false
                                            }*/
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "customer.data", []),
        loading: get(state, "customer.loading", false)
    };

    return data;
};

export default connect(mapStateToProps, () => ({}))(ClientiComponent)