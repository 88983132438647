import Client from "Apolloclient";
import {userLogged, userNotLogged, userLogout, tokenRefreshed} from "views/pages/Login/redux/login.actions";
import store from "store/store";
import gql from "graphql-tag";

export async function  login(username, password){
    let query =  {
        fetchPolicy: 'no-cache',
        variables: {username, password},
        mutation: gql`,
            mutation auth($username: String!, $password: String!){
                auth(username:$username, password:$password) {
                    username
                    jwt
                    id
                    role
                    tags
                    jsonextra
                    email
                    coopId
                    coopName
                }
            }
            `,
    };

    //await sleep(2000);
    return Client.mutate(query).then(
        async response => {
            let obj = {};
            obj.jwt = response.data.auth.jwt;
            let decoded = JSON.parse(atob(obj.jwt.split('.')[1]));
            obj.user = {};
            obj.user.id = response.data.auth.id;
            obj.user.username = response.data.auth.username;
            obj.user.tags = response.data.auth.tags;
            obj.user.role = response.data.auth.role;
            obj.user.jsonextra = response.data.auth.jsonextra;
            obj.user.email = response.data.auth.email;
            obj.user.coopId = response.data.auth.coopId;
            obj.user.coopName = response.data.auth.coopName;
            store.dispatch(userLogged(obj));

            return {error: false, data: obj};
        }
    ).catch(
        e => {
            var message ="Impossibile loggare, contattare l'amministratore di sistema."
            console.error(e);
            store.dispatch(userNotLogged());

            if (e.graphQLErrors && e.graphQLErrors.length > 0){
                message = e.graphQLErrors[0].message;
            }
            else {

            }

            //store.dispatch(userNotLogged(message));
            return {
                error: true,
                message: message
            }
        }
    );
}

export function logout(history){
    store.dispatch(userLogout(history));
}

export async function refreshToken(token){

    let query =  {
        fetchPolicy: 'no-cache',
        variables: {token},
        mutation: gql`,
            mutation refreshToken($token: String!){
                auth:refreshToken(token:$token) {
                    username
                    jwt
                    id
                    role
                    tags
                    jsonextra
                    email
                    coopId
                    coopName
                }
            }
            `,
    };


    return Client.mutate(query).then(
        async response => {


            let obj = {};
            obj.jwt = response.data.auth.jwt;
            let decoded = JSON.parse(atob(obj.jwt.split('.')[1]));
            obj.user = {};
            obj.user.id = response.data.auth.id;
            obj.user.username = response.data.auth.username;
            obj.user.tags = response.data.auth.tags;
            obj.user.role = response.data.auth.role;
            obj.user.jsonextra = response.data.auth.jsonextra;
            obj.user.email = response.data.auth.email;
            obj.user.coopId = response.data.auth.coopId;
            obj.user.coopName = response.data.auth.coopName;

            store.dispatch(tokenRefreshed(obj));
            return {hasError: false, data: obj};
        }
    ).catch(
        e => {
            var message ="Impossibile refreshare il token, contattare l'amministratore di sistema."
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0){
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        }
    );
}
