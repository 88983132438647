import * as types from "./document.types";

export function created(d){
    return {
        type: types.CREATED,
        payload: d
    }
}

export function downloaded(data){
    return {
        type: types.DOWNLOADED,
        payload: data
    }
}

export function readedAll(obj){
    return {
        type: types.READED_ALL,
        payload: obj
    }
}


export function readedAllWorker(obj){
    return {
        type: types.READED_ALL_WORKER,
        payload: obj
    }
}

export function requestReadAllWorker(){
    return {
        type: types.REQUEST_READ_ALL_WORKER
    }
}



export function requestRead(){
    return {
        type: types.REQUEST_READ
    }
}

export function readed(obj){
    return {
        type: types.READED,
        payload: obj
    }
}


//UPDATE
export function requestUpdate(obj){
    return {
        type: types.REQUEST_UPDATE,
        payload: obj
    }
}

export function updated(obj){
    return {
        type: types.UPDATED,
        payload: obj
    }
}

//DELETE
export function requestDelete(id){
    return {
        type: types.REQUEST_DELETE,
        payload: id
    }
}

export function deleted(obj){
    return {
        type: types.DELETED,
        payload: obj
    }
}

export function deleteError(id){
    return {
        type: types.DELETE_ERROR,
        payload: id
    }
}
