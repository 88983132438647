/* eslint-disable */
const ns = 'RAI_ORDINI';

export const REQUEST_CREATE = `${ns}REQUEST_CREATE`  ;
export const REQUEST_READ_ALL = `${ns}REQUEST_READ_ALL`  ;
export const READ_ALL_ERROR = `${ns}READ_ALL_ERROR`  ;

export const REQUEST_READ = `${ns}REQUEST_READ`  ;
export const REQUEST_UPDATE = `${ns}REQUEST_UPDATE`  ;
export const REQUEST_DELETE = `${ns}REQUEST_DELETE`  ;

export const CREATED =  `${ns}CREATED`;
export const READED_ALL = `${ns}READED_ALL`;
export const READED =  `${ns}READED`;
export const UPDATED =   `${ns}UPDATED`;
export const DELETED =`${ns}DELETED`;
export const DELETE_ERROR =`${ns}DELETE_ERROR`;

export const CREATED_RECURRENT = `${ns}CREATED_RECURRENT`;
export const ORDER_CHANGED_STATE =  `${ns}ORDER_CHANGED_STATE`;
export const ORDER_ASSIGN = `${ns}ORDER_ASSIGN`;
