import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './exportDb.types';


export function Ottieni(startDate, endDate, fullText) {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {
        startDate,
        endDate,
        fullText
    }

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
         query read($startDate: UnixDate, $endDate: UnixDate, $fullText: String){
            objectList: analytics(startDate: $startDate, endDate: $endDate, fullText: $fullText) {
              id
              qty
              orderNumber   
              unitSellPrice
              totalSellPrice
              article
              articleId
              lot
              lotId
              applicationNumber
              invoiceNumber
              production
              productionId
              productionNumber
              date
              startTime
              endTime
              contract
              contractId
              type
              uorg
              articleNumber
              totalHours
              totalAssignedHours
             }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return {
                    hasError: false
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i lotti"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}
