import React, {useEffect, useState, useRef} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from "reactstrap";
import * as Commands from "../redux/document.commands";
import * as _ from 'lodash';

import {useSelector, shallowEqual} from "react-redux";
import Select from "react-select";
import ClassNames from "classnames";

import Viewer, {Worker, LocalizationMap} from '@phuocng/react-pdf-viewer';
import moment from 'moment';
import Picker from 'react-month-picker'

import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import it_IT from './it_IT.json';

import DocumentMetasComponent from "./document.metas.component"
import DocumentTypeMetasComponent from "views/rai/documentsType/components/documentType.metas.component";
import FormBuilder from "components/FormBuilder/FormBuilder";

const PdfViewer = React.memo((props) => {

    const [fileContent, setFileContent] = useState("");
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    useEffect(() => {
        if (!props.id || props.id.length === 0) return;
        downloadDocument(props.id);
    }, [props.id]);

    const downloadDocument = async () => {
        setLoading(true);
        var res = await Commands.DownloadDocument(props.id);
        setLoading(false);

        if (res.hasError) {
            // console.log('Error download document', res);
            setSubmitError(true);
            return;
        }
        setFileContent(res.data.Base64);
    };


    if (loading) {
        return <div>Caricamento in corso...</div>
    }

    return (
        // <Worker
        //     workerUrl="https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.worker.min.js">
        //     <div
        //         style={{
        //             height: '750px',
        //             color: 'black'
        //         }}
        //     >
        //         <Viewer localization={it_IT}
        //                 fileUrl={Uint8Array.from(atob(fileContent), c => c.charCodeAt(0))}/>
        //     </div>
        // </Worker>
        <iframe src={`data:application/pdf;base64,${fileContent}`} style={{minHeight: "100%"}} height="100%" width="100%"></iframe>

    );
});


export default function UpdateDocument(props) {


    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const [currentDocumentId, setCurrentDocumentId] = useState("");

    const [id, setId] = useState("");
    const [documentType, setDocumentType] = useState({});
    const [documentPeriod, setDocumentPeriod] = useState({month: 1, year: 2050});
    const [workerSelected, setWorkerSelected] = useState(false);
    const [metas, setMetas] = useState([]);
    const documentTypeMetasComponentRef = useRef(null);


    const workers = useSelector(state => state.documents.workers, shallowEqual);
    const documentTypes = useSelector(state => state.documentType.data, shallowEqual);
    const pickAMonth = useRef(null);

    const [indexSelectedDocument, setIndexSelectedDocument] = useState(0);
    const [totalDocuments, setTotalDocuments] = useState(0);

    const handleKeyDown = (e) => {
        // arrow up/down button should select next/previous list element
        if (e.keyCode === 37) {
            previousDocument()
        } else if (e.keyCode === 39) {
            nextDocument()
        }
    };


    const leftPad = require('left-pad');

    const pickerLang = {
        months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        from: 'Da', to: 'A',
    };

    const makeText = m => {
        if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year);
        return '?'
    };

    const nextDocument = () => {
        const nextIndex = indexSelectedDocument + 1;
        if (nextIndex >= totalDocuments) return;
        setIndexSelectedDocument(nextIndex);
        loadData(nextIndex);
    };

    const previousDocument = () => {
        const nextIndex = indexSelectedDocument - 1;

        if (nextIndex < 0) return;
        setIndexSelectedDocument(nextIndex);
        loadData(nextIndex);
    };

    const loadData = (index) => {
        const currentDocument = props.documents[index]._original;
        // console.log('CURRENT DOC', currentDocument);

        setCurrentDocumentId(currentDocument.id);
        setDocumentType(currentDocument.documentType);
        setWorkerSelected(currentDocument.worker);
        const momentPeriod = moment.unix(currentDocument.periodUnix);
        setDocumentPeriod({month: momentPeriod.month() + 1, year: momentPeriod.year()});
        setMetas(currentDocument.metas);
    };

    useEffect(() => {
        if (props.documents && props.documents.length === 1) {
            // Modalità singola
            const currentDocument = props.documents[0];
            // console.log('CURRENT DOCUMENET', currentDocument);
            setCurrentDocumentId(_.get(currentDocument, "id", ""));
            setDocumentType(currentDocument.documentType);
            setWorkerSelected(currentDocument.worker);
            const momentPeriod = moment.unix(currentDocument.periodUnix);
            setDocumentPeriod({month: momentPeriod.month() + 1, year: momentPeriod.year()});
            setMetas(currentDocument.metas);
        }

        if (props.documents && props.documents.length > 1) {
            // Modalità multipla
            setTotalDocuments(props.documents.length);
            loadData(indexSelectedDocument);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });


    const updateDocument = async () => {
        setLoading(true);

        const tmpMetas = documentTypeMetasComponentRef.current.getFormValues();
        let metasObj = {};
        Object.keys(tmpMetas).map(fieldName => {
            metasObj[fieldName] = {value: tmpMetas[fieldName].value, valueType: tmpMetas[fieldName].type}
        });

        var res = await Commands.UpdateDocument(
            currentDocumentId,
            documentPeriod,
            workerSelected.id,
            documentType.id,
            JSON.stringify(metasObj)
        );

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        props.toggle()
    };


    const clearForm = () => {
    };

    const getMetasField = () => {
        const metasDocType = _.get(documentType, "metas", []);
        let config = {};

        if (!Array.isArray(metasDocType)){
            return config
        }

        metasDocType.map(el => {
            config[el.fieldName] = {
                label: el.fieldName,
                type: el.fieldType
            };
        });

        Object.keys(metas).map(meta => {
            if (config[meta]) config[meta].initialValue = metas[meta].value;
        });

        return config;
    };

    const configField = getMetasField(documentType);

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);


    return (
        <Modal
            modalClassName={ClassNames("modal-black", 'extra-large-sparkle-modal')}

            isOpen={props.show}
            toggle={props.toggle}
        >
            <ModalBody>

                <div className="modal-header justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={props.toggle}
                    >
                        <i className="tim-icons icon-simple-remove"/>
                    </button>
                    <h6 className="title title-up">Gestione documentoooo</h6>
                </div>

                <div className="content assignmentContent">
                    <Card style={{minHeight: "818px", height:"818px"}}>
                        <Row className="mt-2" style={{height: "100%"}}>
                            <Col xs={12} md={8}>
                                <Card style={{height: "100%"}}>
                                    <CardBody>
                                        <PdfViewer id={currentDocumentId}></PdfViewer>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card style={{height: "100%"}}> 
                                    <CardBody>

                                        <FormGroup>
                                            <Label for="superiori">Lavoratore</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                placeholder="Seleziona lavoratori..."
                                                // name="multipleSelect"
                                                closeMenuOnSelect={true}
                                                // isMulti
                                                value={workerSelected.id && workerSelected.id.length > 0 ? workerSelected : undefined}
                                                onChange={value =>
                                                    setWorkerSelected(value)
                                                }
                                                getOptionLabel={w => {

                                                    return (w.cognomeNome + " (" + w.cooperativaAppartenenza + "-" + leftPad(w.matricola, 4, "0") + ")")
                                                }}
                                                getOptionValue={w => w.id}
                                                options={workers}

                                            />
                                            <FormText color="muted">
                                                Selezionare i lavoratori ai quali il documento fa riferimento
                                            </FormText>

                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="contratto">Tipologia documento</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={documentType}
                                                getOptionLabel={(item) => item.description}
                                                onChange={value =>
                                                    setDocumentType(value)
                                                }
                                                options={[{
                                                    value: "",
                                                    description: "Tipologia",
                                                    isDisabled: true
                                                }, ...documentTypes]}
                                                placeholder="Seleziona tipologia documento"
                                            />
                                            <FormText color="muted">
                                                Tipologia documento
                                            </FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="contratto">Periodo</Label>

                                            <Picker
                                                theme="dark"

                                                ref={pickAMonth}
                                                years={[2008, 2011, 2012, 2014, 2016, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025]}
                                                value={documentPeriod}
                                                lang={pickerLang.months}
                                                onChange={(year, month) => {
                                                    setDocumentPeriod({year, month});
                                                    pickAMonth.current.dismiss();
                                                }}
                                                onDismiss={() => {
                                                }}
                                            >
                                            </Picker>

                                            <Input
                                                value={makeText(documentPeriod)}
                                                onClick={() => pickAMonth.current.show()}/>

                                        </FormGroup>
                                        <FormGroup>
                                            <FormBuilder config={configField} ref={documentTypeMetasComponentRef}/>

                                            {/*   <DocumentMetasComponent ref={documentTypeMetasComponentRef}
                                                                    documentType={documentType}
                                                                    metas={metas}>
                                            </DocumentMetasComponent>*/}
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </div>


            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                {
                    props.documents ? <><Button
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={previousDocument}
                        >
                            Precedente
                        </Button>
                            < div>
                                Documento {indexSelectedDocument + 1} di {totalDocuments}
                            </div>

                            <Button
                                color="danger"
                                data-dismiss="modal"
                                type="button"
                                onClick={nextDocument}
                            >
                                Prossimo documento
                            </Button></>
                        : null
                }
                <Button
                    color="primary"
                    type="button"
                    onClick={updateDocument}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
