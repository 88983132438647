import React from "react";
import {Route, Switch} from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// core components
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

import {connect} from "react-redux";

import logoOver from "assets/img/logo-oversinerco.svg";
import logoSavilog from "assets/img/logo-savilog.svg";

let logo = logoOver;
// let companyName = "Oversinerco";
let activeColor = "oversinerco";

if  (window.location.hostname.indexOf("savilog") >= 0){
    logo = logoSavilog;
    // companyName = "Savilog";
    activeColor = "savilog";
}
var ps;

class OnlySideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor: activeColor,
            sidebarMini: true,
            opacity: 0,
            sidebarOpened: false
        };
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(this.refs.mainPanel);
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        window.addEventListener("scroll", this.showNavbarButton);
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
        window.removeEventListener("scroll", this.showNavbarButton);
    }

    componentDidUpdate(e) {
        if (e.location.pathname !== e.history.location.pathname) {
            if (navigator.platform.indexOf("Win") > -1) {
                let tables = document.querySelectorAll(".table-responsive");
                for (let i = 0; i < tables.length; i++) {
                    ps = new PerfectScrollbar(tables[i]);
                }
            }
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }

    showNavbarButton = () => {
        if (
            document.documentElement.scrollTop > 50 ||
            document.scrollingElement.scrollTop > 50 ||
            this.refs.mainPanel.scrollTop > 50
        ) {
            this.setState({opacity: 1});
        } else if (
            document.documentElement.scrollTop <= 50 ||
            document.scrollingElement.scrollTop <= 50 ||
            this.refs.mainPanel.scrollTop <= 50
        ) {
            this.setState({opacity: 0});
        }
    };

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/s") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.pathname.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    handleActiveClick = color => {
        this.setState({activeColor: color});
    };
    handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            this.setState({sidebarMini: false});
        } else {
            this.setState({sidebarMini: true});
        }

        // let options = {};
        // options = {
        //     place: "tr",
        //     message: notifyMessage,
        //     type: "primary",
        //     icon: "tim-icons icon-bell-55",
        //     autoDismiss: 7
        // };
        document.body.classList.toggle("sidebar-mini");
    };
    toggleSidebar = () => {
        this.setState({
            sidebarOpened: !this.state.sidebarOpened
        });
        document.documentElement.classList.toggle("nav-open");
    };
    closeSidebar = () => {
        this.setState({
            sidebarOpened: false
        });
        document.documentElement.classList.remove("nav-open");
    };

    render() {

        return (
            <div className="wrapper">
                <div className="rna-container">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <div
                    className="navbar-minimize-fixed"
                    style={{opacity: this.state.opacity}}
                >
                    <button
                        className="minimize-sidebar btn btn-link btn-just-icon"
                        onClick={this.handleMiniClick}
                    >
                        <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"/>
                        <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"/>
                    </button>
                </div>
                <Sidebar
                    {...this.props}
                    routes={routes}
                    activeColor={this.state.activeColor}
                    logo={{
                        outterLink: undefined,
                        text: "",
                        imgSrc: logo
                    }}
                    closeSidebar={this.closeSidebar}
                />
                <div
                    className="main-panel magazzino"
                    ref="mainPanel"
                    data={this.state.activeColor}
                >
                    <Switch>{this.getRoutes(routes)}</Switch>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
});


export default connect(mapStateToProps, () => ({}))(OnlySideBar)


