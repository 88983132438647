import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/servizi.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function CreateLotComponent(props) {

    const voce = TextInputHook('');
    const descrizione = TextInputHook('');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contratto, setContratto] = useState({});
    const [lotto, setLotto] = useState({});
    const [tipologia, setTipologia] = useState({});
    const prezzoVendita = NumberInputHook(0);
    const prezzoAcquisto = NumberInputHook(0);
    const oreModulo = NumberInputHook(4);
    const agentiModulo = NumberInputHook(1);
    const daKm = NumberInputHook(0);
    const aKm = NumberInputHook(0);

    const contracts = useSelector(state => state.contract.data);
    const lotti = useSelector(state => state.raiLotti.data);

    useSelector(state => state.customer.id);

    const salvaLotto= async () => {
        setLoading(true);
        var res = await Commands.Crea(voce.getValue(), descrizione.getValue(), contratto, lotto, tipologia, prezzoVendita.getValue(), prezzoAcquisto.getValue(), oreModulo.getValue(), agentiModulo.getValue(), daKm.getValue(), aKm.getValue());
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
        voce.clear();
        setContratto(undefined);
        daKm.clear();
        aKm.clear();
    }


    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (

        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo servizio</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaLotto();
                }}>
                    <FormGroup>
                        <Label for="voce">Voce</Label>
                        <Input
                            {...voce.input}
                            name="voce"
                            id="voce"
                            placeholder="Inserisci voce del servizio"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="descrizione">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            id="descrizione"
                            placeholder="Inserisci descrizione"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="contratto">Contratto</Label>
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={contratto}
                                getOptionLabel={(item) => item.description}
                                onChange={value =>
                                    setContratto(value)
                                }
                                options={[{
                                    value: "",
                                    description: "Contratto",
                                    isDisabled: true
                                }, ...contracts]}
                                placeholder="Seleziona un contratto"
                            />
                    </FormGroup>
                    <FormGroup >
                        <Label for="lotto">Lotto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={lotto}
                            getOptionLabel={(item) => item.name}
                            onChange={value =>
                                setLotto(value)
                            }
                            options={[{
                                value: "",
                                name: "Lotto",
                                isDisabled: true
                            }, ...lotti.filter(
                                lotto => contratto && lotto.contract.id === contratto.id
                            )]}
                            placeholder="Seleziona un lotto"
                        />

                    </FormGroup>
                    <FormGroup>
                        <Label for="tipologia">Tipologia</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={tipologia}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setTipologia(value)
                            }
                            options={[{
                                value: "",
                                description: "Tipologia",
                                isDisabled: true
                            },
                                {
                                    value: "",
                                    description: "Canone",
                                },

                                {
                                    value: "",
                                    description: "Modulo",
                                },
                                {
                                    value: "",
                                    description: "Modulo-Trasporto",
                                },
                                {
                                    value: "",
                                    description: "Trasporto",
                                }
                            ]}
                            placeholder="Seleziona una tipologia"
                        />
                    </FormGroup>
                    {
                        (tipologia.description === 'Modulo' || tipologia.description === 'Modulo-Trasporto')  &&
                            <>
                        <FormGroup>
                            <Label for="oreModulo">Ore modulo</Label>
                            <Input
                                {...oreModulo.input}
                                id="oreModulo"
                                placeholder="Inserisci prezzo di vendita"
                            />
                        </FormGroup>
                        <FormGroup>
                        <Label for="agentiModulo">Agenti modulo</Label>
                        <Input
                        {...agentiModulo.input}
                        id="agentiModulo"
                        placeholder="Inserisci prezzo di vendita"
                        />
                        </FormGroup>
                        </>
                    }
                    {
                        (tipologia.description === 'Modulo-Trasporto')  &&
                        <>
                            <FormGroup>
                                <Label for="daKm">Da Km</Label>
                                <Input
                                    {...daKm.input}
                                    id="daKm"
                                    placeholder="Da kilometri"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="aKm">A Km</Label>
                                <Input
                                    {...aKm.input}
                                    id="aKm"
                                    placeholder="Fino a kilometri"
                                />
                            </FormGroup>
                        </>
                    }

                    <FormGroup>
                        <Label for="prezzoVendita">Prezzo di vendita</Label>
                        <Input
                            {...prezzoVendita.input}
                            id="prezzoVendita"
                            placeholder="Inserisci prezzo di vendita"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="prezzoAcquisto">Prezzo di acquisto</Label>
                        <Input
                            {...prezzoAcquisto.input}
                            id="prezzoAcquisto"
                            placeholder="Inserisci prezzo di acquisto"
                        />
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il servizio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaLotto}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
