import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/budgets.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import DateRangePickerWrapper from "components/DateRangePicker/DateRangePickerWrapper";
import moment from "moment";
import {NumberInputHook} from "../../../../hooks/number_input.hook";


export default function CreateLotComponent(props) {

    const [dates, setDates] = useState({
        startDate: new moment(),
        endDate: new moment()
    });

    const importoBudgets = NumberInputHook('0');
    const [sectorsSelected, setSectorsSelected] = useState();
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState({});
    const [tipologia, setTipologia] = useState({});

    const contracts = useSelector(state => state.contract.data);
    const sectors = useSelector(state => state.raiSettori.data);

    const salvaLotto = async () => {
        setLoading(true);
        // console.log("sectorsSelected", sectorsSelected)
        // console.log("sectorsSelected", JSON.stringify(sectorsSelected))


        const res = await Commands.SalvaBudgets(contract.id, contract.description, sectorsSelected, dates.startDate.unix(), dates.endDate.unix(), importoBudgets.getValue(), "", tipologia.value);
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {

        dates.startDate = new moment()
        dates.endDate = new moment()

        importoBudgets.setValue(0)
        setContract(undefined);
        setTipologia(undefined);
        setSectorsSelected([]);
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo lotto</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaLotto();
                }}>
                    <FormGroup>
                        <Label for="period">Periodo validità</Label>
                        <DateRangePickerWrapper
                            disabled={false}
                            initialStartDate={dates.startDate} // momentPropTypes.momentObj or null,
                            startDateId="startDate" // PropTypes.string.isRequired,
                            initialEndDate={dates.endDate} // momentPropTypes.momentObj or null,
                            endDateId="endDate" // PropTypes.string.isRequired,
                            small
                            enableOutsideDays={true}
                            startDatePlaceholderText={"Data inizio"}
                            endDatePlaceholderText={"Data fine"}
                            showDefaultInputIcon
                            isOutsideRange={() => false}
                            onChange={(startDate, endDate) => setDates({startDate, endDate})}
                        />

                    </FormGroup>

                    <FormGroup>
                        <Label for="settori">Settore</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={sectorsSelected}
                            getOptionLabel={(item) => item.description}
                            getOptionValue={(item) => item.id}
                            onChange={value => {
                                setSectorsSelected(value);
                            }
                            }
                            closeMenuOnSelect={false}
                            isMulti
                            options={[{
                                value: "",
                                description: "Settore",
                                isDisabled: true,
                                key: 'id'
                            }, ...sectors]}
                            placeholder="Seleziona un settore"
                        />
                        <FormText color="muted">
                            Settori a cui si riferisce il budgets
                        </FormText>

                    </FormGroup>
                    <FormGroup>
                        <Label for="tipologia">Tipologia</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={tipologia}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setTipologia(value)
                            }
                            options={[{
                                value: "Modulo",
                                description: "Modulo",
                            }, {
                                value: "Canone",
                                description: "Canone",
                            }]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce il lotto
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setContract(value)
                            }
                            options={[{
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce il lotto
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="importo">Importo budgets</Label>
                        <Input
                            {...importoBudgets.input}
                            name="importoBudgets"
                            id="importoBudgets"
                            placeholder="Inserisci importo budgets in euro"
                        />
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il budget.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaLotto}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
