import React, {Component} from 'react';
import OrarioMezziContainer from "./Orari/container";

class GeoMezziComponent extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        return <OrarioMezziContainer/>;
    }
}


export default GeoMezziComponent;

