import React, {Component} from 'react';
import {Button, FormGroup, Input} from "reactstrap";
import Select from "react-select";
import * as _ from 'lodash';

const options = [
    {
        value: "",
        description: "Tipologia",
        isDisabled: true
    },
    {
        value: "currency",
        description: "Valuta",
    },
    {
        value: "unix",
        description: "Data",
    },
    {
        value: "month",
        description: "Mese",
    },
    {
        value: "string",
        description: "Stringa",
    },
    {
        value: "integer",
        description: "Numero intero",
    },
    {
        value: "float",
        description: "Numero con decimali",
    }

];

class DocumentTypeMetasComponent extends Component {

    constructor(props) {

        super(props);

        let count = 0;
        let max = _.maxBy(_.get(this.props, "metas", []), 'index');

        if (max) count = max.index;

        this.state = {
            list: [..._.get(this.props, "metas", [])],
            count
        };
    }




    getMetas = () => this.state.list;

    removeItemFromList = (index) => {
        this.setState({list: this.state.list.filter((el) => el.index !== index)});
    };

    addNewMeta = (e) => {
        e.preventDefault();
        this.setState({
            list: _.orderBy([...this.state.list, {index: this.state.count + 1, fieldName: null, fieldType: null}]),
            count: this.state.count + 1
        });
        // console.log("New state", this.state)
    };

    updateFieldType = (index, value) => {
        const preUpdate = this.state.list.filter((el) => el.index === index)[0];
        this.setState({
            list: _.orderBy([...this.state.list.filter((el) => el.index !== index), {
                ...preUpdate,
                fieldType: value
            }], 'index')
        });
    };

    updateFieldName = (index, value) => {
        const preUpdate = this.state.list.filter((el) => el.index === index)[0];
        this.setState({
            list: _.orderBy([...this.state.list.filter((el) => el.index !== index), {
                ...preUpdate,
                fieldName: value
            }], 'index')
        });
    };

    render() {
        return (
            <div>
                {this.state.list.map(el => <div
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <FormGroup className="extendFlex" style={{flex: 1}} q label="Chiave">
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={options.find(e => e.value === el.fieldType)}
                            getOptionLabel={(item) => item.description}
                            getOptionValue={(item) => item.id}
                            closeMenuOnSelect={false}
                            onChange={value => {
                                this.updateFieldType(el.index, value.value);
                            }}
                            options={options}
                            placeholder="Seleziona tipologia"
                        />
                    </FormGroup>
                    <div style={{width: '5px'}}/>
                    <FormGroup className="extendFlex" style={{flex: 1}} label="Valore">
                        <Input
                            name="fieldName"
                            type="text"
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={el.fieldName}
                            onChange={(e) => this.updateFieldName(el.index, `${e.target.value}`)}
                            placeholder="Inserire descrizione"
                        />
                    </FormGroup>
                    <Button
                        className={"h-100 ml-3"}
                        color="danger"
                        size="sm"
                        onClick={() => this.removeItemFromList(el.index)}
                    >
                        <i className="tim-icons icon-simple-remove"/>
                    </Button>
                </div>)}
                <button className="btn btn-primary w-100"
                        onClick={this.addNewMeta}><i
                    className="tim-icons icon-simple-add"/>Aggiungi metadato
                </button>
            </div>
        );
    }
}

export default DocumentTypeMetasComponent;
