import React from "react";

// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";

import moment from 'moment';

class Timeline extends React.Component {

    render() {
        console.log("Props timeline", this.props);

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12" className="history">
                            <Card className="card-timeline card-plain">
                                <CardBody>
                                    <ul className="timeline">
                                        {this.props.data.map((history) => {

                                            // icon-user-run
                                            //  icon-notes
                                            // icon-simple-delete
                                            // icon-badge
                                            // icon-check-2
                                            // icon-simple-remove

                                            let badge = 'badge-danger';
                                            if (history.userName.toLowerCase() !== 'sistema') badge = 'badge-info'
                                            let icon = 'icon-simple-remove';

                                            let username = history.userName;
                                            if (username.length <= 0) username = "SISTEMA";

                                            switch (history.event) {
                                                case "ASSIGNMENT_SET_WORKER":
                                                    icon = 'icon-badge';
                                                    break
                                                case "RAI_ORDER_CREATED":
                                                    icon = 'icon-notes';
                                                    break
                                                case "RAI_ORDER_APPROVED":
                                                    icon = 'icon-check-2';
                                                    break
                                                case "ASSIGNMENT_REMOVED_WORKER":
                                                    icon = 'icon-simple-delete';
                                                    break
                                                default:
                                                    icon = 'icon-simple-delete';
                                            }

                                            return (<li className="timeline-inverted">
                                                <div className="timeline-badge info">
                                                    <i className={"tim-icons " + icon}/>
                                                </div>

                                                <div className="timeline-panel">
                                                    <div className="timeline-heading"><span
                                                        className={"badge " + badge + " badge-pill"}>{username}</span>
                                                        <div
                                                            className="timeline-heading-left capitalize">{moment.unix(history.createdOn).format('LLLL')}</div>
                                                    </div>

                                                    <div className="timeline-body">
                                                        <p>
                                                            {history.eventDescription}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>);
                                        })
                                        }
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Timeline;
