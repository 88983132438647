import { hot } from 'react-hot-loader';

import React from 'react';
import './App.css';
import store, {persistor} from 'store/store.js'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import { createBrowserHistory } from "history";
import * as loginCommands from 'views/pages/Login/redux/login.commands'
import 'sweetalert/dist/sweetalert.css';
import {SetHistory} from './Apolloclient'
import FirmaSmsComponent from "views/rai/firma_sms/firma_sms.component"
import AccettazioneSmsComponent from "views/rai/accettazione_sms/accettazione_sms.component"
import ApprovazioneDaMailComponent from "views/rai/approvazione_da_mail/approvazione_da_mail.component"
import TimbrataSmsComponent from "views/rai/timbrata_sms/timbrata_sms"
import OnlySideBar from "layouts/OnlySideBar/OnlySideBar";
import {
    RUOLO_FUNZIONARIO_RAI, RUOLO_OPERATORE_SAP_RAI, RUOLO_REFERENTE_SAP_RAI,
    RUOLO_RICHIEDENTE_RAI,
    RUOLO_SUPERVISORE_RAI
} from "./views/rai/utenti/components/costants";
import ConvalidaDaMailComponent from "views/rai/convalida_da_mail/convalida_da_mail.component";
import FirmaOrdiniMail from "views/rai/firma_ordini_mail/firma_ordini_mail";

const hist = createBrowserHistory();
SetHistory(hist);

class App extends React.Component {

    state = {
        loading: false
    }

    onBeforeLift = async () => {

        // take some action before the gate lifts
        if (window.location.href.includes("firma?"))return;
        if (window.location.href.includes("accettazione?"))return;
        if (window.location.href.includes("timbrata?"))return;

        // Check if logged
        let user =  store.getState().login;

        if (!user.logged){
            hist.push("/auth/login");
            return;
        } else {
            // console.log('User before refresh', user);
            if (!user.refreshedToken) {
                this.setState({loading: true});
                const res = await loginCommands.refreshToken(user.token);
                this.setState({loading: false});

                if (res.hasError) {
                    loginCommands.logout(hist);
                    return;
                }

                user =  store.getState().login;
            }

            switch (user.user.role){
                case RUOLO_RICHIEDENTE_RAI:
                        hist.push("/admin/ordini");
                        break;
                    case RUOLO_SUPERVISORE_RAI:
                        hist.push("/admin/analitycs");
                        break;
                case RUOLO_FUNZIONARIO_RAI:
                    hist.push("/admin/analitycs");
                    break;
                    case RUOLO_OPERATORE_SAP_RAI:
                    hist.push("/admin/analitycs");
                    break;
                    case RUOLO_REFERENTE_SAP_RAI:
                    hist.push("/admin/analitycs");
                    break;
                    default:
                        break
            };

            return;
        }
    }

    componentDidMount() {

        if (window.location.href.includes("firma?"))return;
        if (window.location.href.includes("accettazione?"))return;
        if (window.location.href.includes("timbrata?"))return;

        const intervalloControlloIntegritaDati = setInterval(this.controlloIntergritaDati, 5 * 1000);
        const intervalloAggiornamentoDati = setInterval(this.aggiornamentoDati, 120 * 1000);

        this.setState({
            intervalloAggiornamentoDati,
            intervalloControlloIntegritaDati
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalloAggiornamentoDati);
        clearInterval(this.state.intervalloControlloIntegritaDati);
    }

    controlloIntergritaDati = () =>  {

        const user =  store.getState().login;
        if (!user.logged || this.state.loading)return;

        store.dispatch({
           type: 'CHECK_DATA_INTEGRITY',
           payload: {}
        });
    };

    aggiornamentoDati = () => {
        const user =  store.getState().login;
        if (!user.logged || this.state.loading)return;

    };

    render () {

        if (this.state.loading) {
            return <div style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center"}}>Caricamento in corso..</div>
        }

        return (
            <Provider store={store}>
                <PersistGate onBeforeLift={this.onBeforeLift} loading={null} persistor={persistor}>
                    <BrowserRouter history={hist}>
                        <Switch>
                            <Route path="/auth" render={props => <AuthLayout {...props} />}/>
                            <Route path="/admin" render={props => <AdminLayout {...props} />}/>
                            <Route path="/s" render={props => <OnlySideBar {...props} />}/>
                            <Route path="/firma" render={props => <FirmaSmsComponent {...props} />}/>
                            <Route path="/firmacanoni" render={props => <FirmaOrdiniMail {...props} />}/>
                            <Route path="/accettazione" render={props => <AccettazioneSmsComponent {...props} />}/>
                            <Route path="/approvazione" render={props => <ApprovazioneDaMailComponent {...props} />}/>
                            <Route path="/convalida" render={props => <ConvalidaDaMailComponent {...props} />}/>
                            <Route path="/timbrata" render={props => <TimbrataSmsComponent {...props} />}/>
                            <Redirect from="/" to="/admin/dashboard"/>
                        </Switch>
                    </BrowserRouter>

                </PersistGate>
            </Provider>
        );
    }
}

export default hot(module)(App);
