import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/utenti.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'

export default function CambioPwdComponent(props) {

    const vecchiaPass = TextInputHook('');
    const nuovaPass = TextInputHook('');
    const confermaNuovaPass = TextInputHook('');


    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const userId =  useSelector(state => state.login.user.id);

    const salvaProduzione = async () => {
        setLoading(true);
         var res = await Commands.ChangePassword(userId, vecchiaPass.getValue(), nuovaPass.getValue(), confermaNuovaPass.getValue());
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            setErrorMessage(res.message);
            return;
        }


        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        vecchiaPass.clear();
        nuovaPass.clear();
        confermaNuovaPass.clear();
    };


    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Cambio password</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaProduzione();
                }}>
                    <FormGroup>
                        <Label for="vecchiaPass">Vecchia password</Label>
                        <Input
                            {...vecchiaPass.input}
                            name="vecchiaPass"
                            type={"password"}
                            id="vecchiaPass"
                            placeholder="Inserisci vecchia password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nuovaPass">Nuova password</Label>
                        <Input
                            {...nuovaPass.input}
                            name="nuovaPass"
                            type={"password"}
                            id="nuovaPass"
                            placeholder="Inserisci la nuova password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="confNuovaPass">Conferma nuova password</Label>
                        <Input
                            {...confermaNuovaPass.input}
                            name="confNuovaPass"
                            type={"password"}
                            id="confNuovaPass"
                            placeholder="Inserisci nuovamento la nuova password"
                        />
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">{errorMessage}</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaProduzione}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Cambia password'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
