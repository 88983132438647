import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/produzioni.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'

import Select from "react-select";

export default function UpdateProductionComponent(props) {


    const nome = TextInputHook(props.name);
    const descrizione = TextInputHook(props.description);
    const matricola = NumberInputHook(props.number);

    const [contract, setContract] = useState(props.contract);
    const contracts = useSelector(state => state.contract.data);
    const [tipologia, setTipologia] = useState("");
    const [labelMatricola, setLabelMatricola] = useState({label: "..."});


    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);


    const optionsTipologia = [
        {
            value: "",
            description: "Tipologia",
            isDisabled: true
        },
        {
            value: "P",
            description: "Matricola produzione",
            label:"Numero matricola",
            help: "",
        },
        {
            value: "F",
            description: "Ordine interno spese di funzionamento",
            label: "Numero ordine interno (UORG.MATRICOLA)",
            help: ""
        },
        {
            value: "K",
            description: "Centro di costo",
            label: "Indicare solo UORG",
            help: ""
        }
    ]



    const Submit = async () => {
        setLoading(true);

        var res = await Commands.AggiornaProduzione(props.id, nome.getValue(), matricola.getValue(), descrizione.getValue(), contract.id, tipologia.value);

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
        nome.clear();
        matricola.clear();
    };


    useEffect(() => {

        if (submitError) {

            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

        const tipologiaTrovata = optionsTipologia.filter((tip) => tip.value === props.tipologia);

        setTipologia(tipologiaTrovata[0]);
        setLabelMatricola(tipologiaTrovata[0]);

    }, []);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    Submit();
                }}>
                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome produzione"
                        />
                    </FormGroup>


                    <FormGroup>
                        <Label for="tipologia">Tipoplogia</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={tipologia}
                            getOptionLabel={(item) => item.description}
                            onChange={value => {
                                setLabelMatricola(value);
                                setTipologia(value);
                            }
                            }
                            options={optionsTipologia}
                            placeholder="Seleziona una tipologia matricola"
                        />
                    </FormGroup>


                    <FormGroup>
                        <Label for="matricola">{labelMatricola.label}</Label>
                        <Input
                            {...matricola.input}
                            name="matricola"
                            id="matricola"
                            placeholder="Inserire valore..."
                        />
                        <FormText color="muted">
                            {labelMatricola.help}
                        </FormText>
                    </FormGroup>



                    <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value => {
                                setContract(value);
                            }}
                            options={[{
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce il lotto
                        </FormText>
                    </FormGroup>


                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare la produzione.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={Submit}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
