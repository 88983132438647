import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/indirizzi.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import {Col, Row} from "reactstrap";
import Geosuggest from 'react-geosuggest';

export default function CreateAddressComponent(props) {

    // const [address, setAddress] = useState('');
    const descrizione = TextInputHook(props.newName || '');

    const [indirizzoDaSalvare, setIndirizzoDaSalvare] = useState({
        indirizzo: "",
        civico: "",
        citta: "",
        cap: "",
        provincia: "",
        paese: ""
    });


    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(props.contract || null);

    const contracts = useSelector(state => state.contract.data);
    if (!props.contract && contracts.length > 0 && !contract){
        setContract(contracts[0]);
    }

    const customerId = useSelector(state => state.customer.id);

    const salva = async () => {
        setLoading(true);
        const {indirizzo, cap, citta, provincia, civico, paese} = indirizzoDaSalvare;

        var res = await Commands.SalvaIndirizzo(
            descrizione.getValue(),
            indirizzo,
            civico,
            cap,
            citta,
            provincia,
            contract.id,
            customerId,
            paese
        );

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            setErrorMessage(res.message);
            return;
        }


        if (props.onSuccess){
            props.onSuccess(res.data);
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        setIndirizzoDaSalvare({});
        setContract(undefined);
    }

    const suggestToAddress = (suggest) => {

        let indirizzo = "";
        let civico = "";
        let cap = "";
        let citta = "";
        let provincia = "";
        let paese = "";

        if ( suggest && suggest.gmaps ) {
            suggest.gmaps.address_components.forEach(cmp => {

                if (cmp.types.includes("street_number")) civico = cmp.short_name;
                if (cmp.types.includes("route")) indirizzo = cmp.short_name;
                if (cmp.types.includes("administrative_area_level_3")) citta = cmp.short_name;
                if (cmp.types.includes("postal_code")) cap = cmp.short_name;
                if (cmp.types.includes("administrative_area_level_2")) provincia = cmp.short_name;
                if (cmp.types.includes("country")) paese = cmp.short_name;
            });

            setIndirizzoDaSalvare({indirizzo, civico, cap, citta, provincia, paese});
        }
    };

    const onSuggestSelect = suggest => {
        suggestToAddress(suggest);
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo indirizzo</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salva();
                }}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label className={'error'} for="descrizione">Descrizione</Label>
                                <Input
                                    {...descrizione.input}
                                    name="descrizione"
                                    id="descrizione"
                                    placeholder="Inserisci descrizione indirizzo"
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                        <FormGroup>
                            <Label for="contratto">Indirizzo</Label>
                        <Geosuggest inputClassName={"form-control"} initialValue={''}  placeholder={"Inserisci indirizzo.."} onSuggestSelect={onSuggestSelect} />
                        </FormGroup>
                        </Col>
                    </Row>
                    {!props.contract && contracts.length > 1 && <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setContract(value)
                            }
                            options={[{
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce l'indirizzo
                        </FormText>
                    </FormGroup>}
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare l'indirizzo: {errorMessage}</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
