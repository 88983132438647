import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './produzioni.types';
import * as actions from './produzioni.actions';
//import {stringify} from "query-string";

export function SalvaProduzione(name, number, description, contractId, tipologia) {



    var variables = {name, number, description, contractId, tipologia};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $name: String!
            $description: String
            $number: String!
            $contractId: String
            $tipologia: String
        ) {
            createdObject: createProduction (
                name: $name
                description: $description
                number: $number
                contractId: $contractId
                tipologia: $tipologia
            ) {
                id
                description
                name
                number
                tipologia
                contract {
                    description
                    id
                }
            }
            
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare la produzione';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniProduzioni() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList: productions {
                   
                id
                description
                name
                number
                tipologia
                contract {
                    description
                    id
                }
               
            }
        }
        `,
    };

    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere le produzioni";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AggiornaProduzione(id, name, number, description, contractId, tipologia) {


    var variables = {id, name, number, description,  contractId, tipologia};



    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!
            $name: String
            $number: String
            $tipologia: String
            $description: String
            $contractId: String
        ) {
            updatedObject: updateProduction (
                id: $id
                name: $name
                number: $number
                description: $description
                tipologia: $tipologia
                contractId: $contractId
            ) {
                id
                name
                number
                description
                tipologia
                contract {
                    description
                    id
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare la produzione';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaProduzione(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteProduction (id: $id) {
                id
                name
                description
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare la produzione';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
