import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Col,
  CardHeader,
  CardBody,
  CardTitle,
  Card,
  Row,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { TextInputHook } from "hooks/text_input.hook";
import * as Commands from "../redux/lavoratori.commands";
import { CSSTransition } from "react-transition-group";
import { useSelector, shallowEqual } from "react-redux";
import Select from "react-select";
import ReactTable from "react-table";

import "react-tagsinput/react-tagsinput.css";
import Datetime from "react-datetime";
import moment from "moment";
import ModalHeader from "reactstrap/es/ModalHeader";
import DateRangePickerWrapper from "../../../../components/DateRangePicker/DateRangePickerWrapper";
import _ from "lodash";
import ReactTableFilterSelectComponent from "components/ReactTableFilter/reactTable.filter.select.component";

// If using WebPack and style-loader.

export default function ManageAbsences() {
  let state = {
    loading: false,
    nestedModal: false,
    tags: [],
    form: {
      worker: {},
      startDate: "",
      endDate: "",
      reason: "",
    },
    absences: {},
    filters: {},
  };

  const absences = useSelector(
    (state) => state.lavoratori.absences,
    shallowEqual
  );
  const motiviAssenza = useSelector(
    (state) => state.motiviAssenza.data,
    shallowEqual
  );
  const workers = useSelector((state) => state.lavoratori.data, shallowEqual);

  const [startDate, setStartDate] = useState(
    state.form.startDate,
    new moment().toDate()
  );
  const [endDate, setEndDate] = useState(
    state.form.endDate,
    new moment().toDate()
  );
  const [startDateFilter, setStartDateFilter] = useState(
    state.form.startDate,
    new moment().toDate()
  );
  const [endDateFilter, setEndDateFilter] = useState(
    state.form.endDate,
    new moment().toDate()
  );

  const [selectedStartDate, setSelectdStartDate] = useState([]);
  const [selectedEndDate, setSelectdEndDate] = useState([]);
  const [motivoAssenza, setMotivoAssenza] = useState();

  const [selectedWorker, setSelectdWorker] = useState([]);

  //const worker = TextInputHook(state.form.worker);

  const [worker, setWorker] = useState({ nome: "", cognome: "" });

  const reason = TextInputHook(state.form.reason);

  /*    const [worker, setWorker] = useState(state.form.worker);
        const [reason, setReason] = useState(state.form.reason);*/

  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [nested, setNested] = useState(state.nestedModal);

  const caricaDati = async () => {
    await Commands.OttieniAssenze();
  };

  const caricaDatiFiltrati = async (startDate, endDate) => {
    await Commands.ReadAbsencesByPeriod(startDate, endDate);
  };

  useEffect(() => {
    caricaDati();
  }, []);

  const toggleNested = () => {
    state.nestedModal = !state.nestedModal;
    setNested(!nested);
  };

  const Submit = async () => {
    //setLoading(true);

    let myWorkerId = worker.id;
    let myStartDate = startDate;
    let myEndDate = endDate;
    let myName = worker.nome + " " + worker.cognome;

    var res = await Commands.AggiungiAssenza(
      myWorkerId,
      myStartDate,
      myEndDate,
      motivoAssenza.descrizione,
      motivoAssenza.flag,
      myName
    );
    setLoading(false);
    if (res.hasError) {
      setSubmitError(true);
      return;
    } else {
      clearForm();
      setNested(false);
    }
  };

  const deleteRow = async (row) => {
    var res = await Commands.EliminaAssenza(row.original.id);
    if (res.hasError) {
      setSubmitError(true);
      return;
    } else {
      clearForm();
      setNested(false);
    }
  };

  const clearForm = () => {
    // tags.clear();
    setWorker(undefined);
  };

  // useEffect(() => {

  //     if (submitError) {
  //         const timer = setTimeout(() => {
  //             setSubmitError(false);
  //         }, 3000);
  //         return () => clearTimeout(timer);
  //     }
  // }, [submitError]);

  console.log("@@@@@@@@@ RENDER @@@@@@@@@@@@");
  return (
    <>
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Elenco assenze</CardTitle>
            <Col className="mr-auto">
              <DateRangePickerWrapper
                disabled={false}
                initialStartDate={startDateFilter} // momentPropTypes.momentObj or null,
                startDateId="startDate" // PropTypes.string.isRequired,
                initialEndDate={endDateFilter} // momentPropTypes.momentObj or null,
                endDateId="endDate" // PropTypes.string.isRequired,
                small
                enableOutsideDays={true}
                startDatePlaceholderText={"Data inizio"}
                endDatePlaceholderText={"Data fine"}
                showDefaultInputIcon
                isOutsideRange={() => false}
                onChange={(startDate, endDate) => {
                  setStartDateFilter(startDate);
                  setEndDateFilter(endDate);
                  caricaDatiFiltrati(
                    startDate.set({ hour: 0, minute: 0, seconds: 0 }).unix(),
                    endDate.set({}).unix()
                  );
                }}
              />
            </Col>

            <Col className="d-flex justify-content-end">
              <Button color="primary" type="button" onClick={toggleNested}>
                {loading ? (
                  <i className="fa fa-spin fa-spinner" />
                ) : (
                  "Aggiungi assenza"
                )}
              </Button>
            </Col>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={absences}
              filterable
              columns={[
                {
                  Header: "Lavoratore",
                  accessor: "name",
                  filterMethod: (filter, row) => {
                      if (filter && filter.value && filter.value.length === 0)
                      return true;
                    const result = filter.value.some(
                      (f) => f.value === row.name
                    );
                    return result;
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "name";

                    return (
                      <ReactTableFilterSelectComponent
                        options={_.sortBy(absences, ["name"])}
                        optionValue={"name"}
                        optionLabel={"name"}
                        filterName={filterName}
                        onChange={(v) => {
                            onChange(v);
                        }}
                        value={filter && filter.value}
                      />
                    );
                  },
                },
                {
                  Header: "Data inizio",
                  accessor: "startDate",
                  
                  filterMethod: (filter, row) => {
                    console.log('@@@@@@@@@@@VALUE', filter);
                    console.log('@@@@@@@@@ROW', row);

                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some((f) => {
                      return f.value === row._original.startDate;
                    });
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "dataInizio";

                    const abs = _.sortBy(absences, ["startDate"]).map((e) => {
                      return {
                        label: moment.unix(e.startDate).format("DD/MM/YYYY"),
                        value: e.startDate,
                      };
                    });

                    return (
                      <ReactTableFilterSelectComponent
                        options={abs}
                        filterName={filterName}
                        optionValue={"value"}
                        optionLabel={"label"}
                        onChange={(v) => {
                            onChange(v);
                        }}
                        value={filter && filter.value}
                      />
                    );
                  },
                  Cell: (row) => moment.unix(row.value).format("DD/MM/YYYY HH:mm"),
                },
                {
                  Header: "Data fine",
                  accessor: "endDate",
                  filterMethod: (filter, row) => {
                    if (filter && filter.value && filter.value.length === 0)
                      return true;
                    return filter.value.some((f) => {
                      return f.value === row._original.endDate;
                    });
                  },
                  Filter: ({ filter, onChange }) => {
                    const filterName = "dataFine";

                    const abs = _.sortBy(absences, ["endDate"]).map((e) => {
                      return {
                        label: moment.unix(e.endDate).format("DD/MM/YYYY"),
                        value: e.endDate,
                      };
                    });

                    return (
                      <ReactTableFilterSelectComponent
                        options={abs}
                        optionValue={"value"}
                        optionLabel={"label"}
                        filterName={filterName}
                        onChange={(v) => {
                            onChange(v);
                        }}
                        value={filter && filter.value}

                      />
                    );
                  },
                  Cell: (row) =>
                    moment.unix(row.value).format("DD/MM/YYYY HH:mm"),
                },
                {
                  Header: "Motivo",
                  accessor: "reason",
                  filterable: false,
                },
                {
                  id: "flag",
                  Header: "Conta come assenza",
                  accessor: (row) => {
                    return row.flag ? "si" : "no";
                  },
                },
                {
                  Header: "",
                  id: "delete",
                  filterable: false,
                  accessor: () => "delete",
                  Cell: (row) => (
                    <div>
                      <Button
                        className="btn-sm"
                        color="danger"
                        type="button"
                        onClick={() => deleteRow(row)}
                      >
                        x
                      </Button>
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              showPaginationBottom={true}
              className="-striped -highlight"
              nextText={"Avanti"}
              previousText={"Indietro"}
              pageText={"Pagina"}
              ofText={"di"}
              rowsText={"elementi"}
              noDataText={"Nessun dato"}
              loadingText={"Caricamento in corso"}
              loading={false}
            />

            <CSSTransition
              in={submitError}
              appear={true}
              unmountOnExit
              classNames="error"
              timeout={300}
            >
              <blockquote className="blockquote blockquote-danger">
                <p className="mb-0 text-danger">
                  Non è stato possibile aggiungere l'assenza
                </p>
              </blockquote>
            </CSSTransition>

            <Modal
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop={true}
              modalClassName="modal-black"
              isOpen={nested}
              toggle={toggleNested}
            >
              <div className="modal-header justify-content-center">
                <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleNested}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Aggiungi assenza</h6>
              </div>

              <ModalHeader></ModalHeader>
              <ModalBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    Submit();
                  }}
                >
                  <FormGroup>
                    <Label for="contratto">Lavoratore</Label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={worker}
                      getOptionLabel={(item) => `${item.cognome} ${item.nome}`}
                      onChange={(value) => setWorker(value)}
                      options={[
                        {
                          value: "",
                          cognome: "seleziona un",
                          nome: "lavoratore",
                          description: "Lavoratore",
                          isDisabled: true,
                        },
                        ...workers,
                      ]}
                      placeholder="Seleziona un lavoratore"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="startDate">Data di inzio assenza</Label>
                    <Datetime
                      id="startDate"
                      /* value={startDate} */
                      closeOnSelect
                      closeOnTab
                      onChange={(dt) => {
                        setStartDate(
                          moment(dt)
                            .set({
                              hour: 8,
                              minute: 0,
                              seconds: 0,
                              milliseconds: 0,
                            })
                            .unix()
                        );
                      }}
                      timeFormat={false}
                      inputProps={{ placeholder: "Data di inizio assenza" }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="endDate">Data di fine assenza</Label>
                    <Datetime
                      id="endDate"
                      /* value={endDate} */
                      closeOnSelect
                      closeOnTab
                      onChange={(dt) => {
                        //setEndDate(dt.toDate());
                        setEndDate(
                          moment(dt)
                            .set({ hour: 23, minute: 59, seconds: 59 })
                            .subtract(1, "d")
                            .unix()
                        );
                      }}
                      timeFormat={false}
                      inputProps={{ placeholder: "Data di fine assenza" }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="contratto">Motivo assenza</Label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={motivoAssenza}
                      getOptionLabel={(item) => item.descrizione}
                      onChange={(value) => setMotivoAssenza(value)}
                      options={[
                        {
                          value: "",
                          description: "Motivo assenza",
                          isDisabled: true,
                        },
                        ...motiviAssenza,
                      ]}
                      placeholder="Seleziona un motivo"
                    />
                  </FormGroup>

                  {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                  <button style={{ display: "none" }} type="submit" />
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={Submit}>
                  Salva
                </Button>{" "}
              </ModalFooter>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
