import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText, Col} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/motivi.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import {Checkbox} from "@blueprintjs/core";

export default function CreateLotComponent(props) {

    const descrizione = TextInputHook('');
    const [flag, setFlag] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const salvaLotto= async () => {
        setLoading(true);
        var res = await Commands.CreaMotivoAssenza(descrizione.getValue(), flag);
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo motivo assenza</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaLotto();
                }}>
                    <FormGroup>
                        <Label for="nome">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserisci motivo assenza"
                        />
                    </FormGroup>
                    <FormGroup className={"row"} style={{alignItems: 'baseline', justifyContent: 'flex-start'}}>
                       <Label >Contano come ore assenza</Label>
                            <Checkbox style={{marginLeft: '5px', marginTop: '10px'}} checked={flag} onChange={() => {
                                setFlag(!flag);
                            }}></Checkbox>
                    </FormGroup>



                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il motivo assenza.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaLotto}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
