import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './documentType.types';
import * as actions from './documentType.actions';

export function Save(description, privateType, enableForRole, metas, extractionRule) {

    const roles = enableForRole.map((role)=>(role.value))


    const er = JSON.stringify(extractionRule)
    const variables = {description, private: privateType, enableForRole:roles, metas, extractionRule: er};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create (
            $description: String!
            $private: Boolean!
            $enableForRole: [String!]!
            $metas: String!
            $extractionRule: String!
        ) {
            createdObject: createDocumentType (
                description: $description,
                private: $private,
                enableForRole: $enableForRole
                metas: $metas
                extractionRule: $extractionRule
            ) {
                id
                description
                private
                enableForRole
                metas
                extractionRule
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare la tipologia del documento';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function ReadAll() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList : ReadAllDocumentTypes{
                id
                description
                private
                enableForRole
                metas
                extractionRule

            }
        }        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;


                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere  tipologie documenti";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function UpdateDocumentType(id, description, privateType, enableForRole, metas, extractionRule) {

    const roles = enableForRole.map((role)=>(role.value))

    var variables = {id, description, privateType, enableForRole:roles, extractionRule, metas};


    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
            $description: String!,
            $privateType: Boolean!,
            $enableForRole: [String!]!,
             $metas: String!,
            $extractionRule: String!
        ){
            updatedObject: updateDocumentType(
                id: $id,
                description: $description,
                private: $privateType,
                enableForRole: $enableForRole,
                metas: $metas,
                extractionRule: $extractionRule
            ) {
                id
                description
                private
                enableForRole
                metas
                extractionRule
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare tipologia documento';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function DeleteDocumentType(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteDocumentType (id: $id) {
                id
                description
                private
                enableForRole
                metas
                extractionRule

            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare tipologia documento';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
