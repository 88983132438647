import { useState } from "react";

export const TextInputHook = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const clear = () => setValue('');
    const getValue = () => value || '';

    return {
        clear,
        getValue,
        input: {
            value,
            onChange: (e) => setValue(e.target.value)
        }
    };
};
