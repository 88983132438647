import React, {Component} from 'react';
import {Card, CardBody, CardHeader, CardSubtitle, Col} from "reactstrap";
import Fade from "react-reveal/Fade";
import ReactTable from "react-table";
import _, {get} from "lodash";
import {connect} from "react-redux";
import * as Commands from "../../redux/analyticsSoci.commands";
import moment from "moment";
import ReactTableFilterSelectComponent
    from "../../../../../components/ReactTableFilter/reactTable.filter.select.component";

class SociLiberiComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loaded: false,
            filters: {}
        }
    }

    caricaDati = async (startDate, endDate) => {
        const startDateClone = startDate.clone();
        const endDateClone = endDate.clone();
        this.setState({loading: true});
        const res = await Commands.OttieniSociLiberi(startDateClone.format("YYYY-MM-DD"), endDateClone.add(1, 'days').format("YYYY-MM-DD"), "");
        this.setState({loading: false, loaded: true, data: res.data});
    };

    componentWillMount() {
        this.caricaDati(this.props.startDate, this.props.endDate);
    };

    componentWillReceiveProps(newProps) {
        if (this.props.startDate !== newProps.startDate || this.props.endDate !== newProps.endDate){
            this.caricaDati(newProps.startDate, newProps.endDate);
        }
    };

    render() {

        if (!this.state.loaded) return <div>Caricamento dati in corso</div>;
        // const defaultTimeStart = moment()
        //     .startOf("day")
        //     .toDate();
        //
        // const defaultTimeEnd = moment()
        //     .startOf("day")
        //     .add(1, "day")
        //     .toDate();


        return (
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <Fade when={this.state.loading}>
                            <CardSubtitle>Caricamento in corso <i
                                className="fa fa-spin fa-spinner"/></CardSubtitle>
                        </Fade>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={this.state.data}
                            filterable
                            resizable={true}
                            columns={[
                                {
                                    Header: "Socio",
                                    accessor: "fullName",
                                    minWidth: 200,
                                },
                                {
                                    Header: "Società",
                                    accessor: "societa",
                                    width: 100,
                                    Footer: (row) => {
                                        const count = _(row.data).groupBy('societa').size();
                                        return <div
                                            className='assegnazioneCompleta'>Cnt: {count}</div>
                                    },
                                    filterMethod: (filter, row) => {
                                        if (filter && filter.value && filter.value.length === 0) return true;
                                        return filter.value.some(f => f.value === row._original.societa);
                                    },
                                    Filter: ({filter, onChange}) => {

                                        const filterName = 'societa';
                                        return <ReactTableFilterSelectComponent
                                            options={_.sortBy(this.props.data, [{filterName}])}
                                            optionValue={filterName}
                                            optionLabel={filterName}
                                            filterName
                                            value={this.state.filters[filterName]}
                                            onChange={(value) => this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    [filterName]: value
                                                }
                                            }, () => onChange(value))}
                                        />
                                    },
                                },
                                {
                                    Header: "Inizio ultima ass. ",
                                    accessor: "prevStartTime",
                                    Cell: (row) => {
                                        if (!row.original.prevStartTime)return "-";
                                        return moment
                                            .unix(row.original.prevStartTime)
                                            .format("DD/MM/YY HH:mm")}
                                },
                                {
                                    Header: "Fine ultima ass. ",
                                    accessor: "prevEndTime",
                                    Cell: (row) =>{
                                        if (!row.original.prevEndTime)return "-";
                                        return  moment
                                            .unix(row.original.prevEndTime)
                                            .format("DD/MM/YY HH:mm")}
                                },
                                {
                                    Header: "Ore mese",
                                    accessor: "monthWorkedHours",
                                },
                                {
                                    Header: "Ore contratto",
                                    accessor: "contractHour",
                                }
                            ]}
                            defaultPageSize={25}
                            pageSizeOptions={[50, 100, 150, 200, 250]}
                             showPaginationBottom={true}
                            className="-striped -highlight"
                            nextText={'Avanti'}
                            previousText={'Indietro'}
                            pageText={'Pagina'}
                            ofText={'di'}
                            rowsText={'elementi'}
                            noDataText={'Nessun dato'}
                            loadingText={'Caricamento in corso'}
                            loading={false}
                        />
                    </CardBody>
                </Card>
            </Col>


        );
    }
}

const mapStateToProps = (state) => {

    const data = {
        data: get(state, "raiAnalyticsSoci.data", []),
        loading: get(state, "raiAnalyticsSoci.loading", false)
    };

    return data;
};

export default connect(mapStateToProps, () => ({}))(SociLiberiComponent)




