import React, {Component} from 'react';
import SignModelDiurnoNotturno from "views/rai/lotti/components/sign/mod-diurno-notturno";
import SignModelHRS from "views/rai/lotti/components/sign/mod-hrs";

class ModelSwitcher extends Component {
    render() {
        if (this.props.signModel && this.props.signModel) {
            switch (this.props.signModel.value) {
                case "mod-hrs":
                    return <SignModelHRS signConfig={this.props.signConfig} cb={this.props.callback} lotto={this.props.lotId}/>;
                case "mod-diurna-notturna":
                    return <SignModelDiurnoNotturno signConfig={this.props.signConfig} cb={this.props.callback} lotto={this.props.lotId}/>
                default:
                    return null;
            }
        }
        return null;
    }

}

export default ModelSwitcher;
