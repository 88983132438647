import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './analyticsSoci.types';


export function OttieniAssegnazioni(startDate, endDate, fullText) {

    store.dispatch({
        type: types.REQUEST_READ_ALL_ASSIGNEMENTS
    });

    var variables = {
        startDate,
        endDate,
        fullText,
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($startDate: UnixDate, $endDate: UnixDate, $fullText: String){
            objectList :
            assignments(startDate: $startDate, endDate: $endDate, fullText: $fullText) {
                auto
                id
                jsonExtra
                jsonWorker
                orderID
                periodID
                startTime
                endTime
                state
                workerID
                entrata {
                     orarioDichiarato
                     orarioEffettivo
                }
                uscita {
                    orarioDichiarato
                    orarioEffettivo
                }
                order{
                    number
                }
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL_ASSIGNEMENTS,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i assegnazioni"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ASSIGNEMENTS_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function OttieniSociLiberi(startDate, endDate) {

    var variables = {
        startDate,
        endDate
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($startDate: UnixDate, $endDate: UnixDate){
            objectList :
            getFreeWorkersForDateRange(startDate: $startDate, endDate: $endDate) {
                    workerId
                    fullName
                    contractHour
                    monthWorkedHours
                    prevStartTime
                    prevEndTime
                societa
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;
                return {
                    hasError: false,
                     data
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i soci liberi"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ASSIGNEMENTS_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function RptOreLavoratoriDettagliato(startDate, endDate) {
    var variables = {
        startDate,
        endDate,
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query send($startDate: Int, $endDate: Int){
            report:rptOreLavoratoriDettagliato(startDate: $startDate, endDate: $endDate)
        }
        `
    };

    return Client
        .query(query)
        .then(async (response) => {
            var data = response.data.report;

            return {
                error: false,
                data
            };
        })
        .catch((e) => {
            console.error(e);
            var message = 'Non è stato possibile scaricare il report.';

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                message,
                error: true
            };
        });
}

export function RptSoci(ids, reportName) {
    var variables = {
        ids,
        reportName
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query send($ids: [String], $reportName: String){
            report: rptSoci(ids: $ids, reportName: $reportName)
        }
        `
    };

    return Client
        .query(query)
        .then(async (response) => {
            var data = response.data.report;

            return {
                error: false,
                data
            };
        })
        .catch((e) => {
            console.error(e);
            var message = 'Non è stato possibile scaricare il report.';

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                message,
                error: true
            };
        });
}
