import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Button
} from "reactstrap";
import {connect} from 'react-redux'
import * as _ from 'lodash';
import Select from "react-select";
import * as Commands from "./redux/email.commands";
import DateRangePickerWrapper from 'components/DateRangePicker/DateRangePickerWrapper';

class EmailComponent extends Component {


    constructor(props) {
        super(props);

        this.state = {
            mail: {},
            sectors: [],
            recipientsEmails: [],
            startDate: null,
            endDate: null,
        }
    }

    sendMail = () => {

        this.state.recipientsEmails.forEach(item => {
            item.jsonextra = JSON.stringify(item.jsonextra);
            delete item.__typename;
        });

        this.state.sectors.forEach(item => {
            delete item.__typename;
            delete item.officials;
            delete item.contract;
        });
        // console.log("SECTORS", this.state.sectors)

        Commands.SendMail(this.state.startDate.unix(), this.state.endDate.unix(), this.state.mail.value, this.state.recipientsEmails, this.state.sectors)
    }

    clearForm() {
        this.setState(
            {
                mail: {},
                sectors: [],
                recipientsEmails: [],

            })
    }

    onDatesChanged(startDate, endDate) {

        this.clearForm()

        this.setState(
            {
                startDate,
                endDate,
            })
    }


    render() {
        return (
            <>
                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>

                                        <Col xs={12}>
                                            <DateRangePickerWrapper
                                                disabled={false}
                                                // initialStartDate={this.state.initialStartDate} // momentPropTypes.momentObj or null,
                                                startDateId="startDate" // PropTypes.string.isRequired,
                                                // initialEndDate={this.state.initialEndDate} // momentPropTypes.momentObj or null,
                                                endDateId="endDate" // PropTypes.string.isRequired,
                                                small
                                                enableOutsideDays={true}
                                                startDatePlaceholderText={"Data inizio"}
                                                endDatePlaceholderText={"Data fine"}
                                                showDefaultInputIcon
                                                isOutsideRange={() => false}
                                                onChange={(startDate, endDate) => this.onDatesChanged(startDate, endDate)}
                                            />

                                        </Col>
                                        <Col xs={3}>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                getOptionLabel={(item) => item.label}
                                                value={this.state.mail}
                                                onChange={value =>
                                                    this.setState({mail: value})
                                                }
                                                options={[
                                                    {
                                                        value: "mail1",
                                                        label: "Mail 1 - Notifica a funzionario"
                                                    },
                                                    {
                                                        value: "mail2",
                                                        label: "Mail 2 - Notifica pianificazione a referente SAP"
                                                    },
                                                    {
                                                        value: "mail3",
                                                        label: "Mail 3 - Attestazione corretta esecuzione servizio"
                                                    },
                                                    {
                                                        value: "mail4",
                                                        label: "Mail 4 - Variazioni settimanali"
                                                    },
                                                    {
                                                        value: "mail5",
                                                        label: "Mail 5 - Variazioni mensili"
                                                    },
                                                    {
                                                        value: "mail6",
                                                        label: "Mail 6 - Variazioni servizi a modulo - Ausiliario"
                                                    },
                                                    {
                                                        value: "mail7",
                                                        label: "Mail 7 - Variazioni servizi a modulo - Referente SAP"
                                                    },
                                                    {
                                                        value: "mail8",
                                                        label: "Mail 8 - Notifica pianificazione a referente SAP"
                                                    },
                                                    {
                                                        value: "mail9",
                                                        label: "Mail 9 - Notifica consuntivo ad Ausiliario DEC"
                                                    },
                                                    {
                                                        value: "mail10",
                                                        label: "Mail 10 - Notifica consuntivo a referente RAI"
                                                    },
                                                    {
                                                        value: "mail11",
                                                        label: "Mail 11 - Notifica pianificazione a referente SAP"
                                                    },
                                                    {
                                                        value: "mail12",
                                                        label: "Mail 12 - Attestazione corretta esecuzione"
                                                    },
                                                ]}
                                                placeholder="Seleziona mail da inviare"
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={this.state.sectors}
                                                getOptionLabel={(item) => (item.description)}
                                                getOptionValue={(item) => item.id}
                                                onChange={values =>
                                                    this.setState({sectors: values})
                                                }
                                                multiple={true}
                                                isMulti

                                                options={this.props.settori}
                                                placeholder="Seleziona settore per la generazione dei report"

                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={this.state.recipientsEmails}
                                                getOptionLabel={(item) => (item.cognome + " " + item.nome + " - " + item.email)}
                                                getOptionValue={(item) => item.email}
                                                onChange={values =>
                                                    this.setState({recipientsEmails: values})
                                                }
                                                multiple={true}
                                                isMulti
                                                options={this.props.utenti}
                                                placeholder="Seleziona utente a cui inviare la mail"

                                            />
                                        </Col>

                                        <Col xs={3}>
                                            <Button
                                                color="danger"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={this.sendMail}
                                            >
                                                Invia mail
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    let utenti = _.get(state, "raiUtenti.data", [])

    let utentiMail = utenti.map((u) => {
        return {
            id: u.id,
            email: u.email,
            cognome: u.jsonextra.cognome,
            nome: u.jsonextra.nome
        }
    })

        let settori = _.get(state, "raiSettori.data", [])

    utentiMail = _.sortBy(utentiMail, "cognome")
    settori = _.sortBy(settori, "description")

    const data = {
        utenti: utentiMail,
        settori,
        loading: _.get(state, "raiUtenti.loading", false),
    };
    return data;
};

export default connect(mapStateToProps, () => ({}))(EmailComponent)

