import React, {Component} from 'react';
import {Button, FormGroup} from "reactstrap";
import Select from "react-select";
import * as _ from 'lodash';

class SectorRoleComponent extends Component {

    constructor(props) {
        super(props);

        let count = 0;
        let max = _.maxBy(_.get(this.props, "initialValue.jsonextra.sectorsRoles", []), 'index');

        if (max)count = max.index;

        this.state = {
            list: [..._.get(this.props, "initialValue.jsonextra.sectorsRoles", [])],
            count
        };
    }


    getSectorsRoles = () => this.state.list;

    removeItemFromList = (index) => {
        this.setState({ list: this.state.list.filter((el) => el.index !== index) });
    };

    addNewSectorRole = (e) => {
        e.preventDefault();
        this.setState({
            list: _.orderBy([...this.state.list, {index:this.state.count +1, sector: null, role: null}]),
            count: this.state.count +1
        });
    };

    updateSelectedSector = (index, value) => {
        const preUpdate = this.state.list.filter((el) => el.index === index)[0];
        this.setState({
            list: _.orderBy([...this.state.list.filter((el) => el.index !== index), {...preUpdate, sector: value}], 'index')
        });
    };

    updateRole = (index, value) => {
        const preUpdate = this.state.list.filter((el) => el.index === index)[0];
        this.setState({
            list: _.orderBy([...this.state.list.filter((el) => el.index !== index), {...preUpdate, role: value}], 'index')
        });
    };

    render() {
        return (
            <div>
                {this.state.list.map( el=> <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                    <FormGroup className="extendFlex" style={{ flex: 1 }}q label="Chiave">
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={el.sector}
                            getOptionLabel={(item) => item.description}
                            getOptionValue={(item) => item.id}
                            closeMenuOnSelect={false}
                            onChange={value => {
                                this.updateSelectedSector(el.index, value);
                            }}
                            options={[{
                                value: "",
                                description: "Settore",
                                isDisabled: true
                            }, ...this.props.sectors]}
                            placeholder="Seleziona uno o più settori"
                        />
                    </FormGroup>
                    <div style={{ width: '5px' }} />
                    <FormGroup className="extendFlex" style={{ flex: 1 }} label="Valore">
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={el.role}
                            getOptionLabel={(item) => item.description}
                            onChange={value => {
                                this.updateRole(el.index, value);
                            }}
                            options={[{
                                value: "",
                                description: "Ruolo",
                                isDisabled: true
                            }, ...this.props.ruoli]}
                            placeholder="Seleziona un ruolo"
                        />
                    </FormGroup>
                    <Button
                        className={"h-100 ml-3"}
                        color="danger"
                        size="sm"
                        onClick={() => this.removeItemFromList(el.index)}
                    >
                        <i className="tim-icons icon-simple-remove"/>
                    </Button>
                </div>)}
                <button className="btn btn-primary w-100"
                        onClick={this.addNewSectorRole}><i
                    className="tim-icons icon-simple-add"/>Aggiungi settore/ruolo
                </button>
            </div>
        );
    }
}

export default SectorRoleComponent;
