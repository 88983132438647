import React from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';

export default class DayPickerSparkle extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDays: [],
        };
    }
    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
        if (this.props.onChange)this.props.onChange(selectedDays);
    }
    render() {
        return (

            <DayPicker
                locale="it"
                localeUtils={MomentLocaleUtils}
                className={'sparkleDayPicker'}
                selectedDays={this.state.selectedDays}
                onDayClick={this.handleDayClick}
                {...this.props}
            />
        );
    }

    getSelectedDays(){
        return this.state.selectedDays;
    }

}
