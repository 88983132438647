import * as types from "views/pages/Login/redux/login.types.js";

// ONLY FOR OFFLINE TEST
const initialState = {
    logged: false,
    refreshedToken: false,
    logging: false,
    token: "",
    user: {}
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case "persist/REHYDRATE":
            let loginRehydrated = {};
            if (action.payload && action.payload.login)loginRehydrated = action.payload.login;

                state = {
                ...loginRehydrated,
                refreshedToken: false,

            };
            break;
        case types.REFRESHED_TOKEN:
            state = {
                ...state,
                logged: true,
                refreshedToken: true,
                logging: false,
                token: action.payload.user.jwt,
                user: action.payload.user.user
            };
            break
        case types.LOGGED:
            state = {
                ...state,
                logged: true,
                logging: false,
                token: action.payload.jwt,
                user: action.payload.user
            };
            break;
        case types.NOT_LOGGED:
            state = initialState;
            break;
        case types.LOGOUT:
            state = initialState;
            break;
        default:
            return state;
    }

    return state;
};

export default login;
