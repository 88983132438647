import React, {Component} from 'react';
import queryString from 'query-string'
import * as Commands from 'views/rai/ordini/redux/ordini.commands'
import FirmaComponent from "views/rai/ordini/components/ordini.firma.modal";
import * as CommandsUtenti from 'views/rai/utenti/redux/utenti.commands'

class FirmaSmsComponent extends Component {

    state = {
        error: false,
        loading: true,
        firmato: false,
        errDescr: ''
    };

    componentDidMount() {
        this.caricaOrdine();
        this.caricaUtenti();
    };

    toggle = (firmato) => {
        this.setState({firmato});
    };

    caricaOrdine = async () => {
        const queryParams = queryString.parse(this.props.location.search);
        const res = await Commands.OttieniOrdineDaSms(queryParams.token);

        if (res.hasError) {
            this.setState({error: true, loading: false, errDescr: res.message})
            return;
        }

        this.setState({
            order: res.data,
            error: false,
            loading: false
        });
    };

    caricaUtenti = async () => {
        this.setState({loadingUsers: true});
        await CommandsUtenti.OttieniUtenti();
        this.setState({loadingUsers: false});
    };

    render() {

        if (this.state.loading) {
            return (
                <div className={"firmaSms"}>
                    Caricamento delle informazioni in corso...
                </div>);
        }

        if (this.state.loadingUsers) {
            return (
                <div className={"firmaSms"}>
                    Caricamento degli utenti in corso...
                </div>);
        }

        if (this.state.error) {
            return (
                <div className={"firmaSms"}>
                    {this.state.errDescr}
                </div>);
        }

        if (this.state.firmato) {
            return (
                <div className={"firmaSms"}>
                    Ordine firmato con successo
                </div>
            );
        }

        if (this.state.firmato) {
            return (

                <div className={"firmaSms"}>
                    Ordine firmato con successo
                </div>
            );
        }

        return (
            <div>
                <FirmaComponent toggle={this.toggle} ordine={this.state.order}
                                show={true}/>
                                <div>Versione 5.0.0</div>
            </div>
        );
    }
}

export default FirmaSmsComponent;
