import * as types from "./analyticsSoci.types";

const initialState = {
    data: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST_READ_ALL_ASSIGNEMENTS:
            state = {
                ...state,
                loading: true
            }
            break;
        case types.READ_ALL_ASSIGNEMENTS_ERROR: {
            state = {
                ...state,
                loading: false,
                data:  []
            }
            break
        }
        case types.READED_ALL_ASSIGNEMENTS:
            state = {
                ...state,
                data: action.payload,
                loading: false
            }
            break;
        default:
            break;
    }

    return state;
}

export default reducer;
