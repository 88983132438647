import gql from "graphql-tag";
import Client from "Apolloclient";


export function OttieniPosizioneMezzi() {
    // Data odierna
    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query{
             objectList:GeoMezzi {
                    id
                    latitude
                    longitude
                    profile
                    androidID
                    travelled
                    serial
                    date
                    timestamp
                    provider
                    direction
                    speed
                    altitude
                    start
                    battery
                    satellities
                    timeUTC
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.objectList;

                return {
                    data, hasError: false
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i mezzi";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}
