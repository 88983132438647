import {Button, Modal, ModalBody, ModalFooter, FormGroup} from "reactstrap";
import React, {useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import * as Commands from '../redux/lavoratori.commands';

export default function UpdateTags(props) {

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState(props.tags);

    let handleChange = (newTags) => {
       setTags(newTags);
    };

    const Submit = async () => {
        setLoading(true);
        setLoading(false);

        const res = await Commands.UpdateTags(props.id, tags);

        if (res.hasError){
            ShowConfirmationDialog({
                title: "Errore durante l'aggiornamento dei tag",
                text: res.message,
                confirmBtnText: 'Si',
                canEscapeKeyCancel: true
            });
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        // tags.clear();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica tags lavoratore</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    Submit();
                }}>
                    <FormGroup>
                        <TagsInput
                            tagProps={{className: 'react-tagsinput-tag info' }}
                            value={tags}
                            onChange={handleChange}
                            name="tags"
                            id="tags"
                            inputProps={{placeholder: 'Nuovo tag'}}
                        />
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile aggiungere il tags.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={Submit}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
