import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/lavoratori.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import ShowConfirmationDialog from "../../../../components/ShowConfirmationDialog";

export default function LavoratoriSmsComponent(props) {

    const sms = TextInputHook(props.sms);
    const [loading, setLoading] = useState(false);


    const Submit = async () => {

        const doAction = await ShowConfirmationDialog({
            title: "Invio messaggio a " + props.mobNumbers.length + " destinatari ?",
            confirmBtnText: 'Si',
            canEscapeKeyCancel: true,
        });

        if (!doAction.confirm) return;


        setLoading(true);


        var res = await Commands.InviaSMS(props.mobNumbers, sms.getValue());

        setLoading(false);

        if (res.response) {
            // console.log("Error on send SMS", res.message)
            return;
        }

        props.toggle();
    };


    const getSmsCount = () => {
        const smsLenght = sms.getValue().length
        let smsCount = parseInt(Math.floor((sms.getValue().length / 160) + 1).toFixed(0))
        const smsLimit = 160 * smsCount
        return smsLenght + "/" + smsLimit + "(SMS:" + smsCount + ")"
    }


    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Invio messaggio</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    Submit();
                }}>

                    <FormGroup>
                        <Input
                            {...sms.input}
                            type={"textarea"}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserisci testo messaggio"
                        />
                        <label>{getSmsCount()}</label>
                    </FormGroup>
                </form>
                <CSSTransition
                    in={false}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile inviare il messaggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={Submit}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Invia messaggio'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
