import React, {Component} from 'react';
import {get} from "lodash";
import {connect} from "react-redux";


class MagazzinoContainerComponent extends Component {

    render() {

        const urlLogin  = "https://www.overwms.it/#/?username=" +  this.props.user.username + "&password=t7v!IDtpoe6"
        return (
            <div className="content">
            <iframe
                title="magazzino"
                style={{width:'100%', height:'1350px'}}
                ref="iframe"
                src={urlLogin}
                scrolling="no"
                frameBorder="0"
            />
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    let user = get(state, "login.user", "-");

    const data = {
        user
    };

    return data;
};

export default connect(mapStateToProps, () => ({}))(MagazzinoContainerComponent)

