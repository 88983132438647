import React, { Component } from 'react';
import Presentation from './presentation';
import  * as Commands from './redux/geoLavoratori.commands';
import moment from 'moment';

class OrariLavoratoriContainer extends Component {


	constructor(props) {
		super(props);
		this.state ={data:[]};
	};


	caricaDati = async () => {
		this.setState({loading: true});
		const res = await Commands.OttieniTimbrate();
		this.setState({loading: false});
		if (res.hasError){
			return;
		}

		let newData = [];

		for (let ass of res.data){
			let lat = 0;
			let long = 0;
			let entrata = "";
			let uscita = "";
			let matricola = ass.jsonWorker && ass.jsonWorker.matricola;

			if (ass.entrata) {
				lat = ass.entrata.lat;
				long = ass.entrata.long;
				entrata = moment.unix(ass.entrata.orarioDichiarato).format("HH:mm")
			}

			if (ass.uscita) {
				uscita = moment.unix(ass.uscita.orarioDichiarato).format("HH:mm")
			}

			newData.push(
				{
					key: ass.id,
					entrata,
					uscita,
					lat: lat,
					lng: long,
					matricola
				}
			);
		}

		this.setState({data: newData})
	};


	componentDidMount() {
		this.caricaDati()
	}


	render() {
		return <Presentation {...this.props} markers={this.state.data}  />;
	}
}



export default OrariLavoratoriContainer;
