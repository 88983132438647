import Select from "react-select";
import React, {useState} from "react";
import * as _ from "lodash";

export default function ReactTableFilterSelectComponent(props) {


  const [value, setValue] = useState([]);

  const filterList = _.uniqBy(props.options, props.optionValue).map((item) => {
    return {
      value: _.get(item, props.optionValue, "err"),
      label: _.get(item, props.optionLabel, "err"),
    };
  });

  return (
    <>
      <div>
        <Select
          className="react-select primary"
          classNamePrefix="react-select"
          //FIXME: Porcata per settare zIndex
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          //FINE PORCATA

          value={props.value ? props.value : value}
          multiple={true}
          isMulti
          getOptionLabel={(item) => {
            if (props.getOptionLabel) return props.getOptionLabel(item);
            return item.label;
          }}
          getOptionValue={(item) => item.value}
          onChange={(value) => {
            props.onChange(value);
            if (props.handleValue) {
              setValue(value);
            }
          }}
          options={filterList}
          placeholder="---"
        />
      </div>
    </>
  );
}
