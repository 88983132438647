import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './analytics.types';

export function AggiornaAnalyticsPayments(paymentIds, applicationNumber, invoiceNumber, invoiceDueDate, paymentDate, paymentStatus) {
    let ids = [];

    paymentIds && paymentIds.forEach((id) => {
        ids.push(id.replace('select-', ''));
    });

    store.dispatch({
        type: types.REQUEST_UPDATE_PAYMENT
    });

    var variables = {
        ids,
        applicationNumber,
        invoiceNumber,
        invoiceDueDate,
        paymentDate,
        paymentStatus
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation read($ids: [String], $applicationNumber: String, $invoiceNumber: String, $invoiceDueDate: Int, $paymentDate: Int, $paymentStatus: String){
            objectList: updatePayment(ids: $ids, applicationNumber: $applicationNumber, invoiceNumber: $invoiceNumber, invoiceDueDate: $invoiceDueDate, paymentDate: $paymentDate, paymentStatus: $paymentStatus) {
                id
                qty
                orderNumber
                orderId
                unitSellPrice
                totalSellPrice
                article
                articleId
                lot
                lotId
                applicationNumber
                invoiceNumber
                production
                productionId
                state
                approvingUser
                applicantUser
                sector
                study
                validated_by
                validated_at
                productionNumber
                date
                startTime
                endTime
                contract
                contractId
                type
                uorg
                articleNumber
                paymentDate
                paymentStatus
                totalHours
                totalAssignedHours
                applicationNumber
                invoiceDueDate
                orderType
                luogoPartenza
                luogoArrivo
                kilometri
            }
        }
        `,
    };

    return Client
        .mutate(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return {
                    hasError: false
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere dati";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );


}

export function Ottieni(startDate, endDate, fullText, customer) {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {
        startDate,
        endDate,
        fullText,
        customer
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($startDate: Int, $endDate: Int, $fullText: String, $customer: [String!]!){
            objectList: analytics(startDate: $startDate, endDate: $endDate, fullText: $fullText, customer: $customer) {
                id
                qty
                orderNumber
                orderId
                unitSellPrice
                totalSellPrice
                article
                articleId
                lot
                lotId
                applicationNumber
                invoiceNumber
                production
                productionId
                state
                approvingUser
                applicantUser
                sector
                study
                validated_by
                validated_at
                productionNumber
                date
                startTime
                endTime
                contract
                contractId
                type
                uorg
                articleNumber
                paymentDate
                paymentStatus
                totalHours
                totalAssignedHours
                applicationNumber
                invoiceDueDate
                orderType
                luogoPartenza
                luogoArrivo
                kilometri
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return {
                    hasError: false
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i lotti";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function GetReportPdf(reportName, startDate, endDate, sectorIds, tipologia, data) {
    var variables = {
        reportName,
        startDate,
        endDate,
        sectorIds,
        tipologia,
        data
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query send($reportName: String, $startDate: Int, $endDate: Int, $sectorIds: [String], $tipologia: [String], $data: String){
            getReport(reportName:$reportName,
                filter:{
                    startDate:$startDate,
                    endDate:$endDate,
                    sectorIds:$sectorIds,
                    tipologia:$tipologia
                },
                data:$data
            ),
            
        }
        `
    };

    return Client
        .query(query)
        .then(async (response) => {
            var data = response.data.getReport;

            return {
                error: false,
                data
            };
        })
        .catch((e) => {
            console.error(e);
            var message = 'Non è stato possibile generare il report';

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                message,
                error: true
            };
        });
}



export function GetReportByAnalyticsIds(reportName, ids) {
    var variables = {
        reportName,
        ids
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query send($reportName: String, $ids: [String]){
            getReport(reportName:$reportName,
                ids: $ids, 
            ),

        }
        `
    };

    return Client
        .query(query)
        .then(async (response) => {
            var data = response.data.getReport;

            return {
                error: false,
                data
            };
        })
        .catch((e) => {
            console.error(e);
            var message = 'Non è stato possibile generare il report';

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                message,
                error: true
            };
        });
}

// export function ReportPdf(reportData, reportName, reportType) {
//     var variables = {
//         reportData,
//         reportName,
//         reportType
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($reportData: String, $reportName: String, $reportType: String){
//             getAnalyticReport(reportData:$reportData,
//                 reportName:$reportName,
//                 reportType:$reportType)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.getAnalyticReport;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile generare il report';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
//
// export function ReportExcel(data) {
//     var variables = {
//         data
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($data: String){
//             analyticsReportExcel(data:$data)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.analyticsReportExcel;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile generare il report';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
//
// export function ReportExcel01(data) {
//     var variables = {
//         data
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($data: String){
//             report:reportExcel01(data:$data)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile generare il report';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
//
// export function ReportExcel02(data) {
//     var variables = {
//         data
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($data: String){
//             report:reportExcel02(data:$data)
//         }`
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile generare il report';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//
//             return {
//                 message,
//                 error: true
//             };
//
//         });
// }
//
// export function ReportLavoratori1(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:reportLavoratori1(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function ReportServiziPianificati2(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:reportServiziPianificati2(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function ReportDettaglioServiziPianificati4(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:reportDettaglioServiziPianificati4(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function ReportRiepilogoServiziResi3(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:reportRiepilogoServiziResi3(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function ReportDettaglioServiziResi7(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:reportDettaglioServiziResi3(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function ReportVariazioniPianificazione5(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:reportVariazioniPianificazione5(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function TracciatoSAP(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:tracciatoSap(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function TracciatoSAPExcel(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:tracciatoSapExcel(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
// export function ExcelDettagliatoEliminati(startDate, endDate, sectorId) {
//     var variables = {
//         startDate,
//         endDate,
//         sectorId
//     };
//
//     let query = {
//         fetchPolicy: 'network-only',
//         variables: variables,
//         query: gql`,
//         query send($startDate: Int, $endDate: Int, $sectorId: String){
//             report:excelDettagliatoEliminati(startDate: $startDate, endDate: $endDate, sectorId: $sectorId)
//         }
//         `
//     };
//
//     return Client
//         .query(query)
//         .then(async (response) => {
//             var data = response.data.report;
//
//             return {
//                 error: false,
//                 data
//             };
//         })
//         .catch((e) => {
//             console.error(e);
//             var message = 'Non è stato possibile scaricare il report.';
//
//             if (e.graphQLErrors && e.graphQLErrors.length > 0) {
//                 message = e.graphQLErrors[0].message;
//             }
//             return {
//                 message,
//                 error: true
//             };
//         });
// }
//
//
