import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import React, {useState, useEffect} from "react";
import 'react-day-picker/lib/style.css';
import * as OrdiniCommands from "views/rai/ordini/redux/ordini.commands";
import Timeline from "../../../../components/Timeline/Timeline";

export default function OrdineHistory(props) {
    const [ordineCaricato, setOrdineCaricato] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [erroreFetchOrdine, setErroreFetchOrdine] = useState(null);
    const [history, setHistory] = useState([]);

    const ottieniHistory = async () => {
        setOrdineCaricato(false);
        const res = await OrdiniCommands.OttieniHistoryOrdine(props.ordine.id);


        if (res.hasError) {
            setOrdineCaricato(true);
            setErroreFetchOrdine(res.message);
            return;
        }

        //aggiornaAssegnazioni(res.data.assignments);
        setOrdineCaricato(true);
        setHistory(res.data.sort((a,b)=>a.created_on > b.created_on));
    };

    const ContenutoModal = () => {
        if (!ordineCaricato) {
            return (<div>Caricamento storia in corso... <i className="fa fa-spin fa-spinner"/></div>);
        }

        if (erroreFetchOrdine) {
            return (
                <>
                    <p className={"text-danger"}>Non è stato possibile caricare la storia dell'ordine</p>
                    <blockquote className={"blockquote blockquote-danger"}>
                        <p className="mb-0 text-danger">Dettaglio errore: {erroreFetchOrdine}</p>
                    </blockquote>
                    <Button onClick={() => ottieniHistory()}>Riprova</Button>
                </>
            );
        }

        return (
            <div>
                <Timeline data={history}/>
            </div>

        );
    };


    useEffect(() => {
        // Carico dati
        if (props.ordine && !ordineCaricato) {
            ottieniHistory();
        }
        ;

        return () => {
            // Chiamati nel componentWillUnmount
        };
    });

    return (

        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            size={'lg'}
            toggle={props.toggle}

        >
            <div className="modal-header justify-content-center"             style={{"background":"#394B59"}}
            >
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Storia ordine</h6>

            </div>
            <ModalBody style={{"background":"#394B59"}}
            >
                <ContenutoModal/>
            </ModalBody>
            <ModalFooter             style={{"background":"#394B59"}}
            >
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Esci
                </Button>
            </ModalFooter>
        </Modal>
    );

}
