import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './setupMailAccount.types';
import * as actions from "./setupMailAccount.actions";


export function Create(username, password, inServerType, inServer, inServerPort, outServer, outServerPort, coop, pec) {

    var variables = {
        username,
        password,
        inServerType,
        inServer,
        inServerPort,
        outServer,
        outServerPort,
        coop,
        pec
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createMailAccount (
            $username: String!
            $password: String!
            $inServerType: String!
            $inServer: String!
            $inServerPort: String!
            $outServer: String!
            $outServerPort: String!
            $coop: String!
            $pec: Boolean!
        ) {
            createdObject:  createMailAccount(
                username: $username
                password: $password
                inServerType: $inServerType
                inServer: $inServer
                inServerPort: $inServerPort
                outServer: $outServer
                outServerPort: $outServerPort
                coop: $coop
                pec: $pec
            ) {
                id
                username
                password
                inServerType
                inServer
                inServerPort
                outServer
                outServerPort
              coop
                pec
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare account';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function ReadAll() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList :  getAllMailAccount{
                id
                username
                password
                inServerType
                inServer
                inServerPort
                outServer
                outServerPort
                coop
                pec
            }
        }        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;


                // console.log('DATAA', data);
                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return {
                    data: data,
                    hasError: false
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere gli accounts";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function Update(id, username, password, inServerType, inServer, inServerPort, outServer, outServerPort, coop, pec) {
    var variables = {
        id,
        username,
        password,
        inServerType,
        inServer,
        inServerPort,
        outServer,
        outServerPort,
        coop,
        pec
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation createMailAccount (
            $id: String!
            $username: String!
            $password: String!
            $inServerType: String!
            $inServer: String!
            $inServerPort: String!
            $outServer: String!
            $outServerPort: String!
            $coop: String!
            $pec: Boolean!
        ) {
            createdObject:  updateMailAccount(
                id: $id
                username: $username
                password: $password
                inServerType: $inServerType
                inServer: $inServer
                inServerPort: $inServerPort
                outServer: $outServer
                outServerPort: $outServerPort
                coop: $coop
                pec: $pec
            ) {
                id
                username
                password
                inServerType
                inServer
                inServerPort
                outServer
                outServerPort
                coop
                pec
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare account';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}


export function Delete(id) {


    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteMailAccount (id: $id) {
                id
                username
                password
                inServerType
                inServer
                inServerPort
                outServer
                outServerPort
                coop
                pec            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare lo account';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });

}
