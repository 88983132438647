import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './budgets.types';
import * as actions from './budgets.actions';

export function SalvaBudgets(contractId, contractDescription, sectors, startDate, endDate, euro, applicationNumber, tipologia) {

    const variables = {contractId, contractDescription, sectors:JSON.stringify(sectors), startDate, endDate, euro, applicationNumber, tipologia};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create (
            $contractId: String!
            $contractDescription: String!
            $sectors: String!
            $startDate: Int!
            $endDate: Int!
            $euro: Float!
            $applicationNumber: String
            $tipologia: String!
        ) {
            createdObject: createBudgets (
                contractId: $contractId,
                contractDescription: $contractDescription,
                sectors: $sectors,
                startDate: $startDate,
                endDate: $endDate,
                euro: $euro,
                applicationNumber: $applicationNumber,
                tipologia: $tipologia,

            ) {
                id
                contractId
                contractDescription
                sectors{
                    id
                    description
                }
                tipologia
                startDate
                endDate
                euro
                applicationNumber
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare il lotto';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniBudgets() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList: budgets {
                id
                contractId
                contractDescription
                sectors{
                    id
                    description
                }
                tipologia
                startDate
                endDate
                euro
                applicationNumber
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i budgets"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AggiornaBudgets(contractId, contractDescription, sectors, startDate, endDate, euro, applicationNumber) {


    const description = sectors.map((s)=> s.description)


    const variables = {description, contractId, contractDescription, sectors, startDate, endDate, euro, applicationNumber};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $contractId: String!
            $contractDescription: String!
            $sectors: String!
            $startDate: Int!
            $endDate: Int!
            $euro: Float!
            $applicationNumber: String
            $tipologia: String!

        ){
            updatedObject: updateBudgets(
                contractId: $contractId,
                contractDescription: $contractDescription,
                sectors: $sectors,
                startDate: $startDate,
                endDate: $endDate,
                euro: $euro,
                applicationNumber: $applicationNumber,
                tipologia: $tipologia,
            ) {
                id
                contractId
                contractDescription
                sectors{
                    id
                    description
                }
                startDate
                endDate
                euro
                applicationNumber
                tipologia
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare il lotto';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaBudgets(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteBudgets (id: $id) {
                id
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il lotto';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
