import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './utenti.types';
import * as actions from './utenti.actions';

export function SalvaUtente(username, pass, email, nome, cognome, sectorsRoles, role, matricola, tags, allowedContracts, coopID, coopName, orderCreationNotification, orderSignNotification) {

    var jsonextra = JSON.stringify({
        allowedContracts,
        nome,
        cognome,
        sectorsRoles,
        matricola,
        orderCreationNotification,
        orderSignNotification
    });
    var variables = {username, pass, email, role: role.value, tags, jsonextra, coopID, coopName};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create (
            $username: String!
            $pass: String!
            $email: String!
            $role: String!
            $tags: [String!]!
            $jsonextra: String!
            $coopID: String!
            $coopName: String!
        ) {
            createdObject: createUser (
                username: $username,
                pass: $pass,
                email: $email,
                role: $role,
                tags: $tags,
                jsonextra: $jsonextra,
                coopID: $coopID,
                coopName: $coopName
            ) {
                id
                email
                jsonextra
                role
                tags
                username
                coopId
                coopName
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare l\'utente';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniUtenti() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {}

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query{
            users:getUsersTagFiltered {
                id
                email
                jsonextra
                role
                tags
                username
                coopId
                coopName
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.users;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere utenti"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function OttieniUtentiBySectors(sectors) {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {sectors}

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($sectors:[String!]){

            users:getUsersSectorFiltered(sectorId:$sectors) {
                id
                email
                jsonextra
                role
                tags
                username
                coopId
                coopName
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.users;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere utenti"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AggiornaUtente(id, tags, role, nome, cognome, sectorsRoles, matricola, superiori, allowedContracts, notifica, telefono, email, coopID, coopName, orderCreationNotification, orderSignNotification) {
    var jsonextra = JSON.stringify({
        allowedContracts,
        nome,
        cognome,
        sectorsRoles,
        matricola,
        superiori,
        notifica,
        telefono,
        orderCreationNotification, orderSignNotification
    });
    var variables = {id, tags, role: role.value, jsonextra, email, coopID, coopName};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
            $tags: [String!]!
            $role: String!
            $email: String
            $jsonextra: String!
            $coopID: String!
            $coopName: String!
        ){
            updatedObject: updateUser(
                id: $id,
                tags: $tags,
                role: $role,
                jsonextra: $jsonextra,
                email: $email,
                coopID: $coopID,
                coopName: $coopName
            ) {
                id
                email
                jsonextra
                role
                tags
                username
                coopId
                coopName
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare l\'utente';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}


export function AbilitaUtentePianificato(id, jsonExtra, abilitatoPianificazione) {

    var jsonextra = JSON.stringify({...jsonExtra, abilitatoPianificazione})

    var variables = {id, jsonextra};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
            $jsonextra: String!

        ){
            updatedObject: updateUserPlanned(
                id: $id,
                jsonextra: $jsonextra,
            ) {
                id
                email
                jsonextra
                role
                tags
                username
                coopId
                coopName
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare l\'utente';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaUtente(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteUser (id: $id) {
                id
            }
        }`
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare l\'utente';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}

export function ChangePassword(id, oldPass, pass, confPass) {

    let query = {
        fetchPolicy: 'no-cache',
        variables: {
            id,
            newpass: pass,
            confpass: confPass,
            oldpass: oldPass,
        },
        mutation: gql`,
        mutation change_pwd (
            $id: String!
            $oldpass: String
            $newpass: String!
            $confpass: String!
        ) {
            user:changePwd(id: $id, oldpass: $oldpass, newpass: $newpass, confpass: $confpass) {
                id
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.user;

            return {
                data,
                hasError: false
            }
        })
        .catch((e) => {
            var message = "Impossibile aggiornare la password";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}


export function ResetPwd(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            reset: resetPwd (id: $id)
        }`
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.reset;
            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile resettare la password';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
