import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import _ from "lodash";

export default function SignModelHRS(props) {

    const servizi = useSelector(state => state.raiServizi.data);

    let initArticle = {};
    if (props.signConfig && props.signConfig.article) {
        initArticle = servizi.find(el => el.id === props.signConfig.article);
    }

    const [article, setArticle] = useState(initArticle);

    useEffect(() => {
        if (props.cb && article && article.id) {
            props.cb({
                article: article.id
            });
            // console.log('CALLED CB')
        }
    }, [article]);

    return (
        <>
            <FormGroup>
                <Label for="contratto">Servizio HRS da usare per straordinario</Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={article}
                    getOptionLabel={(item) => item.description}
                    onChange={value => {
                        setArticle(value);
                    }}
                    options={[{
                        value: "",
                        description: "Servizio",
                        isDisabled: true
                    }, ..._.orderBy(servizi.filter(
                        servizio => {

                            const lottoServizio = _.get(servizio, 'jsonExtra.lot.id', undefined);
                            const lottoSelezionato = props.lotto;

                            const tipologiaServizio = _.get(servizio, 'jsonExtra.type', "");
                            const tipologiaSelezionata = "Modulo";

                            return servizio &&
                                lottoServizio === lottoSelezionato &&
                                tipologiaSelezionata &&
                                tipologiaSelezionata.toLowerCase() === tipologiaServizio.toLowerCase();
                        }
                    ))]}
                    placeholder="Seleziona un servizio"
                />
            </FormGroup>
        </>
    );

}
