import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/coops.commands'
import {CSSTransition} from "react-transition-group";
import TagsInput from "react-tagsinput";


export default function UpdateCoop(props) {

    const companyName = TextInputHook(props.companyName);
    const [emails, setEmails] = useState(props.emails);
    const [ibans, setIbans] = useState(props.iban);
    const partitaiva = TextInputHook(props.partitaiva);
    const validatedAddress = TextInputHook(props.validatedAddress);
    const codiceGescoop = TextInputHook(props.codiceGescoop);


    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const create = async () => {
        setLoading(true);

        var res = await Commands.Update(
            props.id,
            companyName.getValue(),
            emails,
            ibans,
            partitaiva.getValue(),
            validatedAddress.getValue(),
            codiceGescoop.getValue(),
        );

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
    }


    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuova coop</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    create();
                }}>
                    <FormGroup>
                        <Label for="nome">Ragione sociale</Label>
                        <Input
                            {...companyName.input}
                            name="companyName"
                            id="companyName"
                            placeholder="Inserisci ragione sociale"
                        />
                    </FormGroup>
                    <FormGroup>
                        <TagsInput
                            tagProps={{className: 'react-tagsinput-tag info'}}
                            value={emails}
                            onChange={(v) => setEmails(v)}
                            name="emails"
                            id="emails"
                            inputProps={{placeholder: 'Nuove emails'}}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TagsInput
                            tagProps={{className: 'react-tagsinput-tag info'}}
                            value={ibans}
                            onChange={(v) => setIbans(v)}
                            name="ibans"
                            id="ibans"
                            inputProps={{placeholder: 'Nuovo ibans'}}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Partita IVA</Label>
                        <Input
                            {...partitaiva.input}
                            name="partitaiva"
                            id="partitaiva"
                            placeholder="Inserisci partita iva"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Indirizzo</Label>
                        <Input
                            {...validatedAddress.input}
                            name="validatedAddress"
                            id="validatedAddress"
                            placeholder="Inserisci indirizzo"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Codice Gescoop</Label>
                        <Input
                            {...codiceGescoop.input}
                            name="codiceGescoop"
                            id="codiceGescoop"
                            placeholder="Inserisci codice gescoop"
                        />
                    </FormGroup>


                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare l'account.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={create}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
