/* eslint-disable react/react-in-jsx-scope */
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardLink,
    CardTitle,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from "reactstrap";

import ClassNames from 'classnames';
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import _ from 'lodash';
import moment from 'moment';
import Select from "react-select";
import ReactTable from "react-table";
import {
    Tooltip,
} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import SweetAlert from 'sweetalert-react';
import * as AssegnazioniCommands from '../ordini/redux/assegnazioni.commands';
import * as OrdiniCommands from "../ordini/redux/ordini.commands";
import FinestraRicercaLavoratore from './finestra.ricercasocio.component';

import { Component } from 'react';
import * as Commands from "views/rai/ordini/redux/ordini.commands";

class FinestraAssegnazioniComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchWorker: "",
            assegnazioni: [],
            ordineCaricato: false,
            mostraFinestraRicercaSocio: false,
            loading: false,
            width: window.innerWidth,
            selectedAssRow: {},
            loadingAssRow: -1,
            loadingSociLiberi: false,
            showalert: false,
            lavoratoreDaAssegnare: false,
            mostraDropDownAzioni: false,
            freeWorkers: [],
            erroreFetchOrdine: false,
            oreAssegnate: 0,
            oreTotali: 0,
            capoSquadra: "Non assegnato",
            lavoratoriAssegnati: 0
        };
    }

    handleResize = () => this.setState({ width: window.innerWidth });

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentWillMount() {

        if (!this.props.ordine) return;
        if (this.props.ordine && !this.state.ordineCaricato) {
            this.ottieniAssegnazioni();
        }
        ;

        window.addEventListener('resize', this.handleResize);
    }

    aggiornaAssegnazioni = (assegnazioni) => {
        // Aggiorno aggregati
        // Calcolo ore totali e assegnate
        let oreTotali = 0;
        let oreAssegnate = 0;
        let lavoratoriAssegnati = 0;
        let oggMostraAzioni = {};

        let capoSquadraStr = "Non assegnato";

        assegnazioni.map((ass, index) => {
            oggMostraAzioni[index] = false;

            let ore = (ass.endTime - ass.startTime) / 3600;
            oreTotali += ore;

            if (ass.state === 'assigned') {
                oreAssegnate += ore;
                lavoratoriAssegnati += 1;


                if (ass.jsonExtra.teamLeader && ass.jsonExtra.teamLeader === true) {
                    if (capoSquadraStr !== "Non assegnato") {
                        capoSquadraStr += ", " + ass.jsonWorker.nome + " " + ass.jsonWorker.cognome;
                    } else {
                        capoSquadraStr = ass.jsonWorker.nome + " " + ass.jsonWorker.cognome;
                    }
                }
            }
            return null
        });

        this.setState({
            oreAssegnate,
            lavoratoriAssegnati,
            oreTotali,
            capoSquadraStr,
            mostraDropDownAzioni: oggMostraAzioni,
            assegnazioni
        });
    }

    toggleDropDownAzioni = index => {

        this.setState({
            mostraDropDownAzioni: {
                ...this.state.mostraDropDownAzioni,
                [index]: !this.state.mostraDropDownAzioni[index]
            }
        })

    };

    aggiornaAssegnazione = assegnazione => {

        const ass = [...this.state.assegnazioni];
        var indexCercato = -1;

        this.state.assegnazioni.map((ass, index) => {
            if (ass.id === assegnazione.id) {
                indexCercato = index;
                return null
            }
            return null
        });

        ass[indexCercato] = { ...assegnazione };

        this.setState({ assegnazioni: ass });
    };

    modificaNumeroProvvisorio = async () => {
        const doAction = await ShowConfirmationDialog({
            title: "Modifica numero provvisorio",
            text: `Inserisci il numero provvisorio a cui inviare il link per la firma`,
            confirmBtnText: 'Si',
            canEscapeKeyCancel: true,
            input: true,
            placeholder: 'Numero ( con il +39 davanti )',
            validationMsg: 'Devi inserire un numero valido!'
        });

        if (doAction.confirm) {
            const numero = doAction.response;

            const res = await Commands.CambioNumeroProvvisorio(this.props.ordine.id, numero);
            if (res.error) {
                ShowConfirmationDialog({
                    title: "Errore durante il cambio del numero",
                    text: res.message,
                    confirmBtnText: 'Ok',
                    showCancel: false,
                    canEscapeKeyCancel: true
                });
            }

            this.setState({
                cancelledNumber: numero
            })
        }
    }

    ottieniAssegnazioni = async () => {
        this.setState({ ordineCaricato: false });

        const res = await OrdiniCommands.OttieniOrdine(this.props.ordine.id);

        if (res.hasError) {
            this.setState({
                ordineCaricato: true,
                erroreFetchOrdine: res.message
            });
            return;
        }

        this.setState({
            assegnazioni: res.data.assignments,
            ordineCaricato: true,
            erroreFetchOrdine: false
        });
    };

    inviaSmsAccettazione = async () => {

        this.setState(
            {
                loadingAssRow: this.state.selectedAssRow.index
            });

        var res = await AssegnazioniCommands.InviaSmsAccettazione(this.state.selectedAssRow.original.id);
        this.setState({ loadingAssRow: -1 });

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato possibile inviare il messaggio: ${res.message}`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        if (res.data.sent === 0) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato possibile inviare il messaggio: contattare l'amministrazione`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        ShowConfirmationDialog({
            title: "Risultato",
            text: `Messaggio messo in coda`,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No',
            canEscapeKeyCancel: true
        });
    };

    inviaSmsTimnbrata = async () => {

        this.setState(
            {
                loadingAssRow: this.state.selectedAssRow.index
            });

        var res = await AssegnazioniCommands.InviaSmsTimbrata(this.state.selectedAssRow.original.id);
        this.setState({ loadingAssRow: -1 });

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato possibile inviare il messaggio: ${res.message}`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        if (res.data.sent === 0) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato possibile inviare il messaggio: contattare l'amministrazione`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        ShowConfirmationDialog({
            title: "Risultato",
            text: `Messaggio messo in coda`,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No',
            canEscapeKeyCancel: true
        });
    };


    cambioCapoSquadra = async () => {

        let capoSquadra = true;
        if (this.state.selectedAssRow.original.jsonExtra.teamLeader) capoSquadra = !this.state.selectedAssRow.original.jsonExtra.teamLeader;

        this.setState(
            {
                showAlert: false,
                loadingAssRow: this.state.selectedAssRow.index
            });

        var res = await AssegnazioniCommands.CambioCapoSquadra(this.state.selectedAssRow.original.id, capoSquadra);
        this.setState({ loadingAssRow: -1 });

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato possibile cambiare capo squadra: ${res.message}`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        this.setState({
            freeWorkers: []
        });
        // Devo aggiornare i dati
        this.aggiornaAssegnazione(res.data);
    };


    cambioCapoSquadra = async () => {

        let capoSquadra = true;
        if (this.state.selectedAssRow.original.jsonExtra.teamLeader) capoSquadra = !this.state.selectedAssRow.original.jsonExtra.teamLeader;

        this.setState(
            {
                showAlert: false,
                loadingAssRow: this.state.selectedAssRow.index
            });

        var res = await AssegnazioniCommands.CambioCapoSquadra(this.state.selectedAssRow.original.id, capoSquadra);
        this.setState({ loadingAssRow: -1 });

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato possibile cambiare capo squadra: ${res.message}`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        this.setState({
            freeWorkers: []
        });
        // Devo aggiornare i dati
        this.aggiornaAssegnazione(res.data);
    };


    assegnaLavoratore = async () => {

        this.setState(
            {
                showAlert: false,
                loadingAssRow: this.state.selectedAssRow.index
            });

        var res = await AssegnazioniCommands.AssegnaLavoratore(this.state.selectedAssRow.original.id, this.state.lavoratoreDaAssegnare.workerId);
        this.setState({ loadingAssRow: -1 });

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato posisbile assegnare il lavoratore alla lavorazione: ${res.message}`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        this.setState({
            freeWorkers: []
        });
        // Devo aggiornare i dati
        this.aggiornaAssegnazione(res.data);
    };

    scriviNote = async (ass) => {
        const doAction = await ShowConfirmationDialog({
            title: "Note",
            text: "Inserisci sotto le note da aggiungere all'asseganazione",
            confirmBtnText: "Salva",
            canEscapeKeyCancel: true,
            input: true,
            placeholder: 'Note qui',
            validationMsg: 'Devi scrivere qualcosa!!'
        });

        if (!doAction.confirm) return;
        const note = doAction.response;

        const res = await AssegnazioniCommands.SalvaNote(ass.id, note);

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato posisbile salvare le note: ${res.message}`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        this.aggiornaAssegnazione(res.data);


    };

    rimuoviLavoratore = async (assegnazione) => {

        const confirm = await ShowConfirmationDialog({
            title: 'Procedere',
            text: `Sei sicuro di voler rimuovere ${assegnazione.jsonWorker.nome} ${assegnazione.jsonWorker.cognome} dalla lavorazione?`,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No',
            canEscapeKeyCancel: true
        });

        if (!confirm.confirm) {
            return;
        }

        this.setState({ loadingAssRow: this.state.selectedAssRow.index });

        const res = await AssegnazioniCommands.RimuoviLavoratore(assegnazione.id, assegnazione.workerID);
        this.setState({ loadingAssRow: -1 });

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "C'è stato un errore!",
                text: `Non è stato posisbile rimuovere ${assegnazione.jsonWorker.nome} ${assegnazione.jsonWorker.cognome} dalla lavorazione: ${res.message}`,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        this.aggiornaAssegnazione(res.data);

    };


    ComponenteMobile = () => (
        <Row className={"d-flex flex-wrap"}>
            {
                this.state.assegnazioni.map(ass => (
                    <Col xs={12}>
                        <Card className={ClassNames('card-white', {
                            'assigned': Math.random() >= 0.5,
                            'notAssigned': Math.random() >= 0.7
                        })}>
                            <CardHeader>
                                <CardTitle tag="h4"></CardTitle>


                            </CardHeader>
                            <CardBody>
                                <Row className="ml-2">
                                    <Col xs={12}>
                                        <Label>Data: {moment
                                            .unix(ass.startTime)
                                            .format("DD-MM-YYYY")}</Label>
                                    </Col>
                                </Row>
                                <Row className="ml-2">
                                    <Col xs={12}>
                                        <Label>Ora inizio: {moment
                                            .unix(ass.startTime)
                                            .format("HH:mm")}</Label>
                                    </Col>
                                    <Col xs={12}>
                                        <Label>Ora fine: {moment
                                            .unix(ass.endTime)
                                            .format("HH:mm")}</Label>
                                    </Col>
                                    <Col xs={12}>
                                        <Label>Capo squadra: {moment
                                            .unix(ass.endTime)
                                            .format("HH:mm")}</Label>
                                    </Col>
                                </Row>

                                <Select
                                    onMenuOpen={() => {
                                        this.setState({ loading: true })
                                        setTimeout(() => this.setState({ loading: false }), 2000);
                                    }}
                                    components={{
                                        Option: ({ innerRef, innerProps, isDisabled }) => {
                                            return <div style={{
                                                color: "black",
                                                border: "1px solid red",
                                                padding: 5,
                                                overflow: "hidden"
                                            }} ref={innerRef}  {...innerProps}>
                                                <Col xs={12}>Kliton</Col>
                                                <Col xs={12}>Bare</Col>

                                                <Col xs={12}>Proiezione mese molto molto molto
                                                    gucci</Col>

                                            </div>;
                                        }
                                    }}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    getOptionLabel={(item) => item.description}
                                    options={[{
                                        value: "",
                                        description: "Tipologia",
                                        isDisabled: false
                                    },
                                    {
                                        value: "",
                                        description: "Canone",
                                    },

                                    {
                                        value: "",
                                        description: "Modulo",
                                    },
                                    {
                                        value: "",
                                        description: "Richiesta",
                                    },
                                    ]}
                                    placeholder="Seleziona un lavoratore"
                                />
                            </CardBody>

                            <Col xs={12}>
                                <CardFooter className={"d-flex justify-content-between"}>
                                    <CardLink onClick={() => this.toggleFinestraSocio()}
                                        style={{ "color": "#ba54f5" }}>Assegna</CardLink>
                                    <this.Azioni></this.Azioni>
                                </CardFooter>
                            </Col>
                        </Card>
                    </Col>
                ))
            }
        </Row>)

    ComponenteWeb = (props) => {

        let oreAssegnateStr = `${this.state.oreAssegnate} su ${this.state.oreTotali}`;
        let lavoratoriAssegnatiStr = `${this.state.lavoratoriAssegnati} su ${this.state.assegnazioni.length}`;

        return (
            <div key="componenteWeb">

                <Row>
                    <Col xs={8}>
                        <Row>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lavoratori richiesti</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.assegnazioni}
                                        resizable={true}
                                        getTdProps={(state, rowInfo, column, instance) => {
                                            if (!rowInfo) {
                                                return {};
                                            }
                                            return {
                                                style: {
                                                    background: rowInfo && rowInfo.index === this.state.selectedAssRow && this.state.selectedAssRow.index ? '#45486c' : '#27293d',
                                                },
                                                onClick: (e, handleOriginal) => {

                                                    this.setState({ selectedAssRow: rowInfo });

                                                    if (column.className === 'showDropDown' && rowInfo.original.state === 'assigned') {
                                                        return;
                                                    }

                                                    this.ottieniSociLiberi(rowInfo.original.id, rowInfo.index);

                                                    if (handleOriginal) {
                                                        handleOriginal()
                                                    }
                                                }
                                            }
                                        }}
                                        columns={[
                                            {
                                                Header: "Num.",
                                                accessor: "number",
                                                width: 60,
                                            },
                                            {
                                                Header: "Orario",
                                                Cell: row => (`Dalle ${moment.unix(row.original.startTime).format("HH:mm")} alle ${moment.unix(row.original.endTime).format("HH:mm")}`),
                                                width: 180,
                                            },
                                            {
                                                Header: "Servizio",
                                                Cell: row => {
                                                    return _.get(row.original, 'jsonExtra.jsonArticle.description', '-');
                                                },
                                                width: 100,
                                            },
                                            {
                                                id: 'lavoratore',
                                                Header: "Lavoratore",
                                                Cell: row => {
                                                    let className = '';
                                                    let nome = '';

                                                    const jsonWorker = row.original.jsonWorker;
                                                    if (jsonWorker && (jsonWorker.nome || jsonWorker.cognome)) {
                                                        nome = jsonWorker.cognome + " " + jsonWorker.nome;
                                                        if (row.original.acceptedOn > 0) {

                                                            if (row.original.accepted) className = 'assAccepted';
                                                            else if (!row.original.accepted) className = 'assRefusedName';
                                                        }
                                                    } else {
                                                        nome = 'Non assegnato';
                                                    }

                                                    return (
                                                        <>
                                                            <Tooltip
                                                                // options
                                                                theme={'light'}
                                                                position="bottom"
                                                                trigger="mouseenter"
                                                                delay={500}
                                                                interactive
                                                                html={(
                                                                    <div className={"assInfoTooltip"}>
                                                                        {row.original.acceptedOn > 0 && !row.original.accepted &&
                                                                            <div className={"assInfo"}><b>Motivo
                                                                                rifiuto:</b> {row.original.refusedReason}
                                                                            </div>}
                                                                        <div className={"assInfo"}><b>Accettato/Rifiutato
                                                                            alle:</b> {moment.unix(row.original.acceptedOn).format("DD/MM/YYYY HH:mm")}
                                                                        </div>
                                                                        <div className={"assInfo"}>
                                                                            <b>Device:</b> {row.original.acceptedDeviceUserAgent}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            >
                                                                <div id={"lavoratore" + row.index}
                                                                    className={className}>{nome}</div>

                                                            </Tooltip>

                                                        </>
                                                    );


                                                },
                                                width: 300,
                                            },

                                            {
                                                Header: () => <div style={{ fontSize: 9 }}>Accettato</div>,
                                                Cell: row => {
                                                    let acc = "-";
                                                    if (row.original.acceptedOn > 0) {
                                                        if (row.original.accepted) acc =
                                                            <div className="assAccepted">Si</div>;
                                                        if (!row.original.accepted) acc =
                                                            <div className="assRefused">No</div>;
                                                    }
                                                    return acc;
                                                },
                                                width: 80,
                                            }, {
                                                Header: () => <div style={{ fontSize: 9 }}>Cp. Squadra</div>,
                                                Cell: row => {
                                                    let capoSquadra = "No";
                                                    if (row.original.jsonExtra.teamLeader) capoSquadra = "Si";
                                                    return capoSquadra;
                                                },
                                                width: 80,
                                            },
                                            {
                                                Header: () => <div style={{ fontSize: 9 }}>Note</div>,
                                                Cell: row => {
                                                    return row.original.notes;
                                                },
                                                width: 80,
                                            },
                                            {
                                                Header: () => <div style={{ fontSize: 9 }}>Stato sms</div>,
                                                accessor: 'smsState',
                                                width: 80,
                                                Cell: row => {
                                                    if (!row.value) return null;
                                                    switch (row.value.toLowerCase()) {
                                                        case 'sent':
                                                            return <div className="smsSent">Inviato</div>
                                                        case 'readed':
                                                            return <div className="smsReaded">Letto</div>
                                                        default:
                                                            return <div className="smsToSend">Da inviare</div>;
                                                    }
                                                }
                                            },
                                            /*  {
                                                  Header: () => <div style={{fontSize: 9}}>Tipo</div>,
                                                  Cell: row => {
                                                      if (row.original.auto) return (<div style={{color: "#62D96B"}}>AUTO</div>);
                                                      return (<div style={{color: "#FFC940"}}>MAN</div>);
                                                  },
                                                  width: 80,

                                              },*/
                                            {
                                                Header: "",
                                                Cell: (cellInfo => this.Azioni(cellInfo, props)),
                                                maxWidth: 60,
                                                className: 'showDropDown',
                                                sortable: false,
                                            }
                                        ]}
                                        minRows={5}
                                        defaultPageSize={5}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    />
                                </CardBody>
                            </Card>

                        </Row>
                    </Col>

                    <Col xs={4}>

                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Riepilogo</CardTitle>
                            </CardHeader>
                            <CardBody className="form-horizontal">

                                <Form className="row form-horizontal">

                                    <Col md="6">
                                        <Row>
                                            <Label sm="3">Servizio richiesto</Label>
                                            <Col sm="9">
                                                <FormGroup>
                                                    <p className="form-control-static">
                                                        {this.props.ordine.jsonExtra.article.description}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="3">Capo squadra:</Label>
                                            <Col sm="9">
                                                <FormGroup>
                                                    <p className="form-control-static">
                                                        {this.state.capoSquadra}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Label sm="3">Ore assegnate</Label>
                                            <Col sm="9">
                                                <FormGroup>
                                                    <p className="form-control-static">
                                                        {oreAssegnateStr}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Label sm="3">Numero provvisorio</Label>
                                            <Col sm="9">

                                                <FormGroup>
                                                    <p className="form-control-static">
                                                        <Button onClick={() => this.modificaNumeroProvvisorio()}>{
                                                            this.state.cancelledNumber ?
                                                                this.state.cancelledNumber : (
                                                                    this.props.ordine.cancelledNumber ? this.props.ordine.cancelledNumber :
                                                                        "Imposta")
                                                        }</Button>
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>

                                    <Col md="6">
                                        <Row>
                                            <Label sm="3">Lavoratori assegnati</Label>
                                            <Col sm="9">
                                                <FormGroup>
                                                    <p className="form-control-static">
                                                        {lavoratoriAssegnatiStr}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm="3">Richiedente</Label>
                                            <Col sm="9">
                                                <FormGroup>
                                                    <p className="form-control-static">
                                                        {this.props.ordine.jsonExtra.applicantUser.jsonextra.cognome + " " + this.props.ordine.jsonExtra.applicantUser.jsonextra.nome}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Label sm="3">Produzione</Label>
                                            <Col sm="9">
                                                <FormGroup>
                                                    <p className="form-control-static">
                                                        {this.props.ordine.jsonExtra.production.name}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Form>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Card style={{ marginBottom: 0 }}>
                        <CardHeader>
                            <CardTitle tag="h4">Lavoratori disponibili</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Col xs={12}>
                                <Input name="ricerca" id="ricerca" value={this.state.searchWorker}
                                    placeholder="Ricerca lavoratore"
                                    onChange={e => this.setState({ searchWorker: e.target.value.toLowerCase() })} />
                            </Col>
                            <ReactTable
                                data={this.state.freeWorkers.filter((
                                    worker =>
                                        worker.fullName.toLowerCase().includes(this.state.searchWorker)
                                ))}
                                resizable={true}
                                minRows={5}
                                loading={this.state.loadingSociLiberi}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: async (e, handleOriginal) => {
                                            if (!rowInfo.original) return;

                                            // Se le ore lavorate sono maggiori delle ore contratto, far uscire popup con password hardcoded
                                            //!!! NB: Lo disabilito dato che e' stato richiesto in questo ticket: https://testudosrlsupport.zendesk.com/agent/tickets/1241

                                            /*  if (rowInfo.original.monthWorkedHours >= rowInfo.original.contractHour) {
                                                 const doAction = await ShowConfirmationDialog({
                                                     title: "Inserire password",
                                                     text: "Il lavoratore ha già effettuato le ore da contratto, per forzare il sistema inserire la password.",
                                                     confirmBtnText: 'Si',
                                                     canEscapeKeyCancel: true,
                                                     input: true,
                                                     placeholder: 'Password',
                                                     validationMsg: 'Devi inserire una password valida!'
                                                 });

                                                 if (!doAction.confirm) return;
                                                 const passwordInserita = doAction.response;
                                                 if (passwordInserita !== "ass123!") {
                                                     await ShowConfirmationDialog({
                                                         title: "Errore",
                                                         text: "La password inserita non è valida!!",
                                                         confirmBtnText: 'Si',
                                                         canEscapeKeyCancel: true,
                                                         input: false,
                                                     });
                                                     return;
                                                 }

                                             } */

                                            // Setto il worker
                                            this.setState({
                                                showAlert: true,
                                                lavoratoreDaAssegnare: rowInfo.original
                                            })

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        Header: "Socio",
                                        accessor: "fullName",
                                        width: 200,
                                    },
                                    {
                                        Header: "Società",
                                        accessor: "societa",
                                        width: 100
                                    },
                                    {
                                        Header: "Ore contratto",
                                        accessor: "contractHour",
                                        maxWidth: 80,
                                        Cell: (row) => {
                                            return row.value
                                        }
                                    },

                                ]}
                                showPaginationBottom={true}
                                noDataText={'Nessun dato'}
                                defaultPageSize={5}
                                pageText={'Pagina'}
                                ofText={'di'}
                                rowsText={'elementi'}
                                nextText={'Avanti'}
                                previousText={'Indietro'}
                                className=" "
                            />
                        </CardBody>
                    </Card>

                </Row>

            </div>
        )
    };

    ContenutoModal = () => {
        if (!this.state.ordineCaricato) {
            return (<div>Caricamento assegnazioni in corso... <i className="fa fa-spin fa-spinner" /></div>);
        }

        if (this.state.erroreFetchOrdine) {
            return (
                <>
                    <p className={"text-danger"}>Non è stato possibile caricare l'ordine</p>

                    <blockquote className={"blockquote blockquote-danger"}>
                        <p className="mb-0 text-danger">Dettaglio errore: {this.state.erroreFetchOrdine}</p>
                    </blockquote>
                    <Button onClick={() => this.ottieniAssegnazioni()}>Riprova</Button>
                </>
            );
        }

        if (this.state.width > 1) return <this.ComponenteWeb />;
        return <this.ComponenteMobile />;
    };

    ottieniSociLiberi = async (assegnazioneId, indexRigaTabella) => {
        this.setState({
            freeWorkers: [],
            loadingAssRow: indexRigaTabella,
            loadingSociLiberi: true
        });

        var res = await AssegnazioniCommands.OttieniLavoratoriLiberiPerAssegnazione(assegnazioneId);

        this.setState({
            loadingAssRow: -1,
            loadingSociLiberi: false
        });

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "Errore durante la ricerca di lavoratori disponibili",
                text: res.message,
                confirmButtonText: 'Si!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true
            });
            return;
        }

        this.setState({
            freeWorkers: res.data
        });

    };

    toggleFinestraSocio = () => this.setState({ mostraFinestraRicercaSocio: !this.state.mostraFinestraRicercaSocio });
    Azioni = (cellInfo, props) => {
        return (
            <div className="actions-right">
                {
                    cellInfo.index === this.state.loadingAssRow ? <i className="fa fa-spin fa-spinner" /> :
                        <>
                            {cellInfo.original.state === 'assigned' &&
                                <Dropdown isOpen={this.state.mostraDropDownAzioni[cellInfo.index]}
                                    toggle={() => this.toggleDropDownAzioni(cellInfo.index)}>
                                    <DropdownToggle
                                        caret
                                        className="btn-link btn-icon"
                                        color="default"
                                        data-toggle="dropdown"
                                        type="button"
                                    >
                                        <i className="tim-icons icon-settings-gear-63" />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <div className={"customDropDownAss"}
                                            onClick={() => this.inviaSmsAccettazione(cellInfo.original)}>Reinvia sms
                                            accettazione
                                        </div>
                                        <div className={"customDropDownAss"}
                                            onClick={() => this.inviaSmsTimnbrata(cellInfo.original)}>Reinvia sms timbrata
                                        </div>

                                        <div className={"customDropDownAss"}
                                            onClick={() => this.scriviNote(cellInfo.original)}>Scrivi
                                            note
                                        </div>
                                        <div className={"customDropDownAss"}
                                            onClick={() => this.rimuoviLavoratore(cellInfo.original)}>Rimuovi
                                            lavoratore
                                        </div>
                                        <div className={"customDropDownAss"}
                                            onClick={() => this.cambioCapoSquadra(cellInfo.original)}> Imposta
                                            come
                                            capo squadra
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>}
                        </>
                }
            </div>
        )
    };


    render = () => {
        return (
            <Modal
                backdrop={true}
                modalClassName={ClassNames("modal-black", { 'extra-large-sparkle-modal': this.state.width > 1000 })}
                isOpen={this.props.show}
                toggle={() => this.props.toggle(this.props.ordine.id)}
            >
                <FinestraRicercaLavoratore show={this.state.mostraFinestraRicercaSocio}
                    toggle={this.toggleFinestraSocio} />
                <SweetAlert
                    show={this.state.showAlert}
                    showCancelButton
                    title="Assegnare lavoratore?"
                    text="Procedere?"
                    onConfirm={() => {
                        this.assegnaLavoratore();
                    }}
                    onCancel={() => {
                        this.setState({ showAlert: false });
                    }}
                />
                <div className="modal-header assegnazioni justify-content-center">
                    <div>

                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.props.toggle(this.props.ordine.id)}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>


                    <h6 className="title title-up">Assegna ordine {this.props.ordine.number} </h6>
                    <button
                        className="close"
                        type="button"
                        onClick={() => this.props.toggle(this.props.ordine.id)}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                </div>


                <ModalBody>
                    <this.ContenutoModal></this.ContenutoModal>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>);
    }

}

export default FinestraAssegnazioniComponent;
