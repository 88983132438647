import React, {Component} from 'react';
import * as Commands from "../../../assegnazioni/redux/assegnazioni.commands";
import {get} from "lodash";
import {connect} from "react-redux";
import {Card, CardHeader, Col, Row} from "reactstrap";
import CardBody from "reactstrap/es/CardBody";






class SchedaSocioComponent extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loaded: false
        }


    }

    caricaDati = async (startDate, endDate) => {
        const startDateClone = startDate.clone();
        const endDateClone = endDate.clone();
        this.setState({loading: true});
        await Commands.OttieniAssegnazioni(startDateClone.format("YYYY-MM-DD"), endDateClone.add(1, 'days').format("YYYY-MM-DD"), "");
        this.setState({loading: false, loaded: true});
    };

    componentWillMount() {
        this.caricaDati(this.props.startDate, this.props.endDate);
    };


    render() {
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col xs="12">
                            <Card className="card-chart">
                                <CardHeader>
                                    <h1>Scheda socio</h1>
                                </CardHeader>
                                <CardBody>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const data = {
        data: get(state, "raiAnalyticsSoci.data", []),
        loading: get(state, "raiAnalyticsSoci.loading", false)
    };

    return data;
};

export default connect(mapStateToProps, () => ({}))(SchedaSocioComponent)

