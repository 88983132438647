import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect, useRef} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/setupMailAccount.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function CreateMailAccount(props) {

    const username = TextInputHook('');
    const pass = TextInputHook('');
    const [pec, setPec] = useState(false);

    const outserverport = TextInputHook('');
    const outserver = TextInputHook('');
    const inserver = TextInputHook('');
    const inserverport = TextInputHook('');


    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [serverType, setServerType] = useState("");
    const [selectedCompany, setSelectedCompany] = useState("");

    const coops = useSelector(state => state.coops.data.map(item => ({...item, key: item.id})));

    const create = async () => {
        setLoading(true);

        //usr, pwd, inServerType, inServer, inServerPort, outServer, outServerPort, company_id, pec
        var res = await Commands.Create(
            username.getValue(),
            pass.getValue(),
            serverType.value,
            inserver.getValue(),
            inserverport.getValue(),
            outserver.getValue(),
            outserverport.getValue(),
            selectedCompany.codiceGescoop,
            pec
        );
        
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
    }


    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo account di posta</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    create();
                }}>
                    <FormGroup>
                        <Label for="nome">Username</Label>
                        <Input
                            {...username.input}
                            name="username"
                            id="username"
                            placeholder="Inserisci username"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Password</Label>
                        <Input
                            {...pass.input}
                            name="pass"
                            id="pass"
                            placeholder="Inserisci password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Tipologia serve di posta in arrivo</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={serverType}
                            getOptionLabel={(item) => item.description}
                            getOptionValue={(item) => item.value}
                            onChange={value => {
                                setServerType(value);
                            }
                            }
                            closeMenuOnSelect={true}

                            options={[
                                {
                                    value: "imap",
                                    description: "IMAP",
                                },
                                {
                                    value: "pop3",
                                    description: "POP3",
                                },
                            ]}
                            placeholder="Seleziona tipologia server"
                        />

                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Cooperativa</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={selectedCompany}
                            getOptionLabel={(item) => item.codiceGescoop}
                            getOptionValue={(item) => item.codiceGescoop}
                            onChange={value => {
                                setSelectedCompany(value);
                            }
                            }
                            closeMenuOnSelect={true}

                            options={[
                                {
                                    value: "",
                                    companyName: "Coop",
                                    isDisabled: true
                                }, ...coops,
                            ]}
                            placeholder="Seleziona cooperativa"
                        />

                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Server posta in arrivo</Label>
                        <Input
                            {...inserver.input}
                            name="inserver"
                            id="inserver"
                            placeholder="Inserisci server di posta in arrivo"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Porta server posta in arrivo</Label>
                        <Input
                            {...inserverport.input}
                            name="inserverport"
                            id="inserverport"
                            placeholder="Inserisci porta server in arrivo"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Server di posta in uscita</Label>
                        <Input
                            {...outserver.input}
                            name="outserver"
                            id="outserver"
                            placeholder="Inserisci server di posta in uscita"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Porta server di posta in uscita</Label>
                        <Input
                            {...outserverport.input}
                            name="outserverport"
                            id="outserverport"
                            placeholder="Inserisci porta server di posta in uscita"
                        />
                    </FormGroup>
                    <FormGroup style={{marginTop: 20}} check>
                        <Label check>
                            <Input checked={pec}
                                   onChange={() => setPec(!pec)}
                                   type="checkbox"/>{' '}
                            Account di posta elettronica certificata
                            <span className="form-check-sign">
                                <span className="check"></span>
                            </span>
                        </Label>
                    </FormGroup>


                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare l'account.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={create}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
