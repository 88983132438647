import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect, useRef} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/utenti.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import _ from 'lodash';
import TagsInput from "react-tagsinput";
import SectorRoleComponent from "views/rai/utenti/components/SectorRoleComponent";

import {
    isAdmin,
    ruoli, ruoliSettori
} from "./costants";

export default function UpdateUserComponent(props) {

    const [tags, setTags] = useState(_.get(props, 'tags', []));
    const nome = TextInputHook(_.get(props, 'jsonextra.nome', ''));
    const cognome = TextInputHook(_.get(props, 'jsonextra.cognome', ''));
    const telefono = TextInputHook(_.get(props, 'jsonextra.telefono', ''));
    const email = TextInputHook(_.get(props, 'email', ''));
    const matricola = NumberInputHook(_.get(props, 'jsonextra.matricola', ''));
    const [selectedContracts, setSelectedContracts] = useState(props.jsonextra.allowedContracts.map(item => ({
        ...item,
        key: item.id
    })));

    const contracts = useSelector(state => state.contract.data.map(item => ({...item, key: item.id})));
    const sectors = useSelector(state => state.raiSettori.data.map(item => ({...item, key: item.id})));
    const utenti = useSelector(state => state.raiUtenti.data);
    const elencoNotifiche = [{description: 'SMS', value: 'sms'}, {description: 'E-Mail', value: 'email'}];

    const orderCreationNotificationUsers = _.get(props, 'jsonextra.orderCreationNotification', []);
    const orderSignNotificationUsers = _.get(props, 'jsonextra.orderSignNotification', []);

    const [orderCreationNotification, setOrderCreationNotification] = useState(utenti.filter(u => orderCreationNotificationUsers.includes(u.email)));
    const [orderSignNotification, setOrderSignNotification] = useState(utenti.filter(u => orderSignNotificationUsers.includes(u.email)));

    const ruoloCercato = ruoli.filter(role => role.value === props.role)[0] || {};
    const [role, setRole] = useState(ruoloCercato);

    const handleTags = (newTags) => {
        setTags(newTags);
    };

    const [superiori, setSuperiori] = useState(_.get(props, 'jsonextra.superiori', []));

    const [notifica, setNotifica] = useState(_.get(props, 'jsonextra.notifica', []).map(item => ({
        ...item,
        key: item.id
    })));

    const [selectedCoop, setSelectedCoop] = useState(undefined);
    const coops = useSelector(state => state.coops.data.map(item => ({...item, key: item.id})));

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const sectorRoleComponentRef = useRef(null);

    const Submit = async () => {
        const sectorsRoles = sectorRoleComponentRef.current.getSectorsRoles();

        setLoading(true);
        var res = await Commands.AggiornaUtente(
            props.id,
            tags,
            role,
            nome.getValue(),
            cognome.getValue(),
            sectorsRoles,
            matricola.getValue(),
            superiori,
            selectedContracts,
            notifica,
            telefono.getValue(),
            email.getValue(),
            selectedCoop.id,
            selectedCoop.companyName,
            orderCreationNotification.map(item => item.email),
            orderSignNotification.map(item => item.email)
        );

        setLoading(false);
        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        props.toggle();
    };

    useEffect(() => {

        if (submitError) {

            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica utente</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    Submit();
                }}>
                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="cognome">Cognome</Label>
                        <Input
                            {...cognome.input}
                            name="cognome"
                            id="cognome"
                            placeholder="Inserisci cognome"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="cognome">Cellulare</Label>
                        <Input
                            {...telefono.input}
                            name="cellulare"
                            id="cellulare"
                            placeholder="Inserisci cellulare"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">E-Mail</Label>
                        <Input
                            {...email.input}
                            name="email"
                            id="email"
                            placeholder="Inserisci E-mail"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="uorg">Settori - Ruolo</Label>
                        <SectorRoleComponent initialValue={props} ref={sectorRoleComponentRef} ruoli={ruoliSettori}
                                             sectors={sectors}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="ruolo">Ruolo</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={role}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setRole(value)
                            }
                            options={[{
                                value: "",
                                description: "Ruolo",
                                isDisabled: true
                            }, ...ruoli]}
                            placeholder="Seleziona un ruolo"
                        />
                        <FormText color="muted">
                            Ruolo a cui si riferisce l'utente
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Matricola</Label>
                        <Input
                            {...matricola.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci matricola"
                        />
                        <FormText color="muted">
                            Matricola utente
                        </FormText>

                    </FormGroup>
                    <FormGroup>
                        <TagsInput
                            tagProps={{className: 'react-tagsinput-tag info'}}
                            value={tags}
                            onChange={handleTags}
                            name="tags"
                            id="tags"
                            inputProps={{placeholder: 'Nuovo tag'}}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="superiori">Superiori</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona superiori"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={superiori}
                            onChange={value =>
                                setSuperiori(value)
                            }
                            getOptionLabel={utente => utente.username}
                            getOptionValue={utente => utente.id}
                            options={[{
                                value: "",
                                description: "Superiori",
                                isDisabled: true
                            }, ...utenti]}

                        />
                        <FormText color="muted">
                            Diretti superiori che riceveranno notifiche
                        </FormText>

                    </FormGroup>
                    <FormGroup>
                        <Label for="superiori">Notifica</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona notifiche"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={notifica}
                            onChange={value =>
                                setNotifica(value)
                            }
                            getOptionLabel={elencoNotifiche => elencoNotifiche.description}
                            getOptionValue={elencoNotifiche => elencoNotifiche.value}
                            options={[{
                                value: "",
                                description: "Notifiche",
                                isDisabled: true
                            }, ...elencoNotifiche]}
                        />
                        <FormText color="muted">
                            Sistemi di notifica voluti dall'utente
                        </FormText>

                    </FormGroup>

                    <FormGroup>
                        <Label for="superiori">Notifica creazione ordine</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona utenti"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={orderCreationNotification}
                            onChange={value =>
                                setOrderCreationNotification(value)
                            }
                            getOptionLabel={utente => utente.username}
                            getOptionValue={utente => utente.id}
                            options={[{
                                value: "",
                                description: "Destinatari",
                                isDisabled: true
                            }, ...utenti]}

                        />
                        <FormText color="muted">
                            Destinatari notifica creazione ordine
                        </FormText>

                    </FormGroup>

                    <FormGroup>
                        <Label for="superiori">Notifica firma ordine</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona utenti"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={orderSignNotification}
                            onChange={value =>
                                setOrderSignNotification(value)
                            }
                            getOptionLabel={utente => utente.username}
                            getOptionValue={utente => utente.id}
                            options={[{
                                value: "",
                                description: "Destinatari",
                                isDisabled: true
                            }, ...utenti]}

                        />
                        <FormText color="muted">
                            Destinatari notifica firma ordine
                        </FormText>

                    </FormGroup>

                    <FormGroup>
                        <Label for="contratto">Cooperativa</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={selectedCoop}
                            getOptionLabel={(item) => item.companyName}
                            getOptionValue={(item) => item.id}
                            onChange={value => {
                                setSelectedCoop(value);
                                setSelectedContracts([]);
                            }
                            }
                            closeMenuOnSelect={false}
                            options={[{
                                value: "",
                                companyName: "Coop",
                                isDisabled: true,
                                key: 'coop'
                            }, ...coops]}
                            placeholder="Seleziona cooperativa"
                        />
                        <FormText color="muted">
                            Cooperativa di pertinenza dell'utente
                        </FormText>
                    </FormGroup>
                    {
                        selectedCoop !== undefined ?
                            <FormGroup>
                                <Label for="contratto">Contratto</Label>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={selectedContracts}
                                    getOptionLabel={(item) => item.description}
                                    getOptionValue={(item) => item.id}
                                    onChange={value => {
                                        setSelectedContracts(value);
                                    }
                                    }
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={[{
                                        value: "",
                                        description: "Contratto",
                                        isDisabled: true,
                                        key: 'contratto'
                                    }, ...contracts.filter(el => el.coopID === selectedCoop.id)]}
                                    placeholder="Seleziona un contratto"
                                />
                                <FormText color="muted">
                                    Contratto/i di pertinenza dell'utente
                                </FormText>
                            </FormGroup> : null
                    }


                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare la produzione.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={Submit}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    )
        ;

}
