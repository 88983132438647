import { createStore,applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from 'store/reducers'
// import {createLogger} from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas';

// const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();


const createStoreWithMiddleware = applyMiddleware(
/*    loggerMiddleware,*/
    sagaMiddleware,
)(createStore);


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['login', 'customer', 'contract', 'raiLotti', "coops"],
    debug: true
}



const persistedReducer = persistReducer(persistConfig, rootReducer);

 const store = createStoreWithMiddleware(persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const persistor = persistStore(store);
export default store;

sagaMiddleware.run(sagas)

