import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/contratti.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";
import moment from 'moment';
import Datetime from "react-datetime";
import {CurrentUser, isAdmin} from "../../utenti/components/costants";

export default function CreateContractComponent(props) {
    const descrizione = TextInputHook('');
    const numero = TextInputHook('');
    const [startDate, setStartDate] = useState(new moment().toDate());
    const [endDate, setEndDate] = useState(new moment().toDate());
    const contratto = TextInputHook('');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState({});

    const customers = useSelector(state => state.customer.data.filter(customer => {
        if (isAdmin()) return true;
        return customer.coopID === CurrentUser().coopId
    }));

    const salvaContratto = async () => {

        if (!company || company.coopID.length <= 0) {
            alert("Seleziona un cliente valido");
            return;
        }

        setLoading(true);
        const res = await Commands.SalvaContratto(company.coopID, company.coopName, descrizione.getValue(), moment(startDate).unix(), moment(endDate).unix(), company.id, numero.getValue());

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
        numero.clear();
        contratto.clear();
        setCompany(undefined);
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo contratto</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaContratto();
                }}>
                    <FormGroup>
                        <Label for="descrizione">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserisci descrizione"
                        />
                        <FormText color="muted">
                            La descrizione del contratto
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="numero">Numero contratto</Label>
                        <Input
                            {...numero.input}
                            name="numero"
                            id="numero"
                            placeholder="Inserisci numero"
                        />
                        <FormText color="muted">
                            La descrizione del contratto
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="contratto">Cliente</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={company}
                            getOptionLabel={(item) => item.companyName}
                            onChange={value =>
                                setCompany(value)
                            }
                            options={[{
                                value: "",
                                description: "Cliente",
                                isDisabled: true
                            }, ...customers]}
                            placeholder="Seleziona un cliente"
                        />
                        <FormText color="muted">
                            Cliente a cui si riferisce il lotto
                        </FormText>
                    </FormGroup>

                    <FormGroup>
                        <Label for="startDate">Data di inzio</Label>
                        <Datetime
                            id="startDate"
                            value={startDate}
                            closeOnSelect
                            closeOnTab
                            onChange={(dt) => {
                                setStartDate(dt.toDate());
                            }}
                            timeFormat={false}
                            inputProps={{placeholder: "Data di inizio produzione"}}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="endDate">Data di fine</Label>
                        <Datetime
                            id="endDate"
                            value={endDate}
                            closeOnSelect
                            closeOnTab
                            onChange={(dt) => {
                                setEndDate(dt.toDate());
                            }}
                            timeFormat={false}
                            inputProps={{placeholder: "Data di fine produzione"}}
                        />
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il contratto.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaContratto}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
