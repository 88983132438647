import React, {Component, useState} from 'react';
import queryString from 'query-string'
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import moment from 'moment';
import {Button, FormGroup, Label} from "reactstrap";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import * as Commands from 'views/rai/ordini/redux/ordini.commands';
import * as CommandsAss from 'views/rai/ordini/redux/assegnazioni.commands';
import Datetime from "react-datetime";

function ModificaOrario(props) {
    const [orario, setOrario] = useState(props.orario);

    const cambioOrario = (orario) => {
        setOrario(orario);
        props.cb(orario);
    }

    return (
        <FormGroup className={"row"}>
            <Col xs={3}><Label className="col-form-label" for="lotto">Orario</Label></Col>
            <Col xs={9}>
                <Datetime
                    className={"sparkleTimePicker"}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    inputProps={{
                        className: "form-control",
                        placeholder: "Time picker"
                    }}
                    onChange={startTime => {

                        if (typeof startTime === 'object') {

                            cambioOrario( startTime.unix())
                        }}
                    }
                    value={moment.unix(orario)}
                />
            </Col>
        </FormGroup>
    );
}


class TimbrataSmsComponent extends Component {

    state = {
        error: false,
        loading: true,
        assegnazione: {},
        terminated: false,
        errorePermessiGeoLocation: false,
        erroreGeoGenerale: false,
        lat: 0,
        long: 0,
        heading: null,
        speed: 0,
        altitude: 0,
        gettingPosition: true,
        letture: 0,
        choosedStartTime:0,
        choosedEndTime:0
    };

    successGeo = (dati) => {
        this.setState({
            lat: dati.coords.latitude,
            long: dati.coords.longitude,
            coords: {...dati.coords},
            gettingPosition: false,
            letture: this.state.letture +1,
        })
    };

    errorGeo = (dati) => {
        this.setState({erroreGeoGenerale: true, gettingPosition: false})
    };

    ottieniPosizione = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.successGeo, this.errorGeo);
        } else {
            this.setState({
                errorePermessiGeoLocation: true
            })
        }
    }

    componentDidMount() {
/*
        const intervalloPosizione = setInterval(this.ottieniPosizione, 1 * 1000);
*/

        this.ottieniPosizione();
        this.caricaAssegnazione();
    };

    componentWillUnmount() {
/*
        clearInterval(this.state.intervalloPosizione);
*/
    }

    toggle = (firmato) => {
        this.setState({firmato});
    };

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({hasError: true, error});

    }

    caricaAssegnazione = async () => {
        const queryParams = queryString.parse(this.props.location.search);
        const res = await Commands.OttieniAssegnazioneDaSms(queryParams.token);

        if (res.hasError) {
            this.setState({error: true, loading: false})
            return;
        }

        this.setState({
            assegnazione: res.data,
            error: false,
            loading: false,
            choosedStartTime: res.data.startTime,
            choosedEndTime: res.data.endTime,
        })
    };

    formEntrata = () => (
       <ModificaOrario cb={choosedStartTime => this.setState({choosedStartTime})} orario={this.state.choosedStartTime}/>
    );

    formUscita = () => (
        <ModificaOrario cb={choosedEndTime => this.setState({choosedEndTime})} orario={this.state.choosedEndTime}/>
    );


    registraEntrata = async () => {
        const doAction = await ShowConfirmationDialog({
            title: "Timbra entrata",
            confirmBtnText: 'Invia',
            cancelBtnText: 'Annulla',
            canEscapeKeyCancel: true,
            html: <this.formEntrata/>
        });

        if (!doAction.confirm) return;

        const res = await CommandsAss.TimbraEntrata(this.state.assegnazione.id,this.state.lat, this.state.long,  this.state.choosedStartTime,navigator.userAgent );
        if (res.hasError) {
            ShowConfirmationDialog({
                title: "Errore durante salvataggio",
                text: res.message,
                confirmBtnText: 'Si',
                canEscapeKeyCancel: true
            });
            return;
        };

        ShowConfirmationDialog({
            title: "Timbrata d'ingresso effettuata con successo",
            confirmBtnText: 'Ok',
            showCancel: false,
            canEscapeKeyCancel: true
        });

        this.setState({terminated: true});
        return;
    };

    registraUscita = async () => {
        const doAction = await ShowConfirmationDialog({
            title: "Timbra uscita",
            confirmBtnText: 'Si',
            canEscapeKeyCancel: true,
            html: <this.formUscita/>
        });

        if (!doAction.confirm) return;
        // const motivoRifiuto = doAction.response;

        const res = await CommandsAss.TimbraUscita(this.state.assegnazione.id,this.state.lat, this.state.long,  this.state.choosedEndTime,navigator.userAgent );

        if (res.hasError) {
            ShowConfirmationDialog({
                title: "Errore durante salvataggio",
                text: res.message,
                confirmBtnText: 'Si',
                canEscapeKeyCancel: true
            });
            return;
        };

        ShowConfirmationDialog({
            title: "Timbrata d'uscita effettuata con successo",
            confirmBtnText: 'Ok',
            showCancel: false,
            canEscapeKeyCancel: true
        });

        this.setState({terminated: true});
        return;
    };

    render() {

        if (this.state.gettingPosition) {
            return (
                <div className={"firmaSms"}>
                    Lettura della posizione in corso...
                </div>);
        }

        if (this.state.errorePermessiGeoLocation) {
            // You can render any custom fallback UI
            return (<h1>
                Devi consentire alla lettura della posizione.<br/>
                Modifica le impostazioni del browser per continuare.
            </h1>);
        }

        if (this.state.erroreGeoGenerale) {
            // You can render any custom fallback UI
            return ( <div className={"firmaSms"}>
                Devi consentire alla lettura della posizione.<br/>
                Modifica le impostazioni del browser per continuare.
            </div>);
        }

        if (this.state.errorePermessiGeoLocation) {
            // You can render any custom fallback UI
            return  <div className={"firmaSms"}>Devi consentire la richiesta di posizione attuale. Ricarica la pagina.</div>;
        }

        if (this.state.hasError) {
            // You can render any custom fallback UIloca
            return <h1>C'è stato un errore: {this.state.error}</h1>;
        }

        if (this.state.loading) {
            return (
                <div className={"firmaSms"}>
                    Caricamento delle informazioni in corso...
                </div>);
        }

        if (this.state.error) {
            return (
                <div className={"firmaSms"}>
                    Non è stato possibile caricare i dati relativi al servizio.
                </div>);
        }

        if (this.state.terminated) {
            return (
                <div className={"firmaSms"}>
                    Procedura terminata
                </div>);
        }

        return (

                <Row style={{padding: "10px"}}>
                    <Col sm={12}><Button
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        block
                        onClick={this.registraEntrata}
                    >
                        Timbra entrata
                    </Button>
                    </Col>
                    <Col style={{marginTop: 5}} sm={12}>
                        <Button
                            block
                            color="primary"
                            type="button"
                            onClick={this.registraUscita}
                        >
                            Timbra uscita
                        </Button></Col>
                </Row>
        );
    }
}

export default TimbrataSmsComponent;
