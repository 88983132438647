import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/settori.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function CreateSettoreComponent(props) {

    const descrizione = TextInputHook('');
    TextInputHook('Rai');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState({});
    const [superiori, setSuperiori] = useState([]);
    const [orderCreationNotification, setOrderCreationNotification] = useState([]);
    const [orderSignNotification, setOrderSignNotification] = useState([]);
    const utenti = useSelector(state => state.raiUtenti.data);

    const contracts = useSelector(state => state.contract.data);
    useSelector(state => state.customer.id);

    const salvaSettore = async () => {
        setLoading(true);
        var res = await Commands.SalvaSettore(descrizione.getValue(), contract.id, superiori, orderCreationNotification.map(item => item.email), orderSignNotification.map(item => item.email));
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
        setContract(undefined);
        setSuperiori([]);
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo settore</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaSettore();
                }}>
                    <FormGroup>
                        <Label for="descrizione">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserisci descrizione del settore"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="superiori">Funzionari</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona superiori"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={superiori}
                            onChange={value =>
                                setSuperiori(value)
                            }
                            getOptionLabel={utente => utente.username}
                            getOptionValue={utente => utente.id}
                            options={[{
                                value: "",
                                description: "Superiori",
                                isDisabled: true
                            }, ...utenti]}

                        />
                        <FormText color="muted">
                            Funzionari settore
                        </FormText>

                    </FormGroup>

                    <FormGroup>
                        <Label for="superiori">Notifica creazione ordine</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona superiori"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={orderCreationNotification}
                            onChange={value =>
                                setOrderCreationNotification(value)
                            }
                            getOptionLabel={utente => utente.username}
                            getOptionValue={utente => utente.id}
                            options={[{
                                value: "",
                                description: "Destinatari",
                                isDisabled: true
                            }, ...utenti]}

                        />
                        <FormText color="muted">
                            Destinatari notifica creazione ordine
                        </FormText>

                    </FormGroup>

                    <FormGroup>
                        <Label for="superiori">Notifica firma ordine</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona superiori"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={orderSignNotification}
                            onChange={value =>
                                setOrderSignNotification(value)
                            }
                            getOptionLabel={utente => utente.username}
                            getOptionValue={utente => utente.id}
                            options={[{
                                value: "",
                                description: "Destinatari",
                                isDisabled: true
                            }, ...utenti]}

                        />
                        <FormText color="muted">
                            Destinatari notifica firma ordine
                        </FormText>

                    </FormGroup>


                    <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setContract(value)
                            }
                            options={[{
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce il settore
                        </FormText>
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il lotto.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaSettore}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
