import {Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Col} from "reactstrap";
import React, {useState, useEffect} from "react";
import * as Commands from '../redux/analytics.commands'
import {CSSTransition} from "react-transition-group";
import Select from "react-select";
import DateRangePickerWrapper from "components/DateRangePicker/DateRangePickerWrapper";
import {useSelector} from "react-redux";
import {
    GetSectorsForRoles,
    isAdmin, isAdminCoop,
    RUOLO_AMMINISTRATORE, RUOLO_COGECO_RAI,
    RUOLO_SUPERVISORE_RAI
} from "views/rai/utenti/components/costants";
import moment from 'moment';
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import {b64toBlob} from "utilities/report";

export default function ReportAnalyticsFilter(props) {

    const [submitError, setSubmitError] = useState(false);
    const [errorMessagge] = useState(false);
    const [loading, setLoading] = useState(false);
    const [settore, setSettore] = useState();

    const [dates, setDates] = useState({
        startDate: new moment(),
        endDate: new moment()
    });

    const settori = useSelector(state => state.raiSettori.data).filter(sett => {
        if (isAdmin() || isAdminCoop()) return true;
        const writePermissionSector = GetSectorsForRoles([RUOLO_SUPERVISORE_RAI, RUOLO_COGECO_RAI]);
        const res = writePermissionSector.some(s => s.sector.id === sett.id);
        return res;
    });

    const downloadReportNew = async () => {

        setLoading(true);

        const startDateClone = dates.startDate.clone();
        let endDateClone = dates.endDate.clone();
        startDateClone.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        endDateClone.set({hour: 23, minute: 59, second: 59, millisecond: 0});

        let tipologia = (props.rptFilter && props.rptFilter.tipologia) ? props.rptFilter.tipologia : [];
        let rptData = props.rptData ? props.rptData : null;
        rptData = JSON.stringify(rptData);

        const sectorsIds = settore && settore.map((sector) => {
            return sector.id
        });

        const res = await Commands.GetReportPdf(props.rptName, startDateClone.unix(), endDateClone.unix(), sectorsIds, tipologia, rptData)
        setLoading(false);

        if (res.error) {
            ShowConfirmationDialog({
                title: "Errore durante la creazione del report",
                text: res.message,
                canEscapeKeyCancel: true
            });
            return;
        }

        let extension = ".pdf"

        if (props.rptName.toLowerCase().includes("tsv")) extension = ".tsv"
        if (props.rptName.toLowerCase().includes("xls")) extension = ".xls"
        if (props.rptName.toLowerCase().includes("xlsx")) extension = ".xlsx"

        if (props.rptName.toLowerCase().includes("micheals")) extension = ".zip"

        const element = document.createElement("a");
        let file;
        file = res.data;
        element.href = res.data
        element.download = props.rptName + extension;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        clearForm();
        props.toggle();


    };

    const clearForm = () => {
    };

    useEffect(() => {
        if (submitError) {

            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Esportazione report</h6>
            </div>
            <ModalBody>
                {
                    props.rptData && props.rptData.length > 0 ?
                        <text>Numero ordini selezionati: {props.rptData.length}</text>
                        :
                        <form onSubmit={e => {
                            e.preventDefault();
                            downloadReportNew();
                        }}>
                            <FormGroup>
                                <Label for="paymentDate">Periodo</Label>
                                <DateRangePickerWrapper
                                    disabled={false}
                                    initialStartDate={dates.startDate} // momentPropTypes.momentObj or null,
                                    startDateId="startDate" // PropTypes.string.isRequired,
                                    initialEndDate={dates.endDate} // momentPropTypes.momentObj or null,
                                    endDateId="endDate" // PropTypes.string.isRequired,
                                    small
                                    enableOutsideDays={true}
                                    startDatePlaceholderText={"Data inizio"}
                                    endDatePlaceholderText={"Data fine"}
                                    showDefaultInputIcon
                                    isOutsideRange={() => false}
                                    onChange={(startDate, endDate) => setDates({startDate, endDate})}
                                />
                            </FormGroup>
                            <FormGroup className={"row"}>
                                <Col xs={3}><Label className="col-form-label" for="lotto">Settore *</Label></Col>
                                <Col xs={9}><Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    multiple={true}
                                    isMulti
                                    value={settore}
                                    closeMenuOnSelect={false}
                                    getOptionValue={(item) => item.id}
                                    getOptionLabel={(item) => item.description}
                                    onChange={value => {
                                        setSettore(value);
                                    }}
                                    options={settori}
                                    placeholder="Seleziona un settore"
                                /></Col>
                            </FormGroup>

                            {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                            <button style={{display: 'none'}} type="submit"/>
                        </form>
                }
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">{errorMessagge}</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={downloadReportNew}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Esporta'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
