import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux'
import _, {get} from 'lodash';
// For auto registry purpose
import './redux/indirizzi.reducer';
import * as Commands from './redux/indirizzi.commands';
import CreateComponent from "./components/indirizzi.create.component";
import UpdateComponent from "./components/indirizzi.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";

class IndirizziComponent extends Component {

    state = {
        data: [],
        loading: false,
    };

    caricaDati = async () => {
        this.setState({loading: true});
        await Commands.OttieniIndirizzi();
        this.setState({loading: false});
    };


    componentDidMount() {
        this.caricaDati();
    }


    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    };

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    };

    elimina = async () => {
        var res = await Commands.EliminaIndirizzo(this.state.selectedRecordForDelete.id);

        if (res.hasError) {
            alert('Impossibile eliminare');
            return;
        }
        this.toggleDeleteAlert();
    };

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {
        return (
            <>
                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecordForDelete.description", "")}
                        </ReactBSAlert> : null}

                {/*Modal creazione e aggiornamento produzione*/}
                <CreateComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>
                {
                    this.state.showUpdateModal ? <UpdateComponent {...this.state.selectedRecordForUpdate}
                                                                  show={this.state.showUpdateModal}
                                                                  toggle={this.toggleShowUpdateModal}/> : null
                }

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <button className="btn btn-primary " id="nuovoModal"
                                                    onClick={() => this.toggleShowCreateModal()}><i
                                                className="tim-icons icon-simple-add"/>Nuovo luogo di lavoro
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                Crea un nuovo luogo di lavoro<br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Elenco luoghi di lavoro</CardTitle>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: 'Id',
                                                accessor: 'id',
                                                show: false,
                                            },
                                            {
                                                Header: 'Descrizione',
                                                accessor: 'description',
                                            },
                                            {
                                                Header: 'Indirizzo',
                                                accessor: 'street',
                                            },
                                            {
                                                Header: 'Civico',
                                                accessor: 'streetNumber',
                                            },
                                            {
                                                Header: 'Cap',
                                                accessor: 'postalCode',
                                            },
                                            {
                                                Header: 'Città',
                                                accessor: 'city',
                                            },
                                            {
                                                Header: 'Provincia',
                                                accessor: 'province',
                                            }, {
                                                Header: 'Paese',
                                                accessor: 'country',
                                            },
                                            {
                                                Header: 'Contratto',
                                                accessor: 'contract.description',
                                            },
                                            {
                                                Header: 'Società',
                                                accessor: 'company',
                                                show: false,
                                            },
                                            {
                                                Header: 'Nazione',
                                                accessor: 'country',
                                                show: false,
                                            },
                                            {
                                                Header: 'Latitudine',
                                                accessor: 'lat',
                                                show: false,
                                            },
                                            {
                                                Header: 'Posto di lavoro',
                                                accessor: 'isWorkplace',
                                                show: false,
                                            },
                                            {
                                                Header: 'postalCode',
                                                accessor: 'postalCode',
                                                show: false,
                                            },
                                            {
                                                Header: 'Longitudine',
                                                accessor: 'lng',
                                                show: false,
                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false
                                            }


                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    const data = {
        data: get(state, "raiIndirizzi.data", []),
        loading: get(state, "raiIndirizzi.loading", false)
    };


    return data;
};


export default connect(mapStateToProps, () => ({}))(IndirizziComponent)

