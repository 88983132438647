import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect, useRef} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/documentType.commands'
import {CSSTransition} from "react-transition-group";
import Select from "react-select";
import {ruoliSettori} from "views/rai/utenti/components/costants";
import _ from 'lodash';
import TagsInput from "react-tagsinput";
import DocumentTypeMetasComponent from "./documentType.metas.component";


export default function UpdateComponent(props) {


    const initialSelection = props.enableForRole.map((efr) => {
        return _.find(ruoliSettori, function(rs) {
            return rs.value === efr;
        });
    });

    const descrizione = TextInputHook(props.description);
    const [submitError, setSubmitError] = useState(false);
    const [role, setRole] = useState(initialSelection);
    const [extractionRule, setExtractionRule] = useState(props.extractionRule.extractionRule);
    const [regex, setRegex] = useState(_.get(props, "extractionRule.regex", []));
    const [metas, setMetas] = useState(props.metas);
    const [loading, setLoading] = useState(false);
    const [privateType, setPrivateType] = useState(props.private);

    const documentTypeMetasComponentRef = useRef(null);

    const saveDocumentType = async () => {
        setLoading(true);

        const metas = documentTypeMetasComponentRef.current.getMetas();

        const er = {
            extractionRule: extractionRule,
            regex: regex
        };

        const res = await Commands.UpdateDocumentType(props.id, descrizione.getValue(), privateType, role, JSON.stringify(metas), JSON.stringify(er));
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuova tipologia documento</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    saveDocumentType();
                }}>
                    <FormGroup>
                        <Label for="matricola">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserire descrizione..."
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="ruoli">Ruoli abilitati</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona ruoli abilitati alla visualizzazione"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={role}
                            onChange={value =>
                                setRole(value)
                            }
                            getOptionLabel={r => r.description}
                            getOptionValue={r => r.value}
                            options={[{
                                value: "",
                                description: "Ruoli abilitati",
                                isDisabled: true
                            }, ...ruoliSettori]}

                        />

                    </FormGroup>

                    <FormGroup>
                        <Label for="ruoli">Regole estrazione</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Seleziona regole estrazione"
                            closeMenuOnSelect={true}
                            value={extractionRule}
                            onChange={value =>
                                setExtractionRule(value)
                            }
                            getOptionLabel={r => r.description}
                            getOptionValue={r => r.value}
                            options={[
                                {
                                    value: "bpmBonifici",
                                    description: "Bonifici BPM",
                                },
                                {
                                    value: "regex",
                                    description: "Trova regex nel testo",
                                }]
                            }

                        />

                    </FormGroup>
                    <FormGroup>
                        <TagsInput
                            tagProps={{className: 'react-tagsinput-tag info'}}
                            value={regex}
                            onChange={value =>
                                setRegex(value)
                            }
                            name="regex"
                            id="regex"
                            inputProps={{placeholder: 'Nuova regex'}}
                        />

                    </FormGroup>
                    <FormGroup>
                        <DocumentTypeMetasComponent
                            ref={documentTypeMetasComponentRef}
                            metas={metas}>

                        </DocumentTypeMetasComponent>
                    </FormGroup>

                    <FormGroup style={{marginTop: 20}} check>
                        <Label check>
                            <Input checked={privateType}
                                   onChange={() => setPrivateType(!privateType)}
                                   type="checkbox"/>{' '}
                            Tipologia privata
                            <span className="form-check-sign">
                <span className="check"></span>
                </span>
                        </Label>
                    </FormGroup>


                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare il lotto.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={saveDocumentType}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
