import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './ordini.types';
import * as actions from './ordini.actions';
import * as _ from 'lodash';

export function CreaOrdineRicorrente(contratto, operatoreRichiedente, operatoreApprovante, lotto, tipologia, servizio, produzione, luogo, uorg, dataInizio, oraInizio, oraFine, oreRichieste, qtaModulo, note, selectedDays = [], settore, studio, orderType, luogoPartenza, luogoArrivo, kilometri) {

    try {
        let qtaOrdine = qtaModulo; // Possono essere le ore oppure la qta moduli ( se servizio è modulo )
        if (tipologia.description.toLowerCase() !== 'modulo') {
            qtaOrdine = oreRichieste;
        }

        if (tipologia.description.toLowerCase() === 'trasporto') {
            qtaOrdine = qtaModulo;
        }

        if (tipologia.description.toLowerCase() === 'modulo-trasporto') {
            qtaOrdine = qtaModulo;
        }

        const startTime = oraInizio.getTime() / 1000 | 0;
        const endTime = oraFine.getTime() / 1000 | 0;

        const companyID = _.get(contratto, "company.id", contratto.companyID);

        var variables = {
            orderHours: oreRichieste,
            orderDate: new Date().getTime() / 1000 | 0, // data in cui è stato effettuato l'ordine
            startTime,
            endTime,
            jsonApplicantUser: JSON.stringify(operatoreRichiedente),
            jsonApprovingUser: JSON.stringify(operatoreApprovante),
            jsonArticle: JSON.stringify(servizio),
            jsonLot: JSON.stringify(lotto),
            type: tipologia.description,
            jsonProduction: JSON.stringify(produzione),
            jsonWorkPlace: JSON.stringify(luogo),
            contractID: contratto.id,
            companyID,
            assignmentState: 'not_assigned',
            workerNumber: oreRichieste,
            orderQty: qtaOrdine,
            notes: note,
            uorg,
            hours: Math.abs(endTime - startTime) / 3600,
            selectedDays: selectedDays.map(el => el.getTime() / 1000),
            sector: {id: settore.id, description: settore.description},
            study: studio && {id: studio.id, name: studio.name},
            orderType,
            luogoPartenza,
            luogoArrivo,
            kilometri
        };
    } catch (e) {
        return {hasError: true}
    }

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create($study: StudyInput,$sector:SectorInput, $endTime: Int, $orderNumber: Int, $jsonApplicantUser: String, $jsonProduction: String, $orderDate: Int, $companyID: String, $contractID: String, $startTime: Int, $jsonWorkPlace: String, $jsonArticle: String, $notes: String, $jsonApprovingUser: String, $type: String, $orderHours: Int, $orderQty: Int, $jsonLot: String, $uorg: UorgInput, $workerNumber: Int,
            $selectedDays:[Int],
            $orderType: String,
            $luogoPartenza: String,
            $luogoArrivo: String,
            $kilometri: Int) {
            createdObject: createRaiRecurrentOrder(study:$study,sector: $sector,endTime: $endTime, orderNumber: $orderNumber,
                jsonApplicantUser: $jsonApplicantUser,
                orderDate: $orderDate,
                contractID: $contractID,
                companyID: $companyID,
                startTime: $startTime,
                jsonWorkPlace: $jsonWorkPlace,
                jsonArticle: $jsonArticle,
                notes: $notes,
                jsonApprovingUser: $jsonApprovingUser,
                type: $type, orderQty: $orderQty,
                orderHours: $orderHours,
                jsonLot: $jsonLot,
                jsonProduction: $jsonProduction,
                uorg: $uorg,
                workerNumber: $workerNumber,
                selectedDays: $selectedDays,
                orderType: $orderType,
                luogoPartenza: $luogoPartenza,
                luogoArrivo: $luogoArrivo,
                kilometri: $kilometri
            ) {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                study {
                    id
                    name
                }
                sector {
                    id
                    description
                }
                orderDate
                id
                uorg{
                    description
                    id
                }
                startDate
                cancelledNumber
                state
                number
                orderType
                contractID
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                }
            }
        }`
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            data.map(order => store.dispatch(actions.created(order)));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = "Impossibile creare l'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}


export function OttieniOrdine(id) {

    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($id:ID){
            resQuery :
            order(id:$id) {
                id

                assignments {
                    id
                    endTime
                    notes
                    number
                    jsonWorker
                    workerID
                    periodID
                    startTime
                    state
                    jsonExtra
                    accepted
                    acceptedOn
                    refusedReason
                    smsState
                    auto
                }
            }
        }
        `,
    };

    return Client
        .query(query).then(
            async response => {
                var data = response.data.resQuery;

                return {
                    hasError: false,
                    data: data
                }

            }
        ).catch(
            e => {
                var message = "Impossibile ottenere informazioni sull'ordine"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function OttieniOrdineCompleto(id) {

    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($id:ID){
            resQuery :
            order(id:$id) {
                id
                createdOn
                deleted
                cancelledNumber
                endDate
                jsonExtra
                notes
                study {
                    id
                    name
                }
                sector {
                    id
                    description
                }
                orderDate
                id
                uorg{
                    description
                    id
                }
                startDate
                state
                number
                orderType                contractID
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                    assignments{
                        jsonWorker
                        acceptedOn
                        refusedReason
                        smsState
                        accepted
                    }
                }
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.resQuery;

                return {
                    hasError: false,
                    data: data
                }

            }
        ).catch(
            e => {
                var message = "Impossibile ottenere informazioni sull'ordine"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export async function OttieniOrdineAggiornato(id) {
    const res = await OttieniOrdineCompleto(id);
    if (res.error) {
        return
    }

    store.dispatch(actions.updated(res.data));
}

export function OttieniHistoryOrdine(id) {

    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($id:ID){
            resQuery :
            orderHistorys(orderID:$id) {
                assignementId,
                createdOn,
                orderId,
                event,
                eventDescription,
                periodId,
                userId,
                userName
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.resQuery;

                return {
                    hasError: false,
                    data: data
                }

            }
        ).catch(
            e => {
                var message = "Impossibile ottenere informazioni sull'ordine"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function OttieniOrdini(startDate, endDate, fullText) {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {
        startDate,
        endDate,
        fullText
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($startDate: UnixDate, $endDate: UnixDate, $fullText: String){
            objectList : orders(startDate: $startDate, endDate: $endDate, fullText: $fullText)
            {
                createdOn
                cancelledNumber
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                study {
                    id
                    name
                }
                uorg{
                    description
                    id
                }
                startDate
                state
                number
                orderType
                contractID
                sector {
                    id
                    description
                }
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                    assignments{
                        jsonWorker
                        acceptedOn
                        refusedReason
                        smsState
                        accepted
                        jsonExtra
                    }
                }

            }
        }`,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere gli ordini"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function EliminaProduzione(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteProduction (id: $id) {
                id
                name
                description
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare la produzione';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}

export function ApprovaOrdine(id) {
    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        )
        {
            readedObject: raiApproveOrder(
                id: $id
            )
            {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                study {
                    id
                    name
                }
                uorg{
                    description
                    id
                }
                startDate
                state
                number
                orderType                contractID
                sector {
                    id
                    description
                }
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                    assignments{
                        jsonWorker
                        acceptedOn
                        refusedReason
                        smsState
                        accepted
                    }
                }

            }

        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.changedState(data));

            return {
                hasError: false
            };
        })
        .catch((e) => {
            var message = "Impossibile approvare l'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                error: true, message
            };
        });
}

export function ApprovaOrdineDaMail(token) {
    var variables = {
        token
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $token: String
        )
        {
            readedObject: raiApproveFromMail(token:$token)
        }`
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            return {
                data,
                error: false
            };
        })
        .catch((e) => {
            var message = "Impossibile approvare l'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                error: true, message
            };
        });
}

export function RifiutaOrdine(id) {
    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String
        )
        {
            readedObject: raiRejectOrder(
                id: $id
            )
            {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                startDate
                state
                study {
                    id
                    name
                }
                uorg{
                    description
                    id
                }
                number
                orderType                contractID
                periods
                {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.changedState(data));


            return {
                hasError: false
            };
        })
        .catch((e) => {
            let message = "Impossibile rifiutare l'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            // console.log(message)

            return {
                error: true
            };
        });
}

export function EliminaOrdine(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteOrder (id: $id) {
                id
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            };
        })
        .catch((e) => {
            var message = "Impossibile eliminare l'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message
            };
        });
}

export function FirmaOrdine(id, assegnazioni, rating, utenteFirmatario, firma, note, correttaEsecuzione) {

    let errorMessage = ""
    // Controlli vari e fix orario
    if (!utenteFirmatario) {
        errorMessage = "Non è stato selezionato un utente firmatario!"
        return {
            hasError: true,
            message: errorMessage
        };
    }


// 1. Controllare che lo straordinario sia multiplo di 1h e se oraFine < oraInizio aggiungere 24h
    let assNonValida = false;
    assegnazioni.forEach(ass => {

        if (ass.endTime < ass.startTime) {
            // const newDate = moment.unix(ass.endTime).add(1, 'days')
            ass.endTime += 60 * 60 * 24;
        }

        if ((ass.endTime - ass.startTime) % 3600 !== 0) {
            assNonValida = true;
            errorMessage = "Lo straordinario deve essere multiplo di 1 ora. ( 1 ora, 2 ore, 3 ore ecc...)"
        }

        if (ass.endTime < ass.startTime) {
            assNonValida = true;
            errorMessage = "L'oraio di fine non può essere nel passato"
        }


    });

    if (assNonValida) {
        return {
            hasError: true,
            message: errorMessage
        };
    }

    var variables = {
        id,
        assegnazioni: JSON.stringify(assegnazioni),
        rating,
        utenteFirmatario: utenteFirmatario.id,
        firma,
        note,
        correttaEsecuzione
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
            $assegnazioni: String,
            $firma: String,
            $utenteFirmatario: String,
            $note: String,
            $correttaEsecuzione: Boolean,
            $rating: Int
        ){
            updatedObject: firmaOrdineRai(
                id: $id,
                assegnazioni: $assegnazioni,
                firma: $firma,
                utenteFirmatario: $utenteFirmatario,
                rating: $rating,
                note: $note,
                correttaEsecuzione: $correttaEsecuzione
            )
            {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                study {
                    id
                    name
                }
                uorg{
                    description
                    id
                }
                startDate
                state
                number
                orderType                        contractID
                sector {
                    id
                    description
                }
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                    assignments{
                        jsonWorker
                        acceptedOn
                        refusedReason
                        smsState
                        accepted
                    }
                }

            }

        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;
            store.dispatch(actions.changedState(data));

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile firmare l\'ordine';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}


export function AnteprimaFirmaOrdine(id, assegnazioni, rating, utenteFirmatario, firma, note, correttaEsecuzione) {

    let errorMessage = ""
    // Controlli vari e fix orario
    if (!utenteFirmatario) {
        errorMessage = "Non è stato selezionato un utente firmatario!"
        return {
            hasError: true,
            message: errorMessage
        };
    }


// 1. Controllare che lo straordinario sia multiplo di 1h e se oraFine < oraInizio aggiungere 24h
    let assNonValida = false;
    assegnazioni.forEach(ass => {

        if (ass.endTime < ass.startTime) {
            // const newDate = moment.unix(ass.endTime).add(1, 'days')
            ass.endTime += 60 * 60 * 24;
        }

        if ((ass.endTime - ass.startTime) % 3600 !== 0) {
            assNonValida = true;
            errorMessage = "Lo straordinario deve essere multiplo di 1 ora. ( 1 ora, 2 ore, 3 ore ecc...)"
        }

        if (ass.endTime < ass.startTime) {
            assNonValida = true;
            errorMessage = "L'oraio di fine non può essere nel passato"
        }


    });

    if (assNonValida) {
        return {
            hasError: true,
            message: errorMessage
        };
    }

    var variables = {
        id,
        assegnazioni: JSON.stringify(assegnazioni),
        rating,
        utenteFirmatario: utenteFirmatario.id,
        firma,
        note,
        correttaEsecuzione
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
            $assegnazioni: String,
            $firma: String,
            $utenteFirmatario: String,
            $note: String,
            $correttaEsecuzione: Boolean,
            $rating: Int
        ){
            updatedObject: anteprimaFirmaOrdineRai(
                id: $id,
                assegnazioni: $assegnazioni,
                firma: $firma,
                utenteFirmatario: $utenteFirmatario,
                rating: $rating,
                note: $note,
                correttaEsecuzione: $correttaEsecuzione
            )
            {
                id
                straordinari {
                    startTime
                    endTime
                    workers

                    workersName
                    articleName
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile firmare l\'ordine';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}


export function InviaSmsFirma(id) {
    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String
        )
        {
            readedObject: inviaSmsFirma(
                id: $id
            )
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            // var data = response.data;
            return {
                hasError: false
            };
        })
        .catch((e) => {

            var message = "Impossibile inviare l'sms per la firma dell'ordine";

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message
            };
        });
}

export function OttieniOrdineDaSms(token) {

    var variables = {
        token
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($token:String){
            resQuery :
            ottieniOrdineDaSms(token:$token) {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                startDate
                study {
                    id
                    name
                }
                state
                sector {
                    id
                    description
                }
                uorg{
                    description
                    id
                }
                number
                orderType                contractID
                periods
                {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                }
                assignments {
                    id
                    endTime
                    jsonWorker
                    workerID
                    periodID
                    startTime
                    state
                    jsonExtra
                    auto
                }
            }
        }
        `,
    };

    return Client
        .query(query).then(
            async response => {
                var data = response.data.resQuery;

                return {
                    hasError: false,
                    data: data
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere informazioni sull'ordine"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function OttieniAssegnazioneDaSms(token) {

    var variables = {
        token
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($token:String){
            resQuery :
            ottieniAssegnazioneDaSms(token:$token) {
                id
                endTime
                startTime
                state
                jsonExtra
                workerID
                jsonWorker
                accepted
                acceptedOn
                notes
                order {
                    id
                    notes
                }
            }
        }
        `,
    };

    return Client
        .query(query).then(
            async response => {
                var data = response.data.resQuery;

                return {
                    hasError: false,
                    data: data
                }

            }
        ).catch(
            e => {
                var message = "Impossibile ottenere informazioni sull'ordine"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}


export function ControlloEsistenzaOrdiniSimili(selectedDays, startTimeMs, endTimeMs, production) {

    const startTime = startTimeMs.getTime() / 1000 | 0;
    const endTime = endTimeMs.getTime() / 1000 | 0;

    var variables = {
        startTime,
        endTime,
        production,
        selectedDays: selectedDays.map(el => el.getTime() / 1000)
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($selectedDays: [Int], $startTime: Int, $endTime: Int, $production: String){
            objectList : checkForSimilarOrders(selectedDays: $selectedDays,startTime: $startTime, endTime: $endTime, production: $production)
            {
                endDate
                number
                orderType            jsonExtra
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                }
            }
        }`,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.objectList;

                return {
                    hasError: false,
                    data: data
                }
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere gli ordini"
                console.error("@@@ ERR", e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message
                }
            }
        );
}

export function InviaSmsAccettazione(ids) {

    var variables = {
        ids
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query inviaSmsAccettazione($ids:[String]){
            count : inviaSmsAccettazione(ids:$ids)
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {
                var count = response.data.count;

                return {
                    hasError: false,
                    data: count
                }

            }
        ).catch(
            e => {
                var message = "Impossibile inviare i messaggi"
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AnnullaFirmaOrdine(id, motivo) {

    var variables = {
        id,
        motivo
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String,
            $motivo: String
        )
        {
            readedObject: annullaFirmaOrdine(
                id: $id,
                motivo: $motivo
            )
            {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                study {
                    id
                    name
                }
                uorg{
                    description
                    id
                }
                startDate
                state
                number
                orderType
                contractID
                sector {
                    id
                    description
                }
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                    assignments{
                        jsonWorker
                        acceptedOn
                        refusedReason
                        smsState
                        accepted
                    }
                }

            }

        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.changedState(data));

            return {
                hasError: false
            };
        })
        .catch((e) => {
            var message = "Impossibile approvare l'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                error: true, message
            };
        });
}


export function ConvalidaOrdiniDaMail(token) {
    var variables = {
        token
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $token: String
        )
        {
            readedObject: raiConvalidaOrdini(token:$token)
        }`
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            return {
                data,
                error: false
            };
        })
        .catch((e) => {
            var message = "Impossibile procedere";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                error: true, message
            };
        });
}

export function FirmaOrdiniMail(token, firma) {

    var variables = {
        token,
        firma,
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $token: String!,
            $firma: String,
        ){
            firmaOrdiniRai(
                token: $token,
                firma: $firma
            )
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data;

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile firmare l\'ordine';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}


export function PlannedOrdersApproved(startTime, endTime, sectorId) {

    var variables = {
        startTime,
        endTime,
        sectorId
    };

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read($startTime: Int!, $endTime: Int!, $sectorId: String!){
            plannedOrdersApproved : plannedOrdersApproved(startTime: $startTime, endTime: $endTime, sectorId: $sectorId)
        }`,
    };

    return Client
        .query(query).then(
            async response => {
                return response.data.plannedOrdersApproved;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere plannedOrdersApproved"
                console.error(e);
                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function OttieniImmagineFirma(id) {
    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        query: gql`,
        query (
            $id: String
        )
        {
            readedObject: getOrderSign(
                id: $id
            )
        }
        `
    };

    return Client
        .query(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            return {
                data,
                hasError: false
            };
        })
        .catch((e) => {
            var message = "Impossibile ottenere la firma dell'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true, message
            };
        });
}

export function CambioNumeroProvvisorio(id, cancelledNumber) {

    var variables = {
        id,
        cancelledNumber
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String,
            $cancelledNumber: String
        )
        {
            readedObject: setCancelledNumber(
                id: $id,
                cancelledNumber: $cancelledNumber
            )
            {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                cancelledNumber
                id
                study {
                    id
                    name
                }
                uorg{
                    description
                    id
                }
                startDate
                state
                number
                orderType
                contractID
                sector {
                    id
                    description
                }
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                    assignments{
                        jsonWorker
                        acceptedDeviceUserAgent
                        acceptedOn
                        refusedReason
                        smsState
                        accepted
                    }
                }

            }

        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.changedState(data));

            return {
                hasError: false
            };
        })
        .catch((e) => {
            var message = "Impossibile cambiare il numero provvisorio";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                error: true, message
            };
        });
}


export function CancellaApprovazioneOrdine(id) {
    var variables = {
        id
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String
        )
        {
            readedObject: raiCancelApproveOrder(
                id: $id
            )
            {
                createdOn
                deleted
                endDate
                jsonExtra
                notes
                orderDate
                id
                study {
                    id
                    name
                }
                uorg{
                    description
                    id
                }
                startDate
                state
                number
                orderType                contractID
                sector {
                    id
                    description
                }
                periods {
                    endTime
                    jsonArticle
                    jsonWorkPlace
                    id
                    startTime
                    workers
                    totalHours
                    totalAssignedHours
                    assignments{
                        jsonWorker
                        acceptedOn
                        refusedReason
                        smsState
                        accepted
                    }
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.readedObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.changedState(data));

            return {
                hasError: false
            };
        })
        .catch((e) => {
            var message = "Impossibile cancellare l'approvazione delll'ordine";
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                error: true, message
            };
        });
}
