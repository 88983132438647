import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './email.types';


export function SendMail(startDate, endDate, mail, recipientsEmails, sectors) {

    var variables = {
        startDate,
        endDate,
        mail,
        recipientsEmails,
        sectors
    }


    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create (
            $mail: String!
            $recipientsEmails: [UserEmailType]!
            $sectors: [SectorInput]!
            $startDate: Int!
            $endDate: Int!
        ) {
            createdObject: sendScheduledEmail (
                startDate: $startDate,
                endDate: $endDate
                mailNumber: $mail,
                recipientsEmails: $recipientsEmails,
                sectors: $sectors,
            ) {
                mailNumber
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            return {
                hasError: false
            }
        })
        .catch((e) => {
            let message = 'Impossibile mandare mail';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });

}
