import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Button, UncontrolledTooltip
} from "reactstrap";
import classNames from "classnames";
import {connect} from 'react-redux'
import {get} from 'lodash';
import DateRangePickerWrapper from 'components/DateRangePicker/DateRangePickerWrapper';
import * as Commands from './redux/exportDb.commands';
import moment from 'moment';
import {RUOLO_AMMINISTRATORE} from "../utenti/components/costants";

class ExportDbComponent extends Component {


    constructor(props) {
        super(props);

        this.state = {
            showCreateModal: false,
            showUpdateModal: false,
            loading: false,
            selectedRecordForUpdate: {},
            showDeleteAlert: false,
            selectedRecordForDelete: {},
            startDate: new moment(),
            endDate: new moment(),
            initialStartDate: new moment(),
            initialEndDate: new moment(),
            fullText: ""
        }
    }


    onDatesChanged(startDate, endDate) {
        this.setState(
            {
                startDate,
                endDate
            }, () => {
                this.caricaDati(startDate, endDate, this.state.fullText)
            });
    }

    caricaDati = async (startDate, endDate, fullText) => {
        const startDateClone = startDate.clone();
        const endDateClone = endDate.clone();

        this.setState({loading: true});
        await Commands.Ottieni(startDateClone.format("YYYY-MM-DD"), endDateClone.add(1, 'days').format("YYYY-MM-DD"), fullText);
        this.setState({loading: false});
    }


    componentDidMount() {
        this.caricaDati(this.state.initialStartDate, this.state.initialEndDate, this.state.fullText);
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }


    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {
        return (
            <>
                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col xs={10} sm={10} lg={4}>
                                            <DateRangePickerWrapper
                                                disabled={false}
                                                initialStartDate={this.state.initialStartDate} // momentPropTypes.momentObj or null,
                                                startDateId="startDate" // PropTypes.string.isRequired,
                                                initialEndDate={this.state.initialEndDate} // momentPropTypes.momentObj or null,
                                                endDateId="endDate" // PropTypes.string.isRequired,
                                                small
                                                enableOutsideDays={true}
                                                startDatePlaceholderText={"Data inizio"}
                                                endDatePlaceholderText={"Data fine"}
                                                showDefaultInputIcon
                                                isOutsideRange={() => false}
                                                onChange={(startDate, endDate) => this.onDatesChanged(startDate, endDate)}
                                            />
                                        </Col>

                                            <Col className="d-flex justify-content-end">
                                                <button className="btn btn-primary " id="nuovoModal"
                                                        onClick={() => this.toggleShowCreateModal()}><i
                                                    className="tim-icons icon-simple-add"/>Esporta DB
                                                </button>
                                                <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                    Esporta DB<br/> ( Hotkey F11)
                                                </UncontrolledTooltip>

                                            </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    let userAdmin = false;
    let userRole = get(state, "login.user.role", "-");

    if (userRole.toLowerCase() === RUOLO_AMMINISTRATORE) {
        userAdmin = true
    }


    const data = {
        data: get(state, "analytics.data", []),
        loading: get(state, "analytics.loading", false),
        userAdmin
    };

    return data;
};

export default connect(mapStateToProps, () => ({}))(ExportDbComponent)

