import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './indirizzi.types';
import * as actions from './indirizzi.actions';

export function SalvaIndirizzo(descrizione, indirizzo, civico, cap, citta, provincia, contracId, customerId, nazione) {

    var variables = {
        description: descrizione,
        street: indirizzo,
        streetNumber: civico,
        postalCode: cap,
        city: citta,
        contractID: contracId,
        customerID: customerId,
        country: nazione,
        province: provincia
    };

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation create (
            $description: String!
            $country: String!
            $street: String!
            $province: String!
            $postalCode: String!
            $city: String!
            $streetNumber: String!
            $contractID: String!
        ) {
            createdObject: createAddress (
                description: $description,
                country: $country,
                street: $street,
                province: $province,
                postalCode: $postalCode,
                city: $city,
                streetNumber: $streetNumber,
                contractID: $contractID
            ) {
                id
                postalCode
                city
                country
                company
                description
                streetNumber
                street
                province
                contract
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false,
                data
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare il lotto';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniIndirizzi() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList: addresses {
                city
                company
                contract
                country
                formattedAdress
                description
                id
                lat
                isWorkplace
                postalCode
                province
                lng
                streetNumber
                street
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {
                var data = response.data.objectList;
                //

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere le performance";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AggiornaIndirizzo(id, descrizione, indirizzo, civico, cap, citta, contracId, customerId, nazione) {

    var variables = {
        id: id,
        description: descrizione,
        street: indirizzo,
        streetNumber: civico,
        postalCode: cap,
        city: citta,
        contractID: contracId,
        customerID: customerId,
        country: nazione
    };



    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update (
            $id: String!
            $description: String
            $country: String
            $street: String
            $postalCode: String
            $city: String
            $streetNumber: String
            $contractID: String
        ) {
            updatedObject: updateAddress(
                id: $id,
                description: $description,
                country: $country,
                street: $street,
                postalCode: $postalCode,
                city: $city,
                streetNumber: $streetNumber,
                contractID: $contractID
            ) {
                id
                postalCode
                city
                country
                company
                province
                street
                description
                streetNumber
                contract
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare il luogo di lavoro';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaIndirizzo(id) {
    var variables = {id};


    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation delete (
            $id: String!
        ) {
            deleted: deleteAddress (id: $id) {
                id
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));


    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il luogo';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
