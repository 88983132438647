import * as types from "./lavoratori.types";

const initialState = {
    data: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST_READ_ALL:
            state = {
                ...state,
                loading: true
            };
            break;
        case types.READ_ALL_ERROR: {
            state = {
                ...state,
                loading: false,
                data:  []
            };
            break
        }
        case types.READED_ALL:
            state = {
                ...state,
                data: action.payload,
                loading: false
            };
            break;
        case types.CREATED:
            state = {
                ...state,
                data: [
                    ...state.data,
                    action.payload
                ]
            };
            break;
        case types.DELETED: {
            let data = [...state.data];
            if (data) {
                for (let i = 0; i < data.length; i++)
                    if (data[i].id === action.payload.id) {
                        data.splice(i, 1);
                        break;
                    }
            }
            state = {
                ...state,
                data:data
            };
            break;
        }
        case types.REQUEST_DELETE: {
            let data = [...state.data];

            const index = data.findIndex(item => item.id === action.payload);

            if (index === -1){
                break
            }

            data =  [
                ...data.slice(0, index), // everything before current post
                {
                    ...data[index],
                    _deleting: true,
                },
                ...data.slice(index + 1), // everything after current post
            ];
            state = {
                ...state,
                data:data
            };
            break;
        }
        case types.DELETE_ERROR: {
            let data = [...state.data];
            if (data) {
                const index = data.findIndex(item => item.id === action.payload);
                if (index === -1){
                    break
                }
                data =  [
                    ...data.slice(0, index), // everything before current post
                    {
                        ...data[index],
                        _deleting: false,
                    },
                    ...data.slice(index + 1), // everything after current post
                ]
            }
            state = {
                ...state,
                data:data
            };
            break;
        }

        case types.UPDATED: {
            let data = [...state.data];
            if (data) {
                for (let i = 0; i < data.length; i++)
                    if (data[i].id === action.payload.id) {
                        data[i] = action.payload;
                        break;
                    }
            }
            state = {
                ...state,
                data:data
            };
            break;
        }

        case types.ABS_REQUEST_READ_ALL: {
            state = {
                ...state,
                loading: true
            };
            break;
        }

        case types.ABS_READED_ALL: {
            state = {
                ...state,
                absences: action.payload,
                loading: false
            };
            break;
        }

        case types.ABS_REQUEST_CREATE: {
            state = {
                ...state,
                loading: true
            };
            break;
        }

        case types.ABS_CREATED: {
            state = {
                ...state,
                absences: [
                    ...state.absences,
                    action.payload
                    ]
            };
            break;
        }

        case types.ABS_REQUEST_DELETE: {
            state = {
                ...state,
                loading: true
            };
            break;
        }

        case types.ABS_DELETED: {
            let absences = [...state.absences];
            if (absences) {
                for (let i = 0; i < absences.length; i++)
                    if (absences[i].id === action.payload.id) {
                        absences.splice(i, 1);
                        break;
                    }
            }
            state = {
                ...state,
                absences:absences
            };
            break;
        }

        default:
            break;
    }

    return state;
};

export default reducer;
