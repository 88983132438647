import React, { Component } from 'react';
import Presentation from './presentation';
import  * as Commands from '../geomezzi.commands';

class OrariLavoratoriContainer extends Component {

	constructor(props) {
		super(props);
		this.state ={data:[]};
	};

	caricaDati = async () => {

		this.setState({loading: true});
		const res = await Commands.OttieniPosizioneMezzi();
		this.setState({loading: false});

		if (res.hasError){
			return;
		}

		let newData = [];

		for (let mezzo of res.data){
			let lat = mezzo.latitude;
			let long = mezzo.longitude;
			let matricola = mezzo.profile;

			newData.push(
				{
					key: mezzo.id,
					lat: lat,
					lng: long,
					matricola
				}
			);
		}

		this.setState({data: newData})
	};

	componentDidMount() {
		this.caricaDati()
	}

	render() {
		return <Presentation {...this.props} markers={this.state.data}  />;
	}
}

export default OrariLavoratoriContainer;
