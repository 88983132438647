import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import React, {useState, useRef} from "react";
import {CSSTransition} from "react-transition-group";
import * as Commands from '../redux/ordini.commands'
import SignaturePad from 'react-signature-pad-wrapper'

import 'react-day-picker/lib/style.css';


export default function FirmaOrdiniMailModal(props) {

    const [submitError, setSubmitError] = useState(false);
    const [submitErrorDescr, setSubmitErrorDescr] = useState("");
    const [loading, setLoading] = useState(false);

    const signaturePad = useRef(null);

    const firmaOrdine = async () => {
        setLoading(true)
        let res = await Commands.FirmaOrdiniMail(props.token, signaturePad.current.toDataURL());
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            setSubmitErrorDescr(res.message);
            return;
        }
        ;

        props.toggle(true);
    };

    return (

        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Firma ordini</h6>

            </div>
            <ModalBody>


                <>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            firmaOrdine();
                        }}>

                        <SignaturePad ref={signaturePad} redrawOnResize={true} options={{backgroundColor: "white"}}/>
                    </form>

                    <CSSTransition
                        in={submitError}
                        appear={true}
                        unmountOnExit
                        classNames="error"
                        timeout={300}>
                        <blockquote className="blockquote blockquote-danger mt-2 mb-2">
                            <p className="mb-0 text-danger">Errore: {submitErrorDescr}</p>
                        </blockquote>
                    </CSSTransition>
                </>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                        firmaOrdine();
                    }}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
