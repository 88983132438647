import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './settori.types';
import * as actions from './settori.actions';

export function SalvaSettore(description, contractid, officials,orderCreationNotification, orderSignNotification) {

    officials.forEach(item => {
        item.jsonextra = JSON.stringify(item.jsonextra);
        delete item.__typename;
    });

    var variables = {description, contractid, officials, orderCreationNotification, orderSignNotification};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
            mutation create (
            $officials: [AuthUserInputType]!
            $description: String
            $contractid: String!
            $orderCreationNotification: [String!]
            $orderSignNotification: [String!]
        ) {
            createdObject: createSector (
                officials: $officials,
                description: $description,
                contractid: $contractid,
                orderCreationNotification: $orderCreationNotification,
                orderSignNotification: $orderSignNotification
            ) {
                id
                officials {
                    username
                }
                description
                orderCreationNotification
                orderSignNotification
                contract {
                    id
                    description
                    startDate
                    endDate
                    company
                } 
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch(actions.created(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile creare il settore';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniSettori() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        {
            objectList : getSectors {
                id
                      officials {
                    username
                }
                description
                orderCreationNotification
                orderSignNotification
                contract {
                    description
                    id
                }
            }
        }        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;



                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere gli UORG";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function AggiornaSettore(id, description, contractid, officials,orderCreationNotification, orderSignNotification) {

    officials.forEach(item => {
        item.jsonextra = JSON.stringify(item.jsonextra);
        delete item.__typename;
    });

    var variables = {id,description, contractid, officials,orderCreationNotification, orderSignNotification};


    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation update(
            $id: String!,
          $officials: [AuthUserInputType]!,
            $description: String,
            $contractid: String,
            $orderCreationNotification: [String!],
            $orderSignNotification: [String!]
        ){
            updatedObject: updateSector(
                id: $id,
                officials: $officials,
                description: $description,
                contractid: $contractid,
                orderCreationNotification: $orderCreationNotification,
                orderSignNotification: $orderSignNotification
            ) {
                id
                    officials {
                    username
                }
                description
                orderCreationNotification
                orderSignNotification
                contract {
                    description
                    id
                }
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.updatedObject;

            store.dispatch(actions.updated(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile aggiornare lo UORG';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }
            return {
                hasError: true,
                message: message
            }
        });
}

export function EliminaSettore(id) {
    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            deleted: deleteSector (id: $id) {
                id
                description
            }
        }
        `
    };

    store.dispatch(actions.requestDelete(id));

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.deleted;

            store.dispatch(actions.deleted(data));

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare il settore';
            console.error(e);
            store.dispatch(actions.deleteError(id));


            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            }
        });
}
