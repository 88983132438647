import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux'
import {get} from 'lodash';

import './redux/servizi.reducer';
import * as Commands from './redux/servizi.commands';
import CreateProductionComponent from "./components/servizi.create.component";
import UpdateProductionComponent from "./components/servizi.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import {RUOLO_AMMINISTRATORE, RUOLO_AMMINISTRATORE_COOP} from "../utenti/components/costants";
import ReactTableFilterSelectComponent from "../../../components/ReactTableFilter/reactTable.filter.select.component";

class ServiziComponent extends Component {

    state = {
        data: [],
        showCreateModal: false,
        showUpdateModal: false,
        loading: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
        filters: {}
    }

    caricaDati = async () => {
        this.setState({loading: true});
        await Commands.OttieniDati();
        this.setState({loading: false});
    };

    componentDidMount() {
        this.caricaDati();
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }

    elimina = async () => {
        var res = await Commands.EliminaLotto(this.state.selectedRecordForDelete.id);

        if (res.hasError) {
            alert('Impossibile eliminare');
            return;
        }
        this.toggleDeleteAlert();
    }

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {
        return (
            <>
                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            n style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecordForDelete.description", "")}
                        </ReactBSAlert> : null}

                {/*Modal creazione e aggiornamento produzione*/}
                <CreateProductionComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>
                {
                    this.state.showUpdateModal ? <UpdateProductionComponent {...this.state.selectedRecordForUpdate}
                                                                            show={this.state.showUpdateModal}
                                                                            toggle={this.toggleShowUpdateModal}/> : null
                }

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            {!this.props.readOnly && <>
                                                <button className="btn btn-primary " id="nuovoModal"
                                                        onClick={() => this.toggleShowCreateModal()}><i
                                                    className="tim-icons icon-simple-add"/>Nuovo servizio
                                                </button>
                                                <UncontrolledTooltip placement="bottom" target="nuovoModal" delay={0}>
                                                    Crea un nuovo servizio<br/> ( Hotkey F11)
                                                </UncontrolledTooltip>
                                            </>
                                            }
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista servizi</CardTitle>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={true}
                                        columns={[
                                            {
                                                Header: "Tipologia",
                                                accessor: "jsonExtra.type",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.jsonExtra.type);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'jsonExtra.type';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "Descrizione",
                                                accessor: "description",
                                                width: 400,
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.description);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'description';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "Voce",
                                                accessor: "jsonExtra.voce",
                                                width: 300,
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.jsonExtra.voce);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'jsonExtra.voce';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "Lotto",
                                                accessor: "jsonExtra.lot.name",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.jsonExtra.lot.name);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'jsonExtra.lot.name';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }

                                            },
                                            {
                                                Header: "Prezzo di vendita",
                                                accessor: "sellingPrice",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.sellingPrice);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'sellingPrice';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "Prezzo di acquisto",
                                                accessor: "buyingPrice",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.buyingPrice);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'buyingPrice';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "Contratto",
                                                accessor: "jsonExtra.contract.description",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    return filter.value.some(f => f.value === row._original.jsonExtra.contract.description);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'jsonExtra.contract.description';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, [filterName])}
                                                        optionValue={filterName}
                                                        optionLabel={filterName}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            },
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    let readOnly = true;
    let userRole = get(state, "login.user.role", "-");

    if (userRole.toLowerCase() === RUOLO_AMMINISTRATORE || userRole.toLowerCase() === RUOLO_AMMINISTRATORE_COOP) {
        readOnly = false
    }

    const data = {
        data: get(state, "raiServizi.data", []),
        loading: get(state, "raiServizi.loading", false),
        readOnly
    };

    return data;
};


export default connect(mapStateToProps, () => ({}))(ServiziComponent)
