import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux'
import {get} from 'lodash';
// For auto registry purpose
import './redux/utenti.reducer';
import * as Commands from './redux/utenti.commands';


import {
    RUOLO_AMMINISTRATORE,
    isAdmin,
    GetSectorsForRoles,
    RUOLO_FUNZIONARIO_RAI,
    RUOLO_SUPERVISORE_RAI, RUOLO_RICHIEDENTE_RAI, OneOfRole, RUOLO_AMMINISTRATORE_COOP, IsRole
} from "./components/costants";
import CreateProductionComponent from './components/utenti.create.component'
import UpdateUserComponent from "./components/utenti.update.component";
import Fade from "react-reveal/Fade";
import *  as _ from 'lodash';
import ShowConfirmationDialog from "../../../components/ShowConfirmationDialog";

import {
    Tooltip,
} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import ReactTableFilterSelectComponent from "../../../components/ReactTableFilter/reactTable.filter.select.component";
import moment from "moment";

class UtentiComponent extends Component {

    state = {
        data: [],
        showCreateModal: false,
        showUpdateModal: false,
        loading: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
    }

    caricaDati = async () => {
        this.setState({loading: true});
        // eslint-disable-next-line no-undef
        if (isAdmin() || IsRole(RUOLO_AMMINISTRATORE_COOP)) {
            await Commands.OttieniUtenti();
        } else {
            const sectors = GetSectorsForRoles([RUOLO_FUNZIONARIO_RAI]);
            const ids = sectors.map((m) => m.sector.id)
            await Commands.OttieniUtentiBySectors(ids);
        }
        this.setState({loading: false});
    }

    resetPwd = async (utente) => {
        const doAction = await ShowConfirmationDialog({
            title: "Reset password",
            html: <>La password verrà impostata come: <b>123456</b>. Procedere?</>,
            confirmBtnText: 'Si',
            canEscapeKeyCancel: true,
        });

        if (!doAction.confirm) return;

        const res = await Commands.ResetPwd(utente.id);
        if (res.hasError) {
            ShowConfirmationDialog({
                title: "Errore durante il reset della password",
                confirmButtonText: 'Ok!',
                cancelButtonText: 'No',
                canEscapeKeyCancel: true,
                showCancel: false
            });
            return;
        }

        ShowConfirmationDialog({
            title: "Password reimpostata con successo",
            html: <>La nuova password è: <b>123456</b></>,
            cancelButtonText: 'No',
            canEscapeKeyCancel: true,
            showCancel: false
        });
    };

    componentDidMount() {
        this.caricaDati();
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }

    elimina = async (utente) => {
        const doAction = await ShowConfirmationDialog({
            title: "Conferma eliminazione",
            confirmButtonText: 'Si!',
            cancelButtonText: 'No',
            canEscapeKeyCancel: true
        });

        if (doAction.confirm) {
            const res = Commands.EliminaUtente(utente.id);
            if (res.hasError) {
                ShowConfirmationDialog({
                    title: "Errore durante eliminazione utente",
                    confirmButtonText: 'Ok!',
                    cancelButtonText: 'No',
                    canEscapeKeyCancel: true,
                    showCancel: false
                });
            }
        }
    }

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                _.get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }
            <Button
                id={"btnResetPwd" + cellInfo.original.id}
                color="warning"
                size="sm"
                onClick={() => this.resetPwd(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-lock-circle"/>
            </Button>{" "}
            <UncontrolledTooltip placement="bottom" target={"btnResetPwd" + cellInfo.original.id}
                                 delay={0}>
                Resetta password utente
            </UncontrolledTooltip>
            <Button
                id={"btnEditUser" + cellInfo.original.id}
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <UncontrolledTooltip placement="bottom" target={"btnEditUser" + cellInfo.original.id}
                                 delay={0}>
                Modifica
            </UncontrolledTooltip>
            <Button
                id={"btnDeleteUser" + cellInfo.original.id}
                color="danger"
                size="sm"
                onClick={() => this.elimina(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
            <UncontrolledTooltip placement="bottom" target={"btnDeleteUser" + cellInfo.original.id}
                                 delay={0}>
                Elimina
            </UncontrolledTooltip>
        </div>
    );


    actionsPianificazione = (cellInfo) => {
        return <div className="actions-right">
            <Button
                color="warning"
                size="sm"
                onClick={() => this.cambiaPianificazione(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}>
                <i className={_.get(cellInfo, "original.jsonextra.abilitatoPianificazione", false) ? "tim-icons icon-check-2" : "tim-icons icon-simple-delete"}
                />
            </Button>{" "}
        </div>
    };

    cambiaPianificazione = async (cellInfo) => {
        var res = await Commands.AbilitaUtentePianificato(
            cellInfo.id,
            cellInfo.jsonextra,
            !_.get(cellInfo, "jsonextra.abilitatoPianificazione", false)
        );

        // setLoading(false);
        if (res.hasError) {
            // setSubmitError(true);
            return;
        }

    };

    render() {
        return (
            <>

                {/*Modal creazione e aggiornamento produzione*/}
                <CreateProductionComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {
                    this.state.showUpdateModal ? <UpdateUserComponent {...this.state.selectedRecordForUpdate}
                                                                      show={this.state.showUpdateModal}
                                                                      toggle={this.toggleShowUpdateModal}/> : null
                }

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            {
                                                !this.props.readOnly && <>
                                                    <button className="btn btn-primary " id="nuovoModal"
                                                            onClick={() => this.toggleShowCreateModal()}><i
                                                        className="tim-icons icon-simple-add"/>Nuovo utente
                                                    </button>
                                                    <UncontrolledTooltip placement="bottom" target="nuovoModal"
                                                                         delay={0}>
                                                        Crea un nuovo utente<br/> ( Hotkey F11)
                                                    </UncontrolledTooltip>
                                                </>
                                            }
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati();
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista utenti</CardTitle>
                                    <Fade when={this.state.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={true}
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                show: false,
                                            },
                                            {
                                                Header: "Username",
                                                accessor: "username",

                                            },
                                            {
                                                Header: "E-Mail",
                                                accessor: "email",

                                            },
                                            {
                                                Header: "Nome",
                                                Cell: row => {
                                                    return `${_.get(row, "original.jsonextra.nome", "-")}`
                                                }
                                            },
                                            {
                                                Header: "Cognome",
                                                Cell: row => {
                                                    return `${_.get(row, "original.jsonextra.cognome", "-")}`
                                                }
                                            },
                                            {
                                                Header: "Matricola",
                                                Cell: row => {
                                                    return `${_.get(row, "original.jsonextra.matricola", "-")}`
                                                },
                                                show: true,
                                            },
                                            {
                                                Header: "Settori",
                                                id: 'settori',
                                                Cell: row => {
                                                    const settRuoli = _.get(row, "original.jsonextra.sectorsRoles", []);

                                                    return <Tooltip
                                                        // options
                                                        theme={'light'}
                                                        position="bottom"
                                                        trigger="mouseenter"
                                                        delay={500}
                                                        interactive
                                                        html={(
                                                            <div className={"sectorsRoles"}>
                                                                <div>{settRuoli.map(settRuolo =>
                                                                    <div>{settRuolo.sector && settRuolo.sector.description} ({settRuolo.role && settRuolo.role.description})</div>)}</div>
                                                            </div>
                                                        )}
                                                    >
                                                        <div className={"sectorsRolesLabel"}>Elenco</div>
                                                    </Tooltip>;
                                                },

                                                filterMethod: (filter, row) => {
                                                    const settRuoli = _.get(row, "_original.jsonextra.sectorsRoles", []);
                                                    let finded = false
                                                    settRuoli.forEach(settRuolo => {
                                                        const sectorDescription = _.get(settRuolo, "sector.description", "");
                                                        if (sectorDescription.toLowerCase().includes(filter.value.toLowerCase())) {
                                                            finded = true

                                                        }
                                                    })
                                                    return finded
                                                },


                                            },
                                            {
                                                Header: "Ruoli",
                                                id: 'ruoli',
                                                Cell: row => {
                                                    const settRuoli = _.get(row, "original.jsonextra.sectorsRoles", []);

                                                    return <Tooltip
                                                        // options
                                                        theme={'light'}
                                                        position="bottom"
                                                        trigger="mouseenter"
                                                        delay={500}
                                                        interactive
                                                        html={(
                                                            <div className={"sectorsRoles"}>
                                                                <div>{settRuoli.map(settRuolo =>
                                                                    <div>{settRuolo.role && settRuolo.role.description}: {settRuolo.sector && settRuolo.sector.description}</div>)}
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        <div className={"sectorsRolesLabel"}>Elenco</div>
                                                    </Tooltip>;
                                                },

                                                filterMethod: (filter, row) => {
                                                    const settRuoli = _.get(row, "_original.jsonextra.sectorsRoles", []);
                                                    let finded = false
                                                    settRuoli.forEach(settRuolo => {
                                                        const roleDescription = _.get(settRuolo, "role.description", "");
                                                        if (roleDescription.toLowerCase().includes(filter.value.toLowerCase())) {
                                                            finded = true

                                                        }
                                                    })
                                                    return finded
                                                },


                                            },

                                            {
                                                Header: "Superiori",
                                                Cell: row => {
                                                    const superiori = _.get(row, "original.jsonextra.superiori", []);
                                                    return _.map(superiori, (sup) => (sup.jsonextra.cognome.concat(' ', sup.jsonextra.nome))).join(",")
                                                },
                                                show: !this.props.readOnly,
                                            },
                                            {
                                                Header: "Ruolo",
                                                accessor: "role",
                                                show: !this.props.readOnly,
                                            },
                                            {
                                                Header: "Tags",
                                                accessor: "tags",
                                                Cell: row => {
                                                    return _.map(_.get(row, "original.tags", []), (tag) => (tag)).join(",")
                                                },
                                                show: !this.props.readOnly,
                                            },
                                            {
                                                Header: "Cooperativa",
                                                accessor: "coopName"
                                            },
                                            {
                                                Header: "Contratti",
                                                Cell: row => {
                                                    return (
                                                        <div>{row.original.jsonextra.allowedContracts.map(item => item.description).join(",")}</div>
                                                    );
                                                },
                                                show: !this.props.readOnly,
                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                className: 'showDropDown',
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            }, {
                                                Header: "Modifica pianificazione",
                                                Cell: this.actionsPianificazione,
                                                sortable: false,
                                                filterable: false,
                                            }

                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    let readOnly = true;
    let userRole = get(state, "login.user.role", "-");

    if (userRole.toLowerCase() === RUOLO_AMMINISTRATORE || userRole.toLowerCase() === RUOLO_AMMINISTRATORE_COOP) {
        readOnly = false
    }

    const props = {
        data: _.get(state, "raiUtenti.data", []),
        loading: _.get(state, "raiUtenti.loading", false),
        readOnly: readOnly,
    };

    return props;

};

export default connect(mapStateToProps, () => ({}))(UtentiComponent)

