import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, Col} from "reactstrap";
import React, {useState, useEffect} from "react";
import * as Commands from '../redux/analytics.commands'
import {CSSTransition} from "react-transition-group";
import Select from "react-select";
import Datetime from "react-datetime";

export default function UpdateAnalyticsPaymentDateComponent(props) {

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [numeroApplicazione, setNumeroApplicazione] = useState();
    const [numeroFattura, setNumeroFattura] = useState();
    const [statoPagamento, setStatoPagamento] = useState();
    const [invoiceDueDate, setInvoiceDueDate] = useState();
    const [paymentDate, setPaymentDate] = useState();

    const elencoStatiPagamento = [
        {id: "pagato", description: "Pagato"},
        {id: "scaduto", description: "Scaduto"}
    ];

    const ImpostaPagamento = async () => {
        setLoading(true);

        const invoiceDueDateFilled = invoiceDueDate ? invoiceDueDate.getTime() / 1000 : null;
        const paymentDateFilled = paymentDate ? paymentDate.getTime() / 1000 : null;
        const statoPagamentoDescription = statoPagamento ? statoPagamento.description : null;

        var res = await Commands.AggiornaAnalyticsPayments(props.selection, numeroApplicazione, numeroFattura, invoiceDueDateFilled, paymentDateFilled, statoPagamentoDescription);

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        setPaymentDate();
    };

    useEffect(() => {

        if (submitError) {

            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Registra un pagamento</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    ImpostaPagamento();
                }}>

                    <FormGroup className={"row"}>
                        <Col xs={12}><Label for="contratto">Numero applicazione</Label></Col>
                        <Col xs={12}> <Input type="text" value={numeroApplicazione}
                                             onChange={e => setNumeroApplicazione(e.target.value)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup className={"row"}>
                        <Col xs={12}><Label for="contratto">Numero fattura</Label></Col>
                        <Col xs={12}> <Input type="text" value={numeroFattura}
                                             onChange={e => setNumeroFattura(e.target.value)}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Label for="paymentDate">Data scadenza fattura</Label>
                        <Datetime
                            id="scadenzaFatuura"
                            value={invoiceDueDate}
                            closeOnSelect
                            closeOnTab
                            onChange={(dt) => {
                                setInvoiceDueDate(dt.toDate());
                            }}
                            timeFormat={false}
                            inputProps={{placeholder: "Data scadenza fattura"}}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="paymentDate">Data di pagamento</Label>
                        <Datetime
                            id="paymentDate"
                            value={paymentDate}
                            closeOnSelect
                            closeOnTab

                            onChange={(dt) => {
                                setPaymentDate(dt.toDate());
                            }}
                            timeFormat={false}
                            inputProps={{placeholder: "Data di pagamento"}}
                        />
                    </FormGroup>

                    <FormGroup className={"row"}>
                        <Col xs={12}><Label className="col-form-label" style={{fontSize: '14px'}}
                                            for="statoPagamento">Stato pagamento</Label></Col>
                        <Col xs={12}><Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={statoPagamento}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setStatoPagamento(value)
                            }
                            options={[{
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...elencoStatiPagamento]}
                            placeholder="Seleziona un contratto"
                        /></Col>
                    </FormGroup>

                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile impostare le informazioni di
                            pagamento.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={ImpostaPagamento}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Imposta'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
