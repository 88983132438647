import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText, Row, Col} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/indirizzi.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function UpdateAddressComponent(props) {

    const descrizione = TextInputHook(props.description);
    const indirizzo = TextInputHook(props.street);
    const civico = TextInputHook(props.streetNumber);
    const cap = TextInputHook(props.postalCode);
    const citta = TextInputHook(props.city);
    const [contract, setContract] = useState(props.contract);

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const contracts = useSelector(state => state.contract.data);
    const customerId = useSelector(state => state.customer.id);

    const Submit = async () => {
        setLoading(true);
        var res = await Commands.AggiornaIndirizzo(props.id, descrizione.getValue(), indirizzo.getValue(), civico.getValue(),cap.getValue(), citta.getValue(), contract.id, customerId);

        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
        setContract(undefined);
    };


    useEffect(() => {

        if (submitError) {

            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica luogo di lavoro</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    Submit();
                }}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label className={'error'} for="descrizione">Descrizione</Label>
                                <Input
                                    {...descrizione.input}
                                    name="descrizione"
                                    id="descrizione"
                                    placeholder="Inserisci descrizione indirizzo"
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <FormGroup>
                                <Label className={'error'} for="indirizzo">Indirizzo</Label>
                                <Input
                                    {...indirizzo.input}
                                    name="indirizzo"
                                    id="indirizzo"
                                    placeholder="Inserisci indirizzo"
                                />
                            </FormGroup>

                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label for="civico">Civico</Label>
                                <Input
                                    {...civico.input}
                                    name="civico"
                                    id="civico"
                                    placeholder="Inserisci civico"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <Label for="cap">CAP</Label>
                                <Input
                                    {...cap.input}
                                    name="cap"
                                    id="cap"
                                    placeholder="CAP"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={9}>
                            <FormGroup>
                                <Label for="citta">Città</Label>
                                <Input
                                    {...citta.input}
                                    name="citta"
                                    id="citta"
                                    placeholder="Città"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setContract(value)
                            }
                            options={[{
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce l'indirizzo
                        </FormText>
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare la produzione.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={Submit}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
