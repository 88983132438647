import React, {Component} from 'react';
import queryString from 'query-string'
import * as Commands from 'views/rai/ordini/redux/ordini.commands';

class ApprovazioneDaMailComponent extends Component {

    state = {
        error: false,
        errDescr: '',
        loading: true,
        terminated: false,
        totApprovati: 0
    };

    componentDidMount() {
        this.approvaTutto();
    };


    approvaTutto = async () => {
        const queryParams = queryString.parse(this.props.location.search);
        const res = await Commands.ApprovaOrdineDaMail(queryParams.token);

        if (res.error) {
            this.setState({error: true, loading: false, errDescr: res.message})
            return;
        }

        this.setState({
            totApprovati: res.data,
            error: false,
            loading: false,
            terminated: true
        })
    };

    render() {

        if (this.state.error) {
            // You can render any custom fallback UI
            return <h1>C'è stato un errore: {this.state.errDescr}</h1>;
        }

        if (this.state.loading) {
            return (
                <div className={"firmaSms"}>
                    Operazione in corso...
                </div>);
        }

        if (this.state.terminated) {
            return (
                <div className={"firmaSms"}>
                    Procedura terminata: approvati {this.state.totApprovati} ordini
                </div>);
        }



        return (
                null
        );
    }
}

export default ApprovazioneDaMailComponent;
