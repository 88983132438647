import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {TextInputHook} from "hooks/text_input.hook";
import * as Commands from '../redux/studi.commands'
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'
import Select from "react-select";

export default function CreateLotComponent(props) {

    const nome = TextInputHook(props.newName || '');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [contract, setContract] = useState(props.contract || null);
    const [address, setAddress] = useState({});
    const addressList = useSelector(state => state.raiIndirizzi.data);

    const contracts = useSelector(state => state.contract.data);

    const salvaStudio = async () => {
        setLoading(true);
        var res = await Commands.SalvaStudio(nome.getValue(), address, contract.id);
        setLoading(false);

        if (res.hasError) {
            setSubmitError(true);
            return;
        }

        if (props.onSuccess){
            props.onSuccess(res.data);
        }

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        nome.clear();
        setContract(undefined);
        setAddress({});
    }


    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Nuovo studio </h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salvaStudio();
                }}>
                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome studio"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="luogo">Luogo</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={address}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setAddress(value)
                            }
                            options={[  {
                                value: "",
                                description: "Luogo",
                                isDisabled: true
                            }, ...addressList]}
                            placeholder="Seleziona un luogo"
                        />
                    </FormGroup>


                    <FormGroup>
                        <Label for="contratto">Contratto</Label>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={contract}
                            getOptionLabel={(item) => item.description}
                            onChange={value =>
                                setContract(value)
                            }
                            options={[  {
                                value: "",
                                description: "Contratto",
                                isDisabled: true
                            }, ...contracts]}
                            placeholder="Seleziona un contratto"
                        />
                        <FormText color="muted">
                            Contratto a cui si riferisce lo studio
                        </FormText>
                    </FormGroup>


                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare lo studio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salvaStudio}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        :'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
