import gql from "graphql-tag";
import Client from "Apolloclient";
import store from "store/store";
import * as types from './lavoratori.types';
import * as actions from "../../documents/redux/document.actions";


export function InviaSMS(mobNumers, text) {
    const variables = {mobNumers, text};

    const query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation sendMessage (
            $mobNumers: [String!]!
            $text: String!
        ) {
            createdObject:  sendMessage(
                mobNumers:$mobNumers,
                message:$text,
            ) {
                success
                message
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            let data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            return {
                hasError: false
            }
        })
        .catch((e) => {
            let message = 'Impossibile creare documento';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        });
}

export function OttieniLavoratori() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query{

            templates:getAllMetaTemplates {
                metas
                name
            }

            objectList:GetAllWorkers {
                codiceFiscale
                cognome
                cognomeNome
                cooperativaAppartenenza
                dataAssunzione
                dataScadenzaContratto
                dataScadenzaVisitaMedica
                luogoPartenza
                matricola
                nome
                numeroBadge
                oreContratto
                residenza
                telefono
                mansioni
                id
                tags
                metas
                iban
                intestatarioConto
                email
                emailPec
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i lavoratori";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function OttieniLavoratoriValidi() {

    store.dispatch({
        type: types.REQUEST_READ_ALL
    })

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query{

            templates:getAllMetaTemplates {
                metas
                name
            }

            objectList:GetAllWorkersValid {
                codiceFiscale
                cognome
                cognomeNome
                cooperativaAppartenenza
                dataAssunzione
                dataScadenzaContratto
                dataScadenzaVisitaMedica
                luogoPartenza
                matricola
                nome
                numeroBadge
                oreContratto
                residenza
                telefono
                mansioni
                id
                tags
                metas
                iban
                intestatarioConto
                email
                emailPec
            }
        }
        `,
    };
    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;

                store.dispatch(
                    {
                        type: types.READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere i lavoratori";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );
}

export function OttieniAssenze() {

    store.dispatch({
        type: types.ABS_REQUEST_READ_ALL
    });

    var variables = {};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query{

            objectList:getAbsences {
                id
                workerId
                startDate
                endDate
                reason
                name
                flag
            }
        }
        `,
    };

    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;


                store.dispatch(
                    {
                        type: types.ABS_READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere le assenze";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.ABS_READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );


    //return {hasError: true, message: "mock error"};
}

export function ReadAbsencesByPeriod(startDate, endDate) {

    store.dispatch({
        type: types.ABS_REQUEST_READ_ALL
    });

    var variables = {startDate, endDate};

    let query = {
        fetchPolicy: 'network-only',
        variables: variables,
        query: gql`,
        query read(
            $startDate:Int!,
            $endDate:Int!){
            objectList : getAbsencesByPeriod(startDate:$startDate,endDate:$endDate) {
                id
                workerId
                startDate
                endDate
                reason
                name
                flag
            }
        }
        `,
    };

    return Client
        .query(query).then(
            async response => {

                var data = response.data.objectList;


                store.dispatch(
                    {
                        type: types.ABS_READED_ALL,
                        payload: data
                    }
                );

                return;
            }
        ).catch(
            e => {
                var message = "Impossibile ottenere le assenze";
                console.error(e);

                if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                    message = e.graphQLErrors[0].message;
                }

                store.dispatch({
                    type: types.ABS_READ_ALL_ERROR
                });

                return {
                    hasError: true,
                    message: message
                }
            }
        );


    //return {hasError: true, message: "mock error"};
}

export function AggiungiAssenza(workerId, startDate, endDate, reason, flag, name) {

    /*    let startDateCalculated = startDate / 1000 | 0;

        startDateCalculated = startDateCalculated.toString();
        startDate = startDateCalculated;

        let endDateCalculated = endDate / 1000 | 0;

        endDateCalculated = endDateCalculated.toString();
        endDate = endDateCalculated;*/

    store.dispatch({
        type: types.ABS_REQUEST_CREATE
    });

    if (startDate === endDate) {
        endDate += (86399)
    }

    var variables = {workerId, startDate, endDate, reason, flag, name};


    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $workerId: String!
            $startDate: Int!
            $endDate: Int!
            $reason: String!
            $flag: Boolean!
            $name: String!
        ) {
            createdObject: createAbsence (
                workerId: $workerId
                startDate: $startDate
                endDate: $endDate
                reason: $reason
                flag: $flag
                name: $name
            ) {
                id
                workerId
                startDate
                endDate
                reason
                flag
                name
                flag
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;


            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch({
                type: types.ABS_CREATED,
                payload: data
            });

            return {
                hasError: false
            }
        })
        .catch((e) => {

            store.dispatch({
                type: types.ABS_CREATE_ERROR
            });

            var message = 'Impossibile creare l\'assenza';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        })
}

export function EliminaAssenza(id) {


    store.dispatch({
        type: types.ABS_REQUEST_DELETE
    });

    var variables = {id};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation (
            $id: String!
        ) {
            createdObject: deleteAbsence (
                id: $id
            ) {
                id
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.createdObject;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch({
                type: types.ABS_DELETED,
                payload: data
            });

            return {
                hasError: false
            }
        })
        .catch((e) => {
            var message = 'Impossibile eliminare l\'assenza';
            console.error(e);

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        })
}

export function UpdateTags(id, newTags) {

    var variables = {id, newTags};

    let query = {
        fetchPolicy: 'no-cache',
        variables: variables,
        mutation: gql`,
        mutation updateTags($id:String!, $newTags:[String]!){
            worker:updateWorkerTags(id:$id, newTags:$newTags) {
                codiceFiscale
                cognome
                cooperativaAppartenenza
                dataAssunzione
                dataScadenzaContratto
                dataScadenzaVisitaMedica
                luogoPartenza
                matricola
                nome
                numeroBadge
                oreContratto
                residenza
                telefono
                id
                mansioni
                tags
                metas
                iban
                intestatarioConto
                email
                emailPec
            }
        }
        `
    };

    return Client
        .mutate(query)
        .then(async (response) => {
            var data = response.data.worker;

            if (process.env.NODE_ENV === 'development') {
                data = JSON.parse(JSON.stringify(data));
            }

            store.dispatch({
                type: types.UPDATED,
                payload: data
            });

            return {
                hasError: false
            }
        })
        .catch((e) => {


            var message = 'Impossibile aggiornare i tags';

            if (e.graphQLErrors && e.graphQLErrors.length > 0) {
                message = e.graphQLErrors[0].message;
            }

            return {
                hasError: true,
                message: message
            };
        })
}
