import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    UncontrolledCollapse,
    Collapse,
    Col,
    Button
} from "reactstrap";

import Ordine1 from 'assets/img/aiuto/ordini1.png'
import Ordine2 from 'assets/img/aiuto/ordini2.png'
import Ordine3 from 'assets/img/aiuto/ordini3.png'
import Ordine4 from 'assets/img/aiuto/ordini4.png'
import Ordine5 from 'assets/img/aiuto/ordini5n.png'
// import Ordine6 from 'assets/img/aiuto/ordini6.png'
import Ordine7 from 'assets/img/aiuto/ordini7.png'
import Ordine8 from 'assets/img/aiuto/ordini8.png'
import Ordine9 from 'assets/img/aiuto/ordini9.png'

import Analaytics1 from 'assets/img/aiuto/analytics1.png'
import Azioni from 'assets/img/aiuto/azioni.png'

import Studi1 from 'assets/img/aiuto/studi1.png'
import Studi2 from 'assets/img/aiuto/studi2.png'

import Indirizzi1 from 'assets/img/aiuto/indirizzi1.png'
import Indirizzi2 from 'assets/img/aiuto/indirizzi2.png'

import Produzioni1 from 'assets/img/aiuto/produzioni1.png'
import Produzioni2 from 'assets/img/aiuto/produzioni2.png'

import Uorg1 from 'assets/img/aiuto/uorg1.png'
import Uorg2 from 'assets/img/aiuto/uorg2.png'

export default class AiutoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openedCollapses: ["collapseOne"]
        };
    }
    // with this function we create an array with the opened collapses
    // it is like a toggle function for all collapses from this page
    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
        }
    };

    render() {
        return (
            <>
                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{marginBottom:20, display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
                            <iframe
                                    title={"VideoGuida"}
                                    allowfullscreen="allowfullscreen"
                                    mozallowfullscreen="mozallowfullscreen"
                                    msallowfullscreen="msallowfullscreen"
                                    oallowfullscreen="oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen"
                                    width="420" height="315"
                                    src="https://www.youtube.com/embed/id_TzNUXUAg">
                            </iframe>
                        </Col>
                        <Col xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div
                                aria-multiselectable={true}
                                className="card-collapse"
                                id="accordion"
                                role="tablist"
                                style={{width: '100%'}}
                            >
                                <Card className="card-plain">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "ordini"
                                            )}
                                            href="#pablo"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {e.preventDefault();this.collapsesToggle("ordini")}}
                                        >
                                            Ordini{" "}
                                            <i className="tim-icons icon-minimal-down" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={this.state.openedCollapses.includes("ordini")}
                                    >
                                        <CardBody>
                                            <Button color="info" style={{textAlign:'left'}} className={"btn-link w-100"}  id="visualizzazioneOridni">
                                                Visualizzazione
                                            </Button>
                                            <UncontrolledCollapse toggler="#visualizzazioneOridni">
                                                <Card>
                                                    <CardBody>
                                                            La visualizzazione degli ordini si effettua nella seguente schermata <br/>
                                                           <img className={"imgAiuto"} src={Ordine1} alt=""/>

                                                           Di default vengono visualizzati gli ordini del giorno corrente, ma è possibile selezionare il periodo desiderato
                                                           con il seguente filtro <br/>

                                                           <img className={"imgAiuto"} src={Ordine2} alt=""/>

                                                           E' inoltre possibile filtrare gli ordini sulla testata di ogni colonna con selezioni singole o multiple dei valori desiderati <br/>

                                                           <img className={"imgAiuto"} src={Ordine3} alt=""/>
                                                    </CardBody>
                                                </Card>
                                            </UncontrolledCollapse>
                                            <Button color="info" style={{textAlign:'left'}} className={"btn-link w-100"}  id="inserimento">
                                                Inserimento
                                            </Button>
                                            <UncontrolledCollapse toggler="#inserimento">
                                                <Card>
                                                    <CardBody>
                                                        Per inserire un nuovo ordine premere sul pulsante <a
                                                        href="./">Nuovo ordine</a>: apparirà la seguente schermata <br/>
                                                        <img className={"imgAiuto"} src={Ordine5} alt=""/>

                                                        E' presente un calendario dove si possono selezionare più date: in questo modo si possono creare più ordini identici ( luogo, produzione etc. ) in date diverse
                                                        <img className={"imgAiuto"} src={Ordine7} alt=""/>

                                                        <b>Nota bene:</b>
                                                        <br/>
                                                        Non è possibile inserire ordini nel passato.
                                                        <br/>
                                                        Non è possibile inserire ordini che non hanno almeno 60 minuti di preavviso ( dalla data odierna ).
                                                        <br/>
                                                        <blockquote className="blockquote">

                                                        I campi con con l'asterisco (*) sono obbligatori.
                                                        </blockquote>
                                                    </CardBody>
                                                </Card>
                                            </UncontrolledCollapse>
                                            <Button color="info" style={{textAlign:'left'}} className={"btn-link w-100"}  id="approvazione">
                                                Approvazione
                                            </Button>
                                            <UncontrolledCollapse toggler="#approvazione">
                                                <Card>
                                                    <CardBody>
                                                        Un ordine può essere approvato solo se è in stato <b>CREATO</b> e si riferisce al <b>MESE CORRENTE</b>
                                                        <br/>
                                                        Gli ordini che si riferiscono al mese successivo ( quindi ordini programmati ) possono essere approvati solo tramite mail dagli utenti con i ruoli necessari
                                                        <br/>
                                                        Per approvare un ordine cliccare sulla colonna azioni e cliccare il pulsante <b>Approva</b>
                                                        <br/>
                                                        <img className={"imgAiuto"} src={Ordine4} alt=""/>
                                                    </CardBody>
                                                </Card>
                                            </UncontrolledCollapse>
                                            <Button color="info" style={{textAlign:'left'}} className={"btn-link w-100"}  id="eliminazione">
                                                Eliminazione
                                            </Button>
                                            <UncontrolledCollapse toggler="#eliminazione">
                                                <Card>
                                                    <CardBody>

                                                        Per eliminare un ordine cliccare sulla colonna azioni e cliccare il pulsante <b>Elimina</b>
                                                        <br/>
                                                        <img className={"imgAiuto"} src={Ordine4} alt=""/>
                                                        Se un ordine è <b>in corso</b> oppure <b>firmato</b> non può essere eliminato.
                                                        <br/>
                                                        Un ordine può essere eliminato fino a <b>60 minuti</b> prima dell'inizio del servizio
                                                    </CardBody>
                                                </Card>
                                            </UncontrolledCollapse>
                                            <Button color="info" style={{textAlign:'left'}} className={"btn-link w-100"}  id="firma">
                                                Firma
                                            </Button>
                                            <UncontrolledCollapse toggler="#firma">
                                                <Card>
                                                    <CardBody>
                                                       La firma dell'ordine verrà effettuata sul dispositivo mobile del personale associato allo statino.
                                                        <br/>
                                                        <img className={"imgAiuto"} src={Ordine8} alt=""/>

                                                        Prima della firma è possibile modificare gli orari a uno e/o tutti i lavoratori associati al servizio.
                                                        <img className={"imgAiuto"} src={Ordine9} alt=""/>
                                                        In caso di modifica verrano creati autoaticamente dal sistema tutti gli straordinari effettuati.
                                                        <br/>
                                                        A seguito della firma verrà inviata una mail con tutte le informazioni inserite durante la procedura di firma ( firma allegata alla mail )
                                                    </CardBody>
                                                </Card>
                                            </UncontrolledCollapse>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card className="card-plain">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "analytics"
                                            )}
                                            href="#analytics"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {e.preventDefault();this.collapsesToggle("analytics")}}
                                        >
                                            Analytics{" "}
                                            <i className="tim-icons icon-minimal-down" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={this.state.openedCollapses.includes("analytics")}
                                    >
                                        <CardBody>

                                            La visualizzazione delle analytics si effettua nella seguente schermata <br/>

                                            <img className={"imgAiuto"} src={Analaytics1} alt=""/>

                                            Di default vengono visualizzati le analytics del giorno corrente, ma è possibile selezionare il periodo desiderato
                                            con il seguente filtro <br/>

                                            <img className={"imgAiuto"} src={Ordine2} alt=""/>

                                            E' inoltre possibile filtrare le analytics sulla testata di ogni colonna con selezioni singole o multiple dei valori desiderati <br/>

                                            <img className={"imgAiuto"} src={Ordine3} alt=""/>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card className="card-plain">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "Studi"
                                            )}
                                            href="#Studi"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {e.preventDefault();this.collapsesToggle("Studi")}}
                                        >
                                            Studi{" "}
                                            <i className="tim-icons icon-minimal-down" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={this.state.openedCollapses.includes("Studi")}
                                    >
                                        <CardBody>
                                            La visualizzazione degli studi si può effettuare nella seguente schermata.
                                            <img className={"imgAiuto"} src={Studi1} alt=""/>

                                            Per inserire un nuovo studio premere il pulsante <b>Nuovo studio</b>: appararirà la seguente schermata.
                                            <img className={"imgAiuto"} src={Studi2} alt=""/>

                                            Per modificare e/o eliminare uno studio premere sulla relativa icona nella colonna azioni.
                                            <img className={"imgAiuto"} src={Azioni} alt=""/>


                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card className="card-plain">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "Uorg"
                                            )}
                                            href="#Uorg"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {e.preventDefault();this.collapsesToggle("Uorg")}}
                                        >
                                            Uorg{" "}
                                            <i className="tim-icons icon-minimal-down" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={this.state.openedCollapses.includes("Uorg")}
                                    >
                                        <CardBody>
                                            La visualizzazione degli uorg si può effettuare nella seguente schermata.
                                            <img className={"imgAiuto"} src={Uorg1} alt=""/>

                                            Per inserire un nuovo uorg premere il pulsante <b>Nuovo uorg</b>: appararirà la seguente schermata.
                                            <img className={"imgAiuto"} src={Uorg2} alt=""/>

                                            Per modificare e/o eliminare un uorg premere sulla relativa icona nella colonna azioni.
                                            <img className={"imgAiuto"} src={Azioni} alt=""/>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card className="card-plain">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "Indirizzi"
                                            )}
                                            href="#Studi"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {e.preventDefault();this.collapsesToggle("Indirizzi")}}
                                        >
                                            Indirizzi{" "}
                                            <i className="tim-icons icon-minimal-down" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={this.state.openedCollapses.includes("Indirizzi")}
                                    >
                                        <CardBody>
                                            La visualizzazione degli indirizzi si può effettuare nella seguente schermata.
                                            <img className={"imgAiuto"} src={Indirizzi1} alt=""/>

                                            Per inserire un nuovo indirizzo premere il pulsante <b>Nuovo luogo di lavoro</b>: appararirà la seguente schermata.
                                            <img className={"imgAiuto"} src={Indirizzi2} alt=""/>

                                            Per modificare e/o eliminare uno indirizzo premere sulla relativa icona nella colonna azioni.
                                            <img className={"imgAiuto"} src={Azioni} alt=""/>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card className="card-plain">
                                    <CardHeader role="tab">
                                        <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "Produzioni"
                                            )}
                                            href="#Studi"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {e.preventDefault();this.collapsesToggle("Produzioni")}}
                                        >
                                            Produzioni{" "}
                                            <i className="tim-icons icon-minimal-down" />
                                        </a>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={this.state.openedCollapses.includes("Produzioni")}
                                    >
                                        <CardBody>
                                            La visualizzazione delle produzioni si può effettuare nella seguente schermata.
                                            <img className={"imgAiuto"} src={Produzioni1} alt=""/>

                                            Per inserire una nuova produzione premere il pulsante <b>Nuova produzione</b>: appararirà la seguente schermata.
                                            <img className={"imgAiuto"} src={Produzioni2} alt=""/>

                                            Per modificare e/o eliminare una produzione premere sulla relativa icona nella colonna azioni.
                                            <img className={"imgAiuto"} src={Azioni} alt=""/>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
