import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {NumberInputHook} from "hooks/number_input.hook";
import {TextInputHook} from "hooks/text_input.hook";
import {CSSTransition} from "react-transition-group";
import {useSelector} from 'react-redux'

export default function UpdateProductionComponent(props) {


    const nome = TextInputHook(props.name);
    const descrizione = TextInputHook(props.description);
    const matricola = NumberInputHook(props.number);
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    useSelector(state => state.customer.id);
    const Submit = async () => {
        setLoading(true);

        /* var res = await Commands.AggiornaProduzione(props.id,nome.getValue(), matricola.getValue(), descrizione.getValue(), customerId);

         setLoading(false);

         if (res.hasError) {
             setSubmitError(true);
             return;
         }*/

        clearForm();
        props.toggle();
    };

    const clearForm = () => {
        descrizione.clear();
        nome.clear();
        matricola.clear();
    }


    useEffect(() => {

        if (submitError) {

            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Modifica produzione</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    Submit();
                }}>
                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome produzione"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="descrizione">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserisci descrizione"
                        />
                        <FormText color="muted">
                            La descrizione è facoltativa.
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="matricola">Matricola</Label>
                        <Input
                            {...matricola.input}
                            name="matricola"
                            id="matricola"
                            placeholder="Inserisci matricola"
                        />
                        <FormText color="muted">
                            Codice alfanumerico
                        </FormText>
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{display: 'none'}} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Non è stato possibile salvare la produzione.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={Submit}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}
