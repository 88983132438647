import React, {Component} from 'react';
import store from 'store/store.js';
import ReactTable from "react-table";
import gql from "graphql-tag";
import Client from "Apolloclient";

export default class Assistance extends Component {

    state = {
        data: []
    }



    viewAss = (roomName) => {
        store.dispatch({
            type: 'ANSWER_ASSISTANCE',
            payload: {
                roomName
            }
        });
    };

    componentDidMount() {
        this.caricaDati();
        const intervalloAggiornamentoDati = setInterval(this.caricaDati, 2 * 1000);

        this.setState({
            intervalloAggiornamentoDati,
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalloAggiornamentoDati);
    }

    caricaDati = () =>  {
        let query = {
            fetchPolicy: 'network-only',
            query: gql`,
     {
            objectList: assistanceRooms {
                    roomName
                  }
            }
        `,
        };
        return Client
            .query(query).then(
                async response => {

                    var data = response.data.objectList;
                    this.setState({data});
                    return;
                }
            ).catch(
                e => {
                    console.error(e);
                }
            );
    }

    render() {
        return (
            <div className="content">
                <div id="peerDiv"></div>
                <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                        {
                            Header: "Nome",
                            accessor: "roomName"
                        }
                    ]}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    nextText={'Avanti'}
                    previousText={'Indietro'}
                    pageText={'Pagina'}
                    ofText={'di'}
                    rowsText={'elementi'}
                    noDataText={'Nessuna richiesta di assistenza in corso'}
                    loadingText={'Caricamento in corso'}
                    loading={false}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: async (e, handleOriginal) => {
                                if (!rowInfo || !rowInfo.original) return;
                                this.viewAss(rowInfo.original.roomName);
                                alert('Inizio sincronizzazione');
                            }
                        }
                    }}
                />
            </div>
        )
    }

}
