import * as types from "./analytics.types";

const initialState = {
    data: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST_READ_ALL:
            state = {
                ...state,
                loading: true
            }
            break;
        case types.READ_ALL_ERROR: {
            state = {
                ...state,
                loading: false,
                data:  []
            }
            break
        }
        case types.READED_ALL:
            state = {
                ...state,
                data: action.payload,
                loading: false
            }
            break;
        case types.REQUEST_UPDATE:
            state = {
                ...state,
                loading: true
            }
            break;
        case types.UPDATED: {
            let data = [...state.data];
            if (data) {
                for (let i = 0; i < data.length; i++)
                    if (data[i].id === action.payload.id) {
                        data[i] = action.payload;
                        break;
                    }
            }
            state = {
                ...state,
                data:data
            };
            break;
        }
        default:
            break;

    }

    return state;
}

export default reducer;
