/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";

// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <div className="copyright">
            © {new Date().getFullYear()} Omnia creato da {" "}
            <a href="www.testudosrl.com" target="_blank">
              Testudo s.r.l.
            </a>{" "}
            - Versione {process.env.REACT_APP_VERSION}
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
