import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import React from "react";


export default function CreateLotComponent(props) {

    return (
        <Modal
            size="lg"            modalClassName="modal-black"
            isOpen={props.show}
            toggle={props.toggle}
        >

            <div className="modal-header assegnazioni justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Ricerca lavoratore disponibile</h6>
            </div>

            <ModalBody>

            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    );

}
