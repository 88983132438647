import React, {Component} from 'react';
import {Col, FormGroup, Input, Label, ModalBody} from "reactstrap";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import _ from 'lodash';
import Form from "reactstrap/es/Form";
import DayPicker from "react-day-picker";
import 'moment/locale/it';
import moment from 'moment';

const inputExample = {
    "Importo": {
        label: "Importo 1",
        type: "numeric",
        placeholder: "Inserisci valore"
    }, "Importo float": {
        type: "float",
        placeholder: "Inserisci valore"
    },
    "Data esecuzione": {
        type: "unix",
        initialValue: 0
    },
    "Convalidato": {
        type: "bool",
        initialValue: true
    },
    "Periodo": {
        type: "mese",
        initialValue: true
    }
};

// testo
// data
// mese - periodo
// numero
// float
// booleano

class FormBuilder extends Component {
    // Read form component

    constructor(props) {
        super(props);

        let state = {
            fields: {}
        };

        Object.keys(this.props.config).map(key => state.fields[key] = {
            type: this.props.config[key].type,
            value: this.props.config[key].initialValue,
            initialValue: this.props.config[key].initialValue
        });

        this.state = state;
        this.updateFieldValue = this.updateFieldValue.bind(this);
        this.renderInputEl = this.renderInputEl.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.config){
            let fields = {};

            Object.keys(nextProps.config).map(key => fields[key] = {
                type: nextProps.config[key].type,
                value: nextProps.config[key].initialValue,
                initialValue: nextProps.config[key].initialValue
            });

            this.setState({...this.state, fields})

        }
    }

    updateFieldValue(fieldKey, newValue) {
        console.log({fieldKey, newValue});
      this.setState({
            fields: {
                ...this.state.fields,
                [fieldKey]: {
                    value: newValue,
                    type: this.state.fields[fieldKey].type
                }
            }
        });
    };

    getFieldValue(key) {
        let value = _.get(this.state, 'fields.' + key + ".value", undefined);
        return value;
    }

    getFieldInitialValue(key) {
        let value = _.get(this.state, 'fields.' + key + ".initialValue", undefined);
        return value;
    }

    getFormValues() {
        return this.state.fields;
    }

    renderInputEl(key, fieldDefinition) {
        let label = key;

        if (fieldDefinition.label) {
            label = fieldDefinition.label;
        }

        let type = "text";

        switch (fieldDefinition.type) {
            case 'text':
                return <FormGroup>
                    <Label for={key}>{label}</Label>
                    <Input
                        type={"text"}
                        name={key}
                        value={this.getFieldValue(key)}
                        id={key}
                        onChange={e => this.updateFieldValue(key, e.target.value)}
                        placeholder={fieldDefinition.placeholder}
                    /></FormGroup>;
            case 'numeric':
                return <FormGroup>
                    <Label for={key}>{label}</Label> <Input
                    type={"number"}
                    name={key}
                    value={this.getFieldValue(key)}
                    id={key}
                    onChange={e => this.updateFieldValue(key, e.target.valueAsNumber)}
                    placeholder={fieldDefinition.placeholder}
                /></FormGroup>;
            case 'float':
                return (
                    <FormGroup>
                        <Label
                            for={key}>{label} ({this.getFieldValue(key) && Number(this.getFieldValue(key)).toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })})</Label>
                        <Input
                            type={"number"}
                            step="0.01" min="0"
                            name={key}
                            value={this.getFieldValue(key)}
                            id={key}
                            onChange={e => this.updateFieldValue(key,  e.target.valueAsNumber)}
                            placeholder={fieldDefinition.placeholder}
                        /> </FormGroup>);
            case 'unix':
                return <FormGroup style={{display: "flex", flexDirection: "column"}}>
                    <Label for={key}>{label}</Label>
                    <DayPickerInput
                        locale="it"
                        inputProps={{
                            className: "form-control"
                        }}
                        formatDate={(value, extra) => {
                            return moment(value).format("L")
                        }}
                        value={this.getFieldValue(key) ? moment.unix(this.getFieldValue(key)).toDate() : undefined}
                        todayButton='Oggi'
                        placeholder={`${formatDate(new Date(), 'L', 'it')}`}
                        localeUtils={MomentLocaleUtils}
                        classNames={'sparkleDayPicker'}
                        onDayChange={(day) => {
                            this.updateFieldValue(key, moment(day).unix())
                        }}/>
                </FormGroup>;

            case 'currency':
                return (
                    <FormGroup>
                        <Label
                            for={key}>{label} ({this.state.fields[key] && this.getFieldValue(key) && Number(this.getFieldValue(key)).toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} €)</Label>
                        <Input
                            type={"number"}
                            step="0.01" min="0"
                            name={key}
                            value={this.getFieldValue(key)}
                            id={key}
                            onChange={e => this.updateFieldValue(key, e.target.valueAsNumber)}
                            placeholder={fieldDefinition.placeholder}
                        /> </FormGroup>);
            case 'bool':
                return (
                    <FormGroup>
                        <Label for={key}>{label}</Label>
                        <Input checked={this.getFieldValue(key)}
                               onChange={() => this.updateFieldValue(key, !this.getFieldValue(key))}
                               type="checkbox"/>
                    </FormGroup>
                );
            default:
                return (
                    <div>Field type not recognised: {fieldDefinition.type}</div>
                );
        }
    }

    render() {


        return (
            <form onSubmit={e => {
                e.preventDefault();
            }}>
                {
                    Object.keys(this.props.config).map(key => this.renderInputEl(key, this.props.config[key]))
                }
            </form>
        );
    }
}

export default FormBuilder;
