import React,{useState} from 'react';
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Row,
  Container,
  Form, Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

// For auto registry purpose
import 'views/pages/Login/redux/login.reducer';

import * as Commands from 'views/pages/Login/redux/login.commands';
import {TextInputHook} from "hooks/text_input.hook";
import {
  RUOLO_FUNZIONARIO_RAI,
  RUOLO_OPERATORE_SAP_RAI, RUOLO_REFERENTE_SAP_RAI,
  RUOLO_RICHIEDENTE_RAI,
  RUOLO_SUPERVISORE_RAI
} from "../../rai/utenti/components/costants";

export default function Login(props){

  const username = TextInputHook('');
  const password = TextInputHook('');
  const [loading, setLoading] = useState(false);

  const loginFunc = async (e) => {

    if (e)e.preventDefault();

    setLoading(true);
    var res = await Commands.login(username.getValue(), password.getValue());
    setLoading(false);

    if (res.error){
      return;
    }

    switch (res.data.user.role){
      case RUOLO_RICHIEDENTE_RAI:
        props.history.push("/admin/ordini");
        break;
      case RUOLO_SUPERVISORE_RAI:
        props.history.push("/admin/analitycs");
        break;
      case RUOLO_OPERATORE_SAP_RAI:
        props.history.push("/admin/analitycs");
        break;
      case RUOLO_FUNZIONARIO_RAI:
        props.history.push("/admin/analitycs");
        break;
      case RUOLO_REFERENTE_SAP_RAI:
        props.history.push("/admin/analitycs");
        break;
      default:
        props.history.push("/");
        break
    };

  };

  return (
      <div className="content">
        <Container>
          <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <h1 className="logoText">Omnia</h1>
          </Col>
          </Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={(e) => {
              e.preventDefault();
              loginFunc();
            }}>
              <button style={{display: 'none'}} type="submit"/>

              <Card className="card-login card-white">
                <CardHeader style={{height: "200px"}}>
                  <img
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                  />
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input {...username.input} placeholder="Email" type="text" />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input  placeholder="Password" type="password" {...password.input} />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                      block
                      className="mb-3"
                      color="primary"
                      href="#pablo"
                      onClick={loginFunc}
                      active={!loading}
                      size="lg"
                  >
                      {
                          loading ?
                    <span>
                             <i className="fa fa-spin fa-spinner"/> Attendi...
                    </span>
                       : "Login"
                      }
                  </Button>
                  <div className="pull-right">
                    <h6>
                      <a className="link footer-link"
                          href="#passwordDimenticata"
                          onClick={e => e.preventDefault()}>
                        Password dimenticata
                      </a>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
  );
}

