import React, {Component} from 'react';
import queryString from 'query-string'
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import moment from 'moment';
import {Button} from "reactstrap";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import * as Commands from 'views/rai/ordini/redux/ordini.commands';
import * as CommandsAss from 'views/rai/ordini/redux/assegnazioni.commands';
import {Alert} from "reactstrap";

class AccettazioneSmsComponent extends Component {
    state = {
        error: false,
        errDescr: '',
        loading: true,
        assegnazione: {},
        terminated: false
    };

    componentDidMount() {
        this.caricaAssegnazione();
    };

    toggle = (firmato) => {
        this.setState({firmato});
    };

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({error: true, errDescr: error});
    }

    caricaAssegnazione = async () => {
        const queryParams = queryString.parse(this.props.location.search);
        const res = await Commands.OttieniAssegnazioneDaSms(queryParams.token);

        if (res.hasError) {
            this.setState({error: true, loading: false, errDescr: res.message})
            return;
        }

        this.setState({
            assegnazione: res.data,
            error: false,
            loading: false
        })
    };

    rifiuta = async () => {
        const doAction = await ShowConfirmationDialog({
            title: "Rifiuto incarico",
            text: "Inserisci sotto il motivo per il quale stai rifiutando il servizio",
            confirmBtnText: 'Si',
            canEscapeKeyCancel: true,
            input: true,
            placeholder: 'Motivo del rifiuto',
            validationMsg: 'Devi inserire un motivo valido!'
        });

        if (!doAction.confirm) return;
        const motivoRifiuto = doAction.response;

        const res = await CommandsAss.RifiutaAssegnazione(this.state.assegnazione.id, this.state.assegnazione.workerID, this.state.assegnazione.jsonWorker.telefono, navigator.userAgent, motivoRifiuto);
        if (res.hasError) {
            ShowConfirmationDialog({
                title: "Errore durante rifiuto incarico",
                text: res.message,
                confirmBtnText: 'Si',
                canEscapeKeyCancel: true
            });
            return;
        };

        ShowConfirmationDialog({
            title: "Servizio rifiutato con successo",
            confirmBtnText: 'Ok',
            showCancel: false,
            canEscapeKeyCancel: true
        });

        this.setState({terminated: true});
        return;
    };

    accetta = async () => {
        const doAction = await ShowConfirmationDialog({
            title: "Accettazione incarico",
            confirmBtnText: 'Si',
            canEscapeKeyCancel: true,
        });

        if (!doAction.confirm) return;
        const res = await CommandsAss.AccettaAssegnazione(this.state.assegnazione.id, this.state.assegnazione.workerID, this.state.assegnazione.jsonWorker.telefono, navigator.userAgent);
        if (res.hasError) {
            ShowConfirmationDialog({
                title: "Errore durante l'accettazione dell'incarico",
                text: res.message,
                confirmBtnText: 'Ok',
                showCancel: false,
                canEscapeKeyCancel: true
            });
            return;
        }
        ;

        ShowConfirmationDialog({
            title: "Servizio accettato con successo",
            confirmBtnText: 'Ok',
            showCancel: false,
            canEscapeKeyCancel: true
        });
        this.setState({terminated: true});

        return;
    };


    render() {

        if (this.state.error) {
            // You can render any custom fallback UI
            return <h1>C'è stato un errore: {this.state.errDescr}</h1>;
        }

        if (this.state.loading) {
            return (
                <div className={"firmaSms"}>
                    Caricamento delle informazioni in corso...
                </div>);
        }

        if (this.state.terminated) {
            return (
                <div className={"firmaSms"}>
                    Procedura terminata
                </div>);
        }

        return (

            <div style={{padding: "30px"}}>
                <Row><h2>Nuovo incarico</h2></Row>
                <Row><p>Data: <b>{moment.unix(this.state.assegnazione.startTime).format("DD/MM/YYYY")}</b></p></Row>
                <Row>
                    <p>Orario: <b>{moment.unix(this.state.assegnazione.startTime).format(" HH:mm")} alle {moment.unix(this.state.assegnazione.endTime).format("HH:mm")}</b>
                    </p></Row>
                <Row><p>Produzione: <b>{this.state.assegnazione.jsonExtra.jsonExtraPeriod.production.name}</b></p></Row>
                <Row><p>Luogo: <b>{this.state.assegnazione.jsonExtra.jsonExtraPeriod.workPlace.description}</b></p>
                </Row> {/* TODO: Mettere formattedAddress al posto di description*/}
                <Row><p>Note: <b>{this.state.assegnazione.order.notes}</b></p></Row>

                {this.state.assegnazione.notes && this.state.assegnazione.notes.length > 0 ?
                    <Alert style={{marginTop: 10}}
                           color="default">Comunicazione: {this.state.assegnazione.notes}</Alert>
                    : null}

                {this.state.assegnazione && this.state.assegnazione.acceptedOn > 0 ?
                    <div className={"firmaSms"}>Incarico già assegnato.
                    </div>
                    :

                    <Row>
                        <Col sm={12}><Button
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            block
                            onClick={this.rifiuta}
                        >
                            Rifiuta
                        </Button></Col>
                        <Col sm={12}>
                            <Button
                                block
                                color="primary"
                                type="button"
                                onClick={this.accetta}
                            >
                                Accetta
                            </Button></Col>
                    </Row>
                }
            </div>
        );
    }
}

export default AccettazioneSmsComponent;
